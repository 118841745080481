<template>
  <div class="container">
    <div class="title">{{ $t("store.store") }}</div>
    <div class="linkbox">
      <router-link class="linkitem" to="/w/home/store/index">
        <i class="iconfont icon-tuding"></i>
        {{ $t("store.gift") }}
      </router-link>
      <router-link class="linkitem" to="/w/home/store/mine">
        <i class="iconfont icon-dongtai2"></i>
        {{ $t("store.receive") }}
      </router-link>
    </div>
    <div class="flexbox-s storecontent">
      <div class="flexbox giftitembox">
        <div
          class="flexbox giftitem"
          v-for="(item, index) of mine"
          :key="index"
        >
          <div
            class="img"
            :style="{ backgroundImage: `url(${imgurl + item.path})` }"
          ></div>
          <div class="flexbox giftname">
            {{ $t(`gift.type${item.cid}gift${item.gid}`) }}
          </div>
          <div class="flexbox giftsender">
            <span class="sender">{{ item.givername }}</span>
            {{ $t("store.send") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  computed: {
    mine () {
      return this.$store.state.Store.mine;
    }
  },
  created () {
    this.$store.dispatch('getStoreMine');
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 1000px;
  padding: 100px 30px;
  .title {
    font-size: 20px;
    color: $themeColor;
    margin-bottom: 28px;
    font-weight: bold;
  }
  .linkbox {
    .linkitem {
      padding: 10px 10px;
      background-color: #fff;
      color: $themeColor;
      background-color: transparent;
    }
    .router-link-active {
      background-color: rgb(180, 83, 77);
    }
  }
  .storecontent {
    padding: 10px;
    padding-right: 0;
    background-color: #fff;
    margin-top: 30px;
    font-size: 14px;
    .giftitembox {
      justify-content: flex-start;
      color: $fontColor;
      padding: 10px 50px;
      .giftitem {
        width: 80px;
        height: auto;
        margin: 15px;
        .img {
          width: 80px;
          height: 80px;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
        }
        .giftname {
          min-height: 30px;
          text-align: center;
        }
        .giftsender {
          height: 25px;
          .sender {
            padding: 0 5px;
            color: $linkColor;
          }
        }
      }
    }
  }
}
</style>