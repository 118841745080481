<template>
  <div
    class="expresscontent"
    v-html="content"
    :class="{
      singleline: singleLine,
    }"
  ></div>
</template>

<script>
import { expressionSource, expressionReg } from "../util/expression"
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    fontSize: {
      type: String,
      default: '14px',
    },
    singleLine: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    content () {
      if (!this.text) {
        return "";
      }
      return this.text.replace(expressionReg, ($) => {
        let key = $.substring(1, $.length - 1);
        let imgsrc = expressionSource[key];
        return `<img 
        src="${imgsrc}" 
        style="width: 24px;height: 24px;"
        ></img>`
      })
    },
  },
  mounted () {

  }
}
</script>

<style scoped>
.expresscontent {
  width: 100%;
  height: auto;
  vertical-align: baseline;
}
.singleline {
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>