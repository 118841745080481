import Home from "../views/mobile/Home";
import Login from "../views/mobile/Login";
import Forgotpassword from "../views/mobile/Forgotpassword";
import Register from "../views/mobile/Register";
import Agreement from "../views/mobile/Agreement";
import RegistCode from "../views/mobile/RegistCode";

import My from "../views/mobile/My";
import MyIndex from "../views/mobile/my/Index";

import Invest from "../views/mobile/home/Invest.vue";
import InvestIndex from "../views/mobile/home/Invest/index.vue";
import InvestList from "../views/mobile/home/Invest/list.vue";
import Upgrade from "../views/mobile/home/Upgrade.vue";
import UpgradeIndex from "../views/mobile/home/Upgrade/index.vue";
import UpgradeList from "../views/mobile/home/Upgrade/list.vue";
import UpgradeInfo from "../views/mobile/home/Upgrade/info.vue";

import Setup from "../views/mobile/home/Setup.vue";
import SetupIndex from "../views/mobile/home/Setup/index.vue";
import SetupData from "../views/mobile/home/Setup/data.vue";
import SetupChangebrith from "../views/mobile/home/Setup/changebrith.vue";
import SetupChangegender from "../views/mobile/home/Setup/changegender.vue";
import SetupChangesignature from "../views/mobile/home/Setup/changesignature.vue";
import SetupLang from "../views/mobile/home/Setup/lang.vue";
import SetupChangepwd from "../views/mobile/home/Setup/changepwd.vue";

import Index from "../views/mobile/home/Index.vue";
import MyNewsfeed from "../views/mobile/my/Newsfeed";

import Chat from "../views/mobile/home/Chat.vue";
import ChatIndex from "../views/mobile/home/Chat/Index.vue";
import ChatList from "../views/mobile/home/Chat/List.vue";
import ChatService from "../views/mobile/home/Chat/Service.vue";

import Message from "../views/mobile/home/Message.vue";
import Email from "../views/mobile/home/Email.vue";
import MessageList from "../views/mobile/home/Message/List.vue";
import MessageSend from "../views/mobile/home/Message/Send.vue";

import Trend from "../views/mobile/home/Trend.vue";
import TrendList from "../views/mobile/home/Trend/Trend.vue";
import TrendNew from "../views/mobile/home/Trend/New.vue";

import Gift from "../views/mobile/home/Gift";
import GiftList from "../views/mobile/home/Gift/List.vue";
import GiftListBox from "../views/mobile/home/Gift/Listbox.vue";
import GiftSend from "../views/mobile/home/Gift/Send.vue";

const mobileRouter = [
  {
    path: "home",
    name: "mHome",
    component: Home,
    redirect: "/m/home/index",
    meta: { requireLogin: true },
    children: [
      {
        path: "index",
        component: Index,
        meta: {},
      },
      {
        path: "invest",
        component: Invest,
        meta: {},
        redirect: "/m/home/invest/index",
        children: [
          {
            path: "index",
            component: InvestIndex,
            meta: {},
          },
          {
            path: "list",
            name: "investlist",
            component: InvestList,
            meta: {},
          },
        ],
      },
      {
        path: "upgrade",
        component: Upgrade,
        meta: {},
        redirect: "/m/home/upgrade/index",
        children: [
          {
            path: "index",
            component: UpgradeIndex,
            meta: {},
          },
          {
            path: "list",
            name: "upgradelist",
            component: UpgradeList,
            meta: {},
          },
          {
            path: "info",
            name: "investinfo",
            component: UpgradeInfo,
            meta: {},
          },
        ],
      },
      {
        path: "My",
        component: My,
        // meta: { requireDoAllreadyLoginAction: true },
        redirect: "/m/home/my/index",
        children: [
          {
            path: "index",
            component: MyIndex,
            props: true,
          },
          {
            path: "newsfeed/:id",
            component: MyNewsfeed,
            meta: {},
            props: true,
          },
          {
            path: "setup",
            component: Setup,
            meta: {},
            redirect: "/m/home/my/setup/index",
            children: [
              {
                path: "index",
                component: SetupIndex,
                meta: {},
              },
              {
                path: "data",
                component: SetupData,
                meta: {},
              },
              {
                path: "changebrith",
                component: SetupChangebrith,
                meta: {},
              },
              {
                path: "changegender",
                component: SetupChangegender,
                meta: {},
              },
              {
                path: "changesignature",
                component: SetupChangesignature,
                meta: {},
              },
              {
                path: "lang",
                component: SetupLang,
                meta: {},
              },
              {
                path: "changepwd",
                component: SetupChangepwd,
                meta: {},
              },
            ],
          },
        ],
      },
      {
        path: "chat",
        component: Chat,
        meta: {},
        redirect: "/m/home/chat/list",
        children: [
          {
            path: "list",
            component: ChatList,
            meta: {},
          },
          {
            path: "index",
            component: ChatIndex,
            meta: {},
          },
          {
            path: "service",
            component: ChatService,
            meta: {},
          },
        ],
      },
      {
        path: "message",
        component: Message,
        meta: {},
      },
      {
        path: "email",
        component: Email,
        meta: {},
        redirect: "/m/home/email/send",
        children: [
          {
            path: "list",
            component: MessageList,
            meta: {},
          },
          {
            path: "send",
            component: MessageSend,
            meta: {},
          },
        ],
      },
      {
        path: "trend",
        component: Trend,
        meta: {},
        redirect: "/m/home/trend/list",
        children: [
          {
            path: "list",
            component: TrendList,
            meta: {},
          },
          {
            path: "new",
            component: TrendNew,
            meta: {},
          },
        ],
      },
      {
        path: "gift",
        component: Gift,
        redirect: "/m/home/gift/list",
        meta: {},
        children: [
          {
            path: "list",
            component: GiftList,
            meta: {},
          },
		  {
            path: "listbox",
            component: GiftListBox,
            meta: {},
          },
          {
            path: "send/:id",
            component: GiftSend,
            meta: {},
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: "login",
    name: "mLogin",
    component: Login,
    meta: { requireDoAllreadyLoginAction: true },
  },
  {
    path: "Forgotpassword",
    name: "m-forgotpassword",
    component: Forgotpassword,
    meta: { requireDoAllreadyLoginAction: true },
  },
  {
    path: "Register",
    name: "mregister",
    component: Register,
    meta: { requireDoAllreadyLoginAction: true },
  },
  {
    path: "Agreement",
    name: "m-agreement",
    component: Agreement,
    meta: { requireDoAllreadyLoginAction: true },
  },
  {
    path: "regist/:code",
    component: RegistCode,
    meta: { title: "signup", requireDoAllreadyLoginAction: true },
    props: true,
  },
];

export default mobileRouter;
