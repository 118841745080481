<template>
  <div class="giftcontainer">
    <van-tabs v-model:active="gift.active" swipeable animated>
      <van-tab
        v-for="(type, index) of giftlist"
        :key="index"
        :title="$t(`gift.type${type.id}`)"
      >
        <!-- 礼物列表 -->
        <div class="flexbox giftitembox" v-if="giftlist.length > 0">
          <div
            class="flexbox giftitem"
            v-for="(item, index) of type.gift"
            :key="index"
          >
            <div
              class="img"
              :style="{ backgroundImage: `url(${imgurl + item.path})` }"
            ></div>
            <div class="flexbox giftname">
              {{ $t(`gift.type${type.id}gift${item.id}`) }}
            </div>
            <div class="flexbox giftprice">
              {{ item.money }} {{ $t("store.credits") }}
            </div>
            <router-link
              :to="'/m/home/gift/send/' + item.id"
              class="flexbox sendbtn"
            >
              {{ $t("store.sendgift") }}
            </router-link>
          </div>
        </div>
      </van-tab>
    </van-tabs>

    <div class="receivebox">
      <div class="title">My Received</div>
      <div class="flexbox giftitembox">
        <div
          class="flexbox giftitem"
          v-for="(item, index) of mine"
          :key="index"
        >
          <div
            class="img"
            :style="{ backgroundImage: `url(${imgurl + item.path})` }"
          ></div>
          <div class="flexbox giftname">
            {{ $t(`gift.type${item.cid}gift${item.gid}`) }}
          </div>
          <div class="flexbox giftprice" style="color: #4af">
            {{ item.givername }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      gift: {
        showgift: false,
        active: '',
        showsend: false,
        sendnum: 1,
        sendid: 0,
      },
    }
  },
  computed: {
    giftlist () {
      return this.$store.state.Store.storeList;
    },
    mine () {
      return this.$store.state.Store.mine;
    },
  },
  created () {
    this.$store.dispatch('getStoreMine');
  }
}
</script>

<style lang="scss" scoped>
.giftcontainer {
  width: 100%;
  height: auto;
  .giftitembox {
    justify-content: flex-start;
    padding: 10px;
    .giftitem {
      width: 20%;
      height: auto;
      align-content: space-between;
      font-size: 12px;
      margin: 2.5%;
      .img {
        width: 100%;
        padding: 50%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
      .giftname {
        height: 20px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #333;
      }
      .giftprice {
        height: 20px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: "#999";
      }
      .sendbtn {
        padding: 0 5px;
        height: 20px;
        border: 1px solid #ff4a53;
        color: #ff4a53;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }
}
.receivebox {
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    padding-left: 10px;
  }
}
</style>