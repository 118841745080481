import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store/index";
import i18n from "../i18n/index";
import util from "../util/util";
import { ElMessage } from "element-plus";

import Index from "../views/Index";
import mIndex from "../views/mobile/Index";
import wIndex from "../views/web/Index";
import Invitation from "../views/invitation";
import mobileRouter from "./mobile.router";
import webRouter from "./web.router";

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
    redirect: redirectTerminal(),
    children: [
      {
        path: "m",
        redirect: "/m/home",
        component: mIndex,
        children: mobileRouter,
        meta: {},
      },
      {
        path: "w",
        redirect: "/w/home",
        component: wIndex,
        children: webRouter,
      },
      {
        path: "invitation/:code",
        component: Invitation,
        meta: { requireLogin: false, requireDoAllreadyLoginAction: true },
        props: true,
      },
    ],
  },

  // {
  //   path: "*",
  //   conponent: Index,
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  let pre = util.judgeTerminal() ? "m" : "w";
  if (new RegExp(`(\/${pre}\/)|(invest)`).test(to.path)) {
    if (to.meta.requireLogin && !store.state.User.isLogin) {
      next({ name: pre + "Login" });
    } else if (
      to.meta.requireDoAllreadyLoginAction &&
      store.state.User.isLogin
    ) {
      next({ name: pre + "Home" });
    } else {
      if (to.meta.permission) {
        if (util.checkPermision(to.meta.permission)) {
          next();
        } else { 
          ElMessage.error(i18n.global.t("tips.nopermission")+'!');
          next("/");
        }
      } else {
        next();
      }
    }
  } else {
    next(); //Mr.x
  }
});

router.afterEach((to, from) => {
  if (to.meta.title) {
    document.title = i18n.global.t("title." + to.meta.title);
  } else {
    document.title = i18n.global.t("title.defaulttitle");
  }
});

/**
 * 根据终端跳转对应路由
 * @returns
 */
function redirectTerminal() {
  if (util.judgeTerminal()) {
    return "/m";
  } else {
    return "/w";
  }
}

export default router;
