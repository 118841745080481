<template>
  <div class="flexbox container">
    <div class="flexbox head">
      <div class="menu">
        <Navigator :signin="false" />
      </div>
    </div>
    <div class="flexbox body">
      <h2 class="title">{{ websitname }}</h2>
      <div class="flexbox form">
        <div class="loginbody">
          <div class="formtitle">
            <i class="iconfont icon-wenjian"></i>
            {{ $t("login.signinp") }}
          </div>
          <div class="inputitem">
            <el-input
              :placeholder="$i18n.t('login.username')"
              prefix-icon="el-icon-user"
              v-model="username"
              @keydown.enter="login"
              autofocus
            >
            </el-input>
          </div>
          <div class="inputitem">
            <el-input
              :placeholder="$i18n.t('login.pwd')"
              prefix-icon="el-icon-lock"
              v-model="password"
              type="password"
              autocomplete="off"
              @keydown.enter="login"
            ></el-input>
          </div>
          <div class="flexbox inputitem">
            <el-checkbox v-model="remenberme">
              {{ $t("login.remember") }}
            </el-checkbox>
            <router-link class="forgot" to="/w/forgot-password">
              {{ $t("login.forgot") }}
            </router-link>
          </div>
          <div class="flexbox inputitem">
            <div class="flexbox loginbtn" @click="login">
              <div>{{ $t("login.signin") }}</div>
              |
              <i class="iconfont icon-tubiaozhizuomoban"></i>
            </div>
            <router-link class="forgot" to="/w/regist">{{
              $t("login.join")
            }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="flexbox footer">
      <LoginFooter />
    </div>
  </div>
</template>

<script>
import { websitname } from "../../common/project.config"
import LoginFooter from "../../components/web/LoginFooter"
import Navigator from "../../components/web/Navigator"
import { ElMessage } from 'element-plus'
export default {
  data () {
    return {
      websitname: websitname,
      username: localStorage.getItem('loginformusername') || "",
      password: localStorage.getItem('loginformpassword') || "",
      remenberme: true,
    }
  },
  methods: {
    verify () {
      if (this.username.trim() == "") {
        ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.username'));
        return false;
      } else if (!/[0-9a-zA-Z\u4e00-\u9fa5]{1,12}/.test(this.username.trim())) {
        ElMessage.error(this.$t('regist.username') + ' ' + this.$t('regist.form.fomate'));
        return false;
      } else if (this.password.trim() == "") {
        ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.pwd'));
        return false;
      }
      return true;
    },
    login () {
      if (this.verify()) {
        this.axios.post_form("/member/index/login", {
          account: this.username,
          password: this.password,
        }).then(res => {
          res = res.data;
          if (res.status == 200) {
            ElMessage.success({
              message: this.$t('tips.loginsuccess'),
              type: 'success'
            });
            if (this.remenberme) {
              localStorage.setItem("loginformusername", this.username);
              localStorage.setItem("loginformpassword", this.password);
            } else {
              localStorage.setItem("loginformusername", "");
              localStorage.setItem("loginformpassword", "");
            }
            this.$store.commit('login', res.data, this.remenberme);
            this.$store.dispatch("updateUserAuthority");
            this.$router.push({ path: '/' })
          } else if (res.status == -3) {
            ElMessage.error(this.$t('tips.aproval'));
          } else {
            ElMessage.error(res.msg);
          }
        })
      }
    },
  },


  components: {
    LoginFooter,
    Navigator
  },

}
</script>

<style lang="scss" scoped>
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #333;
}
.container {
  height: 100vh;
  background-image: url(~@/assets/images/promo2.jpg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  flex-direction: column;
  flex-wrap: nowrap;
  .head {
    position: relative;
    height: 80px;
    .menu {
      width: auto;
      height: auto;
      position: fixed;
      right: 0px;
      top: 0px;
    }
  }
  .body {
    width: 400px;
    height: 100%;
    .title {
      width: 100%;
      font-size: 28px;
      line-height: 36px;
      margin: 0 8px 10px 8px;
      padding: 0 40px;
      color: $themeColor;
      font-family: "open_sanssemibold", Tahoma, Geneva, sans-serif;
    }
    .form {
      width: 400px;
      margin: 8px;
      padding: 40px;
      background: rgba(137, 122, 166, 0.4);
      border-radius: 5px;
      box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
      border: none;
      .loginbody {
        width: 320px;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 6px;
        .formtitle {
          padding: 10px;
          height: auto;
          font-size: 16px;
          overflow: hidden;
          font-weight: normal;
          color: $themeColor;
          i {
            margin-right: 10px;
          }
        }
        .inputitem {
          width: 100%;
          padding: 8px 20px;
          justify-content: space-between;
          .el-checkbox {
            color: #333;
          }
          .is-checked {
            .el-checkbox__label {
              color: #333 !important;
            }
          }
          .loginbtn {
            width: 100px;
            height: 32px;
            background-color: #e8cbed;
            padding: 0px 8px;
            border: 1px solid rgba(93, 69, 110, 0.6);
            border-radius: 4px;
            color: $themeColor;
            font-size: 14px;
            justify-content: space-between;
            cursor: pointer;
            .icon-tubiaozhizuomoban {
              font-size: 20px;
              color: $themeColor;
            }
          }
          .forgot {
            font-size: 14px;
            color: #7ce6fe;
            cursor: pointer;
          }
          .forgot:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .footer {
    height: 150px;
    flex-shrink: 0;
  }
}
</style>