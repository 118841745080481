<template>
  <div v-if="userstatus == 2">
    <div style="height: 20px; width: 100%"></div>
    <div class="flexbox alert">
      <i class="iconfont icon-warning"></i>
      <div class="flexbox text">{{ $t("tips.aproval") }}</div>
    </div>

    <div class="flexbox logout" @click="logout">
      {{ $t("navigator.signout") }}
    </div>
  </div>
  <div v-else>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data () {
    return {
      msg: 'Mobile Home',
    }
  },
  computed: {
    username () {
      return this.$store.state.User.username;
    },
    userstatus () {
      return this.$store.state.User.status;
    },
  },
  methods: {
    logout () {
      this.$store.commit("logout");
      this.$store.commit('closeWebsocket');
      let pre = this.util.judgeTerminal() ? "m" : "w";
      this.$router.push({ name: pre + "Login" });
    },
  },
  created () {
    this.$store.dispatch("getStoreList");
    this.$store.dispatch('initWebsocket');
    this.$store.dispatch('getMessage');
  },
}
</script>

<style lang="scss" scoped>
.alert {
  width: 80%;
  margin: 0 auto;
  flex-wrap: nowrap;
  padding: 10px 30px;
  border: 1px solid #f66;
  border-radius: 10px;
  i {
    font-size: 20px;
    flex-shrink: 0;
    color: #f66;
  }
  .text {
    width: 100%;
    color: #f66;
  }
}

.logout {
  position: fixed;
  width: 80%;
  height: 50px;
  font-size: 20px;
  background-color: #f66;
  color: #fff;
  border-radius: 10px;
  bottom: 50px;
  left: 10%;
}
</style>