<template>
  <div class="container">
    <div class="title">{{ $t("home.chat") }}</div>
    <div class="flexbox chatwindow">
      <!-- 聊天列表 -->
      <div class="flexbox-c chatlist">
        <div class="searchbox">
          <el-input
            v-model="searchcontent"
            :placeholder="$t('global.search')"
          ></el-input>
        </div>
        <div class="empty" v-if="chatContentList.length <= 0">
          {{ $t("empty.chat") }}
        </div>
        <el-scrollbar noresize v-else height="540px">
          <div class="flexbox">
            <div
              class="flexbox chatlistitem"
              v-for="(item, index) of chatContentList"
              :key="index"
              @click="setCur(item.uid)"
              :style="{
                backgroundColor:
                  curItem.uid == item.uid
                    ? 'rgba(255,255,255,0.3)'
                    : 'rgba(255,255,255,0.2)',
              }"
            >
              <div class="flexbox chaticon">
                <i class="iconfont icon-liaotian"></i>
              </div>
              <el-badge
                :value="item.count"
                class="item"
                type="danger"
                :hidden="item.count <= 0"
              >
                <router-link
                  class="userheader"
                  :to="`/w/home/user/detail/${item.uid}`"
                  @click.stop
                >
                  <img :src="imgurl + item.avatar" />
				  <img class="vip" src="@/assets/images/icon-dimand.png" v-if="item.vip == 1"/>
				  <img class="vip" src="@/assets/images/icon-king.png" v-if="item.vip == 2"/>
                </router-link>
              </el-badge>
              <div class="flexbox chatcontent">
                <div class="flexbox nameline">
                  <div class="name">
                    <span
                      class="online"
                      :style="{
                        backgroundColor: item.is_online == 1 ? '#9f9' : '#999',
                      }"
                    ></span>

                    {{ item.username }}
                  </div>
                  <div class="time">
                    {{
                      util.getTrendtimeByDate(new Date(item.create_time * 1000))
                    }}
                  </div>
                </div>
                <div class="flexbox contentline">
                  <Expression :text="item.content" :singleLine="true" />
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>

      <!-- 聊天窗口 -->
      <div class="flexbox-c chatcontent">
        <div class="empty" style="margin-top: 161px" v-if="!curItem.id"></div>
        <div class="flexbox chatcontentbody" v-else>
          <!-- 头部 -->
          <div class="flexbox chatheader">
            <router-link
              class="userheader"
              :to="`/w/home/user/detail/${curItem.uid}`"
              @click.stop
            >
              <img :src="imgurl + curItem.avatar" />
			  <img class="vip" src="@/assets/images/icon-dimand.png" v-if="curItem.vip == 1"/>
			  <img class="vip" src="@/assets/images/icon-king.png" v-if="curItem.vip == 2"/>
            </router-link>
            <div class="flexbox username">
              {{ curItem.username }}
            </div>
            <div class="optionbox">
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                @click="deleteuser"
              ></el-button>
            </div>
          </div>
          <!-- 聊天内容 -->
          <el-scrollbar noresize @scroll="chatwindowscrollevent">
            <div class="flexbox history" @click="queryHistory">
              {{ $t("chat.queryhistory") }}
            </div>

            <div class="flexbox chatbody" v-if="curItem">
              <chat-content
                v-for="(item, index) of curItem.list"
                :key="index + ' ' + item.content"
                :data="item"
              />
            </div>
          </el-scrollbar>

          <!-- 输入框 -->
          <div class="flexbox-s chatinputbox" v-if="true">
            <el-input v-model="chatcontent" @keydown.enter="send"></el-input>
            <div class="flexbox opratebox">
			  <!-- 礼物按钮 -->
              <div class="flexbox iconbox" @click="gift.showgift = true">
                <i class="iconfont icon-liwu" style="font-size: 20px"></i>
              </div>
              <!-- 翻译按钮 -->
              <div class="flexbox iconbox" @click.stop="showtrans = true">
                <i class="iconfont icon-zhuanhuan" style="font-size: 20px"></i>
              </div>
              <!-- 图片按钮 -->
              <div class="flexbox iconbox" @click="$refs.fileinput.click()">
                <i class="iconfont icon-tupian" style="font-size: 20px"></i>
              </div>
              <!-- 图片文件 -->
              <input
                type="file"
                accept="image/*"
                v-show="false"
                @change="fileChange"
                ref="fileinput"
              />
              <!-- 表情按钮 -->
              <div class="flexbox iconbox" @click.stop="showexpress = true">
                <i
                  class="iconfont icon-biaoqing"
                  style="color: #eedd55; font-size: 20px"
                ></i>
              </div>
              <!-- 发送按钮 -->
              <div class="flexbox sendbtn" @click="send">
                {{ $t("global.send") }}
              </div>
            </div>
            <!-- 表情框 -->
            <div class="flexbox expressbox">
              <img
                class="img"
                v-for="(item, index) of expressionStrList"
                :key="index"
                :src="expressionSource[item]"
                @click.stop="pushexp(item)"
                v-show="showexpress"
              />
            </div>
            <!-- 翻译框 -->
            <div class="flexbox transbox" v-show="showtrans" @click.stop>
              <Trans :id="curItem.uid" height="100px" />
            </div>
			<!-- 礼物面板 -->
			  <el-dialog :title="$t('mine.giftbox')" v-model="gift.showgift">
				<div class="giftcontainer">
				  <van-tabs v-model:active="gift.active" >
					<van-tab
					  v-for="(type, index) of giftlist"
					  :key="index"
					  :title="$t(`gift.type${type.id}`)"
					>
					  <!-- 礼物列表 -->
					  <div class="flexbox giftitembox" v-if="giftlist.length > 0">
						<div
						  class="flexbox giftitem"
						  v-for="(item, index) of type.gift"
						  :key="index"
						>
						  <div
							class="img"
							:style="{ backgroundImage: `url(${imgurl + item.path})` }"
						  ></div>
						  <div class="flexbox giftname">
							{{ $t(`gift.type${type.id}gift${item.id}`) }}
						  </div>
						  <div class="flexbox giftprice">
							{{ item.money }} {{ $t("store.credits") }}
						  </div>
						  <div class="flexbox sendbtn" @click="sendlaunch(item)">
							{{ $t("store.sendgift") }}
						  </div>
						</div>
					  </div>
					</van-tab>
				  </van-tabs>
				</div>
			  </el-dialog>
          </div>
          <!-- 没有权限 -->
          <div class="flexbox notice" v-else>
            <i class="iconfont icon-warning"></i>
            <div class="flexbox noticedetail">
              余额不足，请到
              <router-link class="link" to="/w/home/upgrade">
                {{ $t("home.upgrade") }}
              </router-link>
              或
              <router-link class="link" to="/w/home/pay">
                {{ $t("home.recharge") }}
              </router-link>
              继续聊天对话框.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-show="false">{{ curItemlist }}</div>
  
  <!-- 赠送礼物弹窗 -->
    <el-dialog :title="$t('store.sendgift')" v-model="sendwindowvisibility">
      <el-form :model="form">
	    <div class="details">
			<div class="dleft">
				<img :src="form.pic" alt="" />
			</div>
			<div class="dright">
				<div class="gtitle">{{ $t("globalgift.giftname") }}：{{ form.gtitle }}</div>
				<p class="ginfo">{{ $t("globalgift.giftinfo") }}：{{ form.ginfo }}</p>
				<p class="gprice">{{ $t("globalgift.giftprice") }}：{{ form.gprice}} {{ $t("store.credits") }}</p>
				<p class="ginfo">{{ $t("globalgift.sendto") }}：{{ form.name }}</p>
				<p class="ginfo">
					<el-button @click="sendwindowvisibility = false">
						{{ $t("global.cancel") }}
					</el-button>
					<el-button type="primary" @click="sendGift">
						{{ $t("global.send") }}
					</el-button>
				</p>
			</div>
		</div>
      </el-form>
    </el-dialog>
</template>


<script>
import ChatContent from "../../../components/ChatContent.vue"
import Expression from "../../../components/Expression.vue"
import Trans from "../../../components/Trans.vue"
import { expressionSource, expressionStrList } from "../../../util/expression"
export default {
  data () {
    return {
      searchcontent: '',
      // 输入内容
      chatcontent: '',
      // 表情数据
      expressionStrList,
      expressionSource,
      showexpress: false,

      lastScrollPos: 0,
      scrollAffix: false,

      showtrans: false,
	  gift: {
        showgift: false,
        active: '',
        showsend: false,
        sendnum: 1,
        sendid: 0,
      },
	  myvip:0,
	  giftid: '',
	  sendwindowvisibility: false,
      form: {
	    pic: '',
		gtitle:'',
		ginfo:'',
		gprice:'',
        name: '',
        message: ''
      },
    }
  },
  components: {
    ChatContent,
    Expression,
    Trans,
  },
  computed: {
    chatContentList () {
      let reg = new RegExp(`(${this.searchcontent})`, 'g');
      return this.$store.state.Chat.chatList.filter((item) => {
        return reg.test(item.username);
      });
    },
    curItem () {
      return this.$store.state.Chat.curUser;
    },
    curItemlist () {
      if (!this.scrollAffix) {
        setTimeout(() => {
          let view_container = document.querySelector('.chatcontent .el-scrollbar__wrap');
          if (view_container) {
            view_container.scrollTo(0, view_container.scrollHeight - view_container.clientHeight);
          }
        })
      }
      return this.$store.state.Chat.curUser.list ? this.$store.state.Chat.curUser.list.length : 0;
    },
	// 礼物列表
    giftlist () {
      return this.$store.state.Store.storeList;
    },
  },
  methods: {
    setCur (id) {
	  this.myvip = localStorage.getItem("currentVip");
	  if(this.myvip == 0 && id != 2){
		this.$message.success(this.$t('tips.nopermission')+', '+this.$t('tips.needupgrade')+'~~');
		setTimeout(() => {
			this.$router.push('/w/home/upgrade')
		},1500);
		return;
	  }
      this.$store.commit('setCur', id);
      this.$store.dispatch('wssend', {
        opt: 0,
        uid: this.$store.state.User.id,
        address: id,
        origin: true,
      });
      this.$store.commit('clearcount', id);
    },
    // 发送图片
    fileChange (e) {
	  if(this.myvip == 1){
		this.$message.success(this.$t('tips.nopermission')+', '+this.$t('tips.needupgrade')+'.');
		setTimeout(() => {
			this.$router.push('/w/home/upgrade')
		},1500);
		return;
	  }
	
      e.preventDefault();
      if (this.loading !== 1) {
        let files = e.target.files || e.dataTransfer.files;
        this.sourceImgUrl = files[0];
        this.axios.post_form("/member/index/uploads", {
          file: this.sourceImgUrl
        }).then(res => {
          res = res.data;
          if (res.status == 200) {
            this.$store.dispatch('wssend', {
              opt: 1,
              uid: this.$store.state.User.id,
              address: this.curItem.uid,
              img: res.data.url,
              type: 'img',
              origin: true,
            })
          }
        })
      }
    },
    // 插入表情
    pushexp (item) {
      this.chatcontent += "[" + item + "]";
    },
    // 发送聊天信息
    send () {
	  this.myvip = localStorage.getItem("currentVip");
	  if(this.myvip == 0 && this.curItem.uid != 2){
		this.$message.success(this.$t('tips.nopermission')+', '+this.$t('tips.needupgrade')+'~~');
		setTimeout(() => {
			this.$router.push('/w/home/upgrade')
		},1500);
		return;
	  }
	
      if (this.chatcontent.trim() == "") {
        this.$message.error(this.$t('tips.contentcantempty'));
        return false;
      }
      this.$store.dispatch('wssend', {
        opt: 1,
        uid: this.$store.state.User.id,
        address: this.curItem.uid,
        txt: this.chatcontent,
        type: 'text',
        origin: true,
      })
      this.chatcontent = "";
    },
    queryHistory () {
      this.$store.dispatch('wssend', {
        opt: 3,
        uid: this.$store.state.User.id,
        address: this.curItem.uid,
        origin: true,
        create_time: this.curItem.list.length > 0 ? this.curItem.list[0].create_time : Math.floor(new Date().getTime / 1000)
      })
    },
    deleteuser () {
      this.$store.dispatch('wssend', {
        opt: 5,
        uid: this.$store.state.User.id,
        address: this.curItem.uid,
        origin: true,
      })
    },
    chatwindowscrollevent () {
      let view_container = document.querySelector('.chatcontent .el-scrollbar__wrap');
      if (view_container.scrollHeight - 20 < view_container.scrollTop + view_container.clientHeight) {
        this.scrollAffix = false;
      } else {
        this.scrollAffix = true;
      }
    },
	sendlaunch (item) {
	  console.log(item)
      this.giftid = item.id;
      this.sendwindowvisibility = true;
	  this.form.pic = this.imgurl + item.path
	  this.form.gtitle = this.$t(`gift.type${item.classify}gift${item.id}`)
	  this.form.ginfo = this.$t(`gift.type${item.classify}giftinfo${item.id}`)
	  this.form.name = this.curItem.username
	  this.form.gprice = item.money;
    },
	
	sendGift () {
	  this.axios.post_form("/member/gift/gift_giving", {
        username: this.form.name,
        message: this.form.message,
        number: 1,
        giftid: this.giftid,
      }).then(res => {
	    this.sendwindowvisibility = false;
        res = res.data;
        if (res.status == 200) {
          this.$message.success({
            message: this.$t('tips.sendsuccess'),
            type: 'success'
          });
		  this.gift.showgift = false;
        } else if (res.status == -1) {
          this.$message.error(this.$t('tips.usernotexist'));
        } else if (res.status == -2) {
          this.$message.error(res.msg);
        } else {
          this.$message.error(this.$t('tips.sendfail'));
        }
      })
      
    },
  },
  created () {
    document.onclick = () => {
      this.showexpress = false;
      this.showtrans = false;
    }
    if (!this.curItem.uid) {
      if (this.chatContentList.length > 0) {
        this.$store.commit('setCur', this.chatContentList[0].uid)
      }
    }
	
	//查看是否有权限
	//this.axios.post_form("/member/index/info", {}).then((res) => {
	//	res = res.data;
	//	if (res.status == 200) {
	//		this.myvip = res.data.vip;
	//	}
	//});
	this.myvip = localStorage.getItem("currentVip");
  },
  beforeUnmount () {
    document.onclick = null;
    this.$store.commit('setCur', -1);
  },
}
</script>

<style lang="scss" scoped>
$boderColor: rgba(
  $color: #fff,
  $alpha: 0.3,
);
$border: 1px solid $boderColor;
.container {
  width: 1000px;
  padding: 100px 30px;
  .title {
    font-size: 20px;
    color: $themeColor;
    margin-bottom: 28px;
    font-weight: bold;
  }
}
.chatwindow {
  width: 940px;
  height: 600px;
  background-color: rgba($color: #fff, $alpha: 0.2);
  border: $border;
  border-radius: 4px;
  overflow: hidden;
  flex-wrap: nowrap;
  .chatlist {
    width: 320px;
    height: 600px;
    border-right: $border;
    .searchbox {
      width: 100%;
      padding: 10px;
      border-bottom: $border;
    }
    .chatlistitem {
      width: 100%;
      height: 65px;
      background-color: rgba($color: #fff, $alpha: 0.2);
      border-bottom: 1px solid rgba($color: #fff, $alpha: 0.3);
      flex-wrap: nowrap;
      cursor: pointer;
      .chaticon {
        width: 30px;
        height: 100%;
        color: #fff;
        flex-shrink: 0;
      }
      .userheader {
        display: block;
        position: relative;
        width: 50px;
        height: 50px;
        position: relative;
        flex-shrink: 0;
        border: 5px solid rgb(206, 191, 210);
        border-radius: 4px;
        overflow: hidden;
        img {
          width: 40px;
          height: 40px;
        }
        .vip {
          position: absolute;
          right: 0px;
          bottom: -2px;
          color: gold;
          font-size: 16px;
		  width:16px;
		  height:16px;
        }
      }
      .chatcontent {
        width: 235px;
        height: 100%;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 14px;
        .nameline {
          height: 20px;
          justify-content: space-between;
          flex-wrap: nowrap;
          .name {
            color: $linkColor;
            font-weight: bold;
            .online {
              display: inline-block;
              width: 5px;
              height: 5px;
              border-radius: 50%;
            }
          }
          .time {
            color: #fff;
          }
        }
        .contentline {
          height: 24px;
          justify-content: flex-start;
          color: #fff;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .chatcontent {
    width: 620px;
    height: 600px;
    .chatcontentbody {
      width: 620px;
      height: 600px;
      flex-direction: column;
      flex-wrap: nowrap;
      .chatheader {
        height: 61px;
        border-bottom: $border;
        padding: 0 15px;
        flex-wrap: nowrap;
        flex-shrink: 0;
        .userheader {
          position: relative;
          width: 50px;
          height: 50px;
          position: relative;
          flex-shrink: 0;
          border: 5px solid rgb(206, 191, 210);
          border-radius: 4px;
          overflow: hidden;
          img {
            width: 40px;
            height: 40px;
          }
          .vip {
            position: absolute;
            right: 0px;
            bottom: -2px;
            color: gold;
            font-size: 16px;
			width: 16px;
            height: 16px;
          }
        }
        .username {
          font-size: 19px;
          font-weight: bold;
          color: #fff;
          justify-content: flex-start;
          padding-left: 10px;
        }
      }
      .history {
        font-size: 14px;
        color: #fff;
        cursor: pointer;
      }
      .chatbody {
        width: 620px;
        height: 100%;
        overflow: auto;
      }
      .chatinputbox {
        height: auto;
        flex-shrink: 0;
        border-top: $border;
        .opratebox {
          justify-content: flex-end;
          padding: 5px;
          .iconbox {
            width: 30px;
            height: 30px;
            cursor: pointer;
            margin-right: 10px;
          }
          .sendbtn {
            width: auto;
            height: 30px;
            padding: 0 8px;
            background-color: $themeColor;
            border-radius: 3px;
            border: 1px solid #333;
            color: #fff;
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
      .notice {
        height: 40px;
        font-size: 14px;
        color: $themeColor;
        border-radius: 8px;
        background-color: rgba(255, 226, 171, 0.6);
        flex-shrink: 0;
        flex-wrap: nowrap;
        padding: 15px;
        margin-bottom: 20px;
        .noticedetail {
          .link {
            color: $linkColor;
            cursor: pointer;
          }
          .link:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .empty {
    color: #fff;
    font-size: 14px;
    margin-top: 100px;
  }
  .expressbox {
    width: 100%;
    height: auto;
    background-color: #fff;
    justify-content: flex-start;
    align-content: flex-start;
    overflow: hidden;
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}
.giftcontainer {
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  .giftitembox {
    justify-content: flex-start;
    color: $fontColor;
    padding: 30px;

    .giftitem {
      width: 15%;
      height: auto;
      align-content: space-between;
      font-size: 12px;
      margin: 2.5%;
      .img {
        width: 100%;
        padding: 50%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
      .giftname {
        height: 20px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .giftprice {
        height: 20px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .sendbtn {
        padding: 0 5px;
        height: 20px;
        border: 1px solid #ff4a53;
        color: #ff4a53;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }
}

.details{
	display: flex;
	flex-wrap: wrap;
	.dleft{
		width:40%;
		padding: 0 0 20px 20px;
		img{
			width:100%;
		}
	}
	.dright{
		width: 55%;
		margin-left: 5%;
	}
}
</style>