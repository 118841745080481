<template>
  <div class="details">
	<div class="dleft">
		<img :src="imgurl + gifts.path" alt="" />
	</div>
	<div class="dright">
		<div class="gtitle">{{ $t(`gift.type${gifts.classify}gift${gifts.id}`) }}</div>
		<p class="ginfo">{{ $t(`gift.type${gifts.classify}giftinfo${gifts.id}`) }}</p>
		<p class="gprice">{{ $t("globalgift.giftprice") }}：{{ gifts.money}} {{ $t("store.credits") }}</p>
	</div>
  </div>
  <div class="inputitem">
    <input
      type="text"
      class="username"
      v-model="username"
      :placeholder="$t('store.username')"
    />
  </div>
  <div class="inputitem">
    <textarea
      v-model="content"
      rows="8"
      class="content"
      :placeholder="$t('store.msg')"
    ></textarea>
  </div>
  <div class="flexbox send" @click="sendgift">{{ $t("global.send") }}</div>
</template>

<script>
export default {
  props: ['id'],
  data () {
    return {
      username: '',
      content: '',
	  gifts:'',
    }
  },
  methods: {
    sendgift () {
      this.axios.post_form("/member/gift/gift_giving", {
        username: this.username,
        message: this.content,
        number: 1,
        giftid: this.id,
      }).then(res => {
        res = res.data;
        if (res.status == 200) {
          this.$message.success({
            message: this.$t('tips.sendsuccess'),
            type: 'success'
          });
          history.back();
        } else if (res.status == -1) {
          this.$message.error(this.$t('tips.usernotexist'));
        } else if (res.status == -2) {
          this.$message.error(res.msg);
        } else {
          this.$message.error(this.$t('tips.sendfail'));
        }
      })
    },
  },
  created () {
	
	//查看是否有权限
	this.axios.post_form("/member/shop/detail", {id:this.id}).then((res) => {
		res = res.data;
		if (res.status == 200) {
			this.gifts = res.data;
		}
	});
  },
}
</script>

<style lang="scss" scoped>
.inputitem {
  margin: 10px 0;
  padding: 10px;
}
.username,
.content {
  border: 0;
  outline: 0;
  resize: none;
  width: 100%;
  background: #f1f2f3;
}
.username {
  height: 40px;
  font-size: 16px;
  border-radius: 20px;
  padding: 0 20px;
}
.content {
  border-radius: 10px;
  padding: 10px;
}
.send {
  width: 70%;
  position: fixed;
  bottom: 20px;
  left: 15%;
  height: 40px;
  background-color: #f43;
  color: #fff;
  border-radius: 20px;
}
.details{
	display: flex;
	flex-wrap: wrap;
	margin-top:20px;
	.dleft{
		width:40%;
		padding: 0 0 20px 20px;
		img{
			width:100%;
		}
	}
	.dright{
		width: 55%;
		margin-left: 5%;
	}
}
</style>