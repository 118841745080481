<template>
  <div class="container">
    <div class="title">{{ $t("member.browseuser") }}</div>
    <div class="linkbox">
      <router-link class="linkitem" to="/w/home/member/online">
        <i class="iconfont icon-tuding"></i>
        {{ $t("member.online") }}
      </router-link>
      <router-link class="linkitem" to="/w/home/member/latest">
        <i class="iconfont icon-dongtai2"></i>
        {{ $t("member.latest") }}
      </router-link>
      <router-link class="linkitem" to="/w/home/member/search">
        <i class="iconfont icon-dashujukeshihuaico-"></i>
        {{ $t("member.search") }}
      </router-link>
    </div>
    <div class="flexbox-s membercontent">
      <div v-for="(item, index) of data" :key="index" class="flexbox card">
        <div class="flexbox-s usermsg">
          <div class="flexbox userheader">
            <router-link tag="div" :to="'/w/home/user/detail/' + item.id">
              <img :src="imgurl + item.avatar" alt="" />
            </router-link>
            <i
              class="iconfont icon-V vip"
              :style="{ color: item.vip == 1 ? 'silver' : 'gold' }"
              v-if="item.vip == 1 || item.vip == 2"
            ></i>
          </div>
          <div class="userother">
            <router-link :to="'/w/home/user/detail/' + item.id">
              <div class="username">{{ item.username }}</div>
            </router-link>
            <div class="userstatus" v-if="item.is_online != 1">
              <span class="offline"></span>
              {{ $t("chat.active") }}：{{
                util.getTrendtimeByDate(new Date(item.last_login_time * 1000))
              }}
            </div>
            <div class="flexbox online" v-else>
              <span></span>
              <div class="btn" @click="addUserToChatList(item)">
                {{ $t("member.chatnow") }}~
              </div>
            </div>
          </div>
        </div>
        <div class="flexbox usermenu">
          <div class="btn" @click="follow(item, index)">
            {{
              item.is_like == 0
                ? $t("member.follow")
                : $t("member.cancelfollow")
            }}
          </div>
          <div @click="sendgift(item)" class="btn">
            {{ $t("member.sendgift") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'

export default {
  data () {
    return {
    }
  },
  computed: {
    data () {
      return this.$store.state.Member.latestList;
    }
  },
  methods: {
    initData () {
      this.$store.dispatch('getLatestList');
    },
    follow (item) {
      this.axios.post_form('/member/like/like', { target_id: item.id }).then(res => {
        res = res.data;
        if (res.status == 200) {
          if (item.is_like == 0) {
            item.is_like = 1;
            this.$message.success(this.$t('tips.followsuccess'));
          } else {
            item.is_like = 0;
            this.$message.success(this.$t('tips.followcancel'));
          }
        }
        this.$store.dispatch('getLatestList');
      })
    },
    addUserToChatList (data) {
      this.$store.commit('addUserToGlobalChatListFromUser', data);
    },
    sendgift (user) {
      this.$store.commit('setGlobalGiftUser', user);
      this.$store.commit('showGlobalgift');
    }
  },
  created () {
    this.initData();
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 1000px;
  padding: 100px 30px;
  .title {
    font-size: 20px;
    color: $themeColor;
    margin-bottom: 28px;
    font-weight: bold;
  }
  .linkbox {
    .linkitem {
      padding: 10px 10px;
      background-color: #fff;
      color: $themeColor;
      background-color: transparent;
    }
    .router-link-active {
      background-color: rgb(180, 83, 77);
    }
  }
  .membercontent {
    padding: 10px;
    padding-right: 0;
    background-color: rgba(255, 255, 255, 0.2);
    margin-top: 30px;
    border-radius: 8px;
    min-height: 400px;
    .card {
      width: 300px;
      height: 160px;
      border-radius: 8px;
      background-color: #fff;
      margin-bottom: 10px;
      margin-right: 10px;
      overflow: hidden;
      .usermsg {
        flex-wrap: nowrap;
        height: 120px;
        .userheader {
          position: relative;
          width: 120px;
          height: 120px;
          img {
            width: 100px;
            height: 100px;
          }
          .vip {
            position: absolute;
            right: 15px;
            bottom: 15px;
            font-size: 20px;
            color: gold;
          }
        }
        .userother {
          width: 180px;
          height: 120px;
          .username {
            font-size: 12px;
            color: $linkColor;
            cursor: pointer;
            margin-top: 15px;
            margin-left: 10px;
          }
          .username:hover {
            text-decoration: underline;
          }
          .userstatus {
            font-size: 12px;
            margin-top: 5px;
            color: #9c6d97;
            margin-left: 10px;
          }
          .offline {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #999;
            margin-right: 10px;
          }
          .online {
            font-size: 12px;
            margin-top: 5px;
            margin-left: 10px;
            justify-content: flex-start;
            span {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-image: linear-gradient(to bottom, greenyellow, green);
              margin-right: 10px;
            }
            .btn {
              color: $themeColor;
              font-weight: bold;
              padding: 0 3px;
              border: 1px solid $themeColor;
              background-color: #e8cbed;
              border-radius: 2px;
              cursor: pointer;
            }
            .btn:hover {
              background-color: #e1b1e1;
            }
          }
        }
      }
      .usermenu {
        height: 40px;
        background-color: #f5f5f5;
        font-size: 12px;
        padding-left: 10px;
        justify-content: flex-start;
        .btn {
          color: #fff;
          padding: 2px 5px;
          border: 1px solid $themeColor;
          background-color: #8f75a1;
          border-radius: 2px;
          cursor: pointer;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>