<template>
  <keep-alive>
    <van-tabs v-model:active="active">
      <van-tab :title="$t('tab.chat')">
        <Chat />
      </van-tab>
      <van-tab :title="$t('tab.customer')">
        <Customer />
      </van-tab>
    </van-tabs>
  </keep-alive>
  <Menu />
</template>

<script>
import Menu from "../../../components/mobile/Menu.vue"
import Chat from "./Chat/List.vue"
import Customer from "./Chat/Customer.vue"
export default {
  data () {
    return {
      active: '',
    }
  },
  components: {
    Menu,
    Chat,
    Customer,
  }
}
</script>

<style>
</style>