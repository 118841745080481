<template>
  <div class="invest_area">
    <div class="invest_area_top">
      <div class="invest_area_top_icon">
        <img
          src="@/assets/images/phone/topback_black.png"
          alt=""
          @click="back"
        />
        <img
          src="@/assets/images/phone/home_black.png"
          alt=""
          @click="gohome"
        />
      </div>
      <span>{{ $i18n.t("user.setup.setting") }}</span>
    </div>
  </div>
  <div class="menuList">
    <div class="menuList_titile">{{ $t("user.setup.Basicinformation") }}</div>
    <!-- 生日 -->
    <div class="menuItem">
      <div class="left">{{ $t("regist.birth") }}</div>
      <router-link class="terms" to="/m/home/my/Setup/changebrith">
        <div class="left">
          <span class="langtxt">{{ birth }}</span>
          <van-icon name="arrow" />
        </div>
      </router-link>
    </div>
    <!-- 国家 -->
    <div class="menuItem">
      <div class="left">{{ $t("regist.country") }}</div>
      <div class="left">
        <span class="langtxt">{{ country }}</span>
      </div>
    </div>
    <!-- 性别 -->
    <div class="menuItem">
      <div class="left">{{ $t("regist.gender") }}</div>
        <div class="left">
          <span class="langtxt" v-if="gender == 1">{{
            $t("regist.male")
          }}</span>
          <span class="langtxt" v-else>{{ $t("regist.female") }}</span>
          <van-icon name="arrow" />
        </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  data () {
    return {
      birth: '',
      country: '',
      gender: '',
    }
  },
  computed: {

  },
  created () {
    this.initData();
  },
  methods: {
    back () {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/' })
        return false
      } else {
        this.$router.go(-1)
      }
    },
    gohome () {
      this.$router.push({ path: '/' })
    },
    initData () {
      // this.birth = new Date(parseInt(this.$store.state.User.birthday));
      var time = new Date(parseInt(this.$store.state.User.birthday))
      this.birth = time.getFullYear() + '-' + (time.getMonth() + 1) + '-' + time.getDate()
      this.gender = parseInt(this.$store.state.User.gender);
      this.country = this.$store.state.User.country
    },
  },
}
</script>

<style lang="scss" scoped>
.invest_area {
  width: 100%;
  height: 20px;
  padding: 20px;
  color: #303133;
  position: relative;
}
.invest_area_top_icon {
  position: absolute;
  height: 20px;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
}
.invest_area_top_icon img:nth-of-type(1) {
  width: 20px;
  height: 16px;
  margin-right: 15px;
}
.invest_area_top_icon img:nth-of-type(2) {
  width: 25px !important;
  height: 25px !important;
  top: 10px;
}
.invest_area_top {
  text-align: center;
  font-size: 18px;
  height: 20px;
  line-height: 20px;
}

.menuList {
  padding: 20px 0 100px;
}
.menuList_titile {
  color: #303133;
  font-size: 14px;
  padding: 15px;
  background-color: #f5f7fa;
}
.menuItem {
  display: flex;
  justify-content: space-between;
  margin: 0px 15px;
  padding: 15px 0px;
  align-items: center;
  border-bottom: 1px solid #edeff2;
}
.menuItem .left {
  display: flex;
  align-items: center;
  color: #909399;
  font-size: 14px;
}
.menuItem .left .ico {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  border: 1px solid #f1f1f1;
}
.langtxt {
  display: inline-block;
  float: right;
  margin-right: 10px;
  color: #000;
}
.logout {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 100;
  background: #fff;
  height: 60px;
  padding: 0px 16px;
}
.draweritem {
  text-align: center;
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #f4f4f4;
}
.el-drawer.btt {
  height: auto !important;
}
</style>