<template>
  <div class="content_area">
    <div class="language" @click="drawer = true">{{ lang }}</div>
    <div class="pleaselogin" @click="langeshow">
      {{ $i18n.t("login.signinp") }}
    </div>

    <input
      type="text"
      class="van-field1"
      v-model="username"
      :placeholder="$i18n.t('login.username') + '/' + $i18n.t('login.email')"
    />

    <input
      type="password"
      v-model="password"
      :placeholder="$i18n.t('login.pwd')"
    />

    <div class="forgot_item">
      <router-link class="forgot" to="/m/Forgotpassword">
        {{ $t("login.forgot") }}
      </router-link>
    </div>
    <div style="margin: 16px">
      <van-button
        round
        block
        type="danger"
        style="background: #ff4a53 !important"
        @click="login"
        >{{ $i18n.t("login.signin") }}</van-button
      >
    </div>

    <div class="joinin">
      <router-link class="register" to="/m/Register"
        >{{ $i18n.t("login.register") }}~~
      </router-link>
    </div>

    <!-- <div class="down_area">
            <img src="~@/assets/images/phone/heart_logo.png" alt="">
            <span>{{$i18n.t('login.download')}}APP</span>
        </div> -->
    <div class="down_area">
      <router-link class="terms" to="/m/agreement">
        <span>{{ $i18n.t("title.terms") }}</span>
      </router-link>
    </div>
    <el-drawer
      v-model="drawer"
      :direction="direction"
      :with-header="false"
      :size="150"
      :before-close="handleClose"
      destroy-on-close
    >
      <div class="draweritem" @click="setlang('en')">English</div>

      <div class="draweritem" @click="setlang('zhcn')">简体中文</div>

      <div class="draweritem" @click="setlang('zhtw')">繁體中文</div>
    </el-drawer>
  </div>
</template>
<script>
import { defaultTitle } from "../../common/project.config";
import { ElMessage } from 'element-plus'
export default {
  data () {
    return {
      lang: '',
      show: true,
      username: '',
      password: '',
      drawer: false,
      direction: 'btt',
    }
  },
  created () {
    this.initlang();
  },
  methods: {
    langeshow () {
      this.show = true;
    },
    handleClose (done) {
      done();
    },
    initlang () {
      let lang = this.$i18n.locale;
      if (lang === "en") {
        this.lang = "English";
      } else if (lang === "zhcn") {
        this.lang = "简体中文";
      } else if (lang === "zhtw") {
        this.lang = "繁體中文";
      }else{
		this.$i18n.setLang("zhtw");
		this.lang = "繁體中文";
		this.$store.commit("changeLang", "zhtw");
	  }
      document.title = this.$route.meta.title ? this.$i18n.t("title." + this.$route.meta.title) : defaultTitle;
    },
    setlang (lang) {
      this.drawer = !this.drawer
      this.$i18n.setLang(lang);
      this.initlang();
      this.$store.commit("changeLang", lang);
    },
    verify () {
      if (this.username.trim() == "") {
        ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.username'));
        return false;
      } else if (!/[0-9a-zA-Z\u4e00-\u9fa5]{1,12}/.test(this.username.trim())) {
        ElMessage.error(this.$t('regist.username') + ' ' + this.$t('regist.form.fomate'));
        return false;
      } else if (this.password.trim() == "") {
        ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.pwd'));
        return false;
      }
      return true;
    },
    login () {
      if (this.verify()) {
        this.axios.post_form("/member/index/login", {
          account: this.username,
          password: this.password,
        }).then(res => {
          res = res.data;
          if (res.status == 200) {
            ElMessage.success({
              message: this.$t('tips.loginsuccess'),
              type: 'success'
            });
            this.$store.commit('login', res.data);
            this.$store.dispatch("updateUserAuthority");
            this.$router.push({ path: '/' })
          } else if (res.status == -3) {
            ElMessage.error(this.$t('tips.aproval'));
          } else {
            ElMessage.error(res.msg);
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content_area {
  width: 100%;
  height: 100vh;
  background: url(~@/assets/images/phone/sign_back.png) no-repeat;
  background-size: 100% 100%;
  padding: 20px;
}
.language {
  width: 100%;
  text-align: right;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}
.pleaselogin {
  width: 100%;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  padding-left: 10px;
  margin-top: 10px;
}
input {
  background: border-box !important;
  border: none;
  color: #fff;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 2px solid #fff;
}
.van-field1 {
  margin-top: 80px;
  margin-bottom: 30px;
}
.van-field__control {
  color: rgb(250, 221, 221) !important;
}
.van-cell {
  background: none;
}
::placeholder {
  //通过这个设置
  color: #ffffff;
}
.van-cell::after {
  border-bottom: 4px solid #fff;
}
.forgot_item {
  margin: 20px 0px;
  margin-bottom: 50px;
  width: 100%;
  text-align: right;
}
.forgot {
  color: #fff;
  font-size: 16px;
}
.joinin {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  color: #ff4a53 !important;
  font-size: 20px;
}
.register {
  color: #ff4a53 !important;
  font-weight: bold;
}
.down_area {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.down_area img {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
.down_area span {
  color: #fff;
}
.draweritem {
  text-align: center;
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #f4f4f4;
}
.el-drawer.btt {
  height: auto !important;
}
</style>
