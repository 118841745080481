<template>
  <div class="flexbox container">
    <div class="flexbox body">
      <div class="loginbody">
        <div class="formtitle">
          <i class="iconfont icon-wenjian"></i>
          {{ $t("forgot.title") }}
        </div>
        <div class="flexbox tips">{{ $t("forgot.subtitle") }}</div>
        <div class="inputitem">
          <el-input
            :placeholder="$i18n.t('forgot.uremail')"
            prefix-icon="el-icon-user"
            v-model="email"
          >
          </el-input>
        </div>

        <div class="flexbox inputitem">
          <div class="flexbox loginbtn" @click="send">
            <div>{{ $t("forgot.sunmit") }}</div>
            |
            <i class="iconfont icon-tubiaozhizuomoban"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  data () {
    return {
      email: "",
    }
  },
  methods: {
    send () {
      if (!/^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(this.email)) {
        ElMessage.error(this.$t('regist.email') + ' ' + this.$t('regist.form.fomate'));
        return false;
      }
      this.axios.post_form('/member/index/forget', {
        email: this.email
      }).then(res => {
        res = res.data;
        if (res.status == -1) {
          ElMessage.error(this.$t('regist.email') + ' ' + this.$t('regist.form.fomate'));
        } else {
          ElMessage.success($t('tips.newpwd'));
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  background-image: url(~@/assets/images/promo2.jpg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  flex-direction: column;
  flex-wrap: nowrap;

  .body {
    width: 550px;
    height: 100%;
    align-items: flex-start;
    align-content: flex-start;
    .title {
      width: 100%;
      font-size: 28px;
      line-height: 36px;
      margin: 0 8px 10px 8px;
      padding: 0 40px;
      color: $themeColor;
      font-family: "open_sanssemibold", Tahoma, Geneva, sans-serif;
    }

    .loginbody {
      width: 550px;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 6px;
      margin-top: 100px;
      .formtitle {
        padding: 10px;
        height: auto;
        font-size: 16px;
        overflow: hidden;
        font-weight: normal;
        color: $themeColor;
        i {
          margin-right: 10px;
        }
      }
      .tips {
        font-size: 14px;
        // color: $themeColor;
      }
      .inputitem {
        width: 100%;
        padding: 8px 20px;
        justify-content: flex-end;
        .loginbtn {
          width: 100px;
          height: 32px;
          background-color: #e8cbed;
          padding: 0px 8px;
          border: 1px solid rgba(93, 69, 110, 0.6);
          border-radius: 4px;
          color: $themeColor;
          font-size: 14px;
          justify-content: space-between;
          cursor: pointer;
          .icon-tubiaozhizuomoban {
            font-size: 20px;
            color: $themeColor;
          }
        }
      }
    }
  }
}
</style>