import { websitname, email, account } from "../common/project.config";
export default {
  title: {
    defaulttitle: `加入${websitname} - 在这里结实朋友`,
    home: "首页",
    signin: `加入${websitname} - 在这里结实朋友`,
    signup: `加入${websitname} - 在这里结实朋友`,
    about: "关于我们",
    terms: "服务条款",
    privacy: "隐私政策",
    refund: "退款协议",
    help: "帮助中心",
    contact: "联系我们",
  },
  login: {
    signinp: "请登录",
    username: "用户名",
    email: "邮箱",
    pwd: "密码",
    remember: "记住我",
    forgot: "忘记密码",
    signin: "登录",
    join: "注册",
    form: {
      error: "用户名或密码不正确",
      success: "登录成功",
    },
    download: "下载",
    aboutus: "现在加入",
	register: "立即注册",
  },
  regist: {
    title: "加入我们的社区",
    subtitle:
      "加入我们这个富有激情的社区。找到新朋友,约会,分享照片和视频。 嗨起来!",
    registp: "请注册您的个人资料",
    formtitlte: "基本资料",
    username: "用户名",
    containe: "必须包含字母和数字",
    email: "邮箱",
    valid: "必须是有效的",
    pwd: "密码",
    pwdc: "再次输入密码",
	laid: "赖ID",
    gender: "性别",
    male: "男",
    female: "女",
    birth: "生日",
    country: "国家",
    singce: "来自哪个国家？",
    terms: "服务条款",
    agree: "我同意",
    signup: "注册",
    form: {
      required: "请输入",
      pwdnomatch: "密码不匹配",
      used: "已被使用",
      faile: "注册失败",
      seccess: "注册成功",
      fomate: "格式错误",
    },
    year: "年",
    month: "月",
    date: "日",
  },
  forgot: {
    title: "邮箱输入",
    subtitle: "输入您在注册时使用的电子邮件地址。",
    uremail: "你的邮箱",
    sunmit: "提交",
  },
  loginfooter: {
    about: "关于我们",
    terms: "服务条款",
    privacy: "隐私政策",
    refund: "退款协议",
    help: "帮助中心",
    contact: "联系我们",
  },
  loginmenu: {
    signin: "登录",
    signup: "注册",
  },
  websit: {
    signin: "登录",
    join: "注册",
    about: {
      title: "关于我们",
      item_1_title: "公司简介",
      item_1_content:
        "本站致力于在230多个国家和地区开拓网上交友服务产业，并管理多种不同语言的网站。在这里您可以尽情的和朋友分享趣事和喜悦，为您提供一个全世界最快捷、最好玩的交友平台，每天都有成千上百新会员加入。",
      item_2_title: "我们的使命是什么？",
      item_2_content:
        "我们的使命很简单：帮助大家认识来自全世界的朋友。我们认为：这方面我们做得相当不错。每个月我们都收到全世界各地的来信，与我们分享他们与在本站上认识的朋友的故事。",
      item_3_title: "怎样操作？",
      item_3_content:
        "我们为用户提供认识及了解全世界朋友的机会，通过不同的自由编写版块，让他们在其档案中详细介绍他们自己。可以上传多张图片，以及有关他们的兴趣爱好。我们知道隐私保护的重要性，所以，会员间所有的联系都是通过匿名邮件网络进行的。我们对所有会员的姓名和联系信息都保密，除非会员自己想与对方分享个人信息的情况除外，一旦注册完成，会员可以立即浏览潜在朋友的照片与日志，并与其联系。",
    },
    terms: {
      title: "服务条款",
      item_1_title: "1. 同意服务条款",
      item_1_content:
        "本协议（使用条款），包括目前和未来的商业网站和客户。通过使用本服务，无论您是否注册为网站会员（以下简称会员），您都将被视为已同意本使用条款。如果您不同意本服务条款，请停止使用我们的服务。",
      item_2_title: "2. 资格要求",
      item_2_content:
        "未满18岁的未成年人不得使用我们的服务。通过使用我们的服务，您声明并保证您有权利和能力遵守使用条款的所有规定。",
      item_3_title: "3. 使用条款变更",
      item_3_content:
        "本网站保留更改本使用条款的权利，恕不另行通知。您可以查看服务条款以定期更改情况。当我们更改使用条款时，如果您使用我们的服务，无论您是否认为您同意这些更改。",
      item_4_title: "4. 隐私",
      item_4_content:
        "我们的网站尊重网站访问者的隐私。使用该服务应遵守我们的隐私政策。我们的网站合作伙伴同意遵守隐私政策的规定",
      item_5_title: "5. 服务变更",
      item_5_content:
        "我们的网站可以决定添加，删除或修改服务或服务，恕不另行通知。",
      item_6_title: "6. 特色服务",
      item_6_subtitle1: "A) 支付",
      item_6_subtitlecontent1:
        "支付信息将作为您同意购买的服务产生的费用进行传输，结果将由您与金融机构、信用卡发卡机构或您选择的其他支付服务提供商之间的返回信息决定。如果我们的网站不接受您的支付服务提供商提供的支付服务，您可以支付您需要的所有金额，并同意继续使用我们的服务。如果您的帐户已付款，您可以要求我们取消您的付款状态。一旦您的帐户被取消，您的付款状态将在您的最终付款日期到期。付费状态不可转让。我们网站的付款状态是最终销售，不能退款。会员在当月或季度到期后不会自动扣除持卡人的续费。如果会员想继续使用，需要手动充值。有争议的费用应在您购买服务后60天内报告给我们的网站。如果您对您的付费状态有任何疑问，请联系我们解决。",
      item_6_subtitle2: "B) 服务合同",
      item_6_subtitlecontent2:
        "您在我们的网站订购服务将不会自动续订。您可以随时联系我们的客户服务团队进行更改或重新订购。如果您选择订购，您的订单将在服务未自动续订时过期。过期前取消定做，无法获得退款。",
      item_6_subtitle3: "C)免费试用及其他促销",
      item_6_subtitlecontent3:
        "任何免费试用或其他促销方式，均可在特定试用期内定制使用我们的服务。如果用户需要订购促销服务条款，则应在试用期前取消订单，以避免交付定制费用。",
      item_7_title: "7. 通过合作伙伴加入我们的网站",
      item_7_content:
        "如果您通过我们的合作伙伴注册使用我们的服务，我们的网站将与其合作伙伴一起提供服务。您将有权使用会员服务，并有权向我们网站转让您的会员资格或付费向我们网站转让您的账户类似的服务。",
      item_8_title: "8. 社区内容",
      item_8_content: `所有服务应遵循社区内容指南的要求。您同意不发布、传输、分发或链接任何涉及非法、骚扰、诽谤、威胁、有害、淫秽、叛逆、诽谤、诽谤他人名誉和侵犯他人隐私的内容，也不向他人发送不高兴的内容，更确切地说是针对其他人权团体或内容。知识产权未经本人书面同意，不得使用他人的知识产权，包括受著作权和商标保护的信息，不得对他人的知识产权进行变更或变更，如非著作权的文字、图片等。我们网站不会对任何由会员或团体发送的邮件内容负责，任何信息、商品或服务均不对第三方或会员负责。使用我们的服务，您同意：在以下情况下，我们的网站将不对您或他人负责：例如：对任何一方的威胁、诽谤、淫秽、猥亵、攻击或冒犯，或侵权，包括非法使用知识产权。我们的网站没有您提供给我们的所有信息（包括反馈和建议），或者您发布我们的网站、下载、输入或提交所有信息（“提交的信息”）。但是，如果您在我们的网站或客户上发布、上传、输入、提供或提交信息，您表示您给予我们的网站永久和不可撤销的转让权，我们的网站有权复制、使用、存储、修改、编辑、翻译或发布您提交的任何或所有信息，而且没有支付版税的信息。我们的网站没有义务发布或使用您提交的信息，我们的网站可以随时决定删除您提交的信息。在本网站中，您下载、输入或提交的信息表明您可以控制信息的发布。对于您的信息被删除、无效或未能保存，我们网站及其合作伙伴将不承担任何责任。`,
      item_9_title: "9. 会员账号",
      item_9_content:
        "一旦您注册我们的服务，您将收到一个帐号和密码，您有责任对您的帐号和密码保密，并应负责与您的帐号有关的所有活动。一旦注册成为会员，您将负责您的所有信息文件。禁止在文件或网络服务项目中发布色情或其他淫秽内容，因为这会威胁您的会员资格。对于下线交友网站会员我们网站，我们网站将不承担任何责任。您同意我们网站向您发送邮件（涉及服务信息（包括升级变更）、新功能或事件、使用服务的意见和建议）。",
      item_10_title: "10. 受版权和商标保护的材料",
      item_10_content: "我们网站的内容受版权保护© 2011-2023.",
      item_11_title: "11. 免责声明",
      item_11_content: `在我们的网站或由我们的网站（或我们的网站代理商或合作伙伴）发布的信息，根据“现有”和“现有”报价。它提供各种明示或暗示的保证和条件。我们的网站不控制第三方提供的任何信息、产品或服务。您明确同意使用本服务将独自承担风险。在适用法律允许的最大范围内：我们网站上所有明示或暗示的保证、免责声明和此类保证，包括但不限于暗示的适销性，并确保适用于特殊用途；我们的网站不保证所提供的服务能满足您的需求，也不保证服务会不间断、及时、安全或正确。我们的网站不用于服务和使用结果作为抵押品。我们的网站不保证本网站或服务不包含任何病毒或其他有害成分。本网站不保证或声明：对网站转让信息保密。我们的网站不保证翻译的准确性和完整性。我们的网站不保证以下内容：通过本网站购买或获得的任何商品或服务，或在本网站上做广告或赞助商品或服务，或通过本网站进行的任何交易。信息我们的网站不是您从我们的网站收到服务或作出任何明示保证（无论是口头或书面）。`,
      item_12_title: "12. 声明",
      item_12_content1: `本网站上所有的礼物都是虚拟礼物。`,
      item_12_content2: `以上所有项目均根据英国法律组织和管理。在线商户位于英国。账单名称为“${account}” 。`,
    },
    privacy: {
      title: "隐私政策",
      item_0_content: `本网站尊重用户的隐私权，并制定隐私政策，保护您的隐私权。隐私政策包括：本网站的用户情况，我们所收集的信息，怎样利用这些信息，以及与谁分享这些信息。当您使用我们的网站或服务，或与我们进行商务交易时，我们建议你仔细阅读“隐私政策”。通过使用我们的网站，表明您已接受本保密协议的规定。本网站非常重视网络隐私。如果您对该隐私政策有任何疑问，请联系我们。`,
      item_1_title: "未满18岁的人士",
      item_1_content: `本网站不会故意收集或保存未满18岁人士的个人验证信息或非个人验证信息，本网站下的任何项目均不面向未满18岁的人士开放。如果你未满18岁，请停止使用或禁止进入本网站或服务。如果本网站获悉所收集的信息中有未满18岁人士的个人验证信息，本网站将采取合理的步骤，删除该信息。`,
      item_2_title: "我们所收集的信息",
      item_2_content: `您填写的相关信息（包括您的个人信息，联系信息，以及当您创建会员档案时所填写的其他档案信息）；当你自由填写顾客调查表，提供反馈和参与竞争时，所提供的相关信息;如果您与我们联系，我们会记录你的通信信息;交易信息包括您从我们网站定购有关产品和服务的信息;浏览我们网站的访客，并且他们的信息已被记录的，可以有权以相同的信息登录，并有权更正信息或查看有关该信息的政策和规则。访客可以直接与我们联系。`,
      item_3_title: "其他方收集的信息",
      item_3_content: `我们允许第三方（包括我们的授权服务提供商，广告公司和广告网站）在我们的网站上发表广告。如果你打开一个链接（例如一个广告标志），这将使你将离开我们的网站，登录其他的网站，如果你随后在其他网站上提供信息，我们将不能控制该信息的使用。本隐私政策将不对其他网站或广告商的信息保密负责。对于其他网站如何使用你的信息，本网站也将不为此负担任何责任。`,
      item_4_title: "我们怎样使用收集的信息？",
      item_4_content1: `管理您的用户账户（包括：通知您任何匹配的对象，提示，通告，更新资料或促销活动`,
      item_4_content2: `帮助你订制个性化的网站回访`,
      item_4_content3: `向您提供您所询问的信息，产品或服务，或者你感兴趣的信息；进行市场分析和调查`,
      item_4_content4: `帮助我们改进服务结构，布局和适用性`,
      item_5_title: "我们与谁分享所收集的信息",
      item_5_content: `我们可能在特定方面将您的信息与第三方分享，例如：当您从我们网站订制服务时，我们提供你的信息给第三方进行付款处理。但是我们将控制第三方使用您的信息，并对此负责。我们有可能将您的某些信息透露给我们的合作公司，以便向您提供服务，例如：邮件或聊天功能服务。为了提供这些服务，我们必须将您的信息（包括您的本网站用户名和email地址）提供给我们的合作伙伴。`,
      item_6_title: "COOKIES",
      item_6_content: `我们竭力按照您的喜好为您提供服务。我们使用Cookies 保存并记录有关于您的信息。 Cookie是指通过网络服务器发送到您浏览器的一小部分数据，该数据储存在您的计算机硬盘上。`,
      item_7_title: "变更隐私政策",
      item_7_content: `我们经常审核“隐私政策”，并在此页面公布政策的变更信息。我们鼓励会员经常审核我们的隐私政策，以便更好地了解我们所收集的信息，以及我们怎样运用信息，以及在何种情况下使用信息，与他人分享信息。`,
      item_8_title: "怎样联系我们",
      item_8_content: `如果您对于本隐私政策或对于我们的信息处理方式有何疑问，或对于我们透露信息给第三方（为了直销的目的）有疑问，请通过email或信件与联系我们。 E-mail: %{email}`,
    },
    refund: {
      title: "退款协议",
      item_1_title: "退款和取消",
      item_1_content: `当你消费时，因为付款单经过不同的银行，所以付款的返还可能会有差异。如果您对退货有任何疑问，可以联系网站客服进行解释。`,
      item_2_title: "退款",
      item_2_content: `如果您对交易订单有任何疑问，需要联系网站客服，客服会帮助您解释订单情况。对于退款处理，根据退款流程，通常需要一周以上的时间。注意查看发卡行的退款信息。`,
      item_3_title: "取消",
      item_3_content1: `如果交易订单已成功充值，在联系网站客服后，可协商退款。按照退款流程，通常需要一个多星期。请核对您的发卡行退款信息。`,
      item_3_content2: `Email: %{email}`,
    },
    help: {
      title: "帮助中心",
      item_1_title: "1. 无法登陆网站",
      item_1_content: `请首先确认您是否成功注册${websitname}账号，然后确认您的账号密码输入是否正确，如果仍然无法登陆，请点击“忘记密码”，如仍然无法登陆，请及时联系网站管理员。`,
      item_2_title: "2. 忘记密码",
      item_2_content: `如果你忘记您的登陆密码，当你登陆的时候，请点击“忘记密码”选项，进行密码找回或是重置密码`,
      item_3_title: "3. 如何升级会员",
      item_3_content: `您可以点击升级会员图标按钮，然后选择你所要进行升级的会员等级进行升级。`,
      item_4_title: "4. 如何更好的使用翻译功能",
      item_4_content: `${websitname}网站是一个针对全球的SNS国际交友网站，在这里通过${websitname}翻译平台，让全球朋友沟通无限！当你拥有较高的会员权限，你将可以享有更多的翻译权限。`,
      item_5_title: "5. 如何举报非法账号",
      item_5_content: `当您发现一些非法账号，可以进入他的个人主页，点击“举报”。或是及时联系我们网站管理员，我们将会作出调查并进行处理，以确保网站的安全性。`,
      item_6_title: "6. 如何进行支付",
      item_6_content: `您可以登录${websitname}点击充值，我们目前提供所有的支付方式都是绝对安全的。本网站绝没有任何自动记忆或重复收费的程序。有任何疑问，请及时联系网站管理员提出您的问题。`,
      item_7_title: "7. 充值不成功",
      item_7_content: `如果您在${websitname}的充值页面无法成功充值，请先查看你的信用卡是否有问题，然后更换浏览器重新尝试。有时您也可以选择联 系贝宝（比如在系统提示\"您的信用卡不能用于支付这笔交易\"的情况下）。如果各种方法都尝试过后仍旧无法成功充值，请将详细情况告诉我们，比如您使用的是 哪种充值方式，有何错误提示？使用哪种浏览器？为了协助我们技术人员快速查找原因帮你解决问题，请您尽可能将出错页面截图发给我们网站管理员。`,
    },
    contact: {
      title: "联系我们",
      item_0_content: `Email: %{email}`,
    },
  },
  authority: {
    Allow_profile_wall_posts: "允许个人资料上墙帖",
    Search_users: "搜索用户",
    View_profiles: "查看个人资料",
    Auth_message: "聊天发图片免费",
    Allow_comments_on_newsfeed: "允许在动态下面评论",
    Dynamic_unrestricted: "无免费条数限制",
    Allows_newsfeed_to_be_sent: "允许发动态",
    Upload_photo: "上传照片",
    View_photo: "查看图片",
    Comment_photo: "评论图片",
    Add_video: "添加视频",
    View_video: "查看视频",
    Comment_video: "评论视频",
    Start_mail_dialog: "启用邮件对话框",
    Continue_mail_dialog: "继续邮件对话框",
    Read_chat_messages: "阅读聊天消息",
    Send_chat_messages_for_free: "免费发送聊天信息",
    Start_chat_dialog: "启用聊天输入框",
    Read_mails: "阅读邮件",
    Continue_chat_dialog: "继续聊天对话框",
    Free_use_of_the_translation_function: "免费使用翻译",
  },
  home: {
    index: "首页",
    member: "会员",
    chat: "聊天",
    recharge: "充值",
    upgrade: "升级",
    store: "商店",
    photo: "照片",
    mails: "邮件",
  },
  dynamic: {
    dynamic: "动态",
    photo: "照片",
    personaldata: "个人资料",
    like: "喜欢",
    detele: "删除背景图片",
    upload: "上传背景图片",
  },
  index: {
    member: "会员",
    mycredits: "我的账户",
    balance: "余额",
    user: "社区用户",
    trend: "最新动态",
    pay: "充值",
    latest: "最新",
    online: "在线",
    viewall: "查看全部",
    invite: "邀请朋友",
    showpath: "查看邀请链接",
    photo: "照片",
    copy: "复制",
  },
  trend: {
    joined: "加入了我们的网站！",
    edited0: "编辑了她的个人资料。",
    edited1: "编辑了他的个人资料。",
    avatar0: "修改了她的头像。",
    avatar1: "修改了他的头像。",
  },
  store: {
    store: "商店",
    gift: "礼物",
    receive: "我收到的礼物",
    sendgift: "发送礼物",
    credits: "金币",
    username: "用户名",
    msg: "赠送留言",
    send: "赠送",
  },
  recharge: {
    self: "为自己充值",
    pay: "充值",
  },
  upgrade: {
    silver: "钻石会员",
    gold: "皇冠会员",
    Rechargesuccess: "支付成功",
    Paymentfailed: "支付失败",
    runninglow: "余额不足",
    choosemeal: "请选择套餐",
    actuallypaid: "实付金额",
    paymentmethod: "支付方式",
    month: "月",
    province: "优惠",
    usd: "美元",
  },
  pay: {
    name: "持卡人名字",
	ename: "请填写英文姓名",
    lastname: "持卡人姓",
    cardnumber: "卡号",
    Expiration: "过期时间",
    CVV: "CVV",
    country: "账单国家",
    state: "账单省(州)",
    city: "账单城市",
    address: "账单地址",
    phone: "电话",
    ZipCode: "邮政编码",
  },
  member: {
	whole: "全部",
    online: "在线",
    latest: "最新",
    mylike: "我喜欢的",
    likeme: "喜欢我的",
    mutua: "互相喜欢",
    search: "搜索",
    searchbyname: "用户名搜索",
    follow: "关注",
    cancelfollow: "取消关注",
    sendgift: "发送礼物",
    chatnow: "跟他聊",
    usersearch: "搜索用户",
    browseuser: "浏览用户",
    basic: "基本资料",
    year: "岁",
  },
  user: {
    setup: {
      myavatar: "我的头像",
      setting: "资料设置",
      signature: "个性签名",
      languge: "语言",
      changepwd: "更换密码",
      photograph: "拍照",
      Choosefromalbum: "从相册中选择",
      UsedAvatar: "使用过的头像",
      Basicinformation: "基础资料",
    },
    edit: {
      title: "资料编辑",
      avatar: "用户头像",
      changepwd: "修改密码",
      oldpwderror: "旧密码输入错误",
      oldpwd: "旧密码",
      modifiedsuccess: "修改成功",
      modifiedfail: "修改失败",
      save: "保存",
      cancel: "取消",
      trendplaceholder: "聊一聊~",
      sendemail: "发送私信",
      follow: "关注",
      gift: "送礼物",
      report: "举报",
      photo: "照片",
      modifyavatar: "修改头像",
      signatureplaceholder: "你的个性签名",
    },
    sendemail: "聊天",
    follow: "关注",
    gift: "送礼物",
    report: "举报",
    photo: "照片",
  },
  message: {
    address: "邮箱地址",
    message: "邮件",
    msg: "邮件内容",
    newmsg: "新邮件",
    send: "发送邮件",
  },
  global: {
    send: "发送",
    save: "保存",
    confirm: "确定",
    cancel: "取消",
    search: "搜索",
    modify: "修改",
    modifyavatar: "修改头像",
  },
  empty: {
    nouser: "没有用户",
    nophoto: "没有照片",
    noresult: "没有结果",
    nonitice: "没有通知",
    chat: "你的会话列表为空",
  },
  tips: {
    savesuccess: "保存成功",
    deletedsuccess: "删除成功",
    usernotexist: "用户不存在",
    insufficientcredits: "余额不足",
    needupgrade: "需要升级",
    sendfail: "发送失败",
    sendsuccess: "发送成功",
    contentcantempty: "内容不能为空",
    newpwd: "请在邮箱查看您的新密码",
    nopermission: "您没有权限",
    loginsuccess: "登陆成功",
    loginfailed: "登陆失败",
    followsuccess: "关注成功",
    followcancel: "取消关注",
    likesuccess: "喜欢成功",
    likecancel: "取消喜欢",
    loginsuccess: "登陆成功",
    loginfailed: "登录失败",
    pullrefresh: "下拉即可刷新...",
    loosingrefresh: "释放即可刷新...",
    loading: "加载中...",
	nomore: "没有更多了",
	clickmore: "查看更多",
    underReview: "注册成功，审核中...",
    copysuccess: "复制成功",
    aproval: "您的账号正在审核中...",
    nofreechat: "免费聊天已用完，请",
    continue: "后发信息",
    upmember: " 升级会员 ",
    nouserfound: "未搜索到相关用户",
	incomeErr: "金额错误",
	patience:"请耐心等待",
  },
  general: {
    title1: "群发邮件",
    item1: "订阅新邮件",
    tips1: "已订阅，未订阅/点击订阅",
  },
  notification: {
    general: "默认",
    notice: "通知",
    title1: "基础",
    title2: "最新动态",
    title3: "消息",
    title4: "图片",
    title5: "视频",
    title6: "用户余额",
    title7: "虚拟礼物",
    title8: "也通过电子邮件发送",
    item1: "有人评论了我的个人资料",
    item2: "有人评论我的状态",
    item3: "有人喜欢我的身份",
    item4: "有人给我的照片墙上写评论",
    item5: "有人给我发了一条新邮件",
    item6: "有人给我发了一条新消息",
    item7: "有人对我的照片发表评论",
    item8: "有人评论了我的视频",
    item9: "有人给予我信用点",
    item10: "有人给我发送礼物",
    item11: "立即",
    item12: "自动（如果您在网站上登录未超过2天）",
    item13: "从不",
  },
  navigator: {
    message: "消息",
    notice: "通知",
    profile: "我的个人主页",
    edit: "资料编辑",
    general: "个性化设置",
    mail: "数据标签",
    signout: "退出",
  },
  account: {
    account: "账户",
    quantity: "剩余数量",
    choose: "选择充值类型",
    service: "服务",
    totalprice: "总价",
    save: "省",
    membership: "会员特权",
  },
  date: {
    second: "秒前",
    minute: "分钟前",
    hour: "小时前",
    just: "刚刚",
    month: "月",
    month1: "1月",
    month2: "2月",
    month3: "3月",
    month4: "4月",
    month5: "5月",
    month6: "6月",
    month7: "7月",
    month8: "8月",
    month9: "9月",
    month10: "10月",
    month11: "11月",
    month12: "12月",
  },
  chat: {
    queryhistory: "查询历史消息",
    active: "活跃",
    chatlist: "会话列表",
  },
  mine: {
    membership: "会员",
    free: "非会员",
    expires: "到期",
    goldcard: "皇冠会员",
    silvercard: "钻石会员",
    permanent: "-",
    newsfeeds: "最新动态",
    balance: "用户余额",
    recharge: "充值",
    upgrade: "升级",
    gift: "礼物",
	giftbox: "礼物盒子",
    albums: "相册",
    video: "视频",
    customerservice: "客服",
    index: "首页",
    newsfeed: "最新动态",
    message: "消息",
    mine: "我",
	storemoney: "储值",
  },
  tab: {
    chat: "聊天",
    mail: "邮件",
    customer: "客服",
  },
  country: {
    AD: "安道尔",
    AE: "阿联酋",
    AF: "阿富汗",
    AG: "安提瓜和巴布达",
    AI: "安圭拉",
    AL: "阿尔巴尼亚",
    AM: "亚美尼亚",
    AO: "安哥拉",
    AQ: "南极洲",
    AR: "阿根廷",
    AS: "美属萨摩亚",
    AT: "奥地利",
    AU: "澳大利亚",
    AW: "阿鲁巴",
    AX: "奥兰群岛",
    AZ: "阿塞拜疆",
    BA: "波黑",
    BB: "巴巴多斯",
    BD: "孟加拉",
    BE: "比利时",
    BF: "布基纳法索",
    BG: "保加利亚",
    BH: "巴林",
    BI: "布隆迪",
    BJ: "贝宁",
    BL: "圣巴泰勒米岛",
    BM: "百慕大",
    BN: "文莱",
    BO: "玻利维亚",
    BQ: "荷兰加勒比区",
    BR: "巴西",
    BS: "巴哈马",
    BT: "不丹",
    BV: "布韦岛",
    BW: "博茨瓦纳",
    BY: "白俄罗斯",
    BZ: "伯利兹",
    CA: "加拿大",
    CC: "科科斯群岛",
    CD: "刚果（金）",
    CF: "中非",
    CG: "刚果（布）",
    CH: "瑞士",
    CI: "科特迪瓦",
    CK: "库克群岛",
    CL: "智利",
    CM: "喀麦隆",
    CN: "中国",
    CO: "哥伦比亚",
    CR: "哥斯达黎加",
    CU: "古巴",
    CV: "佛得角",
    CX: "圣诞岛",
    CY: "塞浦路斯",
    CZ: "捷克",
    DE: "德国",
    DJ: "吉布提",
    DK: "丹麦",
    DM: "多米尼克",
    DO: "多米尼加",
    DZ: "阿尔及利亚",
    AD: "安道尔",
    EC: "厄瓜多尔",
    EE: "爱沙尼亚",
    EG: "埃及",
    EH: "西撒哈拉",
    ER: "厄立特里亚",
    ES: "西班牙",
    ET: "埃塞俄比亚",
    FI: "芬兰",
    FJ: "斐济群岛",
    FK: "马尔维纳斯群岛",
    FM: "密克罗尼西亚联邦",
    FO: "法罗群岛",
    FR: "法国",
    GA: "加蓬",
    GB: "英国",
    GD: "格林纳达",
    GE: "格鲁吉亚",
    GF: "法属圭亚那",
    GG: "根西岛",
    GH: "加纳",
    GI: "直布罗陀",
    GL: "格陵兰",
    GM: "冈比亚",
    GN: "几内亚",
    GP: "瓜德罗普",
    GQ: "赤道几内亚",
    GR: "希腊",
    GS: "南乔治亚岛和南桑威奇群岛",
    GT: "危地马拉",
    GU: "关岛",
    GW: "几内亚比绍",
    GY: "圭亚那",
    HM: "赫德岛和麦克唐纳群岛",
    HN: "洪都拉斯",
    HR: "克罗地亚",
    HT: "海地",
    HU: "匈牙利",
    ID: "印尼",
    IE: "爱尔兰",
    IL: "以色列",
    IM: "马恩岛",
    IN: "印度",
    IO: "英属印度洋领地",
    IQ: "伊拉克",
    IR: "伊朗",
    IS: "冰岛",
    IT: "意大利",
    JE: "泽西岛",
    JM: "牙买加",
    JO: "约旦",
    JP: "日本",
    KE: "肯尼亚",
    KH: "柬埔寨",
    KG: "吉尔吉斯斯坦",
    KI: "基里巴斯",
    KM: "科摩罗",
    KN: "圣基茨和尼维斯",
    KP: "朝鲜",
    KR: "韩国",
    KW: "科威特",
    KY: "开曼群岛",
    KZ: "哈萨克斯坦",
    LA: "老挝",
    LB: "黎巴嫩",
    LC: "圣卢西亚",
    LI: "列支敦士登",
    LK: "斯里兰卡",
    LR: "利比里亚",
    LS: "莱索托",
    LT: "立陶宛",
    LU: "卢森堡",
    LV: "拉脱维亚",
    LY: "利比亚",
    MA: "摩洛哥",
    MC: "摩纳哥",
    MD: "摩尔多瓦",
    ME: "黑山",
    MF: "法属圣马丁",
    MG: "马达加斯加",
    MH: "马绍尔群岛",
    MK: "马其顿",
    ML: "马里",
    MN: "蒙古国",
    MM: "缅甸",
    MP: "北马里亚纳群岛",
    MQ: "马提尼克",
    MR: "毛里塔尼亚",
    MS: "蒙塞拉特岛",
    MT: "马耳他",
    MU: "毛里求斯",
    MV: "马尔代夫",
    MW: "马拉维",
    MX: "墨西哥",
    MY: "马来西亚",
    MZ: "莫桑比克",
    NA: "纳米比亚",
    NC: "新喀里多尼亚",
    NE: "尼日尔",
    NF: "诺福克岛",
    NG: "尼日利亚",
    NI: "尼加拉瓜",
    NO: "挪威",
    NP: "尼泊尔",
    NR: "瑙鲁",
    NU: "纽埃",
    NZ: "新西兰",
    OM: "阿曼",
    PA: "巴拿马",
    PE: "秘鲁",
    PF: "法属波利尼西亚",
    PG: "巴布亚新几内亚",
    PH: "菲律宾",
    PK: "巴基斯坦",
    PL: "波兰",
    PM: "圣皮埃尔和密克隆",
    PN: "皮特凯恩群岛",
    PR: "波多黎各",
    PS: "巴勒斯坦",
    PT: "葡萄牙",
    PW: "帕劳",
    PY: "巴拉圭",
    QA: "卡塔尔",
    RE: "留尼汪",
    RO: "罗马尼亚",
    RS: "塞尔维亚",
    RU: "俄罗斯",
    RW: "卢旺达",
    SA: "新西兰",
    SB: "所罗门群岛",
    SC: "塞舌尔",
    SD: "苏丹",
    SG: "新加坡",
    SH: "圣赫勒拿",
    SI: "斯洛文尼亚",
    SJ: "斯瓦尔巴群岛和扬马延岛",
    SK: "斯洛伐克",
    SL: "塞拉利昂",
    SM: "圣马力诺",
    SN: "塞内加尔",
    SO: "索马里",
    SR: "苏里南",
    SS: "南苏丹",
    ST: "圣多美和普林西比",
    SV: "萨尔瓦多",
    SY: "叙利亚",
    SZ: "斯威士兰",
    TC: "特克斯和凯科斯群岛",
    TD: "乍得",
    TF: "法属南部领地",
    TG: "多哥",
    TH: "泰国",
    TJ: "塔吉克斯坦",
    TK: "托克劳",
    TL: "东帝汶",
    TM: "土库曼斯坦",
    TN: "突尼斯",
    TO: "汤加",
    TR: "土耳其",
    TT: "特立尼达和多巴哥",
    TV: "图瓦卢",
    TZ: "坦桑尼亚",
    UA: "乌克兰",
    UG: "乌干达",
    UM: "美国本土外小岛屿",
    US: "美国",
    UZ: "乌兹别克斯坦",
    UY: "乌拉圭",
    VA: "梵蒂冈",
    VC: "圣文森特和格林纳丁斯",
    VE: "委内瑞拉",
    VG: "英属维尔京群岛",
    VI: "美属维尔京群岛",
    VN: "越南",
    VU: "瓦努阿图",
    WF: "瓦利斯和富图纳",
    WS: "萨摩亚",
    YE: "也门",
    YT: "马约特",
    ZA: "南非",
    ZM: "赞比亚",
    ZW: "津巴布韦",
  },
  report: {
    ads: "广告",
    offensive: "冒犯",
    illegal: "非法",
    content: "举报%{user}的内容为...",
    success: "已提交，结果请等待管理员通知",
  },
  notice: {
    report: {
      result: "举报结果",
      reportdetail: "管理员已经受理了您的的举报信息",
    },
    gift: {
      receive: "收到一份礼物",
      from: "来自：",
      leavemsg: "留言：",
    },
  },
  globalgift: {
    sendto: "赠送礼物给",
    credits: "金币",
    balance: "用户余额",
    psag: "请选择一件礼物",
    message: "赠言",
    nogift: "请按要求完成表单",
	giftname: "礼物名称",
	giftinfo: "礼物说明",
	giftprice: "售价",
  },
};
