<template>
  <div class="invest_area">
		<div class="invest_area_top">
		  <div class="invest_area_top_icon">
			<img
			  src="@/assets/images/phone/topback_black.png"
			  alt=""
			  @click="back"
			/>
			<img
			  src="@/assets/images/phone/home_black.png"
			  alt=""
			  @click="gohome"
			/>
		  </div>
		  <span>{{ $i18n.t("mine.giftbox") }}</span>
		</div>
  </div>
  <div class="giftcontainer">
    

    <div class="receivebox">
      <div class="flexbox giftitembox">
        <div
          class="flexbox giftitem"
          v-for="(item, index) of mine"
          :key="index"
        >
          <div
            class="img"
            :style="{ backgroundImage: `url(${imgurl + item.path})` }"
          ></div>
          <div class="flexbox giftname">
            {{ $t(`gift.type${item.cid}gift${item.gid}`) }}
          </div>
          <div class="flexbox giftprice" style="color: #4af">
            {{ item.givername }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      gift: {
        showgift: false,
        active: '',
        showsend: false,
        sendnum: 1,
        sendid: 0,
      },
    }
  },
  computed: {
    giftlist () {
      return this.$store.state.Store.storeList;
    },
    mine () {
      return this.$store.state.Store.mine;
    },
  },
  created () {
    this.$store.dispatch('getStoreMine');
  },
  methods: {
    back () {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/' })
        return false
      } else {
        this.$router.go(-1)
      }
    },
	gohome () {
      this.$router.push({ path: '/' })
    },
  }
}
</script>

<style lang="scss" scoped>
.invest_area {
  width: 100%;
  height: 20px;
  padding: 20px;
  color: #303133;
  position: relative;
}
.invest_area_top_icon {
  position: absolute;
  height: 20px;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
}
.invest_area_top_icon img:nth-of-type(1) {
  width: 20px;
  height: 16px;
  margin-right: 15px;
}
.invest_area_top_icon img:nth-of-type(2) {
  width: 25px !important;
  height: 25px !important;
  top: 10px;
}
.invest_area_top {
  text-align: center;
  font-size: 18px;
  height: 20px;
  line-height: 20px;
}
.giftcontainer {
  width: 100%;
  height: auto;
  .giftitembox {
    justify-content: flex-start;
    padding: 10px;
    .giftitem {
      width: 20%;
      height: auto;
      align-content: space-between;
      font-size: 12px;
      margin: 2.5%;
      .img {
        width: 100%;
        padding: 50%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
      .giftname {
        height: 20px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #333;
      }
      .giftprice {
        height: 20px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: "#999";
      }
      .sendbtn {
        padding: 0 5px;
        height: 20px;
        border: 1px solid #ff4a53;
        color: #ff4a53;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }
}
.receivebox {
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    padding-left: 10px;
  }
}
</style>