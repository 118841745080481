import i18n from "../i18n/index";
import router from "../router/index";
import store from "../store/index";
import { ElMessage } from "element-plus";

export default {
  htmlEncodeByRegExp(str) {
    var s = "";
    if (str.length == 0) return "";
    s = str.replace(/&/g, "&amp;");
    s = s.replace(/</g, "&lt;");
    s = s.replace(/>/g, "&gt;");
    s = s.replace(/\s/g, "&nbsp;");
    s = s.replace(/'/g, "&#39;");
    s = s.replace(/"/g, "&quot;");
    return s;
  },
  htmlDecodeByRegExp(str) {
    var s = "";
    if (str.length == 0) return "";
    s = str.replace(/&amp;/g, "&");
    s = s.replace(/&lt;/g, "<");
    s = s.replace(/&gt;/g, ">");
    s = s.replace(/&nbsp;/g, " ");
    s = s.replace(/&#39;/g, "'");
    s = s.replace(/&quot;/g, '"');
    return s;
  },

  /**
   * 将时间戳转为时间
   * @param {number} stamp
   * @returns
   */
  getDateByTimeStamp(stamp) {
    let datetime = [];
    let date = new Date(stamp * 1000);
    datetime.push(date.getFullYear());
    datetime.push(date.getMonth() + 1);
    datetime.push(date.getDate());
    return datetime.join("-");
  },

  /**
   * 将时间转为动态时间
   * @param {Date} oldDate
   * @returns
   */
  getTrendtimeByDate(oldDate) {
    let newDate = new Date(),
      mili = newDate - oldDate;
    if (mili < 1000) {
      return i18n.global.t("date.just");
    } else if (mili < 60 * 1000) {
      return Math.floor(mili / 1000) + i18n.global.t("date.second");
    } else if (mili < 60 * 60 * 1000) {
      return Math.floor(mili / 60 / 1000) + i18n.global.t("date.minute");
    } else if (mili < 24 * 60 * 60 * 1000) {
      return Math.floor(mili / 60 / 60 / 1000) + i18n.global.t("date.hour");
    } else {
      if (newDate.getFullYear() == oldDate.getFullYear()) {
        return (
          i18n.global.t("date.month" + (oldDate.getMonth() + 1)) +
          " " +
          oldDate.getDate()
        );
      } else {
        return (
          oldDate.getFullYear() +
          " " +
          i18n.global.t("date.month" + (oldDate.getMonth() + 1)) +
          " " +
          oldDate.getDate()
        );
      }
    }
  },

  /**
   * 从链接中读取传值
   * @param {string} name
   * @returns {string | null}
   */
  getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return decodeURI(r[2]);
    return null;
  },

  /**
   * @function 将键值对转成链接参数
   * @param {Object} data
   */
  urlEncode(data) {
    var _result = [];
    for (var key in data) {
      var value = null;
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty(key)) value = data[key];
      if (value.constructor === Array) {
        value.forEach(function(_value) {
          _result.push(key + "=" + _value);
        });
      } else {
        _result.push(key + "=" + value);
      }
    }
    return _result.join("&");
  },

  /**
   * 移动端返回true，PC端返回false
   * @returns {boolean}
   */
  judgeTerminal() {
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      return true;
    } else {
      return false;
    }
  },

  checkPermisionAndPushRouter(route, permission) {
    if (store.state.User.authority[permission]) {
      router.push(route);
    } else {
      ElMessage.error(i18n.global.t("tips.nopermission")+'!!');
    }
  },

  checkPermision(permission) {
	console.log('permiss:'+permission)
	var auth = localStorage.getItem(permission);
	console.log(auth)
	return auth == 'true';
    //return store.state.User.authority[permission];
  },
};
