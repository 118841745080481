<template>
  <div
    class="flexbox chatcontentbox"
    :style="{
      justifyContent: senderIsSelf ? 'flex-end' : 'flex-start',
      paddingRight: senderIsSelf ? '0px' : '40%',
      paddingLeft: senderIsSelf ? '40%' : '0px',
    }"
  >
    <div class="pop-right" v-if="senderIsSelf">
      <Expression v-if="data.type == 'text'" :text="data.content" />
      <el-image
        style="max-width: 200px; height: auto"
        v-else-if="data.type == 'img'"
        :src="imgurl + data.img"
        :preview-src-list="[imgurl + data.img]"
      ></el-image>
	  <span class="ndate">{{data.ndate}}</span>
    </div>
    <div class="pop-left" v-else>
      <Expression v-if="data.type == 'text'" :text="data.content" />
      <el-image
        style="max-width: 200px; height: auto"
        v-else-if="data.type == 'img'"
        :src="imgurl + data.img"
        :preview-src-list="[imgurl + data.img]"
      ></el-image>
	  <span class="ndate">{{data.ndate}}</span>
    </div>
  </div>
</template>

<script>
import Expression from "./Expression.vue"
export default {
  props: ['data'],
  components: {
    Expression,
  },
  computed: {
    // 判断是不是自己发送的
    senderIsSelf () {
      if (this.$store.state.User.id == this.data.sender_id) {
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>

<style scoped>
.chatcontentbox {
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
.pop-right {
  position: relative;
  padding: 5px 10px;
  background-color: #ffffff;
  border-radius: 5px 0 5px 5px;
  margin-right: 10px;
}

.pop-left {
  position: relative;
  padding: 5px 10px;
  background-color: #ffaa99;
  border-radius: 0 5px 5px 5px;
  margin-left: 10px;
}

.ndate{
  font-size:13px;
  color:#999;
}
</style>