<template>
  <div class="content_area">
    <div class="basicinfo">基本资料</div>
    <el-form-item>
      <el-input
        v-model="username"
        :placeholder="$t('regist.username') + '*'"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-input
        v-model="email"
        :placeholder="$t('login.email') + '*'"
      ></el-input>
    </el-form-item>
	<el-form-item>
      <el-input
        v-model="laid"
        :placeholder="$t('regist.laid') + '*'"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-input
        v-model="password"
        type="password"
        :placeholder="$t('regist.pwd') + '*'"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <span class="el-form-item-span">{{ $t("regist.gender") }}</span>
      <el-radio-group v-model="gender" style="color: #fff">
        <el-radio :label="1">{{ $t("regist.male") }}</el-radio>
        <el-radio :label="0">{{ $t("regist.female") }}</el-radio>
      </el-radio-group>
    </el-form-item>

    <div class="el-form-item">
      <div class="el-form-item__content">
        <span class="el-form-item-span">{{ $t("regist.birth") }}</span>
        <el-input
          style="width: 100%"
          v-model="birthday"
          type="text"
          :placeholder="$t('regist.birth')"
          @focus="birthdayshow = true"
        >
        </el-input>
      </div>
    </div>

    <div class="el-form-item">
      <div class="el-form-item__content">
        <el-checkbox-group v-model="agree">
          <el-checkbox
            :label="$t('regist.agree')"
            name="type"
            @change="handleCheckedCitiesChange"
          ></el-checkbox>
        </el-checkbox-group>
        <router-link class="terms" to="/m/agreement">
          {{ $t("regist.terms") }}
        </router-link>
      </div>
    </div>

    <div style="margin: 16px">
      <van-button
        round
        block
        type="danger"
        style="background: #ff4a53 !important"
        @click="regist"
        >{{ $t("forgot.sunmit") }}</van-button
      >
    </div>
  </div>
  <van-action-sheet v-model:show="birthdayshow">
    <van-datetime-picker
      v-model="curDate"
      type="date"
      :title="$t('regist.birth')"
      :cancel-button-text="$t('global.cancel')"
      :confirm-button-text="$t('global.confirm')"
      :min-date="minDate"
      :max-date="maxDate"
      @cancel="cancelbirth"
      @confirm="confirmbirth"
      :formatter="formatter"
    />
  </van-action-sheet>
</template>
<script>
import { ElMessage } from 'element-plus'
export default {
  data () {
    return {
      username: '',
      email: '',
      password: '',
      laid: '',
      gender: 1,
      birthday: `1990-1-1`,
      agree: true,
      date: '',
      dialog: false,

      birthdayshow: false,
      curDate: new Date(1990, 0, 1),
      minDate: new Date(1950, 0, 1),
      maxDate: new Date(),
    }
  },
  methods: {
    confirmbirth (value) {
      console.log(value);
      let date = new Date(value);
      this.birthday = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      this.birthdayshow = false;
    },
    cancelbirth () {
      this.birthdayshow = false;
    },
    formatDate (date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm (date) {
      this.show = false;
      this.date = this.formatDate(date);
    },
    handleCheckedCitiesChange (value) {
      this.agree = value
    },
    verify () {
      if (this.username.trim() == "") {
        ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.username'));
        return false;
      //} else if (!/[0-9a-zA-Z]{2,8}/.test(this.username.trim())) {
      //  ElMessage.error(this.$t('regist.username') + ' ' + this.$t('regist.form.fomate'));
      //  return false;
      } else if (this.email.trim() == "") {
        ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.email'));
        return false;
      //} else if //(!/^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(this.email)) {
      //  ElMessage.error(this.$t('regist.email') + ' ' + this.$t('regist.form.fomate'));
      //  return false;
      } else if (this.password.trim() == "") {
        ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.pwd'));
        return false;
      } else if (this.laid.trim() == "") {
        ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.laid'));
        return false;
      } else if (this.birthday == "") {
        ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.birth'));
        return false;
      } else if (!this.agree) {
        return false;
      }
      return true;
    },
    regist () {
      let birthday = new Date(this.birthday).getTime();
      if (this.verify()) {
        this.axios.post_form("/member/index/register", {
          username: this.username,
          email: this.email,
          password: this.password,
		  laid: this.laid,
          sex: this.gender,
          birthday,
		  country: '',
        }).then(res => {
          res = res.data;
          if (res.status == 200) {
            if (res.data.status == 2) {
              ElMessage.error(this.$t('tips.underReview'));
            } else {
              ElMessage.success({
                message: this.$t('regist.form.seccess'),
                type: 'success'
              });
            }
            this.$store.commit('login', res.data);
            this.$store.dispatch("updateUserAuthority");
            this.$router.push({ path: '/' })
          } else if (res.status == -1) {
            ElMessage.error(this.$t('regist.username') + ' ' + this.$t('regist.form.used'));
          } else if (res.status == -2) {
            ElMessage.error(this.$t('regist.email') + ' ' + this.$t('regist.form.used'));
          } else if (res.status == -3) {
            ElMessage.error(this.$t('tips.underReview'));
          } else {
            ElMessage.error(this.$t('regist.form.faile'));
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content_area {
  width: 100%;
  height: 100vh;
  padding: 20px;
}
.basicinfo {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.el-form-item__content {
  display: flex !important;
}
.el-form-item-span {
  margin-right: 10px;
  flex-shrink: 0;
}
</style>
