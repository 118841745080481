<template>
  <div class="invest_area">
    <div class="invest_area_top">
      <div class="invest_area_top_icon">
        <img
          src="@/assets/images/phone/topback_black.png"
          alt=""
          @click="back"
        />
        <img
          src="@/assets/images/phone/home_black.png"
          alt=""
          @click="gohome"
        />
      </div>
      <span>{{ $i18n.t("navigator.general") }}</span>
    </div>
  </div>
  <div class="menuList">
    <!-- 我的头像 -->
    <div class="menuItem" @click="avatardrawer = true">
      <div class="left">{{ $t("user.setup.myavatar") }}</div>
      <div class="left">
        <img class="ico" v-if="avatar" :src="imgurl + avatar" alt="" />
        <img
          class="ico"
          v-else
          src="~@/assets/images/phone/no-avatar.png"
          alt=""
        />
        <van-icon name="arrow" />
      </div>
    </div>
    <!-- 资料设置 -->
    <router-link class="terms" to="/m/home/my/Setup/data">
      <div class="menuItem">
        <div class="left">{{ $t("user.setup.setting") }}</div>
        <div class="left">
          <van-icon name="arrow" />
        </div>
      </div>
    </router-link>

    <!-- 个性签名 -->
    <router-link class="terms" to="/m/home/my/Setup/changesignature">
      <div class="menuItem">
        <div class="left">{{ $t("user.setup.signature") }}</div>
        <div class="left">
          <van-icon name="arrow" />
        </div>
      </div>
    </router-link>

    <!-- 语言 -->
    <router-link class="terms" to="/m/home/my/Setup/lang">
      <div class="menuItem">
        <div class="left">{{ $t("user.setup.languge") }}</div>
        <div class="left">
          <span class="langtxt">{{ lang }}</span>
          <van-icon name="arrow" />
        </div>
      </div>
    </router-link>

    <!-- 更换密码 -->
    <router-link class="terms" to="/m/home/my/Setup/changepwd">
      <div class="menuItem">
        <div class="left">{{ $t("user.setup.changepwd") }}</div>
        <div class="left">
          <van-icon name="arrow" />
        </div>
      </div>
    </router-link>

    <div class="logout">
      <van-button
        round
        block
        type="danger"
        style="background: #ff4a53 !important"
        @click="logout"
        >{{ $t("navigator.signout") }}</van-button
      >
    </div>
    <!-- 图片文件 -->
    <input
      type="file"
      accept="image/*"
      v-show="false"
      @change="fileChange"
      ref="fileinput"
    />
    <el-drawer
      v-model="avatardrawer"
      direction="btt"
      :with-header="false"
      :size="100"
      destroy-on-close
    >
      <!-- <div class="draweritem" @click="setavatar('photograph')"> {{ $t("user.setup.photograph") }}</div> -->

      <div class="draweritem" @click="$refs.fileinput.click()">
        {{ $t("user.setup.Choosefromalbum") }}
      </div>

      <!-- <form id="myForm" class="hiddenInput" enctype="multipart/form-data" name="fileinfo" action="" target="uploadFrame">
                <div class="img-file">
                    <input @change='changeImg' id='imgFile' type='file'/>
                </div>
            </form> -->

      <!-- <div  class="draweritem" @click="setavatar('UsedAvatar')"> {{ $t("user.setup.UsedAvatar") }}</div> -->

      <div class="draweritem" @click="setavatar('cacle')">
        {{ $t("user.edit.cancel") }}
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  data () {
    return {
      avatardrawer: false,
      avatar: '',
      lang: '',
      file: '',
      ids: '',
      headimgurl: '',
    }
  },
  computed: {
    avatar () {
      return this.$store.state.User.avatar;
    }
  },
  created () {
    this.initData();
  },
  methods: {
    back () {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/' })
        return false
      } else {
        this.$router.go(-1)
      }
    },
    gohome () {
      this.$router.push({ path: '/' })
    },
    initData () {
      // this.email = this.$store.state.User.email;
      // this.birthday = new Date(parseInt(this.$store.state.User.birthday));
      // this.gender = parseInt(this.$store.state.User.gender);
      // this.avatar = this.$store.state.User.avatar;
      let lang = this.$i18n.locale;
      if (lang === "en") {
        this.lang = "English";
      } else if (lang === "zhcn") {
        this.lang = "简体中文";
      } else if (lang === "zhtw") {
        this.lang = "繁體中文";
      }
    },
    setavatar (type) {
      if (type == 'cacle') {
        this.avatardrawer = false;
      } else if (type == 'photograph') {
        //拍照
      } else if (type == 'Choosefromalbum') {
        //从相册中选择
      } else if (type == 'UsedAvatar') {
        //使用过的头像
      }
    },
    // 保存头像
    saveavatar (avatar) {
      this.axios.post_form("/member/index/profile", {
        avatar,
        email: this.$store.state.User.email,
        sex: this.$store.state.User.gender,
        birthday: this.$store.state.User.birthday,
      }).then(res => {
        res = res.data;
        if (res.status == 200) {
          ElMessage.success({
            message: this.$t('user.edit.modifiedsuccess'),
            type: 'success'
          });
          this.initData();
          this.avatardrawer = false;
          this.$store.dispatch("updateUserInfo");
          this.show = false;
        } else if (res.status == -1) {
          ElMessage.error(this.$t('user.edit.modifiedfail'));
        } else if (res.status == -2) {
          ElMessage.error(this.$t('user.edit.modifiedfail'));
        } else {
          ElMessage.error(this.$t('user.edit.modifiedfail'));
        }

      })
    },
    // 发送图片
    fileChange (e) {
      e.preventDefault();
      if (this.loading !== 1) {
        let files = e.target.files || e.dataTransfer.files;
        this.sourceImgUrl = files[0];
        this.axios
          .post_form("/member/index/uploads", {
            file: this.sourceImgUrl,
          })
          .then((res) => {
            res = res.data;
            if (res.status == 200) {
              this.saveavatar(res.data.url);
            }
          });
      }
    },
    //退出登录
    logout () {
      this.$store.commit("logout");
      this.$store.commit('closeWebsocket');
      let pre = this.util.judgeTerminal() ? "m" : "w";
      this.$router.push({ name: pre + "Login" });
    },
  },
}
</script>

<style lang="scss" scoped>
.invest_area {
  width: 100%;
  height: 20px;
  padding: 20px;
  color: #303133;
  position: relative;
}
.invest_area_top_icon {
  position: absolute;
  height: 20px;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
}
.invest_area_top_icon img:nth-of-type(1) {
  width: 20px;
  height: 16px;
  margin-right: 15px;
}
.invest_area_top_icon img:nth-of-type(2) {
  width: 25px !important;
  height: 25px !important;
  top: 10px;
}
.invest_area_top {
  text-align: center;
  font-size: 18px;
  height: 20px;
  line-height: 20px;
}

.menuList {
  padding: 20px 0 100px;
}
.menuItem {
  display: flex;
  justify-content: space-between;
  margin: 0px 15px;
  padding: 15px 0px;
  align-items: center;
  border-bottom: 1px solid #edeff2;
}
.menuItem .left {
  display: flex;
  align-items: center;
  color: #909399;
  font-size: 14px;
}
.menuItem .left .ico {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  border: 1px solid #f1f1f1;
}
.langtxt {
  display: inline-block;
  float: right;
  margin-right: 10px;
  color: #000;
}
.logout {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 100;
  background: #fff;
  height: 60px;
  padding: 0px 16px;
}
.draweritem {
  text-align: center;
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #f4f4f4;
}
.el-drawer.btt {
  height: auto !important;
}

.hiddenInput {
  opacity: 0;
  width: 100%;
  height: 100%;
}
</style>