<template>
  <div class="flexbox loginmenucontainer">
    <router-link class="sign" to="/w/login" v-if="signin && !isLogin">
      {{ $t("loginmenu.signin") }}
    </router-link>
    <router-link class="sign" to="/w/regist" v-if="signup && !isLogin">
      {{ $t("loginmenu.signup") }}
    </router-link>
    <!-- 通知 -->
    <el-dropdown v-if="isLogin && userstatus">
      <div class="sign" v-if="isLogin">
        {{ $t("navigator.notice") }}
        <div class="cirtip" v-if="unreadnotice.length > 0"></div>
      </div>
      <template #dropdown>
        <el-dropdown-menu v-if="unreadnotice.length > 0">
          <div
            v-for="(item, index) of unreadnotice"
            :key="index"
            @click="showNotice(item)"
          >
            <el-dropdown-item v-if="item.type == 'gift'">
              <i class="iconfont icon-liwu"></i>
              {{ $t("notice.gift.receive") }}
            </el-dropdown-item>
            <el-dropdown-item v-else-if="item.type == 'report'">
              <i class="iconfont icon-menu_shjb"></i>
              {{ $t("notice.report.result") }}
            </el-dropdown-item>
          </div>
        </el-dropdown-menu>
        <el-dropdown-menu v-else>
          <div style="padding: 10px 30px">{{ $t("empty.nonitice") }}</div>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <!-- 消息2 -->
    
    <el-dropdown v-if="isLogin">
      <div class="sign" style="display: block">
        {{ username }} <i class="el-icon-arrow-down"></i>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <router-link to="/w/home/user/index">
            <el-dropdown-item> {{ $t("navigator.profile") }} </el-dropdown-item>
          </router-link>
          <router-link to="/w/home/user/edit">
            <el-dropdown-item> {{ $t("navigator.edit") }} </el-dropdown-item>
          </router-link>
          <router-link to="/w/home/user/general">
            <el-dropdown-item> {{ $t("navigator.general") }} </el-dropdown-item>
          </router-link>
          <router-link to="/w/home/user/notification">
            <el-dropdown-item> {{ $t("navigator.mail") }} </el-dropdown-item>
          </router-link>
          <div class="line"></div>
          <div @click="logout">
            <el-dropdown-item>
              {{ $t("navigator.signout") }}
            </el-dropdown-item>
          </div>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <div class="langitem">
      <el-dropdown>
        <span class="el-dropdown-link">
          <i :class="icon" style="margin-right: 5px"></i>
          {{ lang }}
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="setlang('en')">
              <i class="iconfont icon-yingwen"></i>
              English
            </el-dropdown-item>
            <el-dropdown-item @click="setlang('zhcn')">
              <i class="iconfont icon-jianti"></i>
              简体中文
            </el-dropdown-item>
            <el-dropdown-item @click="setlang('zhtw')">
              <i class="iconfont icon-fanti"></i>
              繁體中文
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>

  <!-- 发送消息 -->
  <el-dialog :title="$t('message.send')" v-model="sendwindowvisibility">
    <el-form :model="form">
      <div class="sendinput">
        <el-input v-model="form.address" :placeholder="$t('message.address')">
        </el-input>
      </div>
      <div class="sendtextarea">
        <el-input
          type="textarea"
          :placeholder="$t('store.msg')"
          v-model="form.message"
          maxlength="50"
          show-word-limit
        >
        </el-input>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="sendwindowvisibility = false">
          {{ $t("global.cancel") }}
        </el-button>
        <el-button type="primary" @click="send">
          {{ $t("global.send") }}
        </el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 查看消息 -->
  <el-dialog :title="detail.sendername" v-model="detailShow">
    <div class="detailcontent">
      {{ detail.msg }}
    </div>
  </el-dialog>

  <!-- 查看礼物 -->
  <el-dialog :title="$t('notice.gift.receive')" v-model="giftShow">
    <div class="giftcontent">
      <div class="giftsender">
        {{ $t("notice.gift.from") }} <span>{{ gift.gift.givername }}</span>
      </div>
      <div class="flexbox giftcontainer">
        <div class="flexbox giftitem">
          <div
            class="img"
            :style="{ backgroundImage: `url(${imgurl + gift.gift.path})` }"
          ></div>
          <div class="flexbox giftname">
            {{ $t(`gift.type${gift.gift.cid}gift${gift.gift.gid}`) }}
          </div>
        </div>
      </div>
      <div class="msg">{{ $t("notice.gift.leavemsg") }} {{ gift.content }}</div>
    </div>
  </el-dialog>

  <!-- 查看举报结果 -->
  <el-dialog :title="$t('notice.report.result')" v-model="reportShow">
    <div class="detailcontent">
      {{ $t("notice.report.reportdetail") }}
    </div>
  </el-dialog>
</template>

<script>
import { defaultTitle } from "../../common/project.config";
export default {
  props: ["signin", "signup"],
  data () {
    return {
      lang: "",
      icon: "",
      sendwindowvisibility: false,
      form: {
        address: '',
        message: '',
      },
      detailShow: false,
      detail: {},

      giftShow: false,
      gift: {},
      reportShow: false,
      report: {},
    }
  },
  computed: {
    username () {
      return this.$store.state.User.username;
    },
    isLogin () {
      return this.$store.state.User.isLogin;
    },
    unreadmail () {
      return this.$store.state.Message.unread;
    },
    unreadnotice () {
      return this.$store.state.Notification.unread;
    },
    userstatus () {
      return this.$store.state.User.status != 2;
    }
  },
  methods: {
    initlang () {
      let lang = this.$i18n.locale;
      if (lang === "en") {
        this.lang = "English";
        this.icon = "iconfont icon-yingwen"
      } else if (lang === "zhcn") {
        this.lang = "简体中文";
        this.icon = "iconfont icon-jianti"
      } else if (lang === "zhtw") {
        this.lang = "繁體中文";
        this.icon = "iconfont icon-fanti"
      }
      document.title = this.$route.meta.title ? this.$i18n.t("title." + this.$route.meta.title) : defaultTitle;
    },
    setlang (lang) {
      this.$i18n.setLang(lang);
      this.initlang();
      this.$store.commit("changeLang", lang);
    },
    logout () {
      this.$store.commit("logout");
      this.$store.commit('closeWebsocket');
      let pre = this.util.judgeTerminal() ? "m" : "w";
      this.$router.push({ name: pre + "Login" });
    },
    send () {
      if (this.util.checkPermision('Start_mail_dialog')) {
        this.axios.post_form("/member/email/released", {
          email: this.form.address,
          content: this.form.message,
        }).then(res => {
          this.sendwindowvisibility = false;
          res = res.data;
          if (res.status == 200) {
            this.$message.success({
              message: this.$t('tips.sendsuccess'),
              type: 'success'
            });
          } else if (res.status == -1) {
            this.$message.error(this.$t('tips.usernotexist'));
          } else if (res.status == -2) {
            this.$message.error(this.$t('tips.usernotexist'));
          } else {
            this.$message.error(this.$t('tips.sendfail'));
          }
        })
      } else {
        this.$message.error(this.$t("tips.nopermission")+'~~');
      }
    },
    showDetail (data) {
      if (this.util.checkPermision('Read_mails')) {
        this.detail = data;
        this.detailShow = true;
        if (data.type == 0) {
          this.axios.post_form('/member/email/updatestatus', {
            id: data.eid,
          }).then(res => {
            res = res.data;
            if (res.status == 200) {
              data.type = 1;
              this.$store.commit("setUnready");
            }
          })
        }
      } else {
        this.$message.error(this.$t("tips.nopermission")+'~');
      }
    },
    showNotice (data) {
      if (data.type == 'gift') {
        this.gift = data;
        this.giftShow = true;
      } else if (data.type == 'report') {
        this.report = data;
        this.reportShow = true;
      }
      if (data.status == 0) {
        this.axios.post_form('/member/index/upnotice', {
          id: data.id,
        }).then(res => {
          res = res.data;
          if (res.status == 200) {
            data.status = 1;
            this.$store.commit("setNoticeUnready");
          }
        })
      }
    },
  },
  created () {
    this.initlang();
  },
  mounted () {

  }
}
</script>

<style lang="scss" scope>
.loginmenucontainer {
  width: 100%;
  height: 80px;
  padding: 10px 15px;
  // background-color: rgba($color: $themeColor, $alpha: 0.2);
  font-size: 16px;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  .sign {
    display: flex;
    height: 26px;
    line-height: 22px;
    font-size: 14px;
    padding: 3px 15px;
    border-radius: 3px;
    background-color: #8f75a2;
    margin-right: 20px;
    cursor: pointer;
    color: #000;
  }
  .langitem {
    background-color: #8f75a2;
    padding: 3px 15px;
    border-radius: 3px;
  }
}
.line {
  width: 100%;
  height: 1px;
  background-color: #8f75a2;
}
.sendtextarea {
  margin-top: 20px;
}
.detailcontent {
  margin-bottom: 10px;
}
.giftcontent {
  margin-bottom: 10px;
  .giftsender {
    font-size: 14px;
    font-weight: bold;
    span {
      color: $linkColor;
    }
  }
  .giftcontainer {
    .giftitem {
      width: 80px;
      height: auto;
      margin: 15px;
      .img {
        width: 80px;
        height: 80px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
      .giftname {
        min-height: 30px;
        text-align: center;
      }
      .giftsender {
        height: 25px;
        .sender {
          padding: 0 5px;
          color: $linkColor;
        }
      }
    }
  }
  .msg {
    padding: 20px 30px;
    background-color: #f1f2f3;
  }
}
.cirtip {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #f66;
  margin-left: 5px;
  margin-top: 5px;
}
</style>