<template>
  <div class="websit">
    <div class="flexbox-c header">
      <div class="menu">
        <Navigator :signin="true" :signup="true" />
      </div>
      <div class="websittitle">MAKE YOUR LIFE MORE INTERESTING</div>
      <div class="flexbox-s loginbox">
        <img src="~@/assets/images/heart_logo.png" alt="" class="logo" />
        <router-link class="btn" to="/w/login">
          {{ $t("websit.signin") }}
        </router-link>
        <router-link class="btn" to="/w/regist">
          {{ $t("websit.join") }}
        </router-link>
      </div>
    </div>
    <div class="flexbox-c body">
      <div class="flexbox-c bodycontainer">
        <div class="formtitle">{{ $t("regist.title") }}</div>
        <div class="formbody">
          <div class="formcontent">
            {{ $t("regist.subtitle") }}
          </div>

          <div class="boxhead" style="margin-bottom: 15px; border: 0">
            <i class="el-icon-user-solid"></i>
            <div class="registtitle">{{ $t("regist.registp") }}</div>
          </div>

          <div class="boxhead">
            <div class="formitemtitle">{{ $t("regist.formtitlte") }}</div>
          </div>
          <div class="boxbody">
            <div class="boxitem">
              <div class="left">{{ $t("regist.username") }}</div>
              <div class="center">
                <el-input
                  v-model="username"
                  :placeholder="$i18n.t('regist.username')"
                  maxlength="12"
                ></el-input>
              </div>
              <div class="right">{{ $t("regist.containe") }}</div>
            </div>
            <div class="boxitem">
              <div class="left">{{ $t("regist.email") }}</div>
              <div class="center">
                <el-input
                  v-model="email"
                  :placeholder="$t('regist.email')"
                ></el-input>
              </div>
              <div class="right">{{ $t("regist.valid") }}</div>
            </div>
			<div class="boxitem">
              <div class="left">{{ $t("regist.laid") }}</div>
              <div class="center">
                <el-input
                  v-model="laid"
                  :placeholder="$t('regist.laid')"
                ></el-input>
              </div>
              <div class="right"></div>
            </div>
            <div class="boxitem">
              <div class="left">{{ $t("regist.pwd") }}</div>
              <div class="center">
                <el-input
                  v-model="password"
                  :placeholder="$t('regist.pwd')"
                  maxlength="18"
                  show-password
                ></el-input>
              </div>
              <div class="right"></div>
            </div>
            <div class="boxitem">
              <div class="left">{{ $t("regist.gender") }}</div>
              <div class="center" style="justify-content: flex-start">
                <el-radio-group v-model="gender" style="color: #fff">
                  <el-radio :label="1">{{ $t("regist.male") }}</el-radio>
                  <el-radio :label="0">{{ $t("regist.female") }}</el-radio>
                </el-radio-group>
              </div>
              <div class="right"></div>
            </div>
            <div class="boxitem">
              <div class="left">{{ $t("regist.birth") }}</div>
              <div class="center" style="justify-content: flex-start">
                <el-select v-model="date" :placeholder="$t('regist.date')">
                  <el-option
                    v-for="item in dateOption"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-model="month"
                  :placeholder="$t('regist.month')"
                  @change="monthchange"
                >
                  <el-option
                    v-for="item in monthOption"
                    :key="item"
                    :label="$t('date.month' + item)"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-model="year"
                  :placeholder="$t('regist.year')"
                  @change="yearchange"
                >
                  <el-option
                    v-for="item in yearOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="right"></div>
            </div>
            <div class="boxitem">
              <div class="left">
                <router-link class="terms" to="/w/info/terms-of-use">
                  {{ $t("loginfooter.terms") }}
                </router-link>
              </div>
              <div class="center" style="justify-content: flex-start">
                <el-checkbox v-model="agree">
                  {{ $t("regist.agree") }}
                </el-checkbox>
              </div>
              <div class="right"></div>
            </div>
          </div>
          <div class="flexbox formbuttoncontainer">
            <div class="flexbox loginbtn" @click="regist">
              <div>{{ $t("regist.signup") }}</div>
              |
              <i class="iconfont icon-tubiaozhizuomoban"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../../components/web/LoginFooter"
import Navigator from "../../components/web/Navigator"
import { getDateOptions, checkmonthdate, yearOption, monthOption } from "../../util/datepickeroption"
import { ElMessage } from 'element-plus'
export default {
  data () {
    return {
      username: "",
      email: "",
      password: "",
      laid: "",
      gender: 1,
      birthday: "",
      year: 1990,
      month: 1,
      date: 1,
      agree: true,
      yearOption,
      monthOption,
      dateOption: getDateOptions('', ''),
    }
  },
  methods: {
    verify () {
      if (this.username.trim() == "") {
        ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.username'));
        return false;
      //} else if (!/[0-9a-zA-Z]{2,8}/.test(this.username.trim())) {
      //  ElMessage.error(this.$t('regist.username') + ' ' + this.$t('regist.form.fomate'));
      //  return false;
      } else if (this.email.trim() == "") {
        ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.email'));
        return false;
      //} else if(!/^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(this.email)) {
      //  ElMessage.error(this.$t('regist.email') + ' ' + this.$t('regist.form.fomate'));
      //  return false;
      } else if (this.password.trim() == "") {
        ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.pwd'));
        return false;
      } else if (this.laid.trim() == "") {
        ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.laid'));
        return false;
      } else if (this.birthday == "") {
        ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.birth'));
        return false;
      } else if (!this.agree) {
        return false;
      }
      return true;
    },
    regist () {
      if (this.year && this.month && this.date) {
        this.birthday = `${this.year}.${this.month}.${this.date}`;
      }
      let birthday = new Date(this.birthday).getTime();
      if (this.verify()) {
        this.axios.post_form("/member/index/register", {
          username: this.username,
          email: this.email,
          password: this.password,
		  laid: this.laid,
          sex: this.gender,
          birthday,
          country: ''
        }).then(res => {
          res = res.data;
          if (res.status == 200) {
            if (res.data.status == 2) {
              ElMessage.error(this.$t('tips.underReview'));
            } else {
              ElMessage.success({
                message: this.$t('regist.form.seccess'),
                type: 'success'
              });
            }

            this.$store.commit('login', res.data);
            this.$store.dispatch("updateUserAuthority");
            this.$router.push({ path: '/' })
          } else if (res.status == -1) {
            ElMessage.error(this.$t('regist.username') + ' ' + this.$t('regist.form.used'));
          } else if (res.status == -2) {
            ElMessage.error(this.$t('regist.email') + ' ' + this.$t('regist.form.used'));
          } else if (res.status == -3) {
            ElMessage.error(this.$t('tips.underReview'));
          } else {
            ElMessage.error(this.$t('regist.form.faile'));
          }
        })
      }
    },
    yearchange () {
      let date = checkmonthdate(this.year, this.month);
      if (date < this.date) {
        this.date = date;
      }
      this.dateOption = getDateOptions(this.year, this.month);
    },
    monthchange () {
      let date = checkmonthdate(this.year, this.month);
      if (date < this.date) {
        this.date = date;
      }
      this.dateOption = getDateOptions(this.year, this.month);
    },
  },
  components: {
    Footer,
    Navigator
  },
}
</script>

<style lang="scss" scoped>
.center .el-radio {
  color: #fff;
}
.center .el-checkbox {
  color: #fff;
}
.center {
  .el-radio {
    .is-checked {
      & + .el-radio__label {
        color: #fff;
      }
    }
  }
}
.websit {
  background-color: #9c6d97;
  background-image: url(~@/assets/images/bodybg.png);
  background-position: left top;
  background-repeat: repeat-x;
}
.header {
  width: 100%;
  height: 500px;
  background-image: url(~@/assets/images/promo2.jpg);
  background-size: cover;
  background-position: center center;
  align-content: space-between;
  .menu {
    width: auto;
    height: auto;
    position: fixed;
    right: 0px;
    top: 0px;
  }
  .websittitle {
    color: #fff;
    font-size: 50px;
    margin-top: 150px;
    padding-right: 100px;
    font-family: "Times New Roman", Times, serif;
  }
  .loginbox {
    width: 100%;
    height: 75px;
    background: rgba(255, 255, 255, 0.3);
    align-self: flex-end;
    padding-top: 20px;
    .logo {
      width: 40px;
      height: 40px;
      margin-right: 60px;
      margin-left: 15px;
    }
    .btn {
      padding-top: 10px;
      padding-right: 20px;
      font-size: 21px;
      color: $themeColor;
    }
    .btn:hover {
      text-decoration: underline;
    }
  }
}
.body {
  min-height: 400px;
  background-color: #9c6d97;
  background-image: url(~@/assets/images/bodybg.png);
  background-position: left top;
  background-repeat: repeat-x;
}
.footer {
  background-color: #b997ae;
}
.infocontainer {
  width: 1000px;
  padding: 70px 0;
}
.bodycontainer {
  width: 1000px;

  .formtitle {
    width: 100%;
    color: $themeColor;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 20px;
  }
  .formbody {
    width: 800px;
  }
  .formcontent {
    width: 100%;
    word-wrap: break-word;
    font-size: 14px;
    color: #fff;
    text-align: center;
    padding: 14px;
  }
  .boxhead {
    display: flex;
    background: rgba(255, 255, 255, 0.2)
      url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpi/P//WRoDGmAB4ifYBF9gE/w3Kkiu4P/Bp/0/DbRTIAgQYAC2PiJjuOjlPgAAAABJRU5ErkJggg==)
      repeat-x left top;
    border: none;
    border-radius: 6px 6px 0px 0px;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding-left: 10px;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
  .boxbody {
    background: rgba(255, 255, 255, 0.05)
      url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpi/P//WRoDGmAB4ifYBF9gE/w3Kkiu4P/Bp/0/DbRTIAgQYAC2PiJjuOjlPgAAAABJRU5ErkJggg==)
      repeat-x left top;
    margin-bottom: 10px;
    .boxitem {
      display: flex;
      width: 100%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      border-left: 1px solid rgba(255, 255, 255, 0.5);
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      .left {
        width: 20%;
        display: flex;
        padding: 10px;
        justify-content: flex-end;
        align-items: center;
        align-content: center;
        color: #fff;
        font-size: 14px;
        .terms {
          color: $linkColor;
        }
        .terms:hover {
          text-decoration: underline;
        }
      }
      .center {
        width: 60%;
        display: flex;
        padding: 5px;
        justify-content: center;
        align-items: center;
        align-content: center;
        color: #fff;
        font-size: 14px;
        border-left: 1px solid rgba(255, 255, 255, 0.5);
        border-right: 1px solid rgba(255, 255, 255, 0.5);
      }
      .right {
        width: 20%;
        display: flex;
        padding: 10px;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        color: #fff;
        font-size: 14px;
      }
    }
  }

  .registtitle {
    color: $themeColor;
    width: 100%;
    padding: 10px;
  }

  .formitemtitle {
    color: #fff;
    width: 100%;
    padding: 10px;
    text-align: center;
  }
  .formbuttoncontainer {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 20px;
    justify-content: flex-end;
    .loginbtn {
      width: 100px;
      height: 32px;
      background-color: #e8cbed;
      padding: 0px 8px;
      border: 1px solid rgba(93, 69, 110, 0.6);
      border-radius: 4px;
      color: $themeColor;
      font-size: 14px;
      justify-content: space-between;
      cursor: pointer;
      .icon-tubiaozhizuomoban {
        font-size: 20px;
        color: $themeColor;
      }
    }
  }
}
</style>