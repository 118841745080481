<template>
  <div class="invest_area">
    <div class="invest_area_top">
      <div class="invest_area_top_icon">
        <img
          src="@/assets/images/phone/topback_black.png"
          alt=""
          @click="back"
        />
        <img
          src="@/assets/images/phone/home_black.png"
          alt=""
          @click="gohome"
        />
      </div>
      <span>{{ $i18n.t("home.upgrade") }}</span>
    </div>
  </div>
  <div class="invest_content">
    <van-tabs v-model="active" sticky @click="tabClick">
      <!-- 金卡会员 -->
      <van-tab :title="$t('upgrade.gold')">
        <div class="card_area">
          <div class="card_area_content" style="background: rgb(255, 137, 57)">
            {{ $t("upgrade.gold") }}
          </div>
          <div class="card_list">
            <div
              class="card_item"
              v-for="(item, index) of vip2.packages"
              :key="index"
              @click="ischoose('gold', index, item.id, item.amounts)"
              :class="[current == index ? 'card_item_choose' : '']"
            >
              <div class="card_item_left">
                <div class="month">
                  {{ item.duration }}{{ $t("date.month") }}
                </div>
                <div class="month_details">
                  <span
                    >{{ (item.amounts / item.duration).toFixed(2) }} USD</span
                  >
                  <span>/1{{ $t("date.month") }}</span>
                  <span
                    >{{ $t("account.save")
                    }}{{
                      parseInt(
                        ((vip2.price - item.amounts / item.duration) /
                          vip2.price) *
                          100
                      )
                    }}%</span
                  >
                </div>
              </div>
              <div class="card_item_right">${{ item.amounts }}</div>
            </div>
          </div>
        </div>
        <div class="privilege">
          <div class="privilege_title">{{ $t("account.membership") }}</div>
          <div class="privilege_list">
            <div
              class="privilege_item"
              v-for="(item, index) of gold"
              :key="'goldauth' + index"
            >
              <img src="@/assets/images/phone/info.png" alt="" />
              {{ $t(`authority.${item}`) }}
            </div>
          </div>
        </div>
        <div class="site"></div>
        <div class="money_fixed">
          <div class="money_fixed_left">
            <span>{{ $i18n.t("account.totalprice") }}</span>
            <span>$</span>
            <span>{{ money }}</span>
          </div>
          <div class="money_fixed_right" @click="gopayfor">
            {{ $i18n.t("mine.upgrade") }}
          </div>
        </div>
      </van-tab>
      <!-- 银卡会员 -->
      <van-tab :title="$t('upgrade.silver')">
        <div class="card_area">
          <div class="card_area_content" style="background: rgb(153, 153, 153)">
            {{ $t("upgrade.silver") }}
          </div>
          <div class="card_list">
            <div
              class="card_item"
              v-for="(item, index) of vip1.packages"
              :key="index"
              @click="ischoose('silver', index, item.id, item.amounts)"
              :class="[currentsilver == index ? 'card_item_choose' : '']"
            >
              <div class="card_item_left">
                <div class="month">
                  {{ item.duration }}{{ $t("date.month") }}
                </div>
                <div class="month_details">
                  <span
                    >{{ (item.amounts / item.duration).toFixed(2) }} USD</span
                  >
                  <span>/1{{ $t("date.month") }}</span>
                  <span
                    >{{ $t("account.save")
                    }}{{
                      parseInt(
                        ((vip2.price - item.amounts / item.duration) /
                          vip2.price) *
                          100
                      )
                    }}%</span
                  >
                </div>
              </div>
              <div class="card_item_right">${{ item.amounts }}</div>
            </div>
          </div>
        </div>
        <div class="privilege">
          <div class="privilege_title">{{ $t("account.membership") }}</div>
          <div class="privilege_list">
            <div
              class="privilege_item"
              v-for="(item, index) of silver"
              :key="'silverauth' + index"
            >
              <img src="@/assets/images/phone/info.png" alt="" />
              {{ $t(`authority.${item}`) }}
            </div>
          </div>
        </div>
        <div class="site"></div>
        <div class="money_fixed">
          <div class="money_fixed_left">
            <span>{{ $i18n.t("account.totalprice") }}</span>
            <span>$</span>
            <span>{{ money }}</span>
          </div>
          <div class="money_fixed_right" @click="gopayfor">
            {{ $i18n.t("mine.upgrade") }}
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  data () {
    return {
      //金卡
      current: -1,
      money: 0,
      //银卡
      currentsilver: -1,
      selected: 0,
    }
  },
  computed: {
    gold () {
      return this.$store.state.goldcard_authority;
    },
    silver () {
      return this.$store.state.silvercard_authority;
    },
    vip1 () {
      return this.$store.state.Upgrade.vip1;
    },
    vip2 () {
      return this.$store.state.Upgrade.vip2;
    },
    amount () {
      return this.$store.state.User.amount;
    }
  },
  created () {
    this.$store.dispatch('getPayPackage');
	let gitem = this.vip2.packages[0];
	this.ischoose('gold', 0, gitem.id, gitem.amounts);
  },
  methods: {
    back () {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/' })
        return false
      } else {
        this.$router.go(-1)
      }
    },
    gohome () {
      this.$router.push({ path: '/m/home/index' })
    },
    tabClick () {
      this.current = -1;
      this.money = 0;
      this.currentsilver = -1;
    },
    ischoose (type, index, id, amounts) {
      if (type == 'gold') {
        this.current = index
      } else if (type == 'silver') {
        this.currentsilver = index
      }
      this.money = amounts
      this.selected = id
    },
    gopayfor () {
      if (this.current == -1) {
        if (this.currentsilver == -1) {
          this.$message.error(this.$t('upgrade.choosemeal'));
          return false
        } else {
          this.$router.push({
            name: 'upgradelist',
            params: {
              amounts: this.money,
              selected: this.selected,
            }
          })
        }
      } else {
        this.$router.push({
          name: 'upgradelist',
          params: {
            amounts: this.money,
            selected: this.selected,
          }
        })
      }

    }
  },
}
</script>

<style lang="scss" scoped>
.invest_area {
  width: 100%;
  height: 20px;
  padding: 20px;
  color: #303133;
  position: relative;
}
.invest_area_top_icon {
  position: absolute;
  height: 20px;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
}
.invest_area_top_icon img:nth-of-type(1) {
  width: 20px;
  height: 16px;
  margin-right: 15px;
}
.invest_area_top_icon img:nth-of-type(2) {
  width: 25px !important;
  height: 25px !important;
  top: 10px;
}
.invest_area_top {
  text-align: center;
  font-size: 18px;
  height: 20px;
  line-height: 20px;
}

.invest_content {
  background: #f5f7fa;
}
.card_area {
  padding: 20px;
  background: #ffffff;
}
.card_area_content {
  width: 262px !important;
  height: 140px;
  display: inline-block;
  margin-left: 45px;
  border-radius: 10px;
  color: #ffffff;
  font-size: 20px;
  padding-top: 23px;
  padding-left: 23px;
  font-weight: 800;
}
.card_list {
  width: 100%;
  align-items: center;
}
.card_item {
  width: 92%;
  margin: 0 auto 10px auto;
  border: 1px solid #edeff2;
  border-radius: 5px;
  position: relative;
  height: 60px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
}
.card_item_choose {
  border: 1px solid rgb(255, 74, 83);
  background-color: rgb(255, 243, 244);
}
.card_item_left {
  flex-shrink: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.month {
  color: #303133;
  font-size: 20px;
  margin-left: 10px;
  font-weight: 600;
}
.month_details {
  display: flex;
  margin-top: 4px;
}
.month_details span:nth-of-type(1) {
  color: #303133;
  font-size: 12px;
  line-height: 20px;
}
.month_details span:nth-of-type(2) {
  color: #bfc2cc;
  font-size: 12px;
  line-height: 20px;
}
.month_details span:nth-of-type(3) {
  color: #ff4a53;
  font-size: 14px;
  margin-left: 10px;
}

.card_item_right {
  flex-shrink: 0;
  font-size: 20px;
  color: #ff4a53;
}
.privilege_title {
  font-size: 16px;
  color: #909399;
  padding: 10px 0 10px 25px;
  width: 100%;
  background: #f5f7fa;
}
.privilege_list {
  padding: 0px 20px;
  background: #ffffff;
  padding-bottom: 10px;
}
.privilege_item {
  width: 100%;
  padding-top: 10px;
  font-size: 14px;
  color: #303133;
  background: #ffffff;
  display: flex;
  align-items: center;
}
.privilege_item img {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}
.site {
  width: 100%;
  height: 60px;
}
.money_fixed {
  position: fixed;
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 60px;
  display: flex;
  z-index: 1000;
}
.money_fixed_left {
  flex-shrink: 1;
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding-left: 20px;
  display: flex;
}
.money_fixed_left span:nth-of-type(1) {
  font-size: 14px;
  color: #303133;
}
.money_fixed_left span:nth-of-type(2),
.money_fixed_left span:nth-of-type(3) {
  font-size: 18px;
  color: #ff4a53;
  margin-left: 4px;
}
.money_fixed_right {
  flex-shrink: 0;
  width: 130px;
  height: 61px;
  background: #ff4a53;
  font-size: 18px;
  font-weight: 100;
  border: 0;
  color: #ffffff;
  text-align: center;
  line-height: 60px;
}
</style>