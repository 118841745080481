<template>
  <div class="hidenchatwindow">
    <el-dropdown placement="top" trigger="click">
      <div class="flexbox chat">
        <i class="iconfont icon-liaotian"></i>
        {{ list.length }}
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-for="item of list"
            :key="item.uid"
            @click="exchangePositionToEnabalVisible(item.uid)"
          >
            {{ item.username }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  props: ['list'],
  methods: {
    exchangePositionToEnabalVisible (id) {
      this.$store.commit('exchangePositionToEnabalVisible', id);
    }
  }
}
</script>

<style lang="scss" scoped>
.hidenchatwindow {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  padding-right: 10px;
  .chat {
    width: 60px;
    height: 30px;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
    font-size: 12px;
    i {
      font-size: 20px;
      margin-right: 10px;
    }
  }
}
</style>