<template></template>

<script>
export default {
  props: ['code'],
  beforeCreate () {
    if (this.util.judgeTerminal()) {
      this.$router.replace('/m/regist/' + this.code);
    } else {
      this.$router.replace('/w/regist/' + this.code);
    }
  }
}
</script>

<style>
</style>