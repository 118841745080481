<template>
  <div class="invest_area">
    <div class="invest_area_top">
      <div class="invest_area_top_icon">
        <img
          src="@/assets/images/phone/topback_black.png"
          alt=""
          @click="back"
        />
      </div>
      <span>{{ $i18n.t("home.recharge") }}</span>
    </div>
  </div>
  <div class="content_area">
    <el-form-item>
      <el-input
        v-model="creditform.name"
        :placeholder="$t('pay.ename')"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-input
        v-model="creditform.email"
        :placeholder="$t('login.email')"
      ></el-input>
    </el-form-item>

    <el-form-item>
      <el-input
        v-model="creditform.phone"
        :placeholder="$t('pay.phone')"
      ></el-input>
    </el-form-item>

    <div style="margin: 16px">
      <van-button
        round
        block
        type="danger"
        @click="creditrecharge"
		:class="bgcolor"
        >{{ $t("mine.storemoney") }}</van-button
      >
    </div>
  </div>
</template>
<script>
import { ElLoading } from 'element-plus';
import { ElMessage } from 'element-plus'
import country from "@/util/country"
export default {
  data () {
    return {
      paytype: -1,
      selectedamount: 0,
      selected: 0,
      paymessage: [
        {
          name: "paypal",
          pay_type: 1,
        },
        {
          name: "cropay",
          pay_type: 3,
        },
        {
          name: "cropay_local",
          pay_type: 0,
          order_type: 0,
        },
        {
          name: "credits",
          pay_type: 2,
        },
      ],
      country,
      uuid: '',
      creditform: {
        name: '',
        email: '',
        phone: '',
      },
	  bgcolor:'red',
	  clickStatus:true,
    }
  },
  computed: {
  },
  created () {
    this.selectedamount = this.$route.params.selectedamount
    this.selected = this.$route.params.selected
    this.paytype = this.$route.params.paytype
    this.order_type = this.$route.params.order_type
  },
  methods: {
    back () {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/' })
        return false
      } else {
        this.$router.go(-2)
      }
    },
    creditrecharge () {
	  //10秒等待
	  if(this.clickStatus == false){
	    this.$message.error(this.$t('tips.patience'));
		return ;
	  }
	
	  this.clickStatus = false
	  this.bgcolor = 'grey'
	  setTimeout(()=>{
	    this.clickStatus = true
	    this.bgcolor = 'red'
	  },10000)
	  
	  this.creditform.email = this.creditform.email.trim()
	  
      if (this.creditform.name == '') {
        this.$message.error(this.$t('regist.form.required') + ' ' + this.$t('pay.name'));
        return false;
	  }else if(!/[a-zA-Z0-9]+/.test(this.creditform.name)) {
		this.$message.error(this.$t('regist.username') + ' ' + this.$t('regist.form.fomate'));
        return false;
      } else if (this.creditform.email == '') {
        this.$message.error(this.$t('regist.form.required') + ' ' + this.$t('login.email'));
        return false;
	  }else if(!/^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(this.creditform.email)) {
		this.$message.error(this.$t('regist.email') + ' ' + this.$t('regist.form.fomate'));
        return false;
      }
      else if (this.creditform.phone == '') {
        this.$message.error(this.$t('regist.form.required') + ' ' + this.$t('pay.phone'));
        return false;
      }
	  
      let pay_type = this.paymessage[this.paytype];
      let loadingInstance = ElLoading.service({ fullscreen: true });
      this.axios.post('member/Pay/pay', {
        user_id: this.$store.state.User.id,
        gainer_id: this.$store.state.User.id,
        pay_type: pay_type.pay_type,
        income: this.selectedamount,
        order_type: this.order_type,
        packege_id: this.selected,
        card_fullName: this.creditform.name,
        card_fullPhone: this.creditform.phone,
		card_email: this.creditform.email,
        uuid: this.uuid,
      }).then(res => {
        loadingInstance.close();
        res = res.data;
        // 金币充值
        if (res.status == 200) {
          if (pay_type.pay_type == 3) {
            document.write(`<iframe width="100%" height="100%" frameborder="0" src="${res.data.payment_urll}"></iframe>`)
          } else if (pay_type.pay_type == 0) {
            // this.$message.success(this.$t('upgrade.Rechargesuccess'));
            location.assign(res.data);
          }
        } else {
          //this.$message.success(this.$t('upgrade.runninglow'));
		  this.$message.error(res.msg);
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.invest_area {
  width: 100%;
  height: 20px;
  padding: 20px;
  color: #303133;
  position: relative;
}
.invest_area_top_icon {
  position: absolute;
  height: 20px;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
}
.invest_area_top_icon img:nth-of-type(1) {
  width: 20px;
  height: 16px;
  margin-right: 15px;
}
.invest_area_top_icon img:nth-of-type(2) {
  width: 25px !important;
  height: 25px !important;
  top: 10px;
}
.invest_area_top {
  text-align: center;
  font-size: 18px;
  height: 20px;
  line-height: 20px;
}
.content_area {
  width: 100%;

  padding: 20px;
}
.basicinfo {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.el-form-item__content {
  display: flex !important;
}
.el-form-item-span {
  margin-right: 10px;
  flex-shrink: 0;
}
.site {
  width: 100%;
  height: 60px;
}
.money_fixed {
  position: fixed;
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 60px;
  display: flex;
  z-index: 1000;
}
.red{
  background: #ff4a53
}
.grey{
  background: #999
}
</style>
