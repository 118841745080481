<template>
  <Trend v-for="(item, index) of trendList" :key="index" :data="item" />
  <router-link to="/m/home/trend/new" class="newmsg">
    <van-icon name="add" style="color: gold" />
  </router-link>
  <Menu />
</template>

<script>
import Trend from "../../../../components/mobile/Trend.vue"
import Menu from '../../../../components/mobile/Menu.vue'
export default {
  data () {
    return {
      trendList: []
    }
  },
  components: {
    Trend,
    Menu,
  },
  methods: {
    getTrendList () {
      this.axios.post_form("/member/trend/trend", {}).then(res => {
        res = res.data;
        if (res.status == 200) {
          this.trendList = res.data;
        }
      })
    },
  },
  created () {
    this.getTrendList();
  }
}
</script>

<style lang="scss" scoped>
.newmsg {
  position: fixed;
  right: 10px;
  bottom: 60px;
  font-size: 50px;
  color: gold;
  cursor: pointer;
}
</style>