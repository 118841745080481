<template>
  <div style="width: 100%; height: 60px"></div>
  <van-tabbar route>
    <van-tabbar-item replace to="/m/home/index" icon="home-o">
      {{ $t("mine.index") }}
    </van-tabbar-item>
    <van-tabbar-item replace to="/m/home/trend/list" icon="fire-o">
      {{ $t("mine.newsfeed") }}
    </van-tabbar-item>
    <van-tabbar-item
      icon="chat-o"
      to="/m/home/message"
      :badge="chatcount > 0 ? chatcount : null"
    >
      {{ $t("mine.message") }}
    </van-tabbar-item>
    <van-tabbar-item replace to="/m/home/my/index" icon="user-o">
      {{ $t("mine.mine") }}
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  computed: {
    chatcount () {
      return this.$store.state.Chat.count;
    }
  }
};
</script>

<style>
</style>