<template>
    <div class="infocontainer">
        <div class="title1">{{ $t("websit.terms.title") }}</div>

        <div class="title2">{{ $t("websit.terms.item_1_title") }}</div>
        <div class="content">{{ $t("websit.terms.item_1_content") }}</div>

        <div class="title2">{{ $t("websit.terms.item_2_title") }}</div>
        <div class="content">{{ $t("websit.terms.item_2_content") }}</div>

        <div class="title2">{{ $t("websit.terms.item_3_title") }}</div>
        <div class="content">{{ $t("websit.terms.item_3_content") }}</div>

        <div class="title2">{{ $t("websit.terms.item_4_title") }}</div>
        <div class="content">{{ $t("websit.terms.item_4_content") }}</div>

        <div class="title2">{{ $t("websit.terms.item_5_title") }}</div>
        <div class="content">{{ $t("websit.terms.item_5_content") }}</div>

        <div class="title2">{{ $t("websit.terms.item_6_title") }}</div>
        <div class="title3">{{ $t("websit.terms.item_6_subtitle1") }}</div>
        <div class="content">{{ $t("websit.terms.item_6_subtitlecontent1") }}</div>
        <div class="title3">{{ $t("websit.terms.item_6_subtitle2") }}</div>
        <div class="content">{{ $t("websit.terms.item_6_subtitlecontent2") }}</div>
        <div class="title3">{{ $t("websit.terms.item_6_subtitle3") }}</div>
        <div class="content">{{ $t("websit.terms.item_6_subtitlecontent3") }}</div>

        <div class="title2">{{ $t("websit.terms.item_7_title") }}</div>
        <div class="content">{{ $t("websit.terms.item_7_content") }}</div>

        <div class="title2">{{ $t("websit.terms.item_8_title") }}</div>
        <div class="content">{{ $t("websit.terms.item_8_content") }}</div>

        <div class="title2">{{ $t("websit.terms.item_9_title") }}</div>
        <div class="content">{{ $t("websit.terms.item_9_content") }}</div>

        <div class="title2">{{ $t("websit.terms.item_10_title") }}</div>
        <div class="content">{{ $t("websit.terms.item_10_content") }}</div>

        <div class="title2">{{ $t("websit.terms.item_11_title") }}</div>
        <div class="content">{{ $t("websit.terms.item_11_content") }}</div>

        <div class="title2">{{ $t("websit.terms.item_12_title") }}</div>
        <div class="content">{{ $t("websit.terms.item_12_content1") }}</div>
        <div class="content">{{ $t("websit.terms.item_12_content2") }}</div>
    </div>
</template>
<style lang="scss" scoped>
.infocontainer {
  width: 100%;
  padding: 20px 10px;
  margin: 5px 0;
}
.title1,
.title2,
.title3 {
  color: $themeColor;
  font-weight: bold;
}
.content {
  color: #000;
  font-size: 14px;
}
.title1 {
  margin-bottom: 28px;
  font-size: 20px;
}
.title2 {
  font-size: 18px;
  line-height: 30px;
}
.title3 {
  font-size: 14px;
  line-height: 26px;
}
</style>
