import Axios from "axios";
import Util from "../util/util";

export default {
  /**
   * post JSON数据
   * @param {string} url
   * @param {any} data
   * @returns {Promise} Axios
   */
  post(url, data) {
    data.token = localStorage.getItem("token");
    return Axios.post(url, data);
  },

  /**
   * post Form表单
   * @param {string} url
   * @param {any} params
   * @returns {Promise} Axios
   */
  post_form(url, params) {
    params.token = localStorage.getItem("token");
    const formData = new FormData();
    Object.keys(params).forEach((key) => {
      formData.append(key, params[key]);
    });
    return Axios.post(url, formData);
  },

  /**
   *
   * @param {string} url
   * @param {any} data
   * @returns {Promise} Axios
   */
  get(url, data) {
    data.token = localStorage.getItem("token");
    url = url + "?" + Util.urlEncode(data);
    return Axios.get(url);
  },
};
