<template>
  <div class="flexbox chatlist">
    <router-link class="flexbox" to="/m/home/chat/service">
      <div class="flexbox chatitem">
        <div class="avatar" v-if="service.count > 0">
          <van-badge :content="service.count" max="99">
            <img :src="imgurl + service.avatar" alt="" class="avatar" />
          </van-badge>
        </div>
        <img :src="imgurl + service.avatar" alt="" class="avatar" v-else />
        <div class="flexbox usermsg">
          <div class="flexbox msgbox">
            <div class="username">
              <i
                class="iconfont icon-V"
                style="color: gold"
                v-if="service.vip == 2"
              ></i>
              <i
                class="iconfont icon-V"
                style="color: silver"
                v-if="service.vip == 1"
              ></i
              >{{ service.username }}
            </div>
            <div class="chatcontent">
              <Expression :text="service.content" :singleLine="true" />
            </div>
          </div>
          <div class="flexbox onlinebox">
            <div
              class="circle"
              :class="{
                online: service.os_online,
                offline: !service.os_online,
              }"
            ></div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import Expression from "../../../../components/Expression.vue"
import Menu from '../../../../components/mobile/Menu.vue'
export default {
  data () {
    return {
    }
  },
  components: {
    Expression,
    Menu,
  },
  computed: {
    service () {
      return this.$store.state.Chat.service;
    },
  },
}
</script>

<style lang="scss" scoped>
.van-swipe-cell {
  width: 100%;
}
.chatlist {
  .chatitem {
    padding: 11px 15px;
    flex-wrap: nowrap;
    .avatar {
      width: 52px;
      height: 52px;
      border-radius: 50%;
      flex-shrink: 0;
    }
    .usermsg {
      height: 52px;
      flex-wrap: nowrap;
      .msgbox {
        height: 52px;
        padding: 0 10px;
        align-content: space-around;
        .username {
          width: 100%;
        }
        .chatcontent {
          width: 100%;
          font-size: 12px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .onlinebox {
        width: 50px;
        flex-shrink: 0;
        .circle {
          width: 5px;
          height: 5px;
          border-radius: 50%;
        }
        .online {
          background-color: green;
        }
        .offline {
          background-color: grey;
        }
      }
    }
  }
}
.delete {
  background-color: #ff4a53;
  width: 74px;
  height: 74px;
}
</style>