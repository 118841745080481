<template>
  <el-dialog
    :title="$t('report.content', { user: user.username })"
    v-model="$store.state.Report.show"
    width="40%"
    :close-on-click-modal="false"
  >
    <el-radio v-model="radio" :label="1" :value="1">
      {{ $t("report.ads") }}
    </el-radio>
    <el-radio v-model="radio" :label="2" :value="2">
      {{ $t("report.offensive") }}
    </el-radio>
    <el-radio v-model="radio" :label="3" :value="3">
      {{ $t("report.illegal") }}
    </el-radio>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">
          {{ $t("global.cancel") }}
        </el-button>
        <el-button type="primary" @click="report">
          {{ $t("user.report") }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      radio: 1,
    }
  },
  methods: {
    close () {
      this.$store.commit('closeReport');
    },
    report () {
      this.axios.post_form('member/reports/reports', {
        type: this.radio,
        userid: this.user.id,
      }).then(res => {
        res = res.data;
        if (res.status == 200) {
          this.$message.success(this.$t('report.success'));
        }
      })
      this.$store.commit('closeReport');
    }
  },
  computed: {
    user () {
      return this.$store.state.Report.user;
    },
  },
}
</script>

<style>
</style>