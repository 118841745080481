<template>
  <div class="flexbox-c trendbox">
    <!-- 最新动态-input -->
    <div class="flexbox inputbox">
      <textarea
        class="trendtextarea"
        rows="8"
        v-model="trendtext"
        :placeholder="$t('user.edit.trendplaceholder')"
      ></textarea>
    </div>
    <!-- 最近动态-按钮 -->
    <div class="flexbox opratebox">
      <!-- 图片按钮 -->
      <div class="flexbox iconbox" @click="$refs.fileinput.click()">
        <i class="iconfont icon-tupian" style="font-size: 20px"></i>
      </div>
      <!-- 图片文件 -->
      <input
        type="file"
        accept="image/*"
        v-show="false"
        @change="fileChange"
        ref="fileinput"
      />
      <!-- 表情按钮 -->
      <div class="flexbox iconbox" @click.stop="showexpress = !showexpress">
        <i
          class="iconfont icon-biaoqing"
          style="color: #eedd55; font-size: 20px"
        ></i>
      </div>
    </div>
    <!-- 表情框 -->
    <div class="flexbox expressbox">
      <img
        class="img"
        v-for="(item, index) of expressionStrList"
        :key="index"
        :src="expressionSource[item]"
        @click.stop="pushexp(item)"
        v-show="showexpress"
      />
    </div>
    <!-- 图片预览 -->
    <div
      @click="$refs.fileinput.click()"
      class="flexbox trendimgprev"
      :style="{ backgroundImage: `url(${imgurl + trendimg})` }"
    >
      <i
        v-if="trendimg"
        class="el-icon-close"
        style="cursor: pointer"
        @click.stop="trendimg = ''"
      ></i>
    </div>
  </div>
  <div class="flexbox send" @click="sendTrend">{{ $t("global.send") }}</div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { expressionSource, expressionStrList } from "../../../../util/expression"
export default {
  data () {
    return {
      // 动态表单内容
      trendtext: "",
      trendimg: "",
      // 表情数据
      expressionStrList,
      expressionSource,
      showexpress: false,
      // 图片文件
      sourceImgUrl: null,
    }
  },
  methods: {
    // 插入表情
    pushexp (item) {
      this.trendtext += "[" + item + "]";
    },
    // 图片选择事件
    fileChange (e) {
      if (this.util.checkPermision('Upload_photo')) {
        e.preventDefault();
        if (this.loading !== 1) {
          let files = e.target.files || e.dataTransfer.files;
          this.sourceImgUrl = files[0];
          this.axios.post_form("/member/index/uploads", {
            file: this.sourceImgUrl
          }).then(res => {
            res = res.data;
            if (res.status == 200) {
              this.trendimg = res.data.url;
            }
          })
        }
      } else {
        ElMessage.error(i18n.global.t("tips.nopermission")+'..');
      }
    },
    setSourceImg (file) {
      let that = this,
        fr = new FileReader();
      fr.onload = function (e) {
        that.sourceImgUrl = fr.result;
      }
      fr.readAsDataURL(file);
    },
    choosefile () {
    },
    sendTrend () {
      if (this.util.checkPermision('Allows_newsfeed_to_be_sent')) {
        if (this.trendtext == "" && this.trendimg == "") {
          ElMessage.error(this.$t('tips.contentcantempty'));
          return false;
        }
        this.axios.post_form("/member/trend/released", {
          content: this.trendtext,
          img: this.trendimg,
        }).then(res => {
          res = res.data;
          if (res.status == 200) {
            ElMessage.success(this.$t('tips.sendsuccess'));
            if (this.trendimg) {
              this.releasePhoto();
            }
            this.trendtext = "";
            this.trendimg = ""
            this.$router.go(-1);
          } else {
            ElMessage.error(this.$t('tips.sendfail'));
          }
        })
      } else {
        ElMessage.error(i18n.global.t("tips.nopermission")+'*');
      }
    },
    releasePhoto () {
      this.axios.post_form("/member/photo/released", {
        img: this.trendimg,
      })
    },
  },
  created () {
    document.onclick = () => {
      this.showexpress = false;
    }
  },
  beforeUnmount () {
    document.onclick = null;
  },
}
</script>

<style lang="scss" scoped>
.trendbox {
  padding: 10px;
}
.inputbox {
  margin-top: 15px;
  .trendtextarea {
    width: 100%;
    border: none;
    outline: none;
    padding: 10px;
    background-color: #f1f2f3;
    border-radius: 10px;
    resize: none;
  }
}
.expressbox {
  width: 100%;
  height: auto;
  background-color: #fff;
  justify-content: flex-start;
  align-content: flex-start;
  overflow: hidden;
  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
.opratebox {
  height: 30px;
  justify-content: flex-end;
  .iconbox {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-right: 10px;
  }
  .sendbtn {
    width: auto;
    height: 30px;
    padding: 0 8px;
    background-color: $themeColor;
    border-radius: 3px;
    border: 1px solid #333;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
  }
}
.trendimgprev {
  width: 100%;
  height: 200px;
  margin-top: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-end;
  color: #333;
  border: 5px dashed #eee;
}
.send {
  width: 70%;
  position: fixed;
  bottom: 20px;
  left: 15%;
  height: 40px;
  background-color: #f43;
  color: #fff;
  border-radius: 20px;
}
</style>