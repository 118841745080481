<template>
  <div class="infocontainer">
    <div class="title1">{{ $t("websit.contact.title") }}</div>

    <div class="content">
      Company: <span id="companyp2">{{contact_us_company}}</span><br/>
	  Address: <span id="addressp">{{contact_us_address}}</span><br/>
	  {{ $t("websit.contact.item_0_content", { email: email }) }}
    </div>
  </div>
</template>

<script>
import { websitname, email, account } from "../../../common/project.config";
export default {
  data () {
    return {
      websitname, email, account,
	  contact_us_company: localStorage.getItem('contact_us_company') || "",
      contact_us_address: localStorage.getItem('contact_us_address') || "",
    }
  }
}
</script>

<style lang="scss" scoped>
.infocontainer {
  width: 1000px;
  padding: 70px 0;
  margin: 5px 0;
}
.title1,
.title2,
.title3 {
  color: $themeColor;
  font-weight: bold;
}
.content {
  color: #fff;
  font-size: 14px;
}
.title1 {
  margin-bottom: 28px;
  font-size: 20px;
}
.title2 {
  font-size: 18px;
  line-height: 30px;
}
.title3 {
  font-size: 14px;
  line-height: 26px;
}
</style>