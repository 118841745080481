// 项目名字
export const websitname = "Winkta";
// 页面默认title
export const defaultTitle = "Winkta";
// 网站地址
export const websiteurl = "https://www.winkta.com";
// Email
export const email = "winkta@outlook.com";
// 账户名称
export const account = "Winkta Love";
// 图片域名
export const imgurl =
  process.env.NODE_ENV == "development" ? "http://fadmin.test.com" : "https://static.winkta.com";

// websocket
export const webSocketUrl = "wss://www.winkta.com:2345/";
//export const webSocketUrl = "ws://localhost:2345/";

