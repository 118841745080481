import Home from "../views/web/Home";
import Login from "../views/web/Login";
import Regist from "../views/web/Regist";
import RegistCode from "../views/web/RegistCode";
import ForgotPassword from "../views/web/ForgotPassword";
import Website from "../views/web/Website";

import About from "../views/web/Website/About";
import Contact from "../views/web/Website/Contact";
import Help from "../views/web/Website/Help";
import Privacy from "../views/web/Website/Privacy";
import Refund from "../views/web/Website/Refund";
import Terms from "../views/web/Website/Terms";

import HomeIndex from "../views/web/Home/Index";
import HomeChat from "../views/web/Home/Chat";
import HomeMember from "../views/web/Home/Member";
import HomeMessage from "../views/web/Home/Message";
import HomePay from "../views/web/Home/Pay";
import HomePhoto from "../views/web/Home/Photo";
import HomeStore from "../views/web/Home/Store";
import HomeUpgrade from "../views/web/Home/Upgrade";
import HomeUser from "../views/web/Home/User";

import HomeMemberOnline from "../views/web/Home/Member/Online";
import HomeMemberLatest from "../views/web/Home/Member/Latest";
import HomeMemberSearch from "../views/web/Home/Member/Search";

import HomeStorestore from "../views/web/Home/Store/Store";
import HomeStoreMine from "../views/web/Home/Store/Mine";

import HomeUserIndex from "../views/web/Home/User/Index";
import HomeUserEdit from "../views/web/Home/User/Edit";
import HomeUserDetail from "../views/web/Home/User/Detail";
import HomeUserGeneral from "../views/web/Home/User/General";
import HomeUserNotification from "../views/web/Home/User/Notification";

const webRouter = [
  {
    path: "home",
    name: "wHome",
    component: Home,
    redirect: "/w/home/index",
    meta: { requireLogin: true },
    children: [
      {
        path: "user",
        component: HomeUser,
        meta: {},
        redirect: "/w/home/user/index",
        children: [
          { path: "index", component: HomeUserIndex, meta: {} },
          { path: "edit", component: HomeUserEdit, meta: {} },
          {
            path: "detail/:id",
            component: HomeUserDetail,
            meta: { permission: "View_profiles" },
            props: true,
          },
          { path: "general", component: HomeUserGeneral, meta: {} },
          { path: "notification", component: HomeUserNotification, meta: {} },
        ],
      },
      { path: "index", component: HomeIndex, meta: {} },
      {
        path: "chat",
        component: HomeChat,
        meta: {
          /* permission: "Read_chat_messages"  */
        },
      },
      {
        path: "member",
        component: HomeMember,
        redirect: "/w/home/member/online",
        meta: {},
        children: [
          {
            path: "online",
            component: HomeMemberOnline,
            meta: {},
          },
          {
            path: "latest",
            component: HomeMemberLatest,
            meta: {},
          },
          {
            path: "search",
            component: HomeMemberSearch,
            meta: {},
          },
        ],
      },
      {
        path: "message",
        component: HomeMessage,
        meta: {},
      },
      {
        path: "pay",
        component: HomePay,
        meta: {},
      },
      {
        path: "upgrade",
        component: HomeUpgrade,
        meta: {},
      },
      {
        path: "photo/:id",
        component: HomePhoto,
        meta: { permission: "View_photo" },
        props: true,
      },
      {
        path: "store",
        component: HomeStore,
        redirect: "/w/home/store/index",
        meta: {},
        children: [
          {
            path: "index",
            component: HomeStorestore,
            meta: {},
          },
          {
            path: "mine",
            component: HomeStoreMine,
            meta: {},
          },
        ],
      },
    ],
  },
  {
    path: "regist",
    component: Regist,
    meta: { title: "signup", requireDoAllreadyLoginAction: true },
  },
  {
    path: "regist/:code",
    component: RegistCode,
    meta: { title: "signup", requireDoAllreadyLoginAction: true },
    props: true,
  },
  {
    path: "login",
    name: "wLogin",
    component: Login,
    meta: { title: "signin", requireDoAllreadyLoginAction: true },
  },
  {
    path: "forgot-password",
    name: "wForgot",
    component: ForgotPassword,
    meta: { requireDoAllreadyLoginAction: true },
  },
  {
    path: "info",
    name: "wInfo",
    component: Website,
    meta: {},
    redirect: "/w/info/about-us",
    children: [
      {
        path: "about-us",
        name: "wAbout",
        component: About,
        meta: { title: "about" },
      },
      {
        path: "contact-us",
        name: "wContact",
        component: Contact,
        meta: { title: "contact" },
      },
      {
        path: "help-center",
        name: "wHelp",
        component: Help,
        meta: { title: "help" },
      },
      {
        path: "privacy-policy",
        name: "wPrivacy",
        component: Privacy,
        meta: { title: "privacy" },
      },
      {
        path: "refund",
        name: "wRefund",
        component: Refund,
        meta: { title: "refund" },
      },
      {
        path: "terms-of-use",
        name: "wTerms",
        component: Terms,
        meta: { title: "terms" },
      },
    ],
  },
];

export default webRouter;
