<template>
  <div class="flexbox loginfootercontainer">
    <div class="flexbox linkcontainer">
      <router-link to="/w/info/about-us">
        <div class="link">{{ $t("loginfooter.about") }}</div>
      </router-link>
      |
      <router-link to="/w/info/terms-of-use" @click.native="items">
        <div class="link">{{ $t("loginfooter.terms") }}</div>
      </router-link>
      |
      <router-link to="/w/info/privacy-policy" @click.native="policys">
        <div class="link">{{ $t("loginfooter.privacy") }}</div>
      </router-link>
      <!--|
      <router-link to="/w/info/refund">
        <div class="link">{{ $t("loginfooter.refund") }}</div>
      </router-link>-->
      |
      <router-link to="/w/info/help-center">
        <div class="link">{{ $t("loginfooter.help") }}</div>
      </router-link>
      |
      <router-link to="/w/info/contact-us" @click.native="contacts">
        <div class="link">{{ $t("loginfooter.contact") }}</div>
      </router-link>
    </div>
    <div class="payimage" id="logop">
      <img  src="~@/assets/images/icepay-logo.png" />
    </div>

    <div class="flexbox website">
      <p>
        Copyright ©{{ new Date().getFullYear() }}
        <span id="companyp">{{ copy_right_company }}</span>
        All Rights Reserved.
      </p>
    </div>
  </div>
</template>

<script>
import { websiteurl } from "../../common/project.config"
export default {
  data () {
    return {
      websiteurl,
      copy_right_company: localStorage.getItem('copy_right_company') || "",
    }
  },
  methods:{
	items:function(){
		window.location.href = 'https://www.tataflirt.com/#/w/info/terms-of-use'
	},
	policys:function(){
		window.location.href = 'https://www.tataflirt.com/#/w/info/privacy-policy'
	},
	contacts:function(){
		window.location.href = 'https://www.tataflirt.com/#/w/info/contact-us'
	}
  },
}


</script>

<style lang="scss" scoped>
.loginfootercontainer {
  width: 100%;
  height: 150px;

  .linkcontainer {
    width: 100%;
    font-size: 16px;
    color: $themeColor;
    .link {
      color: $linkColor;
      padding: 0 8px;
    }
    .link:hover {
      text-decoration: underline;
    }
  }
  .payimage {
    width: 134px;
    margin-top: 20px;
    img {
      width: 134px;
      height: auto;
    }
  }
  .website {
    width: 100%;
    font-size: 12px;
    color: #fff;
  }
}
</style>