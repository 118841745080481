<template>
  <div class="container">
    <div class="title">{{ $t("notification.notice") }}</div>
    <div class="linkbox">
      <router-link class="linkitem" to="/w/home/user/general">
        <i class="iconfont icon-wenjian"></i>
        {{ $t("notification.general") }}
      </router-link>
      <router-link class="linkitem" to="/w/home/user/notification">
        <i class="iconfont icon-wenjian"></i>
        {{ $t("notification.notice") }}
      </router-link>
    </div>
    <div class="flexbox-c membercontent">
      <!-- 基本资料 -->
      <div class="formbody">
        <div class="boxhead">
          <div class="formitemtitle">
            <i class="iconfont icon-wenjian"></i>
            {{ $t("notification.title1") }}
          </div>
        </div>
        <div class="boxbody">
          <div class="boxitem">
            <div class="left">
              <el-checkbox v-model="form.form1"></el-checkbox>
            </div>
            <div class="center" style="justify-content: flex-start">
              {{ $t("notification.item1") }}
            </div>
          </div>
        </div>
      </div>

      <!-- 最新动态 -->
      <div class="formbody">
        <div class="boxhead">
          <div class="formitemtitle">
            <i class="iconfont icon-dongtai2"></i>
            {{ $t("notification.title2") }}
          </div>
        </div>
        <div class="boxbody">
          <div class="boxitem">
            <div class="left">
              <el-checkbox v-model="form.form2"></el-checkbox>
            </div>
            <div class="center" style="justify-content: flex-start">
              {{ $t("notification.item2") }}
            </div>
          </div>
          <div class="boxitem">
            <div class="left">
              <el-checkbox v-model="form.form3"></el-checkbox>
            </div>
            <div class="center" style="justify-content: flex-start">
              {{ $t("notification.item3") }}
            </div>
          </div>
          <div class="boxitem">
            <div class="left">
              <el-checkbox v-model="form.form4"></el-checkbox>
            </div>
            <div class="center" style="justify-content: flex-start">
              {{ $t("notification.item4") }}
            </div>
          </div>
        </div>
      </div>

      <!-- 消息 -->
      <div class="formbody">
        <div class="boxhead">
          <div class="formitemtitle">
            <i class="iconfont icon-youjian"></i>
            {{ $t("notification.title3") }}
          </div>
        </div>
        <div class="boxbody">
          <div class="boxitem">
            <div class="left">
              <el-checkbox v-model="form.form5"></el-checkbox>
            </div>
            <div class="center" style="justify-content: flex-start">
              {{ $t("notification.item5") }}
            </div>
          </div>
          <div class="boxitem">
            <div class="left">
              <el-checkbox v-model="form.form6"></el-checkbox>
            </div>
            <div class="center" style="justify-content: flex-start">
              {{ $t("notification.item6") }}
            </div>
          </div>
        </div>
      </div>

      <!-- 照片 -->
      <div class="formbody">
        <div class="boxhead">
          <div class="formitemtitle">
            <i class="iconfont icon-tupian"></i>
            {{ $t("notification.title4") }}
          </div>
        </div>
        <div class="boxbody">
          <div class="boxitem">
            <div class="left">
              <el-checkbox v-model="form.form7"></el-checkbox>
            </div>
            <div class="center" style="justify-content: flex-start">
              {{ $t("notification.item7") }}
            </div>
          </div>
        </div>
      </div>

      <!-- 视频 -->
      <div class="formbody">
        <div class="boxhead">
          <div class="formitemtitle">
            <i class="iconfont icon-shipin"></i>
            {{ $t("notification.title5") }}
          </div>
        </div>
        <div class="boxbody">
          <div class="boxitem">
            <div class="left">
              <el-checkbox v-model="form.form8"></el-checkbox>
            </div>
            <div class="center" style="justify-content: flex-start">
              {{ $t("notification.item8") }}
            </div>
          </div>
        </div>
      </div>

      <!-- 金币 -->
      <div class="formbody">
        <div class="boxhead">
          <div class="formitemtitle">
            <i class="iconfont icon-meiyuan9"></i>
            {{ $t("notification.title6") }}
          </div>
        </div>
        <div class="boxbody">
          <div class="boxitem">
            <div class="left">
              <el-checkbox v-model="form.form9"></el-checkbox>
            </div>
            <div class="center" style="justify-content: flex-start">
              {{ $t("notification.item9") }}
            </div>
          </div>
        </div>
      </div>

      <!-- 礼物 -->
      <div class="formbody">
        <div class="boxhead">
          <div class="formitemtitle">
            <i class="iconfont icon-liwu"></i>
            {{ $t("notification.title7") }}
          </div>
        </div>
        <div class="boxbody">
          <div class="boxitem">
            <div class="left">
              <el-checkbox v-model="form.form10"></el-checkbox>
            </div>
            <div class="center" style="justify-content: flex-start">
              {{ $t("notification.item10") }}
            </div>
          </div>
        </div>
      </div>

      <!-- 邮件 -->
      <div class="formbody">
        <div class="boxhead">
          <div class="formitemtitle">
            <i class="iconfont icon-youjian"></i>
            {{ $t("notification.title8") }}
          </div>
        </div>
        <div class="boxbody">
          <div class="boxitem">
            <div class="left">
              <el-radio v-model="form.form11" label="Immediately"> </el-radio>
            </div>
            <div class="center" style="justify-content: flex-start">
              {{ $t("notification.item11") }}
            </div>
          </div>
          <div class="boxitem">
            <div class="left">
              <el-radio v-model="form.form11" label="Automatically"> </el-radio>
            </div>
            <div class="center" style="justify-content: flex-start">
              {{ $t("notification.item12") }}
            </div>
          </div>
          <div class="boxitem">
            <div class="left">
              <el-radio v-model="form.form11" label="never"></el-radio>
            </div>
            <div class="center" style="justify-content: flex-start">
              {{ $t("notification.item13") }}
            </div>
          </div>
        </div>
      </div>

      <!-- 保存 -->
      <div class="flexbox usereditcontainer">
        <div class="flexbox commonbtn" @click="save">
          <div class="textbox">{{ $t("user.edit.save") }}</div>
          |
          <div class="iconbox">
            <i class="iconfont icon-tubiaozhizuomoban"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        form1: true,
        form2: true,
        form3: true,
        form4: true,
        form5: true,
        form6: true,
        form7: true,
        form8: true,
        form9: true,
        form10: true,
        form11: 'Automatically',
      }
    }
  },
  methods: {
    save () {
      this.$message.success(this.$t('tips.savesuccess'));
    }
  }
}
</script>

<style lang="scss" scoped>
$boderColor: rgba(
  $color: #fff,
  $alpha: 0.2,
);
$border: 1px solid $boderColor;
.container {
  width: 1000px;
  padding: 100px 30px;
  .title {
    font-size: 20px;
    color: $themeColor;
    margin-bottom: 28px;
    font-weight: bold;
  }
  .linkbox {
    .linkitem {
      padding: 10px 10px;
      background-color: #fff;
      color: $themeColor;
      background-color: transparent;
    }
    .router-link-active {
      background-color: rgb(180, 83, 77);
    }
  }
  .membercontent {
    padding: 30px 10px;
    padding-right: 0;
    background-color: rgba(255, 255, 255, 0.2);
    margin-top: 30px;
    border-radius: 8px;
    min-height: 400px;
  }
  .formtitle {
    width: 100%;
    color: $themeColor;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 20px;
  }
  .formbody {
    width: 800px;
  }
  .formcontent {
    width: 100%;
    word-wrap: break-word;
    font-size: 14px;
    color: #fff;
    text-align: center;
    padding: 14px;
  }
  .boxhead {
    display: flex;
    background: rgba(255, 255, 255, 0.2)
      url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpi/P//WRoDGmAB4ifYBF9gE/w3Kkiu4P/Bp/0/DbRTIAgQYAC2PiJjuOjlPgAAAABJRU5ErkJggg==)
      repeat-x left top;
    border: none;
    border-radius: 6px 6px 0px 0px;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding-left: 10px;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
  .boxbody {
    background: rgba(255, 255, 255, 0.05)
      url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpi/P//WRoDGmAB4ifYBF9gE/w3Kkiu4P/Bp/0/DbRTIAgQYAC2PiJjuOjlPgAAAABJRU5ErkJggg==)
      repeat-x left top;
    margin-bottom: 10px;
    .boxitem {
      display: flex;
      width: 100%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      border-left: 1px solid rgba(255, 255, 255, 0.5);
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      .left {
        width: 20%;
        display: flex;
        padding: 10px;
        justify-content: flex-end;
        align-items: center;
        align-content: center;
        color: #fff;
        font-size: 14px;
        .terms {
          color: $linkColor;
        }
        .terms:hover {
          text-decoration: underline;
        }
      }
      .center {
        width: 80%;
        display: flex;
        padding: 5px;
        justify-content: center;
        align-items: center;
        align-content: center;
        color: #fff;
        font-size: 14px;
        border-left: 1px solid rgba(255, 255, 255, 0.5);
        // border-right: 1px solid rgba(255, 255, 255, 0.5);
        .avatar {
          width: 100px;
          height: 100px;
          cursor: pointer;
        }
      }
      .right {
        width: 20%;
        display: flex;
        padding: 10px;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        color: #fff;
        font-size: 14px;
      }
    }
  }
}
.usereditcontainer {
  width: 800px;
  margin-bottom: 30px;
  justify-content: flex-end;
}
.commonbtn {
  width: auto;
  height: 32px;
  justify-content: space-between;
  color: $themeColor;
  background-color: #e8cbed;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  .textbox {
    padding: 0 5px;
  }
  .iconbox {
    padding: 0 5px;
    font-size: 20px;
  }
}
.fullradiusbox {
  border-radius: 6px;
}
.halfradiusbox {
  border-radius: 6px 6px 0px 0px;
}
.fullradiusbox,
.halfradiusbox {
  background: rgba(255, 255, 255, 0.2)
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpi/P//WRoDGmAB4ifYBF9gE/w3Kkiu4P/Bp/0/DbRTIAgQYAC2PiJjuOjlPgAAAABJRU5ErkJggg==)
    repeat-x left top;
  border: none;
  border-bottom: none;
  .title {
    justify-content: flex-start;
    padding: 8px 10px;
    font-size: 16px;
    i {
      margin-right: 10px;
      color: $themeColor;
      font-size: 16px;
    }
  }
}

.changepwdbox {
  .boxitem {
    margin-top: 30px;
  }
}
.formitemtitle {
  color: $themeColor;
  width: 100%;
  padding: 10px;
}
</style>