<template>
  <router-view></router-view>
  <Audio />
</template>

<script>
import Audio from "../components/Audio.vue"
export default {
  components: {
    Audio,
  }
}
</script>