<template>
  <Online />

  <Menu />
</template>
<script>

import Menu from "../../../components/mobile/Menu.vue"
import Online from "./Index/Online.vue"
export default {
  data () {
    return {
      active: "",
      isLoading: false,
    };
  },
  components: {
    Menu,
    Online,
  },
  watch: {},
  methods: {
    onRefreshLatest () {
      this.$store.dispatch('getLatestList');
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
  },
  computed: {
    username () {
      return this.$store.state.User.username;
    },
  },

};
</script>

<style scoped>
</style>