<template>
  <div class="container">
    <div class="title">{{ $t("home.upgrade") }}</div>
    <div class="flexbox usermsg">
      <Radio :ischecked="true" />
      <span class="objectuser">{{ $t("recharge.self") }}</span>
    </div>
    <div class="flexbox butoptionsbox">
      <div class="memberbox">
        <div class="flexbox memberheader">{{ $t("upgrade.gold") }}</div>
        <div style="width: 100%; height: 150px">
          <el-scrollbar max-height="150px" height="150px">
            <div class="flexbox authoritybox">
              <div
                class="flexbox authorityitem"
                v-for="(item, index) of gold"
                :key="'goldauth' + index"
              >
                <i class="iconfont icon-duigou"></i>
                {{ $t(`authority.${item}`) }}
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="chargepackagebox">
          <div
            class="flexbox chargepackageitem"
            :class="{ checkedpackage: selected == item.id }"
            v-for="(item, index) of vip2.packages"
            :key="'goldpackage' + index"
            @click="setSelected(item.id, item.amounts)"
          >
            <Radio :ischecked="selected == item.id" />
            <span
              >{{ item.amounts }} USD/{{ item.duration
              }}{{ $t("upgrade.month") }}</span
            >
            <span>
              {{ (item.amounts / item.duration).toFixed(2) }}
              USD/1{{ $t("upgrade.month") }}
            </span>
            <span class="province">
              {{ $t("upgrade.province") }}
              {{
                parseInt(
                  ((vip2.price - item.amounts / item.duration) / vip2.price) *
                    100
                )
              }}%
            </span>
          </div>
        </div>
      </div>
      <div class="memberbox">
        <div class="flexbox memberheader">{{ $t("upgrade.silver") }}</div>
        <div style="width: 100%; height: 150px">
          <el-scrollbar max-height="150px" height="150px">
            <div class="flexbox authoritybox">
              <div
                class="flexbox authorityitem"
                v-for="(item, index) of silver"
                :key="'silverauth' + index"
              >
                <i class="iconfont icon-duigou"></i>
                {{ $t(`authority.${item}`) }}
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="chargepackagebox">
          <div
            class="flexbox chargepackageitem"
            :class="{ checkedpackage: selected == item.id }"
            v-for="(item, index) of vip1.packages"
            :key="'goldpackage' + index"
            @click="setSelected(item.id, item.amounts)"
          >
            <Radio :ischecked="selected == item.id" />
            <span
              >{{ item.amounts }} USD/{{ item.duration
              }}{{ $t("upgrade.month") }}</span
            >
            <span>
              {{ (item.amounts / item.duration).toFixed(2) }}
              USD/1{{ $t("upgrade.month") }}
            </span>
            <span class="province">
              {{ $t("upgrade.province") }}
              {{
                parseInt(
                  ((vip1.price - item.amounts / item.duration) / vip1.price) *
                    100
                )
              }}%
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 余额 -->
    <div class="balance">
      <span class="balance"> {{ $t("index.balance") }} </span> :
      <span class="num">{{ amount }}</span>
    </div>
    <!-- 支付方式 -->
    <div class="flexbox-s paytype">
      <div
        class="flexbox paytypeitem"
        :class="{ active: paytype == 1 }"
        @click="paytype = 1"
      >
        <Radio :ischecked="paytype == 1" />
        <div
          class="paylogo"
          :style="{ backgroundImage: `url(${image.pay_cropay})` }"
        ></div>
      </div>
      <div
        class="flexbox paytypeitem"
        :class="{ active: paytype == 2 }"
        @click="paytype = 2"
      >
        <Radio :ischecked="paytype == 2" />
        <div
          class="paylogo"
          :style="{ backgroundImage: `url(${image.pay_lcropay_ocal})` }"
        ></div>
      </div>
      <div
        class="flexbox paytypeitem"
        :class="{ active: paytype == 3 }"
        @click="paytype = 3"
      >
        <Radio :ischecked="paytype == 3" />
        <div class="flexbox paylogo">
          <i
            class="iconfont icon-meiyuan"
            style="color: gold; font-size: 30px"
          ></i
          >{{ $t("store.credits") }}{{ $t("index.balance") }}
        </div>
      </div>
    </div>

    <div class="flexbox rechagebox">
      <div class="button" @click="recharge">{{ $t("mine.upgrade") }}</div>
    </div>
  </div>

  <!-- 信用卡信息窗口 -->
  <van-overlay :show="creditshow" @click="creditshow = false">
    <div class="flexbox creditwindow">
      <div class="flexbox formbox" @click.stop>
        <div class="flexbox inputitem">
          <el-input v-model="creditform.name" :placeholder="$t('pay.ename')">
            <template #prepend>{{ $t("pay.name") }}</template>
          </el-input>
        </div>
        <div class="flexbox inputitem">
          <el-input
            v-model="creditform.email"
            :placeholder="$t('login.email')"
          >
            <template #prepend>{{ $t("login.email") }}</template>
          </el-input>
        </div>
        <div class="flexbox inputitem">
          <el-input v-model="creditform.phone" :placeholder="$t('pay.phone')">
            <template #prepend>{{ $t("pay.phone") }}</template>
          </el-input>
        </div>
        <div class="flexbox creditrechargebtncontainer">
          <div class="flexbox creditrechargebtn" @click="creditrecharge">
            {{ $t("mine.upgrade") }}
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { ElLoading } from 'element-plus';
import Radio from "../../../components/web/radio.vue"
import pay_paypal from "../../../assets/images/pay_paypal.png"
import pay_cropay from "../../../assets/images/pay_cropay.png"
import pay_lcropay_ocal from "../../../assets/images/pay_lcropay_ocal.png"
import country from "../../../util/country"
export default {
  data () {
    return {
      image: {
        pay_paypal,
        pay_cropay,
        pay_lcropay_ocal,
      },
      selected: 0,
      selectedamount: 0,

      paytype: 0,
      paymessage: [
        {
          name: "paypal",
          pay_type: 1,
        },
        {
          name: "cropay",
          pay_type: 3,
        },
        {
          name: "cropay_local",
          pay_type: 0,
          order_type: 0,
        },
        {
          name: "credits",
          pay_type: 2,
        },
      ],
      country,
      uuid: '',
      creditshow: false,
      creditform: {
        name: '',
        email: '',
        phone: '',
      }
    }
  },
  computed: {
    gold () {
      return this.$store.state.goldcard_authority;
    },
    silver () {
      return this.$store.state.silvercard_authority;
    },
    vip1 () {
      return this.$store.state.Upgrade.vip1;
    },
    vip2 () {
      return this.$store.state.Upgrade.vip2;
    },
    amount () {
      return this.$store.state.User.amount;
    }
  },
  components: {
    Radio,
  },
  methods: {
    setSelected (id, amount) {
      this.selected = id;
      this.selectedamount = amount;
    },
    creditrecharge () {
      if (this.selected == 0) {
        this.$message.error(this.$t('upgrade.choosemeal'));
        return void 0;
      }
      if (this.creditform.name == '') {
        this.$message.error(this.$t('regist.form.required') + ' ' + this.$t('pay.name'));
        return false;
	  }else if(!/[a-zA-Z0-9]+/.test(this.creditform.name)) {
		this.$message.error(this.$t('regist.username') + ' ' + this.$t('regist.form.fomate'));
        return false;
	  } else if (this.creditform.email == '') {
        this.$message.error(this.$t('regist.form.required') + ' ' + this.$t('login.email'));
        return false;
	  } else if(!/^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(this.creditform.email)) {
		this.$message.error(this.$t('regist.email') + ' ' + this.$t('regist.form.fomate'));
        return false;
      }
      
      else if (this.creditform.phone == '') {
        this.$message.error(this.$t('regist.form.required') + ' ' + this.$t('pay.phone'));
        return false;
      }
      let pay_type = this.paymessage[this.paytype];
      let loadingInstance = ElLoading.service({ fullscreen: true });
      this.axios.post('member/Pay/pay', {
        user_id: this.$store.state.User.id,
        gainer_id: this.$store.state.User.id,
        pay_type: pay_type.pay_type,
        income: this.selectedamount,
        order_type: 2,
        packege_id: this.selected,
        card_fullName: this.creditform.name,
        card_fullPhone: this.creditform.phone,
		card_email: this.creditform.email,
        uuid: this.uuid,
      }).then(res => {
        loadingInstance.close();
        this.creditshow = false;
        res = res.data;
        if (res.status == 200) {
          if (pay_type.pay_type == 3) {
            document.write(`<iframe width="100%" height="100%" frameborder="0" src="${res.data.payment_urll}"></iframe>`)
          } else if (pay_type.pay_type == 0) {
            // this.$message.success(this.$t('upgrade.Rechargesuccess'));
            location.assign(res.data);
          }
        } else {
          //this.$message.error(this.$t('upgrade.runninglow'));
		  this.$message.error(res.msg);
        }
      })
    },

    recharge () {
      if (this.selected == 0) {
        this.$message.error(this.$t('upgrade.choosemeal'));
        return void 0;
      }
      let pay_type = this.paymessage[this.paytype];
      if (pay_type.pay_type == 2) {
        // 金币支付
        let loadingInstance = ElLoading.service({ fullscreen: true });
        this.axios.post('member/Pay/pay', {
          user_id: this.$store.state.User.id,
          gainer_id: this.$store.state.User.id,
          pay_type: pay_type.pay_type,
          income: this.selectedamount,
          order_type: 2,
          packege_id: this.selected,
        }).then(res => {
          loadingInstance.close();
          this.creditshow = false;
          res = res.data;
          // 金币充值
          if (res.status == 200) {
            this.$message.success(this.$t('upgrade.Rechargesuccess'));
          } else {
            //this.$message.success(this.$t('upgrade.Paymentfailed'));
			this.$message.error(res.msg);
          }
        })
      } else if (pay_type.pay_type == 0 || pay_type.pay_type == 3) {
        // cropay 
        this.creditshow = true;
      } else if (pay_type.pay_type == 1) {
        // paypal
        let loadingInstance = ElLoading.service({ fullscreen: true });
        this.axios.post('member/Pay/pay', {
          user_id: this.$store.state.User.id,
          gainer_id: this.$store.state.User.id,
          pay_type: pay_type.pay_type,
          income: this.selectedamount,
          order_type: 2,
          packege_id: this.selected,
        }).then(res => {
          loadingInstance.close();
          this.creditshow = false;
          res = res.data;
          // 金币充值
          if (res.status == 200) {
            location.assign(res.url);
          } else {
            //this.$message.error(this.$t('upgrade.Paymentfailed'));
			this.$message.error(res.msg);
          }
        })
      }

    },
    getuuid () {
      this.axios.post_form('/member/pay/generateuuid', {}).then(res => {
        res = res.data;
        if (res.status == 200) {
          this.uuid = res.data.uuid;
        }
      })
    },
  },
  created () {
    // this.selected = this.$store.state.Upgrade.vip2.packages[0].id;
    this.$store.dispatch('getUpgradePackages');
    this.getuuid();
	
	let gitem = this.vip2.packages[0];
	this.setSelected(gitem.id, gitem.amounts);
	
	this.paytype = 1;
  }
}
</script>

<style lang="scss" scoped>
.creditwindow {
  height: 100%;
  .formbox {
    width: 600px;
    padding: 30px;
    background-color: rgb(156, 109, 151);
    .inputitem {
      margin-bottom: 10px;
      justify-content: flex-start;
    }
    .creditrechargebtncontainer {
      justify-content: flex-end;
      .creditrechargebtn {
        width: auto;
        height: 30px;
        padding: 0 15px;
        background-color: #f33;
        color: #fff;
        border-radius: 15px;
        cursor: pointer;
      }
    }
  }
}
.container {
  width: 1000px;
  padding: 60px 30px;
  background-color: #fff;
  .title {
    font-size: 20px;
    color: $themeColor;
    margin-bottom: 28px;
    font-weight: bold;
  }
  .usermsg {
    height: 30px;
    justify-content: flex-start;
    color: $fontColor;
    padding-left: 15px;
    .balance {
      font-size: 16px;
    }
    i {
      color: gold;
      font-size: 25px;
      margin-left: 10px;
    }
    .num {
      padding-left: 5px;
      padding-right: 30px;
    }
    .objectuser {
      font-size: 14px;
      padding-left: 5px;
    }
  }
  .butoptionsbox {
    width: 940;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    .memberbox {
      width: 460px;
      border: 1px solid #ccc;
      padding-bottom: 10px;
      .memberheader {
        height: 40px;
        background-color: rgb(212, 139, 184);
        color: gold;
        font-size: 20px;
        font-weight: bold;
      }
      .authoritybox {
        justify-content: flex-start;
        .authorityitem {
          width: 50%;
          height: 50px;
          font-size: 14px;
          color: $fontColor;
          justify-content: flex-start;
          overflow: hidden;
          flex-wrap: nowrap;
          i {
            font-size: 20px;
            color: green;
          }
        }
      }
      .chargepackagebox {
        width: 100%;
        height: auto;
        padding: 0 10px;
        .chargepackageitem {
          height: 50px;
          border-bottom: 1px solid #f1f1f1;
          font-size: 14px;
          color: $fontColor;
          flex-wrap: nowrap;
          justify-content: space-between;
          padding: 0 6px;
          cursor: pointer;
          .province {
            font-weight: bold;
          }
        }
        .checkedpackage {
          border: 1px solid #f88;
          border-radius: 10px;
          background-color: #fee;
        }
      }
    }
  }
  .balance {
    color: $fontColor;
    font-size: 14px;
    margin: 20px 0;
  }
  .paytype {
    .paytypeitem {
      width: 200px;
      height: 54px;
      flex-wrap: nowrap;
      justify-content: flex-start;
      padding: 0 10px;
      border: 1px solid #dcdee1;
      border-radius: 10px;
      cursor: pointer;
      margin: 0 20px 20px 20px;
      .paylogo {
        width: 150px;
        height: 40px;
        margin-left: 20px;
        background-position: left center;
        background-repeat: no-repeat;
        background-size: contain;
        justify-content: space-around;
      }
    }
    .active {
      background: #fff2f3;
      border: 1px solid #ff4a53 !important;
    }
  }

  .rechagebox {
    justify-content: flex-end;
    .button {
      padding: 2px 15px;
      color: #fff;
      background-color: $fontColor;
      border-radius: 4px;
      margin-right: 50px;
      cursor: pointer;
    }
  }
}
</style>