import { websitname, email, account } from "../common/project.config";
export default {
  title: {
    defaulttitle: `加入${websitname} - 在這裏結識朋友`,
    home: "首页",
    signin: `加入${websitname} - 在這裏結識朋友`,
    signup: `加入${websitname} - 在這裏結識朋友`,
    about: "關於我們",
    terms: "服務條款",
    privacy: "隱私政策",
    refund: "退款協議",
    help: "幫助中心",
    contact: "聯係我們",
  },
  regist: {
    title: "加入我們的社區",
    subtitle:
      "加入我們這個富有激情的社區。找到新的朋友，約會，分享照片和視頻。 嗨起來!",
    registp: "注冊您的個人資料",
    formtitlte: "基本資料",
    username: "用戶名",
    containe: "必須包含字母和數字",
    email: "郵箱",
    valid: "必須是有效的",
    pwd: "密碼",
    pwdc: "再次輸入密碼",
	laid: "賴ID",
    gender: "性別",
    male: "男",
    female: "女",
    birth: "生日",
    country: "國家",
    singce: "來自哪個國家",
    terms: "服務條款",
    agree: "我同意",
    signup: "注冊",
    form: {
      required: "請輸入",
      pwdnomatch: "密碼不匹配",
      used: "已被使用",
      faile: "注冊失敗",
      seccess: "注冊成功",
      fomate: "格式錯誤",
    },
    year: "年",
    month: "月",
    date: "日",
  },
  forgot: {
    title: "郵箱輸入",
    subtitle: "輸入您在注冊時使用的電子郵件",
    uremail: "你的郵箱",
    sunmit: "提交",
  },
  login: {
    signinp: "請登錄",
    username: "用戶名",
    email: "郵箱",
    pwd: "密碼",
    remember: "記住我",
    forgot: "忘記密碼",
    signin: "登錄",
    join: "注冊",
    form: {
      error: "用戶名或密碼不正確",
      success: "登錄成功",
    },
    download: "下載",
    aboutus: "現在加入",
	register: "立即註冊",
  },
  loginfooter: {
    about: "關於我們",
    terms: "服務條款",
    privacy: "隱私政策",
    refund: "退款協議",
    help: "幫助中心",
    contact: "聯係我們",
  },
  loginmenu: {
    signin: "登錄",
    signup: "注冊",
  },
  websit: {
    signin: "登錄",
    join: "注冊",
    about: {
      title: "關於我們",
      item_1_title: "公司簡介",
      item_1_content:
        "本站致力於在230多個國家和地區開拓網上交友服務產業，並管理多種不同語言的網站。在這裡您可以盡情的和朋友分享趣事和喜悅，為您提供一個全世界最快捷、最好玩的交友平台，每天都有成千上百新會員加入。",
      item_2_title: "我們的使命是什麼？",
      item_2_content:
        "我們的使命很簡單：幫助大家認識來自全世界的朋友。我們認為：這方面我們做得相當不錯。每個月我們都收到全世界各地的來信，與我們分享他們與在本站上認識的朋友的故事。",
      item_3_title: "怎樣操作？",
      item_3_content:
        "我們為用戶提供認識及了解全世界朋友的機會，通過不同的自由編寫版塊，讓他們在其檔案中詳細介紹他們自己。可以上傳多張圖片，以及有關他們的興趣愛好。我們知道隱私保護的重要性，所以，會員間所有的聯繫都是通過匿名郵件網絡進行的。我們對所有會員的姓名和聯繫信息都保密，除非會員自己想與對方分享個人信息的情況除外，一旦註冊完成，會員可以立即瀏覽潛在朋友的照片與日誌，並與其聯繫。",
    },
    terms: {
      title: "服務條款",
      item_1_title: "1. 同意服務條款",
      item_1_content:
        "本協定（使用條款），包括現時和未來的商業網站和客戶。通過使用本服務，無論您是否注册為網站會員（以下簡稱會員），您都將被視為已同意本使用條款。如果您不同意本服務條款，請停止使用我們的服務。",
      item_2_title: "2. 資格要求",
      item_2_content:
        "未滿18歲的未成年人不得使用我們的服務。通過使用我們的服務，您聲明並保證您有權利和能力遵守使用條款的所有規定。",
      item_3_title: "3. 使用條款的變更",
      item_3_content:
        "本網站保留更改本使用條款的權利，恕不另行通知。您可以查看服務條款以定期更改情况。當我們更改使用條款時，如果您使用我們的服務，無論您是否認為您同意這些更改。",
      item_4_title: "4. 隱私",
      item_4_content:
        "我們的網站尊重網站訪問者的隱私。使用該服務應遵守我們的隱私政策。我們的網站合作夥伴同意遵守隱私政策的規定",
      item_5_title: "5. 服務變更",
      item_5_content:
        "我們的網站可以决定添加，删除或修改服務或服務，恕不另行通知。",
      item_6_title: "6. 特色服務",
      item_6_subtitle1: "A) 付款",
      item_6_subtitlecontent1:
        "支付資訊將作為您同意購買的服務產生的費用進行傳輸，結果將由您與金融機構、信用卡髮卡機构或您選擇的其他支付服務提供者之間的返回資訊决定。如果我們的網站不接受您的支付服務提供者提供的支付服務，您可以支付您需要的所有金額，並同意繼續使用我們的服務。如果您的帳戶已付款，您可以要求我們取消您的付款狀態。一旦您的帳戶被取消，您的付款狀態將在您的最終付款日期到期。付費狀態不可轉讓。我們網站的付款狀態是最終銷售，不能退款。會員在當月或季度到期後不會自動扣除持卡人的續費。如果會員想繼續使用，需要手動充值。有爭議的費用應在您購買服務後60天內報告給我們的網站。如果您對您的付費狀態有任何疑問，請聯繫我們解决。",
      item_6_subtitle2: "B) 服務合同",
      item_6_subtitlecontent2:
        "您在我們的網站訂購服務將不會自動續訂。您可以隨時聯繫我們的客戶服務團隊進行更改或重新訂購。如果您選擇訂購，您的訂單將在服務未自動續訂時過期。過期前取消定做，無法獲得退款。",
      item_6_subtitle3: "C)免費試用及其他促銷",
      item_6_subtitlecontent3:
        "任何免費試用或其他促銷管道，均可在特定試用期內定制使用我們的服務。如果用戶需要訂購促銷服務條款，則應在試用期前取消訂單，以避免交付定制費用。",
      item_7_title: "7. 通過合作夥伴加入我們的網站",
      item_7_content:
        "如果您通過我們的合作夥伴注册使用我們的服務，我們的網站將與其合作夥伴一起提供服務。您將有權使用會員服務，並有權向我們網站轉讓您的會員資格或付費向我們網站轉讓您的帳戶類似的服務。",
      item_8_title: "8. 社區內容",
      item_8_content: `所有服務應遵循社區內容指南的要求。您同意不發佈、傳輸、分發或連結任何涉及非法、騷擾、誹謗、威脅、有害、淫穢、叛逆、誹謗、誹謗他人名譽和侵犯他人隱私的內容，也不向他人發送不高興的內容，更確切地說是針對其他人權團體或內容。知識產權未經本人書面同意，不得使用他人的知識產權，包括受著作權和商標保護的資訊，不得對他人的知識產權進行變更或變更，如非著作權的文字、圖片等。我們網站不會對任何由會員或團體發送的郵件內容負責，任何資訊、商品或服務均不對協力廠商或會員負責。使用我們的服務，您同意：在以下情况下，我們的網站將不對您或他人負責：例如：對任何一方的威脅、誹謗、淫穢、猥褻、攻擊或冒犯，或侵權，包括非法使用知識產權。我們的網站沒有您提供給我們的所有資訊（包括迴響和建議），或者您發佈我們的網站、下載、輸入或提交所有資訊（“提交的資訊”）。但是，如果您在我們的網站或客戶上發佈、上傳、輸入、提供或提交資訊，您表示您給予我們的網站永久和不可撤銷的轉讓權，我們的網站有權複製、使用、存儲、修改、編輯、翻譯或發佈您提交的任何或所有資訊，而且沒有支付版稅的資訊。我們的網站沒有義務發佈或使用您提交的資訊，我們的網站可以隨時决定删除您提交的資訊。在本網站中，您下載、輸入或提交的資訊表明您可以控制資訊的發佈。對於您的資訊被删除、無效或未能保存，我們網站及其合作夥伴將不承擔任何責任。`,
      item_9_title: "9. 會員帳戶",
      item_9_content:
        "一旦您注册我們的服務，您將收到一個帳號和密碼，您有責任對您的帳號和密碼保密，並應負責與您的帳號有關的所有活動。一旦注册成為會員，您將負責您的所有資訊檔案。禁止在檔案或網絡服務專案中發佈色情或其他淫穢內容，因為這會威脅您的會員資格。對於下線交友網站會員我們網站，我們網站將不承擔任何責任。您同意我們網站向您發送郵件（涉及服務資訊（包括陞級變更）、新功能或事件、使用服務的意見和建議）。",
      item_10_title: "10. 受版權和商標保護的資料",
      item_10_content: "我們網站的內容受版權保護© 2011-2023.",
      item_11_title: "11. 免責聲明",
      item_11_content: `在我們的網站或由我們的網站（或我們的網站代理商或合作夥伴）發佈的資訊，根據“現有”和“現有”報價。它提供各種明示或暗示的保證和條件。我們的網站不控制協力廠商提供的任何資訊、產品或服務。您明確同意使用本服務將獨自承擔風險。在適用法律允許的最大範圍內：我們網站上所有明示或暗示的保證、免責聲明和此類保證，包括但不限於暗示的適銷性，並確保適用於特殊用途；我們的網站不保證所提供的服務能滿足您的需求，也不保證服務會不間斷、及時、安全或正確。我們的網站不用於服務和使用結果作為抵押品。我們的網站不保證本網站或服務不包含任何病毒或其他有害成分。本網站不保證或聲明：對網站轉讓資訊保密。我們的網站不保證翻譯的準確性和完整性。我們的網站不保證以下內容：通過本網站購買或獲得的任何商品或服務，或在本網站上做廣告或贊助商品或服務，或通過本網站進行的任何交易。資訊我們的網站不是您從我們的網站收到服務或作出任何明示保證（無論是口頭或書面）。`,
      item_12_title: "12.聲明",
      item_12_content1: `這個網站上所有的禮物都是虛擬禮物。`,
      item_12_content2: `以上所有項目均根據英國法律組織和管理。線上商戶位於英國。帳單帳單名稱將為“${account}”。`,
    },
    privacy: {
      title: "隱私政策",
      item_0_content: `本網站尊重用戶的隱私權，並製定隱私政策，保護您的隱私權。隱私政策包括：用戶情況，我們所收集的信息，怎樣利用這些信息，以及與誰分享這些信息。當您使用我們的網站或服務，或與我們進行商務交易時，我們建議你仔細閱讀“隱私政策”。通過使用我們的網站，表明您已接受本保密協議的規定。 非常重視網絡隱私。如果您對該隱私政策有任何疑問，請聯繫我們。`,
      item_1_title: "未滿18歲的人士",
      item_1_content: `不會故意收集或保存未滿18歲人士的個人驗證信息或非個人驗證信息，本網站下的任何項目均不面向未滿18歲的人士開放。如果你未滿18歲，請停止使用或禁止進入本網站或服務。如果獲悉所收集的信息中有未滿18歲人士的個人驗證信息，本網站將採取合理的步驟，刪除該信息。`,
      item_2_title: "我們所收集的信息",
      item_2_content: `您填寫的相關信息（包括您的個人信息，聯繫信息，以及當您創建會員檔案時所填寫的其他檔案信息）；當你自由填寫顧客調查表，提供反饋和參與競爭時，所提供的相關信息;如果您與我們聯繫，我們會記錄你的通信信息;交易信息包括您從我們網站定購有關產品和服務的信息;瀏覽我們網站的訪客，並且他們的信息已被記錄的，可以有權以相同的信息登錄，並有權更正信息或查看有關該信息的政策和規則。訪客可以直接與我們聯繫。`,
      item_3_title: "其他方收集的信息",
      item_3_content: `我們允許第三方（包括我們的授權服務提供商，廣告公司和廣告網站）在我們的網站上發表廣告。如果你打開一個鏈接（例如一個廣告標誌），這將使你將離開我們的網站，登錄其他的網站，如果你隨後在其他網站上提供信息，我們將不能控制該信息的使用。本隱私政策將不對其他網站或廣告商的信息保密負責。對於其他網站如何使用你的信息，我站也將不為此負擔任何責任。`,
      item_4_title: "我們怎樣使用收集的信息？",
      item_4_content1: `管理您的用戶賬戶（包括：通知您任何匹配的對象，提示，通告，更新資料或促銷活動`,
      item_4_content2: `幫助你訂製個性化的網站回訪`,
      item_4_content3: `向您提供您所詢問的信息，產品或服務，或者你感興趣的信息；進行市場分析和調查`,
      item_4_content4: `幫助我們改進服務結構，佈局和適用性`,
      item_5_title: "我們與誰分享所收集的信息",
      item_5_content: `我們可能在特定方面將您的信息與第三方分享，例如：當您從我們網站訂製服務時，我們提供你的信息給第三方進行付款處理。但是我們將控制第三方使用您的信息，並對此負責。我們有可能將您的某些信息透露給我們的合作公司，以便向您提供服務，例如：郵件或聊天功能服務。為了提供這些服務，我們必須將您的信息（包括您的用戶名和email地址）提供給我們的合作夥伴。`,
      item_6_title: "COOKIES",
      item_6_content: `我們竭力按照您的喜好為您提供服務。我們使用Cookies 保存並記錄有關於您的信息。 Cookie是指通過網絡服務器發送到您瀏覽器的一小部分數據，該數據儲存在您的計算機硬盤上。`,
      item_7_title: "變更隱私政策",
      item_7_content: `我們經常審核“隱私政策”，並在此頁面公佈政策的變更信息。我們鼓勵會員經常審核我們的隱私政策，以便更好地了解我們所收集的信息，以及我們怎樣運用信息，以及在何種情況下使用信息，與他人分享信息。`,
      item_8_title: "怎樣聯繫我們",
      item_8_content: `如果您對於本隱私政策或對於我們的信息處理方式有何疑問，或對於我們透露信息給第三方（為了直銷的目的）有疑問，請通過email或信件與聯繫我們。 E-mail: %{email}`,
    },
    refund: {
      title: "退款協議",
      item_1_title: "退款和取消",
      item_1_content: `如果交易指令已成功充值，在消費時聯系後，由於支付指令經過不同的銀行，支付返還可能存在差异。如果您對退貨有任何疑問，可以聯系網站客服進行解釋。`,
      item_2_title: "退款",
      item_2_content: `如果您對交易訂單有任何疑問，需要聯系網站客服，客服會幫助您解釋訂單情况。對於退款處理，根據退款流程，通常需要一周以上的時間。注意查看髮卡行的退款資訊。`,
      item_3_title: "取消",
      item_3_content1: `如果交易訂單已成功充值，在聯系網站客服後，可協商退款。按照退款流程，通常需要一個多星期。請核對您的髮卡行退款資訊。`,
      item_3_content2: `Email: %{email}`,
    },
    help: {
      title: "幫助中心",
      item_1_title: "1. 無法登陸網站",
      item_1_content: `請首先確認您是否成功註冊${websitname}賬號，然後確認您的賬號密碼輸入是否正確，如果仍然無法登陸，請點擊“忘記密碼”，如仍然無法登陸，請及時聯繫網站管理員。`,
      item_2_title: "2. 忘記密碼",
      item_2_content: `如果你忘記您的登陸密碼，當你登陸的時候，請點擊“忘記密碼”選項，進行密碼找回或是重置密碼。`,
      item_3_title: "3. 如何升級會員",
      item_3_content: `您可以點擊升級會員圖標按鈕，然後選擇你所要進行升級的會員等級進行升級。`,
      item_4_title: "4. 如何更好的使用翻譯功能",
      item_4_content: `${websitname}網站是一個針對全球的SNS國際交友網站，在這里通過${websitname}翻譯平台，讓全球朋友溝通無限！當你擁有較高的會員權限，你將可以享有更多的翻譯權限。`,
      item_5_title: "5. 如何舉報非法賬號",
      item_5_content: `當您發現一些非法賬號，可以進入他的個人主頁，點擊“舉報”。或是及時聯繫我們網站管理員，我們將會作出調查並進行處理，以確保網站的安全性。`,
      item_6_title: "6. 如何進行支付",
      item_6_content: `您可以登錄${websitname}點擊充值，我們目前僅提供PayPal、支付寶等網絡安全支付方式，所有的支付方式都是絕對安全的。本網站絕沒有任何自動記憶或重複收費的程序。有任何疑問，請及時聯繫網站管理員提出您的問題。`,
      item_7_title: "7. 充值不成功",
      item_7_content: `如果您在${websitname}的充值頁面無法成功充值，請先查看你的信用卡是否有問題，然後更換瀏覽器重新嘗試。有時您也可以選擇聯繫貝寶（比如在系統提示\"您的信用卡不能用於支付這筆交易\"的情況下）。如果各種方法都嘗試過後仍舊無法成功充值，請將詳細情況告訴我們，比如您使用的是哪種充值方式，有何錯誤提示？使用哪種瀏覽器？為了協助我們技術人員快速查找原因幫你解決問題，請您盡可能將出錯頁面截圖發給我們網站管理員。`,
    },
    contact: {
      title: "聯係我們",
      item_0_content: `Email: %{email}`,
    },
  },
  authority: {
    Allow_profile_wall_posts: "允许个人资料上墙帖",
    Search_users: "搜索用戶",
    View_profiles: "查看個人資料",
    Auth_message: "聊天發圖片免費",
    Allow_comments_on_newsfeed: "允許在動態下面評論",
    Dynamic_unrestricted: "無免費條數限制",
    Allows_newsfeed_to_be_sent: "允许發動態",
    Upload_photo: "上傳照片",
    View_photo: "查看圖片",
    Comment_photo: "評論圖片",
    Add_video: "添加視頻",
    View_video: "查看視頻",
    Comment_video: "評論視頻",
    Start_mail_dialog: "啓用郵件對話框",
    Continue_mail_dialog: "繼續聊天對話框",
    Read_chat_messages: "閲讀聊天消息",
    Send_chat_messages_for_free: "免費發送聊天消息",
    Start_chat_dialog: "啟用聊天輸入框",
    Read_mails: "閲讀郵件",
    Continue_chat_dialog: "繼續聊天對話框",
    Free_use_of_the_translation_function: "免費使用翻譯",
  },
  home: {
    index: "首頁",
    member: "會員",
    chat: "聊天",
    recharge: "充值",
    upgrade: "升級",
    store: "商店",
    photo: "照片",
    mails: "郵件",
  },
  dynamic: {
    dynamic: "動態",
    photo: "照片",
    personaldata: "個人資料",
    like: "喜歡",
    detele: "刪除背景圖片",
	deteleone: "刪除",
    upload: "上傳背景圖片",
  },
  index: {
    member: "會員",
    mycredits: "我的賬戶",
    balance: "餘額",
    user: "社區用戶",
    trend: "最新動態",
    pay: "充值",
    latest: "最新",
    online: "在綫",
    viewall: "查看全部",
    invite: "邀請朋友",
    showpath: "查看邀請鏈接",
    photo: "照片",
    copy: "複製",
  },
  trend: {
    joined: "加入了我們的網站。",
    edited0: "編輯了她的個人資料。",
    edited1: "編輯了他的個人資料。",
    avatar0: "修改了她的頭像。",
    avatar1: "修改了他的頭像。",
  },
  store: {
    store: "商店",
    gift: "禮物",
    receive: "我收到的禮物",
    sendgift: "發送禮物",
    credits: "金幣",
    username: "用戶名",
    msg: "贈送留言",
    send: "贈送",
  },
  recharge: {
    self: "為自己充值",
    pay: "充值",
  },
  upgrade: {
    silver: "鑽石會員",
    gold: "皇冠會員",
    Rechargesuccess: "支付成功",
    Paymentfailed: "支付失敗",
    runninglow: "餘額不足",
    choosemeal: "請選擇套餐",
    actuallypaid: "實付金額",
    paymentmethod: "支付方式",
    month: "月",
    province: "優惠",
    usd: "美元",
  },
  pay: {
    name: "持卡人名字",
	ename: "請填寫英文姓名",
    lastname: "持卡人姓",
    cardnumber: "卡號",
    Expiration: "過期時間",
    CVV: "CVV",
    country: "帳單國家",
    state: "帳單省(州)",
    city: "帳單都市",
    address: "帳單地址",
    phone: "電話",
    ZipCode: "郵遞區號",
  },
  member: {
	whole: "全部",
    online: "在綫",
    latest: "最新",
    mylike: "我喜歡的",
    likeme: "喜歡我的",
    mutua: "互相喜歡",
    search: "搜索",
    searchbyname: "用戶名搜索",
    follow: "關注",
    cancelfollow: "取消關注",
    sendgift: "發送禮物",
    chatnow: "跟他聊",
    usersearch: "搜索用戶",
    browseuser: "瀏覽用戶",
    basic: "基本資料",
    year: "歲",
  },
  user: {
    setup: {
      myavatar: "我的頭像",
      setting: "資料設定",
      signature: "個性簽名",
      languge: "語言",
      changepwd: "更換密碼",
      photograph: "拍照",
      Choosefromalbum: "从相册中选择",
      UsedAvatar: "使用過的頭像",
      Basicinformation: "基本資料",
    },
    edit: {
      title: "資料編輯",
      avatar: "用戶頭像",
      changepwd: "修改密碼",
      oldpwderror: "舊密碼輸入錯誤",
      oldpwd: "舊密碼",
      modifiedsuccess: "修改成功",
      modifiedfail: "修改失敗",
      save: "保存",
      cancel: "取消",
      trendplaceholder: "聊一聊~",
      sendemail: "發送私信",
      follow: "關注",
      gift: "送禮物",
      report: "舉報",
      photo: "照片",
      modifyavatar: "修改頭像",
      signatureplaceholder: "你的個性簽名",
    },
    sendemail: "聊天",
    follow: "關注",
    gift: "送禮物",
    report: "舉報",
    photo: "照片",
  },
  message: {
    address: "郵箱地址",
    message: "郵件",
    msg: "郵件内容",
    newmsg: "新郵件",
    send: "發送郵件",
  },
  global: {
    send: "發送",
    save: "保存",
    confirm: "確定",
    cancel: "取消",
    search: "搜索",
    modify: "修改",
  },
  empty: {
    nouser: "沒有用戶",
    nophoto: "沒有照片",
    noresult: "沒有結果",
    nonitice: "沒有通知",
    chat: "你的會話列表為空",
  },
  tips: {
    savesuccess: "保存成功",
    deletedsuccess: "刪除成功",
    usernotexist: "用戶不存在",
    insufficientcredits: "餘額不足",
    needupgrade: "需要升級",
    sendfail: "發送失敗",
    sendsuccess: "發送成功",
    followsuccess: "關注成功",
    contentcantempty: "内容不能為空",
    newpwd: "請在郵箱查看您的新密碼",
    nopermission: "您沒有權限",
    loginsuccess: "登陸成功",
    loginfailed: "登錄失敗",
    followsuccess: "關注成功",
    followcancel: "取消關注",
    likesuccess: "喜歡成功",
    likecancel: "取消喜歡",
    loginsuccess: "登陸成功",
    loginfailed: "登陸失敗",
    pullrefresh: "下拉即可刷新...",
    loosingrefresh: "釋放即可刷新...",
    loading: "加載中...",
	nomore: "沒有更多了",
	clickmore: "查看更多",
    underReview: "注冊成功，審核中...",
    copysuccess: "複製成功",
    aproval: "您的賬號正在審核中...",
    nofreechat: "免費聊天已用完，請",
    continue: "后發信息",
    upmember: " 升級會員 ",
    nouserfound: "未搜索到相關用戶",
	incomeErr: "金額錯誤",
	patience:"請耐心等待",
  },
  general: {
    title1: "群發郵件",
    item1: "訂閱新郵件",
    tips1: "已訂閱，未訂閱/點擊訂閱",
  },
  notification: {
    general: "默认",
    notice: "通知",
    title1: "基礎",
    title2: "最新动态",
    title3: "消息",
    title4: "图片",
    title5: "视频",
    title6: "用户余额",
    title7: "虚拟礼物",
    title8: "也通过电子邮件发送",
    item1: "有人評論了我的個人資料",
    item2: "有人评论我的状态",
    item3: "有人喜欢我的身份",
    item4: "有人给我的照片墙上写评论",
    item5: "有人給我發了一條新郵件",
    item6: "有人給我發了一條新消息",
    item7: "有人对我的照片发表评论",
    item8: "有人評論我的視頻",
    item9: "有人给予我信用点",
    item10: "有人给我发送礼物",
    item11: "立即",
    item12: "自动（如果您在网站上登陸未超過2天）",
    item13: "从不",
  },
  navigator: {
    message: "消息",
    notice: "通知",
    profile: "我的個人主頁",
    edit: "資料編輯",
    general: "個性化設置",
    mail: "數據標簽",
    signout: "退出",
  },
  account: {
    account: "賬戶",
    quantity: "剩餘數量",
    choose: "選擇充值類型",
    service: "服務",
    totalprice: "總價",
    save: "省",
    membership: "會員特權",
  },
  date: {
    second: "秒前",
    minute: "分鐘前",
    hour: "小時前",
    just: "剛剛",
    month: "月",
    month1: "1月",
    month2: "2月",
    month3: "3月",
    month4: "4月",
    month5: "5月",
    month6: "6月",
    month7: "7月",
    month8: "8月",
    month9: "9月",
    month10: "10月",
    month11: "11月",
    month12: "12月",
  },
  chat: {
    queryhistory: "查詢歷史消息",
    active: "活躍",
    chatlist: "會話列表",
  },
  mine: {
    membership: "會員",
    free: "非會員",
    expires: "到期",
    goldcard: "皇冠會員",
    silvercard: "鑽石會員",
    permanent: "-", 
    newsfeeds: "最新動態",
    balance: "用戶余額",
    recharge: "充值",
    upgrade: "升級",
    gift: "禮物",
	giftbox: "禮物盒子",
    albums: "相冊",
    video: "視頻",
    customerservice: "客服",
    index: "首頁",
    newsfeed: "最新動態",
    message: "消息",
    mine: "我",
	storemoney:"儲值",
  },
  tab: {
    chat: "聊天",
    mail: "郵件",
    customer: "客服",
  },
  country: {
    AD: "安道爾",
    AE: "阿聯酋",
    AF: "阿富汗",
    AG: "安堤瓜及巴爾布達",
    AI: "安圭拉",
    AL: "阿爾巴尼亞",
    AM: "亞美尼亞",
    AO: "安哥拉",
    AQ: "南極洲",
    AR: "阿根廷",
    AS: "美屬薩摩亞",
    AT: "奧地利",
    AU: "澳大利亞",
    AW: "阿魯巴",
    AX: "奧蘭群島",
    AZ: "阿塞拜疆",
    BA: "波黑",
    BB: "巴巴多斯",
    BD: "孟加拉",
    BE: "比利時",
    BF: "伯基納法索",
    BG: "保加利亞",
    BH: "巴林",
    BI: "布隆迪",
    BJ: "貝南",
    BL: "聖巴泰勒米島",
    BM: "百慕大",
    BN: "汶萊",
    BO: "玻利維亞",
    BQ: "荷蘭加勒比區",
    BR: "巴西",
    BS: "巴哈馬",
    BT: "不丹",
    BV: "布韋島",
    BW: "波劄那",
    BY: "白俄羅斯",
    BZ: "貝裡斯",
    CA: "加拿大",
    CC: "科科斯群島",
    CD: "剛果（金）",
    CF: "中非",
    CG: "剛果（布）",
    CH: "瑞士",
    CI: "象牙海岸",
    CK: "科克群島",
    CL: "智利",
    CM: "喀麥隆",
    CN: "中國",
    CO: "哥倫比亞",
    CR: "哥斯大黎加",
    CU: "古巴",
    CV: "佛得角",
    CX: "聖誕島",
    CY: "賽普勒斯",
    CZ: "捷克",
    DE: "德國",
    DJ: "吉佈提",
    DK: "丹麥",
    DM: "多明尼克",
    DO: "多明尼加",
    DZ: "阿爾及利亞",
    EC: "厄瓜多",
    EE: "愛沙尼亞",
    EG: "埃及",
    EH: "西撒哈拉",
    ER: "厄利垂亞",
    ES: "西班牙",
    ET: "衣索比亞",
    FI: "芬蘭",
    FJ: "斐濟群島",
    FK: "馬爾維納斯群島",
    FM: "密克羅尼西亞聯邦",
    FO: "法羅群島",
    FR: "法國",
    GA: "加蓬",
    GB: "英國",
    GD: "格林伍德",
    GE: "格魯吉亞",
    GF: "法屬圭亞那",
    GG: "根西島",
    GH: "加納",
    GI: "直布羅陀",
    GL: "格陵蘭",
    GM: "岡比亞",
    GN: "幾內亞",
    GP: "瓜德羅普",
    GQ: "赤道幾內亞",
    GR: "希臘",
    GS: "南喬治亞島和南桑威奇群島",
    GT: "瓜地馬拉",
    GU: "關島",
    GW: "幾內亞·比索",
    GY: "蓋亞那",
    HM: "赫德島和麥克唐納群島",
    HN: "洪都拉斯",
    HR: "克羅地亞",
    HT: "海地",
    HU: "匈牙利",
    ID: "印尼",
    IE: "愛爾蘭",
    IL: "以色列",
    IM: "曼島",
    IN: "印度",
    IO: "英屬印度洋領地",
    IQ: "伊拉克",
    IR: "伊朗",
    IS: "冰島",
    IT: "義大利",
    JE: "澤西島",
    JM: "牙買加",
    JO: "約旦",
    JP: "日本",
    KE: "肯雅",
    KH: "高棉",
    KG: "吉爾吉斯斯坦",
    KI: "吉裡巴斯",
    KM: "科摩羅",
    KN: "聖基茨和尼維斯",
    KP: "朝鮮",
    KR: "韓國",
    KW: "科威特",
    KY: "開曼群島",
    KZ: "哈薩克共和國",
    LA: "老撾",
    LB: "黎巴嫩",
    LC: "聖盧西亞",
    LI: "列支敦士登",
    LK: "斯里蘭卡",
    LR: "賴比瑞亞",
    LS: "萊索托",
    LT: "立陶宛",
    LU: "盧森堡",
    LV: "拉脫維亞",
    LY: "利比亞",
    MA: "摩洛哥",
    MC: "摩納哥",
    MD: "莫爾達瓦",
    ME: "黑山",
    MF: "法屬聖馬丁",
    MG: "馬拉加西",
    MH: "馬紹爾群島",
    MK: "馬其頓",
    ML: "馬利",
    MN: "蒙古國",
    MM: "緬甸",
    MP: "北馬利安納群島",
    MQ: "馬提尼克",
    MR: "茅利塔尼亞",
    MS: "蒙塞拉特島",
    MT: "馬爾他",
    MU: "模裡西斯",
    MV: "瑪律地夫",
    MW: "馬拉威",
    MX: "墨西哥",
    MY: "馬來西亞",
    MZ: "莫三比克",
    NA: "納米比亞",
    NC: "新赫里多尼亞",
    NE: "尼日",
    NF: "諾福克島",
    NG: "奈及利亞",
    NI: "尼加拉瓜",
    NO: "挪威",
    NP: "尼泊爾",
    NR: "瑙魯",
    NU: "紐埃",
    NZ: "紐西蘭",
    OM: "阿曼",
    PA: "巴拿馬",
    PE: "秘魯",
    PF: "法屬波利尼西亞",
    PG: "巴布亞新磯內亞",
    PH: "菲律賓",
    PK: "巴基斯坦",
    PL: "波蘭",
    PM: "聖皮爾和密克隆",
    PN: "皮特凱恩群島",
    PR: "波多黎各",
    PS: "巴勒斯坦",
    PT: "葡萄牙",
    PW: "帕勞",
    PY: "巴拉圭",
    QA: "卡塔爾",
    RE: "留尼汪",
    RO: "羅馬尼亞",
    RS: "塞爾維亞",
    RU: "俄羅斯",
    RW: "盧安達",
    SA: "紐西蘭",
    SB: "所羅門群島",
    SC: "塞舌耳",
    SD: "蘇丹",
    SG: "新加坡",
    SH: "聖赫倫那",
    SI: "斯洛文尼亞",
    SJ: "斯瓦爾巴群島和揚馬延島",
    SK: "斯洛伐克",
    SL: "塞拉里昂",
    SM: "聖馬利諾",
    SN: "塞內加爾",
    SO: "索馬利亞",
    SR: "蘇利南",
    SS: "南蘇丹",
    ST: "聖多美及普林西比島",
    SV: "薩爾瓦多",
    SY: "敘利亞",
    SZ: "史瓦濟蘭",
    TC: "特克斯和凱科斯群島",
    TD: "查德",
    TF: "法屬南部領地",
    TG: "多哥",
    TH: "泰國",
    TJ: "塔吉克共和國",
    TK: "托克勞",
    TL: "東帝汶",
    TM: "土庫曼共和國",
    TN: "突尼斯",
    TO: "東加",
    TR: "土耳其",
    TT: "千裡達托貝哥",
    TV: "吐瓦魯",
    TZ: "坦尚尼亞",
    UA: "烏克蘭",
    UG: "烏干達",
    UM: "美國本土外小島嶼",
    US: "美國",
    UZ: "烏茲別克",
    UY: "烏拉圭",
    VA: "梵蒂岡",
    VC: "聖文森特和格林納丁斯群島St.Vincent",
    VE: "委內瑞拉",
    VG: "英屬維京群島",
    VI: "美屬維爾京群島",
    VN: "越南",
    VU: "瓦努阿圖",
    WF: "瓦利斯和富圖納",
    WS: "薩摩亞",
    YE: "葉門",
    YT: "馬約特",
    ZA: "南非",
    ZM: "尚比亞",
    ZW: "辛巴威",
  },
  report: {
    ads: "廣告",
    offensive: "冒犯",
    illegal: "非法",
    content: "舉報%{user}的内容為...",
    success: "已提交，結果請等待管理員通知",
  },
  notice: {
    report: {
      result: "舉報結果",
      reportdetail: "管理員已經受理了您的舉報信息",
    },
    gift: {
      receive: "收到一份禮物",
      from: "來自：",
      leavemsg: "留言：",
    },
  },
  globalgift: {
    sendto: "贈送禮物給",
    credits: "金幣",
    balance: "用戶餘額",
    psag: "請選擇一件禮物",
    message: "贈言",
    nogift: "請按要求完成表單",
	giftname: "禮物名稱",
	giftinfo: "禮物說明",
	giftprice: "售價",
  },
};
