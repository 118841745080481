<template>
  <div class="flexbox transcomponents">
    <div class="flexbox optionbox">
      <div class="flexbox langbtn" @click="showaction = true">
        {{ showlang }}
      </div>
      <div class="flexbox submit" @click="send">{{ $t("global.send") }}</div>
    </div>
    <div
      class="flexbox inputbox"
      :style="{ height: height ? height : '200px' }"
    >
      <div class="left">
        <textarea v-model="origin"></textarea>
      </div>
      <div class="line"></div>
      <div class="right">
        <textarea v-model="result"></textarea>
      </div>
    </div>
  </div>
  <van-action-sheet
    v-model:show="showaction"
    :actions="actions"
    @select="onSelect"
  />
</template>

<script>
export default {
  props: ['id', 'height'],
  data () {
    return {
      origin: '',
      result: '',
      showlang: 'English',
      lang: 'en',

      showaction: false,
      actions: [
        { name: 'English', lang: 'en' }, { name: '简体中文', lang: 'zh' }, { name: '繁體中文', lang: 'cht' }
      ],

      timeout: null,
    }
  },
  watch: {
    origin () {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getTrans();
      }, 500);
    }
  },
  methods: {
    getTrans () {
      this.axios.post_form('/member/index/translate', {
        content: this.origin,
        to: this.lang,
      }).then(res => {
        res = res.data;
        if (res.status == 200) {
          this.result = res.data.dst;
        } else if (res.status == -1) {
          this.$message.error(this.$t('tips.insufficientcredits'))
        }
      })
    },
    onSelect (item) {
      this.showaction = false;
      this.lang = item.lang;
      this.showlang = item.name;
    },
    // 发送聊天信息
    send () {
      if (this.result.trim() == "") {
        this.$message.error(this.$t('tips.contentcantempty'));
        return false;
      }
      this.$store.dispatch('wssend', {
        opt: 1,
        uid: this.$store.state.User.id,
        address: this.id,
        txt: this.result,
        type: 'text',
        origin: true,
      })
      this.origin = "";
      this.result = "";
    },
  },

}
</script>

<style lang="scss" scoped>
.transcomponents {
  padding: 10px;
  background-color: #fff;
  font-size: 14px;
  .optionbox {
    padding: 10px;
    justify-content: space-between;
    .langbtn {
      width: auto;
      padding: 0 15px;
      height: 30px;
      border: 1px solid #999;
      border-radius: 5px;
      color: #333;
      cursor: pointer;
    }
    .submit {
      width: auto;
      padding: 0 15px;
      height: 30px;
      border-radius: 5px;
      background-color: #6666ff;
      color: #fff;
      cursor: pointer;
    }
  }
  .inputbox {
    flex-wrap: nowrap;
    height: 200px;
    .left {
      width: 50%;
      height: 100%;
    }
    .line {
      width: 1px;
      height: 100%;
      border-right: 1px solid #eee;
    }
    .right {
      width: 50%;
      height: 100%;
    }
    textarea {
      width: 100%;
      height: 100%;
      border: 0;
      resize: none;
    }
  }
}
</style>