import store from "./index";

export default {
  /**
   * 查询聊天列表中用户id的索引值
   * @param {number} id
   * @return {number} number | -1
   */
  checkHasUser(id) {
    let flag = -1;
    this.chatList.forEach((item, index) => {
      if (item.uid == id) {
        flag = index;
      }
    });
    return flag;
  },

  checkGlobalChatListHasUser(id) {
    let flag = -1;
    this.globalChatList.forEach((item, index) => {
      if (item.uid == id) {
        flag = index;
      }
    });
    return flag;
  },

  // 返回聊天对象id
  checkId(data) {
    let selfid = store.state.User.id;
    if (data.sender_id == selfid) {
      return data.reciver_id;
    } else {
      return data.sender_id;
    }
  },

  // 是否是自己发送的消息
  checkSenderIsSelf(data) {
    let selfid = store.state.User.id;
    if (data.sender_id == selfid) {
      return true;
    } else {
      return false;
    }
  },

  // 播放发送消息声音
  playAudioSend() {
    document.getElementById("s_audio").play();
  },

  // 播放接受消息声音
  playAudioReceive() {
    document.getElementById("r_audio").play();
  },
};
