<template>
  <div class="infocontainer">
    <div class="title1">{{ $t("websit.about.title") }}</div>

    <div class="title2">{{ $t("websit.about.item_1_title") }}</div>
    <div class="content">{{ $t("websit.about.item_1_content") }}</div>

    <div class="title2">{{ $t("websit.about.item_2_title") }}</div>
    <div class="content">{{ $t("websit.about.item_2_content") }}</div>

    <div class="title2">{{ $t("websit.about.item_3_title") }}</div>
    <div class="content">{{ $t("websit.about.item_3_content") }}</div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.infocontainer {
  width: 1000px;
  padding: 70px 0;
}
.title1,
.title2,
.title3 {
  color: $themeColor;
  font-weight: bold;
  margin: 5px 0;
}
.content {
  color: #fff;
  font-size: 14px;
}
.title1 {
  margin-bottom: 28px;
  font-size: 20px;
}
.title2 {
  font-size: 16px;
  line-height: 30px;
}
.title3 {
  font-size: 14px;
  line-height: 26px;
}
</style>