export default {
  state: {
    user: {},
    show: false,
  },
  mutations: {
    report(state, data) {
      state.user = data;
      state.show = true;
    },
    closeReport(state) {
      state.show = false;
    },
  },
};
