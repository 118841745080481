const expressionStrList = [
  "Smile",
  "Grimace",
  "Drooling",
  "Scowl",
  "Chill",
  "Sob",
  "Shy",
  "Silence",
  "Cry",
  "Embarrassed",
  "Onfire",
  "Wink",
  "Grin",
  "Surprised",
  "Sad",
  "Cool",
  "Frightened",
  "Scream",
  "Puke",
  "Chuckle",
  "Lovely",
  "Sneer",
  "Arrogant",
  "Hungry",
  "Drowsy",
  "Panic",
  "Sweating",
  "Laugh",
  "Soldier",
  "Strive",
  "Confused",
  "Shhh",
  "Hypnotized",
  "Torment",
  "Frustrated",
  "Skull",
  "Hammer",
  "Wave/Bye",
  "Relived",
  "Picknose",
  "Applause",
  "Flushed",
  "Hellooo",
  "Snub1",
  "Snub2",
  "Yawn",
  "Distressed",
  "Sniffle",
  "Sly",
  "Pucker",
  "Scared",
  "Pathetic",
  "blink1",
  "ssmile",
  "gill",
  "blink2",
  "lcry",
  "blood",
  "hug",
  "slipe",
  "loveyou",
  "entrust",
  "hook",
  "no",
  "yes",
  "assist",
  "fist",
  "loser",
  "shears",
  "handshake",
  "theart",
  "gift",
  "cake",
  "flower",
  "dflower",
  "coffe",
  "rice",
  "footb",
  "backetb",
  "colund",
  "rain",
  "mouth",
  "lightning",
  "banana",
  "tsama",
  "panda",
  "drug",
  "gnight",
  "gmorning",
  "sofa",
  "bill",
  "ppb",
  "pop",
  "worm",
  "noodle",
  "candle",
  "microphone",
  "ring",
  "lihua",
  "knife",
  "back",
  "pinwheel",
  "car",
  "banger",
  "suger",
  "clock",
  "mail",
  "umbrella",
];
let expressionSource = {};
expressionStrList.forEach((item, index) => {
  expressionSource[
    `${item}`
  ] = require(`../assets/images/expression/face${index + 1}.gif`);
});
const expressionReg = new RegExp(
  expressionStrList
    .map((item) => {
      return `(\\[${item}\\])`;
    })
    .join("|"),
  "g"
);

export { expressionStrList, expressionSource, expressionReg };
