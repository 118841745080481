<template>
  <router-view />
</template>

<script>
export default {
  methods: {
    setLang (lang) {
      this.$i18n.locale = lang;
    },

  },
  mounted () {

  },
}
</script>