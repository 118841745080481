<template>
  <div class="container">
    <div class="title">{{ $t("member.browseuser") }}</div>
    <div class="linkbox">
      <router-link class="linkitem" to="/w/home/member/online">
        <i class="iconfont icon-tuding"></i>
        {{ $t("member.whole") }}
      </router-link>
      <router-link class="linkitem" to="/w/home/member/search">
        <i class="iconfont icon-dashujukeshihuaico-"></i>
        {{ $t("member.search") }}
      </router-link>
    </div>
    <div class="flexbox-s membercontent">
      <div v-for="(item, index) of data" :key="index" class="flexbox card">
        <div class="flexbox-s usermsg">
          <div class="flexbox userheader">
            <router-link tag="div" :to="'/w/home/user/detail/' + item.id">
              <img :src="imgurl + item.avatar" alt="" />
            </router-link>
          </div>
          <div class="userother">
            <router-link tag="div" :to="'/w/home/user/detail/' + item.id">
              <div class="username">
				<span class="online" v-if="item.is_online == 1"></span>
				{{ item.username }}
				<img class="vip" src="@/assets/images/icon-dimand.png" v-if="item.vip == 1"/>
				<img class="vip" src="@/assets/images/icon-king.png" v-if="item.vip == 2"/>
			  </div>
            </router-link>
            <div class="userstatus" v-if="item.is_online != 1">
              {{ $t("chat.active") }}：{{
                util.getTrendtimeByDate(new Date(item.last_login_time * 1000))
              }}
            </div>
			
			<div class="flexbox usermenu">
			  <div class="btn" @click="follow(item, index)">
				  <img src="@/assets/images/like.png" v-if="item.is_like == 1"/>
				  <img src="@/assets/images/unlike.png" v-if="item.is_like == 0"/>
			  </div>
			  <div @click="addUserToChatList(item)" class="btn">
				<img src="@/assets/images/msg.jpg"/>
			  </div>
		    </div>
          </div>
		  
		  
        </div>
        
      </div>
	  
    </div>
	<div class="pager" @click="moreuser()" v-show="loading" >{{ $t("tips.clickmore") }}</div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  data () {
    return {
		data: [],
		page:1,
		last_page:0,
		searchcontent:'',
		loading:true,
		vip:0,
    }
  },
  methods: {
    initData () {
      this.getUserList();
    },
    follow (item) {
      this.axios.post_form('/member/like/like', { target_id: item.id }).then(res => {
        res = res.data;
        if (res.status == 200) {
          if (item.is_like == 0) {
            item.is_like = 1;
            this.$message.success(this.$t('tips.followsuccess'));
          } else {
            item.is_like = 0;
            this.$message.success(this.$t('tips.followcancel'));
          }
        }
      })
    },
    addUserToChatList (data) {
	  if(data.id == 2){
		
	  }else if(this.vip == 0){
		this.$message.success(this.$t('tips.nopermission')+', '+this.$t('tips.needupgrade')+'..');
		setTimeout(() => {
			this.$router.push('/w/home/upgrade')
		},1500);
		return;
	  }
      this.$store.commit('addUserToGlobalChatListFromUser', data);
    },
    sendgift (user) {
      this.$store.commit('setGlobalGiftUser', user);
      this.$store.commit('showGlobalgift');
    },
	getUserList(){
		var page = this.page;
		this.axios.post_form("/member/index/userlist", {page:page, username: this.searchcontent}).then((res) => {
			res = res.data;
			if (res.status == 200) {
				if(page==1){
					this.data = res.data.data;
				}else{
					this.data = this.data.concat(res.data.data);
				}
				this.page = page + 1;
				if(page == res.data.last_page){
					this.loading = false;
				}
				this.vip = res.data.user.vip;
			}
		});
    },
	moreuser(){
	    this.vip = localStorage.getItem("currentVip");
		if(this.vip == 0){
			this.$message.success(this.$t('tips.needupgrade'));
			location.assign('/#/w/home/upgrade');
			return;
		}
	
		this.getUserList();
	},
  },
  created () {
    this.initData();
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 1000px;
  padding: 100px 30px;
  .title {
    font-size: 20px;
    color: $themeColor;
    margin-bottom: 28px;
    font-weight: bold;
  }
  .linkbox {
    .linkitem {
      padding: 10px 10px;
      background-color: #fff;
      color: $themeColor;
      background-color: transparent;
    }
    .router-link-active {
      background-color: rgb(180, 83, 77);
    }
  }
  .membercontent {
    padding: 10px;
    padding-right: 0;
    background-color: rgba(255, 255, 255, 0.2);
    margin-top: 30px;
    border-radius: 8px;
    min-height: 400px;
    .card {
      width: 49%;
      border-radius: 8px;
      background-color: #fff;
      margin-bottom: 10px;
      margin-right: 1%;
      overflow: hidden;
      .usermsg {
		flex-wrap: nowrap;
        height: 180px;
		padding:30px;
        .userheader {
          width: 120px;
          height: 120px;
		  margin-right:10px;
          img {
            width: 120px;
            height: 120px;
			border-radius: 10px;
          }
        }
        .userother {
          width: 100%;
          height: 120px;
          .username {
            font-size: 16px;
            color: #333;
            cursor: pointer;
            margin-left: 15px;
			position: relative;
			.vip {
				position: absolute;
				right: 15px;
				bottom: -2px;
				font-size: 0px;
				width:30px;
				height:30px;
			}
			.online {
			  display: inline-block;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-image: linear-gradient(to bottom, greenyellow, green);
              margin-right: 10px;
            }
          }
          .username:hover {
            text-decoration: underline;
          }
          .userstatus {
            font-size: 12px;
            margin-top: 5px;
            color: #9c6d97;
            margin-left: 10px;
          }
          .offline {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #999;
            margin-right: 10px;
          }
          .online {
            font-size: 12px;
            margin-top: 5px;
            margin-left: 10px;
            justify-content: flex-start;
            span {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-image: linear-gradient(to bottom, greenyellow, green);
              margin-right: 10px;
            }
            .btn {
              font-weight: bold;
              padding: 0 3px;
              border: 1px solid $themeColor;
              border-radius: 2px;
              cursor: pointer;
			  
            }
            .btn:hover {
              
            }
			
          }
		  
		  .usermenu {
			height: 40px;
			background-color: #f5f5f5;
			font-size: 12px;
			padding-left: 10px;
			justify-content: flex-start;
			margin-top:40px;
			.btn {
			  color: #fff;
			  padding: 2px 5px;
			  cursor: pointer;
			  margin-right: 5px;
			  margin-left:20%;
			}
		  }
        }

      }
      
    }
  }
  .pager{
	text-align: center;
    height: 40px;
    line-height: 40px;
    color: #333;
	cursor:pointer;
  }
}
</style>