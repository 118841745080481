<template>
  <div class="container">
    <div class="title">{{ username }}</div>
    <div class="flexbox-s usercontent">
      <div class="leftcontent">
        <div class="flexbox fullradiusbox userheader">
          <img :src="imgurl + avatar" />
          <i
            v-if="vip != 0"
            class="iconfont icon-V vip"
            :style="{ color: vip == 1 ? 'silver' : 'gold' }"
          ></i>
          <div class="editbtn" @click="show = true">
            {{ $t("user.edit.modifyavatar") }}
          </div>
          <div class="online"></div>
        </div>
        <div class="usersign">
          <el-input
            type="textarea"
            :rows="5"
            :placeholder="$t('user.edit.signatureplaceholder')"
            v-model="signature"
            @focus="signshow = true"
          >
          </el-input>
          <div class="flexbox btncontainer" v-show="signshow">
            <div class="flexbox commonbtn" @click="modifysignature">
              <div class="textbox">{{ $t("global.modify") }}</div>
              |
              <div class="iconbox">
                <i class="iconfont icon-jia"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rightcontent">
        <!-- 编辑资料 -->
        <div class="flexbox usereditcontainer">
          <router-link to="/w/home/user/edit" class="flexbox commonbtn">
            <div class="textbox">{{ $t("navigator.edit") }}</div>
            |
            <div class="iconbox">
              <i class="iconfont icon-jia"></i>
            </div>
          </router-link>
        </div>
        <!-- 用户信息 -->
        <div class="flexbox usermsgbox">
          <div class="flexbox item">
            <div class="flexbox left">{{ $t("regist.username") }}</div>
            <div class="flexbox right">{{ username }}</div>
          </div>
          <div class="flexbox item">
            <div class="flexbox left">{{ $t("regist.gender") }}</div>
            <div class="flexbox right">{{ gender }}</div>
          </div>
        </div>
        <div class="flexbox-s trendbox">
          <!-- 最新动态-标题 -->
          <div class="flexbox halfradiusbox">
            <div class="flexbox title">
              <i class="iconfont icon-dongtai2"></i>
              {{ $t("index.trend") }}
            </div>
          </div>
          <!-- 最新动态-input -->
          <div class="flexbox inputbox">
            <el-input
              type="textarea"
              row="3"
              :placeholder="$t('user.edit.trendplaceholder')"
              v-model="trendtext"
            ></el-input>
          </div>
          <!-- 最近动态-按钮 -->
          <div class="flexbox opratebox">
            <!-- 图片按钮 -->
            <div class="flexbox iconbox" @click="$refs.fileinput.click()">
              <i class="iconfont icon-tupian" style="font-size: 20px"></i>
            </div>
            <!-- 图片文件 -->
            <input
              type="file"
              accept="image/*"
              v-show="false"
              @change="fileChange"
              ref="fileinput"
            />
            <!-- 表情按钮 -->
            <div
              class="flexbox iconbox"
              @click.stop="showexpress = !showexpress"
            >
              <i
                class="iconfont icon-biaoqing"
                style="color: #eedd55; font-size: 20px"
              ></i>
            </div>
            <!-- 发送按钮 -->
            <div class="flexbox sendbtn" @click="sendTrend">
              {{ $t("global.send") }}
            </div>
          </div>
          <!-- 表情框 -->
          <div class="flexbox expressbox">
            <img
              class="img"
              v-for="(item, index) of expressionStrList"
              :key="index"
              :src="expressionSource[item]"
              @click.stop="pushexp(item)"
              v-show="showexpress"
            />
          </div>
          <!-- 图片预览 -->
          <div
            class="flexbox trendimgprev"
            :style="{ backgroundImage: `url(${imgurl + trendimg})` }"
            v-if="trendimg"
          >
            <i
              class="el-icon-close"
              style="cursor: pointer"
              @click="trendimg = ''"
            ></i>
          </div>
          <!-- 最新动态-动态列表 -->
          <div class="flexbox-s trendlist">
            <Trend
              v-for="(item, index) of trendList"
              :key="index"
              :data="item"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 修改头像插件 -->
  <my-upload
    field="img"
    @crop-success="cropSuccess"
    @crop-upload-success="cropUploadSuccess"
    @crop-upload-fail="cropUploadFail"
    v-model="show"
    :width="300"
    :height="300"
    url="/member/index/uploadImg"
    img-format="png"
    :noCircle="true"
  ></my-upload>
</template>

<script>
import { ElMessage } from 'element-plus'
import { expressionSource, expressionStrList } from "../../../../util/expression"
import myUpload from "../../../../components/web/AvatarCrop/AvatarCrop.vue";
import Trend from "../../../../components/web/Trend.vue";
import img from "../../../../assets/images/user/avatar_big_5.jpg"
export default {
  data () {
    return {
      signature: this.$store.state.User.signature,
      // 展示签名发送按钮
      signshow: false,
      // 头像
      img,
      // 头像组件显示
      show: false,
      // 动态表单内容
      trendtext: "",
      trendimg: "",
      // 表情数据
      expressionStrList,
      expressionSource,
      showexpress: false,
      // 图片文件
      sourceImgUrl: null,
      // 动态列表
      trendList: [],
    }
  },
  computed: {
    username () {
      return this.$store.state.User.username;
    },
    avatar () {
      return this.$store.state.User.avatar;
    },
    vip () {
      return this.$store.state.User.vip;
    },
    gender () {
      return this.$store.state.User.gender == 1 ? this.$t('regist.male') : this.$t('regist.female');
    }
  },
  components: {
    'my-upload': myUpload,
    Trend,
  },
  methods: {
    // 保存头像
    saveavatar (avatar) {
      this.axios.post_form("/member/index/profile", {
        avatar,
        email: this.$store.state.User.email,
        sex: this.$store.state.User.gender,
        birthday: this.$store.state.User.birthday,
      }).then(res => {
        res = res.data;
        if (res.status == 200) {
          ElMessage.success({
            message: this.$t('user.edit.modifiedsuccess'),
            type: 'success'
          });
          this.$store.dispatch("updateUserInfo");
          this.show = false;
        } else if (res.status == -1) {
          ElMessage.error(this.$t('user.edit.modifiedfail'));
        } else if (res.status == -2) {
          ElMessage.error(this.$t('user.edit.modifiedfail'));
        } else {
          ElMessage.error(this.$t('user.edit.modifiedfail'));
        }
      })
    },
    cropSuccess (imgDataUrl, field) {
      this.imgDataUrl = imgDataUrl;
    },
    cropUploadSuccess (res, field) {
      if (res.status == 200) {
        this.saveavatar("/" + res.data);
      }
    },
    cropUploadFail (status, field) {
    },
    // 保存个人签名
    modifysignature () {
      this.signshow = false;
      this.axios.post_form("/member/index/changesignature", {
        signature: this.signature,
      }).then(res => {
        res = res.data;
        if (res.status == 200) {
          this.showpwd = false;
          ElMessage.success({
            message: this.$t('user.edit.modifiedsuccess'),
            type: 'success'
          });
          this.$store.dispatch("updateUserInfo");
        } else if (res.status == 0) {
          ElMessage.error(this.$t('user.edit.modifiedfail'));
        } else {
          ElMessage.error(this.$t('user.edit.modifiedfail'));
        }
      })
    },
    // 插入表情
    pushexp (item) {
      this.trendtext += "[" + item + "]";
    },
    // 图片选择事件
    fileChange (e) {
      if (this.util.checkPermision('Upload_photo')) {
        e.preventDefault();
        if (this.loading !== 1) {
          let files = e.target.files || e.dataTransfer.files;
          this.sourceImgUrl = files[0];
          this.axios.post_form("/member/index/uploads", {
            file: this.sourceImgUrl
          }).then(res => {
            res = res.data;
            if (res.status == 200) {
              this.trendimg = res.data.url;
            }
          })
        }
      } else {
        ElMessage.error(i18n.global.t("tips.nopermission")+'%');
      }
    },
    setSourceImg (file) {
      let that = this,
        fr = new FileReader();
      fr.onload = function (e) {
        that.sourceImgUrl = fr.result;
      }
      fr.readAsDataURL(file);
    },
    choosefile () {
    },
    sendTrend () {
      if (this.util.checkPermision('Allows_newsfeed_to_be_sent')) {
        if (this.trendtext == "" && this.trendimg == "") {
          ElMessage.error(this.$t('tips.contentcantempty'));
          return false;
        }
        this.axios.post_form("/member/trend/released", {
          content: this.trendtext,
          img: this.trendimg,
        }).then(res => {
          res = res.data;
          if (res.status == 200) {
            ElMessage.success(this.$t('tips.sendsuccess'));
            this.trendList.unshift({
              avatar: this.$store.state.User.avatar,
              username: this.$store.state.User.username,
              content: this.trendtext,
              img: this.trendimg,
              type: 1,
              create_time: new Date().getTime(),
            })
            if (this.trendimg) {
              this.releasePhoto();
            }
            this.trendtext = "";
            this.trendimg = ""
          } else {
            ElMessage.error(this.$t('tips.sendfail'));
          }
        })
      } else {
        ElMessage.error(i18n.global.t("tips.nopermission")+'&&');
      }
    },
    getTrendList () {
      this.axios.post_form("/member/trend/my_trend", {}).then(res => {
        res = res.data;
        if (res.status == 200) {
          this.trendList = res.data;
        }
      })
    },
    releasePhoto () {
      this.axios.post_form("/member/photo/released", {
        img: this.trendimg,
      })
    },
    load () {
    }
  },
  created () {
    this.getTrendList();
    document.onclick = () => {
      this.showexpress = false;
    }
  },
  beforeUnmount () {
    document.onclick = null;
  },
}
</script>

<style lang="scss" scoped>
$boderColor: rgba(
  $color: #fff,
  $alpha: 0.2,
);
$border: 1px solid $boderColor;
.container {
  width: 1000px;
  padding: 100px 30px;
  .title {
    font-size: 20px;
    color: $themeColor;
    font-weight: bold;
  }
  .usercontent {
    justify-content: space-between;
    margin-top: 30px;
    font-size: 14px;
    .leftcontent {
      width: 215px;
      .userheader {
        position: relative;
        width: 215px;
        height: 215px;
        img {
          width: 175px;
          height: 175px;
        }
        .vip {
          position: absolute;
          right: 20px;
          bottom: 20px;
          font-size: 30px;
        }
        .editbtn {
          position: absolute;
          top: 10px;
          right: 10px;
          padding: 2px 8px;
          color: $themeColor;
          cursor: pointer;
          background-color: rgba($color: $fontColor, $alpha: 0.8);
          border-radius: 4px;
        }
        .online {
          position: absolute;
          bottom: 5px;
          left: 5px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: yellowgreen;
        }
      }
      .usersign {
        width: 215px;
        margin-top: 30px;
        background-color: #fff;
        padding: 5px;
        border-radius: 6px;

        .btncontainer {
          margin-top: 20px;
        }
      }
    }
    .rightcontent {
      width: 705px;
      .usereditcontainer {
        justify-content: flex-end;
      }
      .usermsgbox {
        color: #fff;
        .item {
          height: 30px;
          .left {
            width: 30%;
            justify-content: flex-end;
            padding: 0 5px;
            border-right: 1px solid rgba($color: #fff, $alpha: 0.5);
          }
          .right {
            width: 70%;
            justify-content: flex-start;
            padding: 0 5px;
          }
        }
      }
      .trendbox {
        margin-top: 30px;
      }
      .inputbox {
        margin-top: 15px;
      }
      .expressbox {
        width: 100%;
        height: auto;
        background-color: #fff;
        justify-content: flex-start;
        align-content: flex-start;
        overflow: hidden;
        img {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
      .opratebox {
        height: 30px;
        justify-content: flex-end;
        .iconbox {
          width: 30px;
          height: 30px;
          cursor: pointer;
          margin-right: 10px;
        }
        .sendbtn {
          width: auto;
          height: 30px;
          padding: 0 8px;
          background-color: $themeColor;
          border-radius: 3px;
          border: 1px solid #333;
          color: #fff;
          font-weight: bold;
          cursor: pointer;
        }
      }
      .trendimgprev {
        width: 300px;
        height: 300px;
        margin-top: 8px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: start start;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-end;
        color: #fff;
      }
      .trendlist {
        margin-top: 15px;
      }
    }
  }
}

.commonbtn {
  width: auto;
  height: 32px;
  justify-content: space-between;
  color: $themeColor;
  background-color: #e8cbed;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  .textbox {
    padding: 0 5px;
  }
  .iconbox {
    padding: 0 5px;
    font-size: 20px;
  }
}

.fullradiusbox {
  border-radius: 6px;
}
.halfradiusbox {
  border-radius: 6px 6px 0px 0px;
}
.fullradiusbox,
.halfradiusbox {
  background: rgba(255, 255, 255, 0.2)
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpi/P//WRoDGmAB4ifYBF9gE/w3Kkiu4P/Bp/0/DbRTIAgQYAC2PiJjuOjlPgAAAABJRU5ErkJggg==)
    repeat-x left top;
  border: none;
  border-bottom: none;
  .title {
    justify-content: flex-start;
    padding: 8px 10px;
    font-size: 16px;
    i {
      margin-right: 10px;
      color: $themeColor;
      font-size: 16px;
    }
  }
}
</style>