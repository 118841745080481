<template>
  <div class="chatwindow" :style="{ height: windowheight + 'px' }">
    <!-- 聊天窗口 -->
    <div class="flexbox-c chatcontent">
      <div class="empty" style="margin-top: 161px" v-if="!curItem.id"></div>
      <div class="flexbox chatcontentbody" v-else>
        <!-- 头部 -->
        <router-link
          class="flexbox chatheader"
          :to="'/m/home/my/newsfeed/' + curItem.uid"
        >
          <div class="userheader">
            <img :src="imgurl + curItem.avatar" />
          </div>
          <div class="flexbox username">
            <img class="vip" src="@/assets/images/icon-dimand.png" v-if="curItem.vip == 1"/>
			<img class="vip" src="@/assets/images/icon-king.png" v-if="curItem.vip == 2"/>
            {{ curItem.username }}
          </div>
          <div class="optionbox">
            <van-icon name="arrow" />
          </div>
        </router-link>
        <!-- 聊天内容 -->
        <el-scrollbar noresize @scroll="chatwindowscrollevent">
          <div class="flexbox history" @click="queryHistory">
            {{ $t("chat.queryhistory") }}
          </div>

          <div class="flexbox chatbody" v-if="curItem">
            <chat-content
              v-for="(item, index) of curItem.list"
              :key="index + ' ' + item.content"
              :data="item"
            />
          </div>
        </el-scrollbar>

        <!-- 输入框 -->
        <div class="flexbox-s chatinputbox" v-if="true">
          <div class="flexbox inputctainer">
            <el-input v-model="chatcontent" @keydown.enter="send"> </el-input>
            <div class="flexbox sendbtn" @click="send">
              <i class="iconfont icon-send"></i>
            </div>
          </div>

          <div class="flexbox opratebox">
            <!-- 礼物按钮 -->
            <div class="flexbox iconbox" @click="gift.showgift = true">
              <i class="iconfont icon-liwu" style="font-size: 20px"></i>
            </div>
            <!-- 翻译按钮 -->
            <div class="flexbox iconbox" @click="trans.showtrans = true">
              <i class="iconfont icon-zhuanhuan" style="font-size: 20px"></i>
            </div>
            <!-- 图片按钮 -->
            <div class="flexbox iconbox" @click="$refs.fileinput.click()">
              <i class="iconfont icon-tupian" style="font-size: 20px"></i>
            </div>
            <!-- 图片文件 -->
            <input
              type="file"
              accept="image/*"
              v-show="false"
              @change="fileChange"
              ref="fileinput"
            />
            <!-- 表情按钮 -->
            <div class="flexbox iconbox" @click.stop="showexpress = true">
              <i
                class="iconfont icon-biaoqing"
                style="color: #eedd55; font-size: 20px"
              ></i>
            </div>
          </div>
          <!-- 表情框 -->
          <div class="flexbox expressbox">
            <img
              class="img"
              v-for="(item, index) of expressionStrList"
              :key="index"
              :src="expressionSource[item]"
              @click.stop="pushexp(item)"
              v-show="showexpress"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-show="false">{{ curItemlist }}</div>
  <!-- 礼物面板 -->
  <van-action-sheet v-model:show="gift.showgift">
    <div class="giftcontainer">
      <van-tabs v-model:active="gift.active" swipeable animated>
        <van-tab
          v-for="(type, index) of giftlist"
          :key="index"
          :title="$t(`gift.type${type.id}`)"
        >
          <!-- 礼物列表 -->
          <div class="flexbox giftitembox" v-if="giftlist.length > 0">
            <div
              class="flexbox giftitem"
              v-for="(item, index) of type.gift"
              :key="index"
            >
              <div
                class="img"
                :style="{ backgroundImage: `url(${imgurl + item.path})` }"
              ></div>
              <div class="flexbox giftname">
                {{ $t(`gift.type${type.id}gift${item.id}`) }}
              </div>
              <div class="flexbox giftprice">
                {{ item.money }} {{ $t("store.credits") }}
              </div>
              <div class="flexbox sendbtn" @click="sendlaunch(item.id)">
                {{ $t("store.sendgift") }}
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </van-action-sheet>
  <!-- 赠送礼物面板 -->
  <van-overlay :show="gift.showsend" @click="gift.showsend = false">
    <div class="flexbox" style="height: 100%">
      <div class="flexbox sendbox" @click.stop>
        <div class="flexbox">
          <van-stepper v-model="value" input-width="40px" button-size="32px" />
        </div>
        <div class="flexbox sendbtn" @click="sendgift">
          {{ $t("store.sendgift") }}
        </div>
      </div>
    </div>
  </van-overlay>

  <!-- 翻译面板 -->
  <van-action-sheet v-model:show="trans.showtrans">
    <div class="transcontainer">
      <Trans :id="curItem.uid" />
    </div>
  </van-action-sheet>
</template>

<script>
import ChatContent from "../../../../components/ChatContent.vue"
import Expression from "../../../../components/Expression.vue"
import Trans from "../../../../components/Trans.vue"
import { expressionSource, expressionStrList } from "../../../../util/expression"
export default {
  data () {
    return {
      // 输入内容
      chatcontent: '',
      // 表情数据
      expressionStrList,
      expressionSource,
      showexpress: false,

      lastScrollPos: 0,
      scrollAffix: false,

      windowheight: 0,

      gift: {
        showgift: false,
        active: '',
        showsend: false,
        sendnum: 1,
        sendgid: 0,
      },

      trans: {
        showtrans: false,
      }

    }
  },
  computed: {
    chatContentList () {
      return this.$store.state.Chat.chatList;
    },
    curItem () {
      return this.$store.state.Chat.service;
    },
    curItemlist () {
      if (!this.scrollAffix) {
        setTimeout(() => {
          let view_container = document.querySelector('.el-scrollbar__wrap');
          if (view_container) {
            view_container.scrollTo(0, view_container.scrollHeight - view_container.clientHeight);
          }
        })
      }
      return this.$store.state.Chat.service.list ? this.$store.state.Chat.service.list.length : 0;
    },
    // 礼物列表
    giftlist () {
      return this.$store.state.Store.storeList;
    },

  },
  components: {
    ChatContent,
    Expression,
    Trans,
  },
  methods: {
    // 发送图片
    fileChange (e) {
      e.preventDefault();
      if (this.loading !== 1) {
        let files = e.target.files || e.dataTransfer.files;
        this.sourceImgUrl = files[0];
        this.axios.post_form("/member/index/uploads", {
          file: this.sourceImgUrl
        }).then(res => {
          res = res.data;
          if (res.status == 200) {
            this.$store.dispatch('wssend', {
              opt: 1,
              uid: this.$store.state.User.id,
              address: this.curItem.uid,
              img: res.data.url,
              type: 'img',
              origin: true,
            })
          }
        })
      }
    },
    // 插入表情
    pushexp (item) {
      this.chatcontent += "[" + item + "]";
    },
    // 发送聊天信息
    send () {
      if (this.chatcontent.trim() == "") {
        this.$message.error(this.$t('tips.contentcantempty'));
        return false;
      }
      this.$store.dispatch('wssend', {
        opt: 1,
        uid: this.$store.state.User.id,
        address: this.curItem.uid,
        txt: this.chatcontent,
        type: 'text',
        origin: true,
      })
      this.chatcontent = "";
    },
    queryHistory () {
      this.$store.dispatch('wssend', {
        opt: 3,
        uid: this.$store.state.User.id,
        address: this.curItem.uid,
        origin: true,
        create_time: this.curItem.list.length > 0 ? this.curItem.list[0].create_time : Math.floor(new Date().getTime / 1000)
      })
    },
    chatwindowscrollevent () {
      let view_container = document.querySelector('.el-scrollbar__wrap');
      if (view_container.scrollHeight - 20 < view_container.scrollTop + view_container.clientHeight) {
        this.scrollAffix = false;
      } else {
        this.scrollAffix = true;
      }
    },
    sendlaunch (id) {
      this.gift.sendgid = id;
      this.gift.showsend = true;
      this.gift.showgift = false;
    },
    sendgift () {
      this.gift.showsend = false;
      this.axios.post_form("/member/gift/gift_giving", {
        username: this.curItem.username,
        message: '',
        number: this.gift.sendnum,
        giftid: this.gift.sendid,
      }).then(res => {
        this.sendwindowvisibility = false;
        res = res.data;
        if (res.status == 200) {
          this.$message.success({
            message: this.$t('tips.sendsuccess'),
            type: 'success'
          });
        } else if (res.status == -1) {
          this.$message.error(this.$t('tips.usernotexist'));
        } else if (res.status == -2) {
          this.$message.error(res.msg);
        } else {
          this.$message.error(this.$t('tips.sendfail'));
        }
      })
    }
  },
  created () {
    document.onclick = () => {
      this.showexpress = false;
    }
    this.windowheight = document.documentElement.clientHeight;
  },
  beforeUnmount () {
    document.onclick = null;
  },
}
</script>

<style lang="scss" scoped>
$boderColor: rgba(
  $color: #fff,
  $alpha: 0.3,
);
$border: 1px solid $boderColor;
#app {
  height: 100%;
}
.sendbox {
  width: 80%;
  height: auto;
  padding: 30px;
  border-radius: 8px;
  background-color: #fff;
  .sendbtn {
    width: auto;
    padding: 0 5px;
    height: 20px;
    border: 1px solid #ff4a53;
    color: #ff4a53;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 30px;
  }
}
.giftcontainer {
  width: 100%;
  height: 500px;
  .giftitembox {
    justify-content: flex-start;
    color: $fontColor;
    padding: 30px;

    .giftitem {
      width: 20%;
      height: auto;
      align-content: space-between;
      font-size: 12px;
      margin: 2.5%;
      .img {
        width: 100%;
        padding: 50%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
      .giftname {
        height: 20px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .giftprice {
        height: 20px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .sendbtn {
        padding: 0 5px;
        height: 20px;
        border: 1px solid #ff4a53;
        color: #ff4a53;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }
}
.chatwindow {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #fff, $alpha: 0.2);
  border: $border;
  border-radius: 4px;
  overflow: hidden;
  flex-wrap: nowrap;
  .chatcontent {
    width: 100%;
    height: 100%;
    .chatcontentbody {
      width: 100%;
      height: 100%;
      flex-direction: column;
      flex-wrap: nowrap;
      background-color: #f1f2f3;
      .chatheader {
        height: 64px;
        border-bottom: $border;
        padding: 0 15px;
        flex-wrap: nowrap;
        flex-shrink: 0;
        background-color: #fff;
        .userheader {
          position: relative;
          width: 50px;
          height: 50px;
          position: relative;
          flex-shrink: 0;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 50px;
            height: 50px;
          }
          .vip {
            position: absolute;
            right: 0px;
            bottom: 0px;
            color: gold;
            font-size: 16px;
          }
        }
        .username {
          font-size: 16px;
          font-weight: bold;
          color: $linkColor;
          justify-content: flex-start;
          padding-left: 10px;
          padding-bottom: 15px;
		  .vip{
			width:22px;
		  }
        }
      }
      .history {
        font-size: 14px;
        color: $linkColor;
        cursor: pointer;
      }
      .chatbody {
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: #f1f2f3;
      }
      .chatinputbox {
        height: auto;
        flex-shrink: 0;
        border-top: $border;
        .inputctainer {
          flex-wrap: nowrap;
          .sendbtn {
            width: 50px;
            height: 40px;
            border-radius: 4px;
            border: #e1e1e1 1px solid;
          }
        }

        .opratebox {
          justify-content: flex-start;
          padding: 5px;
          .iconbox {
            width: 30px;
            height: 30px;
            cursor: pointer;
            margin-right: 10px;
          }
        }
      }
      .notice {
        height: 40px;
        font-size: 14px;
        color: $themeColor;
        border-radius: 8px;
        background-color: rgba(255, 226, 171, 0.6);
        flex-shrink: 0;
        flex-wrap: nowrap;
        padding: 15px;
        margin-bottom: 20px;
        .noticedetail {
          .link {
            color: $linkColor;
            cursor: pointer;
          }
          .link:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .empty {
    color: #fff;
    font-size: 14px;
    margin-top: 100px;
  }
  .expressbox {
    width: 100%;
    height: auto;
    background-color: #fff;
    justify-content: flex-start;
    align-content: flex-start;
    overflow: hidden;
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}
.el-scrollbar {
  width: 100%;
}
</style>