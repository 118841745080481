import { ElMessage } from "element-plus";
import { ElNotification } from "element-plus";
import { Notify } from "vant";
import { h } from "vue";
import chat from "./chatfunction";
import axios from "../util/axios";
import util from "../util/util";
import i18n from "../i18n/index";

export default {
  state() {
    return {
      chatList: [],
      curUser: {},
      count: 0,
      service: {},

      globalChatList: [],
      globalWindowCount: 2,
    };
  },
  mutations: {
    // 设置当前聊天用户id
    setCur(state, id) {
      let userindex = chat.checkHasUser.call(state, id);
      if (userindex != -1) {
        state.curUser = state.chatList[userindex];
      } else {
        state.curUser = {};
      }
    },

    clearcount(state, id) {
      let userindex = chat.checkHasUser.call(state, id);
      if (userindex != -1) {
        state.count -= state.chatList[userindex].count;
        state.chatList[userindex].count = 0;
      }
    },

    // 添加新用户到聊天列表
    addUserToChatList(state, user) {
      user.uid = user.id;
      let userindex = chat.checkHasUser.call(state, user.id);
      if (userindex == -1) {
        state.chatList.unshift({
          ...user,
          ...{
            content: "",
            type: "text",
            img: "",
            list: [],
            count: 0,
            create_time: Math.floor(new Date().getTime() / 1000),
          },
        });
      }
    },

    // 向聊天列表插入消息
    // data: 聊天消息数据
    // index: 插入用户的索引值
    // history: 是否是历史消息
    addmsg(state, { data, index, history, globalindex }) {
      // 如果是查看历史消息，将其从顶部推入，否则从底部推入
      if (!history) {
        // 聊天列表插入消息
        state.chatList[index].list.push(data);
        // 客服插入消息
        if (chat.checkId(data) == state.service.uid) {
          state.service.list.push(data);
        }
        // 全局聊天窗口插入消息
        if (globalindex != -1) {
          state.globalChatList[globalindex].list.push(data);
        }
      } else {
        // 聊天列表插入历史消息
        state.chatList[index].list.unshift(data);
        // 客服插入历史消息
        if (chat.checkId(data) == state.service.uid) {
          state.service.list.unshift(data);
        }
        // 全局聊天列表插入历史消息
        if (globalindex != -1) {
          state.globalChatList[globalindex].list.unshift(data);
        }
      }
      // 收到消息提示，如果全局列表存在则不提示
      if (!chat.checkSenderIsSelf(data) && globalindex == -1) {
        if (!history) {
          chat.playAudioReceive();
          if (util.judgeTerminal()) {
            Notify({
              type: "success",
              message: state.chatList[index].username + ":" + data.content,
            });
          } else {
            ElNotification({
              title: state.chatList[index].username,
              message: h("i", { style: "color: teal" }, data.content),
            });
          }
        }
        // 如果不是当前聊天用户，未读消息+1
        if (
          state.chatList[index].uid != state.curUser.uid &&
          globalindex == -1
        ) {
          state.chatList[index].count++;
          state.count++;
        }
      }
      // 非历史消息，修改列表内容，推到顶部
      if (!history) {
        let newObj = Object.assign(state.chatList[index], data);
        state.chatList.splice(index, 1);
        state.chatList.unshift(newObj);
        if ((state.curUser.mid = newObj.mid)) {
          state.curUser = state.chatList[0];
        }
      }
    },

    deleteUser(state, id) {
      let userindex = chat.checkHasUser.call(state, id);
      state.count -= state.chatList[userindex].count;
      state.chatList.splice(userindex, 1);
    },

    setService(state, data) {
      data.uid = data.id;
      state.service = {
        ...data,
        ...{
          content: "",
          type: "text",
          img: "",
          list: [],
          count: 0,
          create_time: Math.floor(new Date().getTime() / 1000),
        },
      };
    },

    // 聊天窗口 - 添加用户
    addUserToGlobalChatListFromUser(state, user) {
      user.uid = user.id;
      let userindex = chat.checkGlobalChatListHasUser.call(state, user.id);
      if (userindex == -1) {
        if (state.globalChatList.length > state.globalWindowCount) {
          state.globalChatList.splice(state.globalWindowCount - 1, 0, {
            ...user,
            ...{
              show: true,
              content: "",
              type: "text",
              img: "",
              list: [],
              count: 0,
              create_time: Math.floor(new Date().getTime() / 1000),
            },
          });
        } else {
          state.globalChatList.push({
            ...user,
            ...{
              show: true,
              content: "",
              type: "text",
              img: "",
              list: [],
              count: 0,
              create_time: Math.floor(new Date().getTime() / 1000),
            },
          });
        }
      } else {
        let finalcount = state.globalWindowCount - 1;
        state.globalChatList[userindex].show = true;
        if (userindex > finalcount) {
          const user = state.globalChatList[userindex];
          state.globalChatList.splice(userindex, 1);
          state.globalChatList.splice(finalcount, 0, user);
          state.globalChatList[userindex];
        }
      }
    },
    addUserToGlobalChatListFromChatList(state, user) {
      let userindex = chat.checkGlobalChatListHasUser.call(state, user.uid);
      if (userindex == -1) {
        const newuser = Object.assign(
          {
            uid: user.uid,
            username: user.username,
            avatar: user.avatar,
            vip: user.vip,
            username: user.username,
            list: [...user.list],
          },
          {
            show: true,
            content: "",
            type: "text",
            img: "",
            count: 0,
            create_time: Math.floor(new Date().getTime() / 1000),
          }
        );
        if (state.globalChatList.length > state.globalWindowCount) {
          state.globalChatList.splice(state.globalWindowCount - 1, 0, newuser);
        } else {
          state.globalChatList.push(newuser);
        }
      } else {
        let finalcount = state.globalWindowCount - 1;
        state.globalChatList[userindex].show = true;
        if (userindex > finalcount) {
          const user = state.globalChatList[userindex];
          state.globalChatList.splice(userindex, 1);
          state.globalChatList.splice(finalcount, 0, user);
          state.globalChatList[userindex];
        }
      }
    },
    // 切换可视聊天窗口
    exchangePositionToEnabalVisible(state, id) {
      let userindex = chat.checkGlobalChatListHasUser.call(state, id);
      let finalcount = state.globalWindowCount - 1;
      state.globalChatList[userindex].show = true;
      if (userindex > finalcount) {
        const user = state.globalChatList[userindex];
        state.globalChatList.splice(userindex, 1);
        state.globalChatList.splice(finalcount, 0, user);
        state.globalChatList[userindex];
      }
    },
    // 显示/隐藏聊天窗口
    toogleUserFromChatWindowList(state, id) {
      let globalindex = chat.checkGlobalChatListHasUser.call(state, id);
      if (globalindex != -1) {
        state.globalChatList[globalindex].show = !state.globalChatList[
          globalindex
        ].show;
      }
    },

    // 聊天窗口 - 删除用户
    deleteUserFromChatWindowList(state, id) {
      let globalindex = chat.checkGlobalChatListHasUser.call(state, id);
      if (globalindex != -1) {
        state.globalChatList.splice(globalindex, 1);
      }
    },

    // 聊天窗口数量
    setGlobalWindowCount(state, count) {
      state.globalWindowCount = count;
    },
  },
  actions: {
    // 收到消息
    chatEvent({ dispatch, commit, state }, data) {
      switch (data.opt) {
        // 初始化聊天列表
        case 0:
          data.msg.reverse().forEach((msg) => {
            data.contact.forEach((user) => {
              if (msg.sender_id == user.uid) {
                user.list.push(msg);
                user.count++;
              }
            });
          });
          state.chatList = data.contact;
          state.count = data.msg.length;
          break;
        // 收到新消息
        case 1:
          // 聊天列表是否拥有该用户
          let id = chat.checkId(data.msg);
          let userindex = chat.checkHasUser.call(state, id);
          let globalindex = chat.checkGlobalChatListHasUser.call(state, id);
          if (userindex != -1) {
            commit("addmsg", {
              data: data.msg,
              index: userindex,
              globalindex: globalindex,
            });
          } else {
            // 是陌生人的消息
            axios
              .post_form("/member/index/userdetail", {
                id: id,
              })
              .then((res) => {
                res = res.data;
                if (res.status == 200) {
                  let user = res.data;
                  userindex = chat.checkHasUser.call(state, id);
                  if (userindex != -1) {
                    commit("addmsg", {
                      data: data.msg,
                      index: userindex,
                      globalindex: globalindex,
                    });
                  } else {
                    commit("addUserToChatList", user);
                    commit("addmsg", {
                      data: data.msg,
                      index: 0,
                      globalindex: globalindex,
                    });
                  }
                }
              });
          }
          break;
        // 翻译
        case 2:
          break;
        // 历史消息
        case 3:
          data.data.forEach((item) => {
            let id = chat.checkId(item);
            let index = chat.checkHasUser.call(state, id);
            let globalindex = chat.checkGlobalChatListHasUser.call(state, id);
            if (userindex != -1) {
              commit("addmsg", {
                data: item,
                index: index,
                history: true,
                globalindex: globalindex,
              });
            }
          });
          break;
        // 用户上线
        case 4:
          break;
        case 5:
          break;
        // 删除联系人结果
        case 6:
          commit("deleteUser", data.data);
          break;
        // 金币不足
        case 7:
          // ElMessage.error(i18n.global.t("tips.insufficientcredits"));
          ElMessage({
            iconClass: "iconfont icon-warning warningcolor",
            showClose: true,
            type: "error",
            message: h("p", null, [
              h("span", null, i18n.global.t("tips.nofreechat")),
              h(
                "a",
                {
                  style: "color: #f66; text-decoration: underline;",
                  href: util.judgeTerminal()
                    ? "/#/m/home/upgrade/index"
                    : "/#/w/home/upgrade",
                },
                i18n.global.t("tips.upmember")
              ),
              h("span", null, i18n.global.t("tips.continue")),
            ]),
          });
          break;
        // 需要升级
        case 8:
          ElMessage.error(i18n.global.t("tips.needupgrade"));
          break;
      }
    },
  },
};
