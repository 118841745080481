export function getDateOptions(year, month) {
  if (month == "") {
    return [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31,
    ];
  } else if (year == "") {
    month = parseInt(month);
    return (() => {
      switch (month) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          return [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
          ];
        case 2:
          return [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
          ];
        case 4:
        case 6:
        case 8:
        case 11:
          return [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
          ];
      }
    })();
  } else {
    year = parseInt(year);
    month = parseInt(month);
    return (() => {
      switch (month) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          return [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
          ];
        case 2:
          if (year % 4 == 0 && (year % 100 != 0 || year % 400 == 0)) {
            return [
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
              21,
              22,
              23,
              24,
              25,
              26,
              27,
              28,
              29,
            ];
          } else {
            return [
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
              21,
              22,
              23,
              24,
              25,
              26,
              27,
              28,
            ];
          }
        case 4:
        case 6:
        case 8:
        case 11:
          return [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
          ];
      }
    })();
  }
}

export function checkmonthdate(year, month) {
  if (month == "") {
    return 31;
  } else if (year == "") {
    return (() => {
      switch (month) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          return 31;
        case 2:
          return 23;
        case 4:
        case 6:
        case 8:
        case 11:
          return 31;
      }
    })();
  } else {
    year = parseInt(year);
    month = parseInt(month);
    return (() => {
      switch (month) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          return 31;
        case 2:
          if (year % 4 == 0 && (year % 100 != 0 || year % 400 == 0)) {
            return 29;
          } else {
            return 28;
          }
        case 4:
        case 6:
        case 8:
        case 11:
          return 31;
      }
    })();
  }
}

function getYearOptions() {
  let year = new Date().getFullYear();
  let years = [];
  for (let i = year - 50; i < year - 18; i++) {
    years.push({
      value: i,
      label: i,
    });
  }
  return years;
}

export const yearOption = getYearOptions();
export const monthOption = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
