<template>
  <div class="infocontainer">
    <div class="title1">{{ $t("websit.refund.title") }}</div>

    <div class="title2">{{ $t("websit.refund.item_1_title") }}</div>
    <div class="content">{{ $t("websit.refund.item_1_content") }}</div>

    <div class="title2">{{ $t("websit.refund.item_2_title") }}</div>
    <div class="content">{{ $t("websit.refund.item_2_content") }}</div>

    <div class="title2">{{ $t("websit.refund.item_3_title") }}</div>
    <div class="content">{{ $t("websit.refund.item_3_content1") }}</div>
    <div class="content">
      {{ $t("websit.refund.item_3_content2", { email: email }) }}
    </div>
  </div>
</template>

<script>
import { websitname, email, account } from "../../../common/project.config";
export default {
  data () {
    return {
      websitname, email, account
    }
  }
}
</script>

<style lang="scss" scoped>
.infocontainer {
  width: 1000px;
  padding: 70px 0;
  margin: 5px 0;
}
.title1,
.title2,
.title3 {
  color: $themeColor;
  font-weight: bold;
}
.content {
  color: #fff;
  font-size: 14px;
}
.title1 {
  margin-bottom: 28px;
  font-size: 20px;
}
.title2 {
  font-size: 18px;
  line-height: 30px;
}
.title3 {
  font-size: 14px;
  line-height: 26px;
}
</style>