<template>
  <div class="inputitem">
    <input
      type="text"
      class="username"
      v-model="username"
      :placeholder="$t('message.address')"
    />
  </div>
  <div class="inputitem">
    <textarea
      v-model="content"
      rows="8"
      class="content"
      :placeholder="$t('message.msg')"
    ></textarea>
  </div>
  <div class="flexbox send" @click="send">{{ $t("global.send") }}</div>
</template>

<script>
export default {
  data () {
    return {
      username: '',
      content: '',
    }
  },
  methods: {
    send () {
      if (this.util.checkPermision('Start_mail_dialog')) {
        this.axios.post_form("/member/email/released", {
          email: this.username,
          content: this.content,
        }).then(res => {
          res = res.data;
          if (res.status == 200) {
            this.$message.success({
              message: this.$t('tips.sendsuccess'),
              type: 'success'
            });
          } else if (res.status == -1) {
            this.$message.error(this.$t('tips.usernotexist'));
          } else if (res.status == -2) {
            this.$message.error(this.$t('tips.usernotexist'));
          } else {
            this.$message.error(this.$t('tips.sendfail'));
          }
        })
      } else {
        this.$message.error(this.$t("tips.nopermission")+'.');
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.inputitem {
  margin: 10px 0;
  padding: 10px;
}
.username,
.content {
  border: 0;
  outline: 0;
  resize: none;
  width: 100%;
  background: #f1f2f3;
}
.username {
  height: 40px;
  font-size: 16px;
  border-radius: 20px;
  padding: 0 20px;
}
.content {
  border-radius: 10px;
  padding: 10px;
}
.send {
  width: 70%;
  position: fixed;
  bottom: 20px;
  left: 15%;
  height: 40px;
  background-color: #f43;
  color: #fff;
  border-radius: 20px;
}
</style>