<template>
    <div class="content_area">
        
        <div class="tops_area">{{$i18n.t('forgot.subtitle')}}</div>

        <div class="emails_area">
            <input type="text" class="emails_input"  v-model="email" :placeholder="$i18n.t('forgot.uremail') " >
        </div>

        <div class="btn_area">
            <van-button round block type="danger" style="background:#FF4A53!important;" @click="send" >{{$i18n.t('forgot.sunmit')}}</van-button>
        </div>

        <div class="joinin">
            <router-link class="register" to="/m/Register">{{$i18n.t('login.aboutus')}}~~</router-link>
        </div>

    </div>
</template>
<script>
import { ElMessage } from 'element-plus'
export default {
    data () {
        return {
            email: "",
        }
    },
    methods:{
        send () {
            if (!/^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(this.email)) {
                ElMessage.error(this.$t('regist.email') + ' ' + this.$t('regist.form.fomate'));
                return false;
            }
            this.axios.post_form('/member/index/forget', {
                email: this.email
            }).then(res => {
                res = res.data;
                if (res.status == -1) {
                    ElMessage.error(this.$t('regist.email') + ' ' + this.$t('regist.form.fomate'));
                } else {
                    ElMessage.success(this.$t('tips.newpwd'));
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.content_area{
    width: 100%;
    height: 100vh;
    background: url(~@/assets/images/phone/sign_back.png) no-repeat;
    background-size: 100% 100%;
    padding: 20px 0px;
}
.tops_area{
    margin-top: 30px;
    color: #fff;
    font-size: 18px;
    text-align: center;
}
.emails_area{
    background: #fff;
    height: 60px;
    width: 100%;
    margin: 10px 0px;
}
.emails_input{
    background: border-box !important;
    border:none;
    width: 100%;
    margin: 0px 20px;
    line-height: 60px;
    font-size: 18px;
}

.btn_area{
    margin: 0px 16px;
    margin-top: 50px;
}
.joinin{
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    color: #FF4A53!important;
    font-size: 20px;
}
.register{
    color: #FF4A53!important;
}
</style>
