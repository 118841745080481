<template>
  <div class="container">
    <div class="title">{{ $t("message.message") }}</div>
    <div class="flexbox searchbtncontainer">
      <div class="flexbox commonbtn">
        <div class="textbox" @click="sendwindowvisibility = true">
          {{ $t("message.newmsg") }}
        </div>
        |
        <div class="iconbox">
          <i class="iconfont icon-jia"></i>
        </div>
      </div>
    </div>
    <div class="flexbox-c msgwindow">
      <div class="flexbox searchbox">
        <el-input placeholder="search" v-model="searchcontent"></el-input>
      </div>
      <div class="flexbox messagebox">
        <div
          class="flexbox item"
          v-for="(item, index) in email"
          :key="index"
          @click="showDetail(item)"
        >
          <i class="iconfont icon-youjian" v-if="item.type == 0"></i>
          <i class="iconfont icon-xinfengdakai" v-else></i>
          <div class="flexbox-s sendername">
            {{ item.sendername }}
            <div class="tip" v-if="item.type == 0"></div>
          </div>
          <div class="msgtitle">{{ item.msg }}</div>
          <div class="datetime">{{ item.create_time }}</div>
        </div>
      </div>
    </div>
  </div>

  <!-- 发送消息 -->
  <el-dialog :title="$t('message.send')" v-model="sendwindowvisibility">
    <el-form :model="form">
      <div class="sendinput">
        <el-input v-model="form.address" :placeholder="$t('message.address')">
        </el-input>
      </div>
      <div class="sendtextarea">
        <el-input
          type="textarea"
          :placeholder="$t('message.msg')"
          v-model="form.message"
          maxlength="50"
          show-word-limit
        >
        </el-input>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="sendwindowvisibility = false">
          {{ $t("global.cancel") }}
        </el-button>
        <el-button type="primary" @click="send">
          {{ $t("global.send") }}
        </el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 查看消息 -->
  <el-dialog :title="detail.sendername" v-model="detailShow">
    <div class="detailcontent">
      {{ detail.msg }}
    </div>
  </el-dialog>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  data () {
    return {
      sendwindowvisibility: false,
      form: {
        address: '',
        message: '',
      },
      detailShow: false,
      detail: {},

      searchcontent: "",
    }
  },
  methods: {
    send () {
      if (this.util.checkPermision('Start_mail_dialog')) {
        this.axios.post_form("/member/email/released", {
          email: this.form.address,
          content: this.form.message,
        }).then(res => {
          this.sendwindowvisibility = false;
          res = res.data;
          if (res.status == 200) {
            ElMessage.success({
              message: this.$t('tips.sendsuccess'),
              type: 'success'
            });
          } else if (res.status == -1) {
            ElMessage.error(this.$t('tips.usernotexist'));
          } else if (res.status == -2) {
            ElMessage.error(this.$t('tips.usernotexist'));
          } else {
            ElMessage.error(this.$t('tips.sendfail'));
          }
        })
      } else {
        this.$message.error(this.$t("tips.nopermission")+'##');
      }
    },
    showDetail (data) {
      if (this.util.checkPermision('Read_mails')) {
        this.detail = data;
        this.detailShow = true;
        if (data.type == 0) {
          this.axios.post_form('/member/email/updatestatus', {
            id: data.eid,
          }).then(res => {
            res = res.data;
            if (res.status == 200) {
              data.type = 1;
              this.$store.commit("setUnready");
            }
          })
        }
      } else {
        this.$message.error(this.$t("tips.nopermission")+'@');
      }
    },
  },
  computed: {
    email () {
      let reg = new RegExp(`(${this.searchcontent})`, 'g');
      return this.$store.state.Message.message.filter((item) => {
        return reg.test(item.sendername);
      });
    },
  },
  created () {
    this.$store.dispatch('getMessage');
  }
}
</script>

<style lang="scss" scoped>
$boderColor: rgba(
  $color: #fff,
  $alpha: 0.2,
);
$border: 1px solid $boderColor;
.container {
  width: 1000px;
  padding: 100px 30px;
  .title {
    font-size: 20px;
    color: $themeColor;
    font-weight: bold;
  }
}
.msgwindow {
  width: 940px;
  background-color: rgba($color: #fff, $alpha: 0.2);
  border: $border;
  border-radius: 4px;
  overflow: hidden;
  padding-bottom: 60px;
  .searchbox {
    padding: 10px 15px;
    border-bottom: $border;
  }
  .messagebox {
    padding: 0 15px;
    color: $themeColor;
    font-size: 14px;
    .item {
      height: 60px;
      border-bottom: $border;
      justify-content: flex-start;
      flex-wrap: nowrap;
      cursor: pointer;
      i {
        font-size: 20px;
        flex-shrink: 0;
      }
      .sendername {
        width: 20%;
        padding-left: 10px;
        overflow: hidden;
        white-space: nowrap;
        flex-shrink: 0;
        .tip {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #c00;
          margin-left: 5px;
        }
      }
      .msgtitle {
        width: 100%;
        padding-left: 10px;
        overflow: hidden;
        white-space: nowrap;
      }
      .datetime {
        padding-left: 10px;
        overflow: hidden;
        white-space: nowrap;
        flex-shrink: 0;
      }
    }
  }
}
.searchbtncontainer {
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: 10px;
}
.commonbtn {
  width: auto;
  height: 32px;
  justify-content: space-between;
  color: $themeColor;
  background-color: #e8cbed;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  .textbox {
    padding: 0 5px;
  }
  .iconbox {
    padding: 0 5px;
    font-size: 20px;
  }
}
.sendtextarea {
  margin-top: 20px;
}
.detailcontent {
  margin-bottom: 10px;
}
</style>