<template>
  <div class="invest_area">
    <div class="invest_area_top">
      <div class="invest_area_top_icon">
        <div @click="back">取消</div>
      </div>
      <span>{{ $i18n.t("user.edit.title") }}</span>
      <div class="invest_area_top_save" @click="save">
        <el-button
          type="primary"
          style="background: #ff4a53; border: 1px solid #ff4a53"
        >
          {{ $i18n.t("user.edit.save") }}</el-button
        >
      </div>
    </div>
  </div>
  <div class="menuList">
    <div class="el-form-item">
      <div class="el-form-item__content">
        <span class="el-form-item-span">{{ $t("regist.birth") }}</span>
        <!-- <div class="el-input__inner" style="flex-shrink: 1" @click="dialog=true"></div> -->
        <!-- <el-input v-model="birthday" :placeholder="$t('regist.birth')" @focus="dialog=true" style="    flex-shrink: 1"></el-input> -->
        <el-date-picker
          style="width: 100%"
          v-model="birthday"
          type="date"
          :placeholder="$t('regist.birth')"
        >
        </el-date-picker>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  data () {
    return {
      birthday: '',
    }
  },
  computed: {

  },
  created () {
    this.initData();
  },
  methods: {
    back () {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/' })
        return false
      } else {
        this.$router.back()
      }
    },
    initData () {
      this.birthday = new Date(parseInt(this.$store.state.User.birthday));
    },
    save () {
      this.axios.post_form("/member/index/profile", {
        avatar: this.$store.state.User.avatar,
        email: this.$store.state.User.email,
        sex: this.$store.state.User.gender,
        birthday: new Date(this.birthday).getTime(),
      }).then(res => {
        res = res.data;
        if (res.status == 200) {
          ElMessage.success({
            message: this.$t('user.edit.modifiedsuccess'),
            type: 'success'
          });
          this.$store.dispatch("updateUserInfo");
          this.$router.go(-2)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.invest_area {
  width: 100%;
  height: 20px;
  padding: 20px;
  color: #303133;
  position: relative;
}
.invest_area_top_icon {
  position: absolute;
  height: 20px;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  color: #465dfe;
}
.invest_area_top {
  text-align: center;
  font-size: 18px;
  height: 20px;
  line-height: 20px;
}
.invest_area_top_save {
  position: absolute;
  height: 20px;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
}
.el-form-item-span {
  margin-right: 16px;
}
.menuList {
  padding: 20px 0 100px;
}
.menuList_titile {
  color: #303133;
  font-size: 14px;
  padding: 15px;
  background-color: #f5f7fa;
}
.menuItem {
  display: flex;
  justify-content: space-between;
  margin: 0px 15px;
  padding: 15px 0px;
  align-items: center;
  border-bottom: 1px solid #edeff2;
}
.menuItem .left {
  display: flex;
  align-items: center;
  color: #909399;
  font-size: 14px;
}
.menuItem .left .ico {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  border: 1px solid #f1f1f1;
}
.langtxt {
  display: inline-block;
  float: right;
  margin-right: 10px;
  color: #000;
}
.logout {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 100;
  background: #fff;
  height: 60px;
  padding: 0px 16px;
}
.draweritem {
  text-align: center;
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #f4f4f4;
}
.el-drawer.btt {
  height: auto !important;
}
.el-form-item {
  margin-bottom: 22px;
  display: flex;
  width: 100%;
  padding: 0px 16px;
  background: #f5f7fa;
  height: 60px;
  align-items: center;
}
.el-form-item__content {
  display: flex !important;
  width: 100%;
}
.el-form-item-span {
  margin-right: 10px;
  flex-shrink: 0;
}
</style>