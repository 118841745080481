export default {
  state: {
    user: {},
    showwindow: false,
  },
  mutations: {
    setGlobalGiftUser(state, user) {
      state.user = user;
    },
    hideGlobalgift(state) {
      state.showwindow = false;
    },
    showGlobalgift(state) {
      state.showwindow = true;
    },
  },
};
