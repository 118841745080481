<template>
  <van-pull-refresh
    v-model="isLoading"
    @refresh="onRefresh"
    :pulling-text="$t('tips.pullrefresh')"
    :loosing-text="$t('tips.loosingrefresh')"
    :loading-text="$t('tips.loading')"
  >
	<van-search v-model="searchcontent" :placeholder="$t('member.search')" />
    <van-list class="indexuserBox"  >
      <User
        v-for="(item, index) in data"
        :key="index"
        :item="item"
		:myvip="myvip"
        @follow="item.is_like = item.is_like == 0 ? 1 : 0"
      />

    </van-list>
	<div class="pager" @click="moreuser()" v-show="loading" >{{ $t("tips.clickmore") }}</div>
  </van-pull-refresh>
</template>

<script>
import User from "./User.vue"
export default {
  data () {
    return {
      isLoading: false,
	  searchcontent: '',
	  setTimeout: null,
	  data: [],
	  loading: true,
	  page:1,
	  myvip:0,
    }
  },
  watch: {
    searchcontent (oldValue, newValue) {
      if (this.util.checkPermision('Search_users')) {
        clearTimeout(this.setTimeout);
        this.setTimeout = setTimeout(this.search, 500);
      }
    }
  },
  components: {
    User,
  },
  methods: {
    onRefresh () {
      this.search();
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    follow () {
      ElMessage.success(this.$t("tips.followsuccess"));
    },
	search () {
	  this.page = 1;
      this.loading = true;
	  this.getUserList();
    },
	getUserList(){
		var page = this.page;
		this.axios.post_form("/member/index/userlist", {page:page, username: this.searchcontent}).then((res) => {
			res = res.data;
			if (res.status == 200) {
				if(page==1){
					this.data = res.data.data;
					this.myvip = res.data.user.vip;
				}else{
					this.data = this.data.concat(res.data.data);
				}
				this.page = page + 1;
				if(page == res.data.last_page){
					this.loading = false;
				}
			}
		});
    },
	moreuser(){
	    this.myvip = localStorage.getItem("currentVip");
		if(this.myvip == 0){
			this.$message.success(this.$t('tips.needupgrade'));
			location.assign('/#/m/home/Upgrade/index');
			return;
		}
	
		this.getUserList();
	},
	initData () {
      this.axios.post_form("/member/index/initdata", {}).then(res => {
        res = res.data;
        if (res.status == 200) {
          this.$store.commit("setAuthority", res.data.authority);
          this.$store.dispatch("updateUserAuthority");
          this.$store.commit("setService", res.data.service);
		  localStorage.setItem("currentVip", res.data.vip);
        }
      })
    },
  },
  created () {
    this.getUserList();
	this.initData();
  },
  
  

}
</script>


<style lang="scss" scoped>
.indexuserBox {
  padding: 20px 0px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  min-height: 501px;
}
.pager{
  text-align:center;
  font-size:13px;
  color:#666;
  margin-bottom:15px;
}
</style>

<style lang="scss">
.van-list__finished-text{
  text-align: center;
  width: 100%;
}

</style>
