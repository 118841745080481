<template>
  <div class="flexbox-s trenditem">
    <!-- 用户信息 -->
    <div class="flexbox userinfo">
      <!-- 头像 -->
      <router-link
        :to="'/m/home/my/newsfeed/' + data.mid"
        class="flexbox useravatar"
      >
        <img class="avatar" :src="imgurl + data.avatar" />
      </router-link>
      <!-- 会员信息及用户名 -->
      <div class="flexbox-s namebox">
        <router-link :to="'/m/home/my/newsfeed/' + data.mid" class="username">
          <i
            class="iconfont icon-V"
            style="color: gold"
            v-if="data.vip == 2"
          ></i>
          <i
            class="iconfont icon-V"
            style="color: silver"
            v-if="data.vip == 1"
          ></i>
          {{ data.username }}
        </router-link>
        <!-- 性别和年龄 -->
        <div class="flexbox sexandage">
          <i
            class="iconfont icon-nan"
            v-if="data.sex == 1"
            style="color: #99f"
          ></i>
          <i
            class="iconfont icon-nv"
            v-if="data.sex == 0"
            style="color: #f4a"
          ></i>
          <div class="age">
            {{
              new Date().getFullYear() -
              new Date(parseInt(data.birthday)).getFullYear()
            }}
          </div>
        </div>
      </div>
      <!-- 聊天icon -->
      <div class="chatbox">
        <i
		  v-if="data.mine == 1"
          @click="delChat(data.id,data.mid)"
          class="iconfont"
          style="color: #999; font-size: 13px;width:30px;display:block;"
        >删除</i>
      </div>
    </div>
    <!-- 动态内容 -->
    <div class="flexbox trendcontent">
      <div class="flexbox-s textsector">
        <!-- 用户发布得动态 -->
        <div v-if="data.type == 1">
          <ExpText :text="data.content" />
        </div>
        <!-- 加入了网站 -->
        <div v-if="data.type == 0">
          {{ $t("trend.joined") }}
        </div>
        <!-- 更换了头像 -->
        <div v-if="data.type == 2">
          {{ $t(`trend.avatar${data.sex}`) }}
        </div>
      </div>
      <div
        class="flexbox-s imagesector"
        v-if="data.img && data.img != 'null' && data.img != 'undefined'"
      >
        <div class="img">
          <el-image :src="url" lazy :preview-src-list="[url]"></el-image>
        </div>
      </div>
    </div>
    <!-- 发布时间 -->
    <div class="flexbox trendtime">
      {{ util.getTrendtimeByDate(new Date(data.create_time)) }}
    </div>
  </div>
</template>

<script>
import ExpText from "../Expression.vue"
export default {
  props: ["data"],
  data () {
    return {
	  myvip:0,
    }
  },
  computed: {
    date () {
      return $i18n.locale;
    },
    url () {
      return this.imgurl + this.data.img
    }
  },
  components: {
    ExpText
  },
  methods: {
    addUserToChatList () {
	  this.myvip = localStorage.getItem("currentVip");
	  if(this.myvip == 0 && this.data.mid != 2){
		this.$message.success(this.$t('tips.nopermission')+', '+this.$t('tips.needupgrade')+'!');
		setTimeout(() => {
			this.$router.push('/m/home/Upgrade/index')
		},1500);
		return;
	  }
      this.$store.commit('addUserToChatList', this.data.user);
      this.$store.commit('setCur', this.data.mid);
	  if(this.data.mid == 2){
		this.$router.push('/m/home/chat/service')
		return;
	  }
      this.$router.push('/m/home/chat/index');
    },
	delChat(id,uid){
	  if(id>0){
	    this.axios.post_form("/member/trend/del", {
          id: id,
        })
        .then((res) => {
          res = res.data;
          if (res.status == 200) {
            this.$message.success(this.$t("tips.deletedsuccess"));
			this.$router.go(0)
          }else{
			
		  }
        });
	  }
	},
  },
  created () {
    //查看是否有权限
	//this.axios.post_form("/member/index/info", {}).then((res) => {
	//	res = res.data;
	//	if (res.status == 200) {
	//		this.myvip = res.data.vip;
	//	}
	//});
	
  },
}
</script>

<style lang="scss" scoped>
.trenditem {
  padding: 10px;
  border-bottom: 1px solid #eee;
  .userinfo {
    height: 70px;
    flex-wrap: nowrap;
    .useravatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      cursor: pointer;
      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
    .namebox {
      padding-left: 10px;
      .username {
        width: 100%;
        color: #333;
        padding: 0 5px;
        font-weight: bold;
        cursor: pointer;
        margin-bottom: 5px;
      }
      .username:hover {
        text-decoration: underline;
      }
      .sexandage {
        width: auto;
        font-size: 14px;
        background-color: #f1f2f3;
        padding: 2px 8px;
        height: 20px;
        border-radius: 10px;
        .age {
          padding-left: 5px;
        }
      }
    }
  }

  .trendcontent {
    padding: 0 5px;
    color: #333;
    .textsector {
      margin-bottom: 10px;
    }
    .imagesector {
      padding: 0 10px;
      margin-bottom: 10px;
      .img {
        max-width: 200px;
        height: auto;
      }
    }
  }
  .trendtime {
    color: #aaa;
    width: auto;
    height: 30px;
    align-items: flex-end;
    align-content: flex-end;
    flex-shrink: 0;
    font-size: 12px;
  }
}
</style>