<template>
  <audio id="r_audio" :src="r_audio"></audio>
  <audio id="s_audio" :src="s_audio"></audio>
</template>

<script>
import r_audio from "../assets/audio/iphone1.mp3"
import s_audio from "../assets/audio/iphone2.mp3"
export default {
  data () {
    return {
      r_audio, s_audio,
    }
  }
}
</script>

<style>
</style>