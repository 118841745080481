<template>
  <div class="cardcontainer">
    <div class="flexbox card">
      <div class="flexbox-s usermsg">
        <div class="cardbg">
          {{ data.sex == 1 ? $t("regist.male") : $t("regist.female") }},{{
            new Date().getFullYear() -
            new Date(parseInt(data.birthday)).getFullYear()
          }}{{ $t("member.year") }}
        </div>
        <div class="flexbox userheader">
          <img :src="imgurl + data.avatar" alt="" />
          <i
            v-if="data.vip != 0"
            class="iconfont icon-V vip"
            :style="{ color: data.vip == 1 ? 'silver' : 'gold' }"
          ></i>
        </div>
        <div class="userother">
          <a
            class="username"
            @click="
              util.checkPermisionAndPushRouter(
                '/w/home/user/detail/' + data.id,
                'View_profiles'
              )
            "
          >
            {{ data.username }}
          </a>
          <div class="userstatus" v-if="data.is_online != 1">
            <span class="offline"></span>
            {{ $t("chat.active") }}：{{
              util.getTrendtimeByDate(new Date(data.last_login_time * 1000))
            }}
          </div>
          <div class="flexbox online" v-else>
            <span></span>
            <div class="btn" @click="chatnow">{{ $t("member.chatnow") }}~</div>
          </div>
        </div>
      </div>
      <div class="flexbox usermenu">
        <div class="btn" @click="follow(data)">
          {{
            data.is_like == 1 ? $t("member.cancelfollow") : $t("member.follow")
          }}
        </div>
        <div @click="sendgift(data)" class="btn">
          {{ $t("member.sendgift") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  methods: {
    follow ({ id, is_like }) {
      this.axios.post_form('/member/like/like', { target_id: id }).then(res => {
        res = res.data;
        if (res.status == 200) {
          if (is_like == 0) {
            this.$message.success(this.$t('tips.followsuccess'));
          } else {
            this.$message.success(this.$t('tips.followcancel'));
          }
          this.$emit('follow');
        }
      })
    },
    chatnow () {
      this.$store.commit('addUserToChatList', this.data);
      this.$store.commit('setCur', this.data.id);
      this.$router.push('/w/home/chat');
    },
    sendgift (user) {
      this.$store.commit('setGlobalGiftUser', user);
      this.$store.commit('showGlobalgift');
    }
  },
}
</script>

<style lang="scss" scoped>
.cardcontainer {
  width: 100%;
  height: 100%;
  .card {
    width: 300px;
    height: 160px;
    border-radius: 8px;
    background-color: rgb(159, 117, 155);
    overflow: hidden;
    .usermsg {
      flex-wrap: nowrap;
      height: 120px;
      position: relative;
      .cardbg {
        position: absolute;
        width: 100%;
        height: 60px;
        top: 60px;
        background-color: rgb(156, 109, 151);
        color: #fff;
        font-size: 12px;
        padding-left: 130px;
        border-top: 1px solid rgb(169, 127, 165);
        border-bottom: 1px solid rgb(169, 127, 165);
      }
      .userheader {
        position: relative;
        width: 120px;
        height: 120px;
        img {
          border: 5px solid rgb(210, 174, 211);
          width: 100px;
          height: 100px;
          border-radius: 8px;
        }
        .vip {
          position: absolute;
          right: 15px;
          bottom: 10px;
          font-size: 20px;
          color: gold;
        }
      }
      .userother {
        width: 180px;
        height: 120px;
        .username {
          font-size: 12px;
          color: $linkColor;
          cursor: pointer;
          margin-top: 15px;
          margin-left: 10px;
        }
        .username:hover {
          text-decoration: underline;
        }
        .userstatus {
          font-size: 12px;
          margin-top: 5px;
          color: #fff;
          margin-left: 10px;
        }
        .offline {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #999;
          margin-right: 10px;
        }
        .online {
          font-size: 12px;
          margin-top: 5px;
          margin-left: 10px;
          justify-content: flex-start;
          span {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #9f9;
            margin-right: 10px;
          }
          .btn {
            color: $themeColor;
            font-weight: bold;
            padding: 0 3px;
            border: 1px solid $themeColor;
            background-color: #e8cbed;
            border-radius: 2px;
            cursor: pointer;
          }
          .btn:hover {
            background-color: #e1b1e1;
          }
        }
      }
    }
    .usermenu {
      height: 40px;
      background-color: rgb(159, 117, 155);
      font-size: 12px;
      padding-left: 10px;
      justify-content: flex-start;
      .btn {
        color: #fff;
        padding: 2px 5px;
        border: 1px solid $themeColor;
        background-color: #8f75a1;
        border-radius: 2px;
        cursor: pointer;
        margin-right: 5px;
      }
    }
  }
}
</style>