import axios from "../util/axios";
export default {
  state() {
    return {
      wall: [],
    };
  },
  mutations: {
    setWall(state, data) {
      state.wall = data;
    },
  },
  actions: {
    getWall({ commit }) {
      axios.post_form("/member/index/memberwall", {}).then((res) => {
        res = res.data;
        commit("setWall", res.data);
      });
    },
  },
};
