import axios from "../util/axios";
export default {
  state() {
    return {
      // 银卡
      vip1: {},
      // 金卡
      vip2: {},
    };
  },
  mutations: {
    setUpgradePackages1(state, data) {
      state.vip1 = data;
    },
    setUpgradePackages2(state, data) {
      state.vip2 = data;
    },
  },
  actions: {
    getUpgradePackages({ commit }) {
      axios.post_form("/member/Upgrade/package", {}).then((res) => {
        res = res.data;
        commit("setUpgradePackages1", res.data.vip1);
        commit("setUpgradePackages2", res.data.vip2);
      });
    },
  },
};
