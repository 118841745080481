<template>
  <div class="userItem">
    <div class="img">
      <router-link :to="'/m/home/my/newsfeed/' + item.id">
        <el-image :src="imgurl + item.avatar"></el-image>
      </router-link>
	  <img class="vip" src="@/assets/images/icon-dimand.png" v-if="item.vip == 1"/>
	  <img class="vip" src="@/assets/images/icon-king.png" v-if="item.vip == 2"/>
    </div>
    <div class="userName">
      {{ item.username }}
      <div class="userstatus" v-if="item.is_online != 1">
        <!-- {{ $t("chat.active") }}：{{
          util.getTrendtimeByDate(new Date(item.last_login_time * 1000))
        }} -->
      </div>
      <div class="online" v-else></div>
    </div>
    <div class="flexbox userMenu">
      <!-- 喜欢 -->
      <img src="@/assets/images/unlike.png" size="1.7rem" v-if="item.is_like == 0" @click="follow(item)"
      />
      <img src="@/assets/images/like.png"
        size="1.7rem"
        @click="follow(item)"
        v-else
      />
      <!-- 聊天 -->
      <img src="@/assets/images/msg.jpg" size="1.7rem" @click="chatnow" />
      <!-- 邮件 -->
      <!--<van-icon name="envelop-o" size="1.7rem" color="#666" @click="sendmsg" />-->
      <!-- 礼物 -->
      <!--<van-icon name="gift-o" size="1.7rem" color="#666" @click="sendgift" />-->
    </div>
  </div>
</template>

<script>
export default {
  props: ['item','myvip'],
  methods: {  
    follow ({ id, is_like }) {
      this.axios.post_form('/member/like/like', { target_id: id }).then(res => {
        res = res.data;
        if (res.status == 200) {
          if (is_like == 0) {
            this.$message.success(this.$t('tips.likesuccess'));
          } else {
            this.$message.success(this.$t('tips.likecancel'));
          }
          this.$emit('follow');
        }
      })
    },
    chatnow () {
	  if(this.myvip == 0 && this.item.id != 2){
		this.$message.success(this.$t('tips.nopermission')+', '+this.$t('tips.needupgrade')+'!~');
		setTimeout(() => {
			this.$router.push('/m/home/Upgrade/index')
		},1500);
		return;
	  }
      this.$store.commit('addUserToChatList', this.item);
      this.$store.commit('setCur', this.item.id);
	  if(this.item.id == 2){
		this.$router.push('/m/home/chat/service')
		return;
	  }
      this.$router.push('/m/home/chat/index');
    },
    sendmsg () {
      this.$router.push('/m/home/email/send')
    },
    sendgift () {
      this.$router.push('/m/home/gift')
    }
  }
}
</script>

<style lang="scss"  scoped>
.userItem {
  width: 50%;
  text-align: center;
  padding: 0 3%;
  padding-bottom: 15px;
  .userName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0 8px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .userstatus {
    font-size: 12px;
    color: #999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .userMenu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
  .img {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    .el-image {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .vip {
      position: absolute;
      right: 2px;
      top: 3px;
	  width:22px;
    }
  }
}
.online {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-image: linear-gradient(to bottom, greenyellow, green);
  margin-right: 10px;
}
</style>
