<template>
  <div class="container">
    <div class="title">{{ $t("store.store") }}</div>
    <div class="linkbox">
      <router-link class="linkitem" to="/w/home/store/index">
        <i class="iconfont icon-tuding"></i>
        {{ $t("store.gift") }}
      </router-link>
      <router-link class="linkitem" to="/w/home/store/mine">
        <i class="iconfont icon-dongtai2"></i>
        {{ $t("store.receive") }}
      </router-link>
    </div>
    <div class="flexbox-s storecontent">
      <!-- 礼物分类 -->
      <div class="flexbox gifttypebox" v-if="data.length > 0">
        <div
          class="flexbox gifttypeitem"
          v-for="(item, index) of data"
          :key="index"
          @click="setType(index)"
          :class="{ gifttypeactive: type == index }"
          :data-type="type"
        >
          {{ $t(`gift.type${item.id}`) }}
        </div>
      </div>
      <!-- 礼物列表 -->
      <div class="flexbox giftitembox" v-if="data.length > 0">
        <div
          class="flexbox giftitem"
          v-for="(item, index) of curtype.gift"
          :key="index"
        >
          <div
            class="img"
            :style="{ backgroundImage: `url(${imgurl + item.path})` }"
          ></div>
          <div class="flexbox giftname">
            {{ $t(`gift.type${curtype.id}gift${item.id}`) }}
          </div>
          <div class="flexbox giftprice">
            {{ item.money }} {{ $t("store.credits") }}
          </div>
          <div class="flexbox sendbtn" @click="sendlaunch(item)">
            {{ $t("store.sendgift") }}
          </div>
        </div>
      </div>
    </div>
    <!-- 赠送礼物弹窗 -->
    <el-dialog :title="$t('store.sendgift')" v-model="sendwindowvisibility">
      <el-form :model="form">
	    <div class="details">
			<div class="dleft">
				<img :src="form.pic" alt="" />
			</div>
			<div class="dright">
				<div class="gtitle">{{ $t("globalgift.giftname") }}：{{ form.gtitle }}</div>
				<p class="ginfo">{{ $t("globalgift.giftinfo") }}：{{ form.ginfo }}</p>
				<p class="gprice">{{ $t("globalgift.giftprice") }}：{{ form.gprice}} {{ $t("store.credits") }}</p>
			</div>
		</div>
        <div class="sendinput">
          <el-input v-model="form.name" :placeholder="$t('store.username')">
          </el-input>
        </div>
        <div class="sendtextarea">
          <el-input
            type="textarea"
            :placeholder="$t('store.msg')"
            v-model="form.message"
            maxlength="50"
            show-word-limit
          >
          </el-input>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="sendwindowvisibility = false">
            {{ $t("global.cancel") }}
          </el-button>
          <el-button type="primary" @click="send">
            {{ $t("global.send") }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  data () {
    return {
      sendwindowvisibility: false,
      type: 0,
      giftid: '',
      form: {
	    pic: '',
		gtitle:'',
		ginfo:'',
		gprice:'',
        name: '',
        message: ''
      },
    }
  },
  computed: {
    curtype () {
      return this.data[this.type];
    },
    data () {
      return this.$store.state.Store.storeList;
    }
  },
  methods: {
    sendlaunch (item) {
      this.giftid = item.id;
      this.sendwindowvisibility = true;
	  this.form.pic = this.imgurl + item.path
	  this.form.gtitle = this.$t(`gift.type${this.curtype.id}gift${item.id}`)
	   this.form.ginfo = this.$t(`gift.type${this.curtype.id}giftinfo${item.id}`)
	  this.form.gprice = item.money;
    },
    setType (index) {
      this.type = index;
    },
    send () {
      this.axios.post_form("/member/gift/gift_giving", {
        username: this.form.name,
        message: this.form.message,
        number: 1,
        giftid: this.giftid,
      }).then(res => {
        this.sendwindowvisibility = false;
        res = res.data;
        if (res.status == 200) {
          ElMessage.success({
            message: this.$t('tips.sendsuccess'),
            type: 'success'
          });
        } else if (res.status == -1) {
          ElMessage.error(this.$t('tips.usernotexist'));
        } else if (res.status == -2) {
          ElMessage.error(res.msg);
        } else {
          ElMessage.error(this.$t('tips.sendfail'));
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 1000px;
  padding: 100px 30px;
  .title {
    font-size: 20px;
    color: $themeColor;
    margin-bottom: 28px;
    font-weight: bold;
  }
  .linkbox {
    .linkitem {
      padding: 10px 10px;
      background-color: #fff;
      color: $themeColor;
      background-color: transparent;
    }
    .router-link-active {
      background-color: rgb(180, 83, 77);
    }
  }
  .storecontent {
    padding: 10px;
    padding-right: 0;
    background-color: #fff;
    margin-top: 30px;
    font-size: 14px;
    min-height: 400px;
    .gifttypebox {
      justify-content: flex-start;
      .gifttypeactive {
        color: #ff4a53 !important;
      }
      .gifttypeitem {
        width: 155px;
        height: 32px;
        color: $fontColor;
        margin: 5px 10px;
        border: 1px solid #ccc;
        cursor: pointer;
      }
    }
    .giftitembox {
      justify-content: flex-start;
      color: $fontColor;
      padding: 30px;

      .giftitem {
        width: 144px;
        height: auto;
        margin: 15px;
        align-content: space-between;
        .img {
          width: 120px;
          height: 120px;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
        }
        .giftname {
          min-height: 30px;
          text-align: center;
        }
        .giftprice {
          min-height: 30px;
          text-align: center;
        }
        .sendbtn {
          width: 75px;
          height: 30px;
          border: 1px solid #ff4a53;
          color: #ff4a53;
          border-radius: 30px;
          cursor: pointer;
        }
      }
    }
  }
  .sendtextarea {
    margin-top: 20px;
  }
  .details{
    display: flex;
    flex-wrap: wrap;
	.dleft{
		width:40%;
		padding: 0 0 20px 20px;
		img{
			width:100%;
		}
	}
	.dright{
		width: 55%;
		margin-left: 5%;
	}
  }
}
</style>