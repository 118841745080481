<template>
  <div class="container">
    <div class="title">{{ $t("home.photo") }}</div>
    <div class="flexbox-s phtotcontent" v-if="data">
      <div class="flexbox dateitem" v-for="(album, index) in data" :key="index">
        <div class="flexbox-s userbox">
          <img class="userheader" :src="imgurl + album[0].avatar" />
          <div class="flexbox usermsgbox">
            <div class="username">{{ album[0].username }}</div>
            <div class="date">{{ album[0].create_time.split(" ")[0] }}</div>
          </div>
        </div>
        <div class="flexbox-s photobox">
          <div class="photo" v-for="(item, index) of album" :key="index">
            <el-image
              class="photo"
              :src="imgurl + item.img"
              lazy
              fit="cover"
              :preview-src-list="
                album.map((item) => {
                  return imgurl + item.img;
                })
              "
            ></el-image>
          </div>
        </div>
      </div>
    </div>

    <div class="flexbox-s phtotcontent" v-else>{{ $t("empty.nophoto") }}</div>
  </div>
</template>

<script>
export default {
  props: ['id'],
  data () {
    return {
      data: null,
    }
  },
  computed: {
    view_photo () {
      return this.$store.state.User.View_photo;
    }
  },
  methods: {
    getList () {
      this.axios.post_form('member/photo/his_photo', {
        userid: this.id
      }).then(res => {
        res = res.data;
        if (res.status == 200) {
          if (res.data.length > 0) {
            let data = {};
            for (const item of res.data) {
              if (data[item.create_time]) {
                data[item.create_time].push(item);
              } else {
                data[item.create_time] = [];
                data[item.create_time].push(item);
              }
            }
            this.data = data;
          }
        }

      })
    }
  },
  created () {
    this.getList();
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 1000px;
  padding: 100px 30px;
  .title {
    font-size: 20px;
    color: $themeColor;
    margin-bottom: 28px;
    font-weight: bold;
  }
  .phtotcontent {
    padding-right: 0;
    background-color: #fff;
    margin-top: 30px;
    font-size: 14px;
    padding: 20px 30px;
    color: $fontColor;
    .dateitem {
      border-bottom: 1px solid #f1f1f1;
      .userbox {
        padding: 10px 0;
        flex-wrap: nowrap;
        .userheader {
          width: 40px;
          height: 40px;
          flex-shrink: 0;
        }
        .usermsgbox {
          width: 100%;
          font-size: 14px;
          padding-left: 15px;
          .username {
            width: 100%;
            height: 20px;
            color: $linkColor;
          }
          .date {
            width: 100%;
            height: 20px;
            color: #999;
          }
        }
      }
      .photobox {
        padding-top: 20px;
        padding-left: 55px;
        .photo {
          width: 120px;
          height: 120px;
          margin-right: 20px;
          margin-bottom: 20px;
          overflow: hidden;
        }
      }
    }
  }
}
</style>