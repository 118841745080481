import { createStore } from "vuex";
import { webSocketUrl } from "../common/project.config";
import axios from "../util/axios";
import User from "./User";
import Store from "./Store";
import Member from "./Member";
import Pay from "./Pay";
import Upgrade from "./Upgrade";
import Photo from "./Photo";
import Wall from "./Wall";
import Chat from "./Chat";
import Message from "./Message";
import Report from "./Report";
import Notification from "./Notification";
import GlobalGift from "./GlobalGift";

export default createStore({
  state: {
    lang: localStorage.getItem("lang") || "en",
    general_authority: [],
    silvercard_authority: [],
    goldcard_authority: [],
    ws: null,
  },
  mutations: {
    // 修改语言
    changeLang(state, lang) {
      state.lang = lang;
    },
    // 设置用户权限
    setAuthority(state, data) {
      state.general_authority = data.vip0;
      state.silvercard_authority = data.vip1;
      state.goldcard_authority = data.vip2;
    },

    closeWebsocket(state) {
      state.ws.close();
      state.ws = null;
    },
  },
  actions: {
    // 更新用户信息
    updateUserInfo({ commit, state }) {
      axios.post_form("/member/index/userinfo", {}).then((res) => {
        res = res.data;
        commit("updateUserInfo", res.data);
        if (res.status == 200) {
          let vip = state.User.vip;
		  console.log('vip:'+vip)
		  if (vip == 0) {
            commit("setUserAuthority", state.general_authority);
          } else if (vip == 1) {
            commit("setUserAuthority", state.silvercard_authority);
          } else if (vip == 2) {
            commit("setUserAuthority", state.goldcard_authority);
          }
        }
      });
    },
    // 设置用户权限
    updateUserAuthority({ commit, state }) {
      let vip = state.User.vip;
	  console.log('vip1:'+vip)
      if (vip == 0) {
        commit("setUserAuthority", state.general_authority);
      } else if (vip == 1) {
        commit("setUserAuthority", state.silvercard_authority);
      } else if (vip == 2) {
        commit("setUserAuthority", state.goldcard_authority);
      }
    },
    // 初始化websocket
    initWebsocket({ dispatch, commit, state }) {
      state.ws = new WebSocket(`${webSocketUrl}`);
      state.ws.onopen = () => {
        state.ws.send(
          JSON.stringify({
            opt: "100",
            hasInit: "no",
            uid: state.User.id,
            origin: true,
          })
        );
        state.ws.send(
          JSON.stringify({
            opt: "100",
            hasInit: "0",
            uid: state.User.id,
            origin: true,
          })
        );
      };
      state.ws.onmessage = (res) => {
        let data = JSON.parse(res.data);
        if (data.status == "success") {
          dispatch("chatEvent", data);
        }
      };
      state.ws.onclose = () => {
        console.error("websocket 连接已断开");
        if (state.User.isLogin) {
          dispatch("initWebsocket");
        }
      };
    },
    // 发送websocket消息
    wssend({ state }, data) {
      state.ws.send(JSON.stringify(data));
    },
  },
  modules: {
    User,
    Store,
    Member,
    Pay,
    Upgrade,
    Photo,
    Wall,
    Chat,
    Message,
    Report,
    Notification,
    GlobalGift,
  },
});
