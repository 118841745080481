<template>
  <van-overlay :show="show" @click="hide">
    <div class="flexbox globalgiftsendwindow">
      <div class="flexbox-c giftcontainer" @click.stop>
        <div class="flexbox giftsendtitle">
          {{ $t("globalgift.sendto") }}{{ user.username }}
        </div>
        <div class="flexbox giftsendamount">
          {{ $t("globalgift.balance") }}:{{ amount }}
          {{ $t("globalgift.credits") }}
        </div>
        <div class="flexbox-s giftsendcontent">
          <!-- 礼物分类 -->
          <div class="gifttypebox" v-if="data.length > 0">
            <div
              class="flexbox gifttypeitem"
              v-for="(item, index) of data"
              :key="index"
              @click="setType(index)"
              :class="{ gifttypeactive: type == index }"
              :data-type="type"
            >
              <i class="iconfont icon-aixin"></i>
              {{ $t(`gift.type${item.id}`) }}
            </div>
          </div>
          <!-- 礼物列表 -->
          <el-scrollbar max-height="250px" height="250px">
            <div class="giftitembox" v-if="data.length > 0">
              <div
                class="flexbox giftitem"
                v-for="(item, index) of curtype.gift"
                :key="index"
                :class="{ giftactive: item.id == giftid }"
                @click="giftid = item.id"
              >
                <div
                  class="img"
                  :style="{ backgroundImage: `url(${imgurl + item.path})` }"
                ></div>
                <div class="flexbox giftname">
                  {{ $t(`gift.type${curtype.id}gift${item.id}`) }}
                </div>
                <div class="flexbox giftprice">
                  {{ item.money }} {{ $t("store.credits") }}
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="flexbox giftsendform">
          <div class="msgbox">{{ tips ? $t("globalgift.psag") : "" }}</div>
          <div class="flexbox textareabox">
            <div class="textareatitle">
              {{ $t("globalgift.message") }}
            </div>
            <textarea class="textarea" rows="3" v-model="message"></textarea>
          </div>
        </div>
        <div class="flexbox sendbtncontainer">
          <div class="flexbox commonbtn" @click="send">
            <div class="textbox">{{ $t("global.send") }}</div>
            |
            <div class="iconbox">
              <i class="iconfont icon-tubiaozhizuomoban"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  data () {
    return {
      type: 0,
      message: '',
      giftid: -1,
      tips: false,
    }
  },
  methods: {
    setType (index) {
      this.type = index;
    },
    hide () {
      this.$store.commit('hideGlobalgift');
    },
    send () {
      if (this.giftid == -1) {
        this.tips = true;
        this.$message.error(this.$t('globalgift.nogift'));
        return false;
      }
      this.axios.post_form("/member/gift/gift_giving", {
        username: this.user.username,
        message: this.message,
        number: 1,
        giftid: this.giftid,
      }).then(res => {
        this.sendwindowvisibility = false;
        res = res.data;
        this.hide();
        if (res.status == 200) {
          this.$message.success({
            message: this.$t('tips.sendsuccess'),
            type: 'success'
          });
        } else if (res.status == -1) {
          this.$message.error(this.$t('tips.usernotexist'));
        } else if (res.status == -2) {
          this.$message.error(res.msg);
        } else {
          this.$message.error(this.$t('tips.sendfail'));
        }
      })
    },
  },
  computed: {
    curtype () {
      return this.data[this.type];
    },
    data () {
      return this.$store.state.Store.storeList;
    },
    amount () {
      return this.$store.state.User.amount;
    },
    user () {
      return this.$store.state.GlobalGift.user;
    },
    show () {
      return this.$store.state.GlobalGift.showwindow;
    }
  },
}
</script>

<style lang="scss" scoped>
.globalgiftsendwindow {
  height: 100%;
  .giftcontainer {
    width: 1000px;
    border-radius: 8px;
    background-color: rgb(156, 109, 151);
    padding: 20px;
  }
  .giftsendtitle {
    justify-content: flex-start;
    font-size: 18px;
    color: #333;
  }
  .giftsendamount {
    justify-content: flex-start;
    font-size: 14px;
    color: #333;
    padding-left: 700px;
  }
  .giftsendcontent {
    padding: 10px;
    padding-right: 0;
    margin-top: 30px;
    font-size: 14px;
    .gifttypebox {
      width: 100%;
      border-bottom: 10px solid rgb(140, 98, 136);
      .gifttypeactive {
        background-color: rgb(180, 83, 77) !important;
        font-weight: bold;
        border-right: none !important;
      }
      .gifttypeitem {
        display: inline-block;
        width: auto;
        height: 40px;
        line-height: 40px;
        padding: 0 15px;
        color: #333;
        cursor: pointer;
        border-right: 1px solid #333;
        i {
          color: #fff;
          margin-right: 5px;
        }
      }
      .gifttypeitem:last-child {
        border-right: none !important;
      }
    }
    .giftitembox {
      justify-content: flex-start;
      color: #333;
      padding: 10px;
      cursor: pointer;
      overflow: hidden;
      .giftitem {
        width: 90px;
        height: auto;
        margin: 5px;
        padding: 5px;
        align-content: space-between;
        display: inline-block;
        .img {
          width: 80px;
          height: 80px;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
        }
        .giftname {
          min-height: 30px;
          text-align: center;
        }
        .giftprice {
          min-height: 30px;
          text-align: center;
        }
      }
      .giftactive {
        background-color: rgb(166, 124, 162);
      }
    }
  }
  .giftsendform {
    width: 1000px;
    height: auto;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: flex-start;
    .msgbox {
      width: 200px;
      color: red;
    }
    .textareabox {
      width: 760px;
      flex-wrap: nowrap;
      align-content: flex-start;
      align-items: flex-start;
      .textareatitle {
        height: 40px;
        line-height: 40px;
        border-right: 1px solid #fff;
        flex-shrink: 0;
        padding: 0 15px;
        color: #fff;
      }
      .textarea {
        width: 100%;
        resize: vertical;
        margin: 0 15px;
      }
    }
  }
}
.sendbtncontainer {
  justify-content: flex-end;
  margin: 20px 0;
  padding: 0 15px;
  .commonbtn {
    width: auto;
    height: 32px;
    justify-content: space-between;
    color: $themeColor;
    background-color: #e8cbed;
    padding: 0 8px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    .textbox {
      padding: 0 5px;
    }
    .iconbox {
      padding: 0 5px;
      font-size: 20px;
    }
  }
}
</style>