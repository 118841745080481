import Mock from "mockjs";
import { success, error } from "./common";

const User = {
  id: 14,
  username: "@name()",
  country: "@city(true)",
  birthday: "@date(yyyy-MM-dd hh:mm:ss)",
  avatar: "/static/modules/member/img/avatar.png",
  "sex|0-1": 1,
  "vip|0-2": 1,
  email: "123@163.com",
  token: "1623316261478699357",
};

const data = Mock.mock({
  total: 40,
  per_page: 15,
  current_page: 1,
  last_page: 3,
  "data|15": [User],
});

// Mock.mock("/member/user/edit", "post", success({}));
// Mock.mock("/member/index/userinfo", "post", success(Mock.mock(User)));
