<template>
  <div class="invest_area">
    <div class="invest_area_top">
      <div class="invest_area_top_icon">
        <img src="@/assets/images/phone/white_back.png" alt="" @click="back" />
        <img
          src="@/assets/images/phone/home_white.png"
          alt=""
          @click="gohome"
        />
      </div>
      <span>{{ $i18n.t("account.account") }}</span>
    </div>
    <div class="invest_num">
      <span class="num">{{ amount }}</span>
      <span class="num_txt">{{ $i18n.t("account.quantity") }}</span>
    </div>
  </div>
  <div class="invest_content">
    <div class="invest_content_title">
      <div></div>
      <span>{{ $i18n.t("account.choose") }}</span>
      <div></div>
    </div>
    <div class="invest_list">
      <div
        class="invest_item"
        @click="ischoose(-1)"
        :class="[current == -1 ? 'invest_item_choose' : '']"
      >
        <img src="@/assets/images/phone/glod.png" alt="" />
        <input class="input" v-model="inputamount" @input="checkmount" />
      </div>
      <div
        class="invest_item"
        v-for="(item, index) of packages"
        :key="index"
        @click="ischoose(index, item.id, item.amounts)"
        :class="[current == index ? 'invest_item_choose' : '']"
      >
        <img src="@/assets/images/phone/glod.png" alt="" />
        <span>{{ item.amounts }}</span>
      </div>
    </div>
    <div class="invesr_service">
      <div class="appservice">APP{{ $i18n.t("account.service") }}</div>
      <div class="service_list">
        <router-link class="service_item" to="/m/home/Upgrade/index">
          <img src="@/assets/images/phone/account_up.png" alt="" />
          <span>{{ $i18n.t("home.upgrade") }}</span>
        </router-link>
        <router-link class="service_item" to="/m/home/gift/list">
          <img src="@/assets/images/phone/account_gift.png" alt="" />
          <span>{{ $i18n.t("store.gift") }}</span>
        </router-link>
      </div>
    </div>
  </div>
  <div class="site"></div>
  <div class="money_fixed">
    <div class="money_fixed_left">
      <span>{{ $i18n.t("account.totalprice") }}</span>
      <span>$</span>
      <span>{{ money }}</span>
    </div>
    <div class="money_fixed_right" @click="gopayfor">
      {{ $i18n.t("recharge.pay") }}
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  data () {
    return {
      current: 2,
      money: 800,
      selected: 3,
      inputamount: '',
    }
  },
  computed: {
    packages () {
      return this.$store.state.Pay.packages;
    },
    amount () {
      return this.$store.state.User.amount;
    }
  },
  created () {
    this.$store.dispatch('getPayPackage');
    this.$store.dispatch('updateUserInfo');
  },
  methods: {
    back () {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/' })
        return false
      } else {
        this.$router.go(-1)
      }
    },
    gohome () {
      this.$router.push({ path: '/' })
    },
    ischoose (index, id, amounts) {
      if (index == -1) {
        this.current = index;
        this.selected = 0;
      } else {
        this.current = index;
        this.money = amounts;
        this.selected = id;
      }
    },
    gopayfor () {
	  let incomes = this.selected == 0 ? this.inputamount : this.money;
	  if(incomes == '' || incomes <= 0){
		this.$message.error(this.$t('regist.form.required') + ' ' + this.$t('tips.incomeErr'));
        return false;
	  }
      this.$router.push({
        name: 'investlist',
        params: {
          amounts: incomes,
          selected: this.selected,
        }
      })
    },
    checkmount (e) {
      this.$nextTick(() => {
        let val = e.target.value.toString();
        val = val.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
        val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
        val = val.replace(/^0+\./g, '0.');
        val = val.match(/^0+[1-9]+/) ? val = val.replace(/^0+/g, '') : val
        val = (val.match(/^\d*(\.?\d{0,2})/g)[0]) || ''
        this.inputamount = val;
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.invest_area {
  width: 100%;
  height: 200px;
  background: url(~@/assets/images/phone/chang_subscribe.png) no-repeat;
  background-size: 100% 100%;
  padding: 20px;
  color: #fff;
  position: relative;
}
.invest_area_top_icon {
  position: absolute;
  height: 20px;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
}
.invest_area_top_icon img:nth-of-type(1) {
  width: 20px;
  height: 16px;
  margin-right: 15px;
}
.invest_area_top_icon img:nth-of-type(2) {
  width: 25px !important;
  height: 25px !important;
  top: 10px;
}
.invest_area_top {
  text-align: center;
  font-size: 18px;
  height: 20px;
  line-height: 20px;
}
.invest_num {
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.invest_num span {
  display: block;
  text-align: center;
}
.num {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 5px;
}
.num_txt {
  font-size: 16px;
}
.invest_content {
  background: #fff;
  padding: 20px;
}
.invest_content_title {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  color: #ff4a53;
  font-size: 18px;
  line-height: 20px;
}
.invest_content_title div {
  width: 40px;
  height: 2px;
  background: #ff4a53;
  display: inline-block;
}
.invest_content_title span {
  font-size: 14px;
  padding: 0px 10px;
}
.invest_list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.invest_item {
  width: calc(50% - 10px);
  background: rgb(255, 255, 255);
  border: 1px solid rgb(191, 194, 204);
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 20px;
  color: rgb(191, 194, 204);
  font-size: 26px;
  margin-right: 20px;
  margin-top: 20px;
}
.invest_item:nth-child(2n) {
  margin-right: 0px;
}
.invest_item img {
  width: 30px;
  margin-right: 10px;
}
.invest_item .input {
  border: 1px solid rgb(191, 194, 204);
  outline: none;
  font-size: 18px;
  width: 80px;
  height: 30px;
  border-radius: 4px;
}
.invest_item_choose {
  background: rgb(255, 248, 248);
  border: 1px solid rgb(255, 74, 83);
  color: rgb(255, 74, 83);
}
.invesr_service {
  margin-top: 40px;
}
.appservice {
  font-size: 16px;
  font-weight: bold;
  color: #303133;
  margin-bottom: 16px;
}
.service_list {
  width: 100%;
  display: flex;
}
.service_item {
  margin-right: 50px;
  display: flex;
  flex-direction: column;
}
.service_item img {
  width: 40px;
  height: 40px;
  display: inline;
}
.service_item span {
  font-size: 14px;
  color: #606266;
  text-align: center;
  margin-top: 10px;
}
.site {
  width: 100%;
  height: 60px;
}
.money_fixed {
  position: fixed;
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 60px;
  display: flex;
  z-index: 1000;
}
.money_fixed_left {
  flex-shrink: 1;
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding-left: 20px;
  display: flex;
}
.money_fixed_left span:nth-of-type(1) {
  font-size: 14px;
  color: #303133;
}
.money_fixed_left span:nth-of-type(2),
.money_fixed_left span:nth-of-type(3) {
  font-size: 18px;
  color: #ff4a53;
  margin-left: 4px;
}
.money_fixed_right {
  flex-shrink: 0;
  width: 130px;
  height: 61px;
  background: #ff4a53;
  font-size: 18px;
  font-weight: 100;
  border: 0;
  color: #ffffff;
  text-align: center;
  line-height: 60px;
}
</style>