<template>
    <div class="invest_area">
        <div class="invest_area_top">
            <div class="invest_area_top_icon">
                <div @click="back">取消</div>
            </div>
            <span>{{ $t("user.setup.changepwd") }}</span>
            <div class="invest_area_top_save" @click="save">
                <el-button type="primary" style="background:#ff4a53;border:1px solid #ff4a53"> {{$i18n.t('forgot.sunmit')}}</el-button>
            </div>
        </div>
    </div>
    <div class="menuList">
        <div class="el-form-item" >
            <div class="el-form-item__content">
                <el-input
                    v-model="oldpassword"
                    :placeholder="$t('user.edit.oldpwd')"
                    maxlength="18"
                    show-password
                ></el-input>
            </div>
        </div>
        <div class="el-form-item" >
            <div class="el-form-item__content">
                <el-input
                    v-model="password"
                    :placeholder="$t('regist.pwd')"
                    maxlength="18"
                    show-password
                    ></el-input>
            </div>
        </div>
        <div class="el-form-item" >
            <div class="el-form-item__content">
                <el-input
                    v-model="repassword"
                    :placeholder="$t('regist.pwdc')"
                    show-password
                    maxlength="18"
                    ></el-input>
            </div>
        </div>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    data () {
        return {
            oldpassword: "",
            password: "",
            repassword: "",
        }
    },
    computed: {
        
    },
    created () {
    },
    methods:{
        back(){
            if (window.history.length <= 1) {
                this.$router.push({path:'/'})
                return false
            } else {
                this.$router.back()  
            }
        },
        pwdverify () {
            if (this.oldpassword.trim() == "") {
                ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.email'));
                return false;
            } else if (this.password == "") {
                ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.pwd'));
                return false;
            } else if (this.password.trim() != this.repassword.trim()) {
                ElMessage.error(this.$t('regist.form.pwdnomatch'));
                return false;
            } return true;
        },
        save () {
            if (this.pwdverify()) {
                this.axios.post_form("/member/index/changepwd", {
                oldpassword: this.oldpassword,
                newpassword: this.password,
                }).then(res => {
                res = res.data;
                if (res.status == 200) {
                    ElMessage.success({
                        message: this.$t('user.edit.modifiedsuccess'),
                        type: 'success'
                    });
                    this.back()
                } else if (res.status == 0) {
                    ElMessage.error(this.$t('user.edit.oldpwderror'));
                } else {
                    ElMessage.error("error");
                }
                })
            }

        },
    },
}
</script>

<style lang="scss" scoped>
.invest_area{
    width: 100%;
    height: 20px;
    padding: 20px;
    color: #303133;
    position: relative;
}
.invest_area_top_icon{
    position: absolute;
    height: 20px;
    left: 20px;
    top: 20px;
    display: flex;
    align-items: center;
    color: #465DFE;
}
.invest_area_top{
    text-align: center;
    font-size: 18px;
    height: 20px;
    line-height:20px;
}
.invest_area_top_save{
    position: absolute;
    height: 20px;
    right: 20px;
    top: 20px;
    display: flex;
    align-items: center;
}
.el-form-item-span{
    margin-right: 16px;
}
.menuList {
    padding: 20px 0 100px;
}
.menuList_titile{
    color: #303133;
    font-size: 14px;
    padding: 15px;
    background-color: #F5F7FA;
}
.menuItem {
    display: flex;
    justify-content: space-between;
    margin: 0px 15px;
    padding: 15px 0px;
    align-items: center;
    border-bottom: 1px solid #EDEFF2;
}
.menuItem .left {
    display: flex;
    align-items: center;
    color: #909399;
    font-size: 14px;
}
.menuItem .left .ico {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    border: 1px solid #f1f1f1;
}
.langtxt{
    display: inline-block;
    float: right;
    margin-right: 10px;
    color: #000;
}
.logout{
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 100;
    background: #fff;
    height: 60px;
    padding:0px 16px;
}
.draweritem{
    text-align: center;
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #f4f4f4;
}
.el-drawer.btt{
    height: auto !important;
}
.el-form-item{    
    margin-bottom: 22px;
    display: flex;
    width: 100%;
    padding:0px 16px;background:#F5F7FA;    height: 60px;    align-items: center;margin-bottom: 30px;}
.el-form-item__content{display: flex !important;width: 100%;}
.el-form-item-span{margin-right: 10px;flex-shrink: 0;}
</style>