<template>
  <div class="container">
    <div class="title">{{ $t("user.edit.title") }}</div>
    <div class="flexbox-s usercontent">
      <!-- 表单 -->
      <div class="flexbox-c bodycontainer">
        <!-- 修改密码 -->
        <div class="flexbox usereditcontainer">
          <div class="flexbox commonbtn" @click="showpwd = true">
            <div class="textbox">{{ $t("user.edit.changepwd") }}</div>
            |
            <div class="iconbox">
              <i class="iconfont icon-tubiaozhizuomoban"></i>
            </div>
          </div>
        </div>
        <div class="formbody">
          <div class="boxhead">
            <div class="formitemtitle">{{ $t("regist.formtitlte") }}</div>
          </div>
          <div class="boxbody">
            <div class="boxitem">
              <div class="left">{{ $t("user.edit.avatar") }}</div>
              <div class="center" style="justify-content: flex-start">
                <img
                  class="avatar"
                  :src="imgurl + avatar"
                  @click="toggleShow"
                />
              </div>
              <div class="right"></div>
            </div>
            <div class="boxitem">
              <div class="left">{{ $t("regist.email") }}</div>
              <div class="center">
                <el-input
                  v-model="email"
                  :placeholder="$t('regist.email')"
                ></el-input>
              </div>
              <div class="right">{{ $t("regist.valid") }}</div>
            </div>

            <div class="boxitem">
              <div class="left">{{ $t("regist.gender") }}</div>
              <div class="center" style="justify-content: flex-start">
                <el-radio-group v-model="gender" style="color: #fff">
                  <el-radio :label="1">{{ $t("regist.male") }}</el-radio>
                  <el-radio :label="0">{{ $t("regist.female") }}</el-radio>
                </el-radio-group>
              </div>
              <div class="right"></div>
            </div>
            <div class="boxitem">
              <div class="left">{{ $t("regist.birth") }}</div>
              <div class="center" style="justify-content: flex-start">
                <el-date-picker
                  v-model="birthday"
                  type="date"
                  :placeholder="$t('regist.birth')"
                >
                </el-date-picker>
              </div>
              <div class="right"></div>
            </div>
          </div>
          <!-- 修改密码 -->
          <div class="flexbox usereditcontainer">
            <div class="flexbox commonbtn" @click="save">
              <div class="textbox">{{ $t("user.edit.save") }}</div>
              |
              <div class="iconbox">
                <i class="iconfont icon-tubiaozhizuomoban"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 修改头像插件 -->
  <my-upload
    field="img"
    @crop-success="cropSuccess"
    @crop-upload-success="cropUploadSuccess"
    @crop-upload-fail="cropUploadFail"
    v-model="show"
    :width="300"
    :height="300"
    url="/member/index/uploadImg"
    img-format="png"
    :noCircle="true"
  ></my-upload>

  <el-dialog :title="$t('user.edit.changepwd')" v-model="showpwd" width="800px">
    <div class="changepwdbox">
      <div class="boxitem">
        <el-input
          v-model="oldpassword"
          :placeholder="$t('user.edit.oldpwd')"
          maxlength="18"
          show-password
        ></el-input>
      </div>
      <div class="boxitem">
        <el-input
          v-model="password"
          :placeholder="$t('regist.pwd')"
          maxlength="18"
          show-password
        ></el-input>
      </div>
      <div class="boxitem">
        <el-input
          v-model="repassword"
          :placeholder="$t('regist.pwdc')"
          show-password
          maxlength="18"
        ></el-input>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showpwd = false">{{
          $t("user.edit.cancel")
        }}</el-button>
        <el-button type="primary" @click="change">{{
          $t("user.edit.save")
        }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElMessage } from 'element-plus'
import myUpload from "../../../../components/web/AvatarCrop/AvatarCrop.vue";
export default {
  data () {
    return {
      email: "",
      gender: 0,
      birthday: "",
      avatar: "",

      oldpassword: "",
      password: "",
      repassword: "",

      show: false,
      imgDataUrl: "",

      showpwd: false,
    }
  },
  computed: {

  },
  components: {
    'my-upload': myUpload
  },
  methods: {
    verify () {
      if (this.email.trim() == "") {
        ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.email'));
        return false;
      } else if (!/^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(this.email)) {
        ElMessage.error(this.$t('regist.email') + ' ' + this.$t('regist.form.fomate'));
        return false;
      } else if (this.birthday == "") {
        ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.birth'));
        return false;
      } return true;
    },
    save () {
      let birthday = new Date(this.birthday).getTime();
      if (this.verify()) {
        this.axios.post_form("/member/index/profile", {
          avatar: this.avatar,
          email: this.email,
          sex: this.gender,
          birthday,
        }).then(res => {
          res = res.data;
          if (res.status == 200) {
            ElMessage.success({
              message: this.$t('user.edit.modifiedsuccess'),
              type: 'success'
            });
            this.$store.dispatch("updateUserInfo");
          } else if (res.status == -1) {
            ElMessage.error(this.$t('user.edit.modifiedfail'));
            ElMessage.error(this.$t('regist.email') + ' ' + this.$t('regist.form.used'));
          } else if (res.status == -2) {
          } else {
            ElMessage.error(this.$t('user.edit.modifiedfail'));
          }
        })
      }
    },

    pwdverify () {
      if (this.oldpassword.trim() == "") {
        ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.email'));
        return false;
      } else if (this.password == "") {
        ElMessage.error(this.$t('regist.form.required') + ' ' + this.$t('regist.pwd'));
        return false;
      } else if (this.password.trim() != this.repassword.trim()) {
        ElMessage.error(this.$t('regist.form.pwdnomatch'));
        return false;
      } return true;
    },
    change () {
      if (this.pwdverify()) {
        this.axios.post_form("/member/index/changepwd", {
          oldpassword: this.oldpassword,
          newpassword: this.password,
        }).then(res => {
          res = res.data;
          if (res.status == 200) {
            this.showpwd = false;
            ElMessage.success({
              message: this.$t('user.edit.modifiedsuccess'),
              type: 'success'
            });
          } else if (res.status == 0) {
            ElMessage.error(this.$t('user.edit.oldpwderror'));
          } else {
            ElMessage.error("error");
          }
        })
      }

    },
    toggleShow () {
      this.show = !this.show;
    },
    cropSuccess (imgDataUrl, field) {
      this.imgDataUrl = imgDataUrl;
    },
    cropUploadSuccess (res, field) {
      if (res.status == 200) {
        this.avatar = "/" + res.data;
      }
    },
    cropUploadFail (status, field) {
    },

    initData () {
      this.email = this.$store.state.User.email;
      this.birthday = new Date(parseInt(this.$store.state.User.birthday));
      this.gender = parseInt(this.$store.state.User.gender);
      this.avatar = this.$store.state.User.avatar;
    },
  },
  created () {
    this.initData();
  },
  mounted () {

  },
}
</script>

<style lang="scss" scoped>
$boderColor: rgba(
  $color: #fff,
  $alpha: 0.2,
);
$border: 1px solid $boderColor;
.container {
  width: 1000px;
  padding: 100px 30px;
  .title {
    font-size: 20px;
    color: $themeColor;
    font-weight: bold;
  }
  .usercontent {
    justify-content: space-between;
    margin-top: 30px;
    font-size: 14px;
    .bodycontainer {
      width: 1000px;

      .formtitle {
        width: 100%;
        color: $themeColor;
        font-weight: bold;
        margin-top: 50px;
        margin-bottom: 30px;
        font-size: 20px;
      }
      .formbody {
        width: 800px;
      }
      .formcontent {
        width: 100%;
        word-wrap: break-word;
        font-size: 14px;
        color: #fff;
        text-align: center;
        padding: 14px;
      }
      .boxhead {
        display: flex;
        background: rgba(255, 255, 255, 0.2)
          url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpi/P//WRoDGmAB4ifYBF9gE/w3Kkiu4P/Bp/0/DbRTIAgQYAC2PiJjuOjlPgAAAABJRU5ErkJggg==)
          repeat-x left top;
        border: none;
        border-radius: 6px 6px 0px 0px;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        padding-left: 10px;
        border: 1px solid rgba(255, 255, 255, 0.5);
      }
      .boxbody {
        background: rgba(255, 255, 255, 0.05)
          url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpi/P//WRoDGmAB4ifYBF9gE/w3Kkiu4P/Bp/0/DbRTIAgQYAC2PiJjuOjlPgAAAABJRU5ErkJggg==)
          repeat-x left top;
        margin-bottom: 10px;
        .boxitem {
          display: flex;
          width: 100%;
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);
          border-left: 1px solid rgba(255, 255, 255, 0.5);
          border-right: 1px solid rgba(255, 255, 255, 0.5);
          .left {
            width: 20%;
            display: flex;
            padding: 10px;
            justify-content: flex-end;
            align-items: center;
            align-content: center;
            color: #fff;
            font-size: 14px;
            .terms {
              color: $linkColor;
            }
            .terms:hover {
              text-decoration: underline;
            }
          }
          .center {
            width: 60%;
            display: flex;
            padding: 5px;
            justify-content: center;
            align-items: center;
            align-content: center;
            color: #fff;
            font-size: 14px;
            border-left: 1px solid rgba(255, 255, 255, 0.5);
            border-right: 1px solid rgba(255, 255, 255, 0.5);
            .avatar {
              width: 100px;
              height: 100px;
              cursor: pointer;
            }
          }
          .right {
            width: 20%;
            display: flex;
            padding: 10px;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            color: #fff;
            font-size: 14px;
          }
        }
      }

      .registtitle {
        color: $themeColor;
        width: 100%;
        padding: 10px;
      }

      .formitemtitle {
        color: #fff;
        width: 100%;
        padding: 10px;
        text-align: center;
      }
      .formbuttoncontainer {
        width: 100%;
        margin-top: 50px;
        margin-bottom: 20px;
        justify-content: flex-end;
        .loginbtn {
          width: 100px;
          height: 32px;
          background-color: #e8cbed;
          padding: 0px 8px;
          border: 1px solid rgba(93, 69, 110, 0.6);
          border-radius: 4px;
          color: $themeColor;
          font-size: 14px;
          justify-content: space-between;
          cursor: pointer;
          .icon-tubiaozhizuomoban {
            font-size: 20px;
            color: $themeColor;
          }
        }
      }
    }
  }
}
.usereditcontainer {
  width: 800px;
  margin-bottom: 30px;
  justify-content: flex-end;
}
.commonbtn {
  width: auto;
  height: 32px;
  justify-content: space-between;
  color: $themeColor;
  background-color: #e8cbed;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  .textbox {
    padding: 0 5px;
  }
  .iconbox {
    padding: 0 5px;
    font-size: 20px;
  }
}

.fullradiusbox {
  border-radius: 6px;
}
.halfradiusbox {
  border-radius: 6px 6px 0px 0px;
}
.fullradiusbox,
.halfradiusbox {
  background: rgba(255, 255, 255, 0.2)
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpi/P//WRoDGmAB4ifYBF9gE/w3Kkiu4P/Bp/0/DbRTIAgQYAC2PiJjuOjlPgAAAABJRU5ErkJggg==)
    repeat-x left top;
  border: none;
  border-bottom: none;
  .title {
    justify-content: flex-start;
    padding: 8px 10px;
    font-size: 16px;
    i {
      margin-right: 10px;
      color: $themeColor;
      font-size: 16px;
    }
  }
}

.changepwdbox {
  .boxitem {
    margin-top: 30px;
  }
}
</style>