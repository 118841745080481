import axios from "../util/axios";
import i18n from "../i18n/index";

const Store = {
  state() {
    return {
      storeList: [],
      mine: [],
    };
  },
  mutations: {
    setStoreList(state, data) {
      state.storeList = data;
    },
    setStoreMine(state, data) {
      state.mine = data;
    },
  },
  actions: {
    getStoreList({ commit, state }) {
      let en = {};
      let zhcn = {};
      let zhtw = {};
      let $i18n = i18n.global;
      axios.post_form("/member/shop/gift", {}).then((res) => {
        res = res.data;
        for (const type of res.data) {
          en[`type${type.id}`] = type.classifye;
          zhcn[`type${type.id}`] = type.classifyc;
          zhtw[`type${type.id}`] = type.classifyf;
          for (const gift of type.gift) {
            en[`type${type.id}gift${gift.id}`] = gift.titlee;
            zhcn[`type${type.id}gift${gift.id}`] = gift.titlec;
            zhtw[`type${type.id}gift${gift.id}`] = gift.titlef;
			en[`type${type.id}giftinfo${gift.id}`] = gift.infoe;
            zhcn[`type${type.id}giftinfo${gift.id}`] = gift.infoc;
            zhtw[`type${type.id}giftinfo${gift.id}`] = gift.infof;
          }
        }
        $i18n.setLocaleMessage(
          "en",
          Object.assign($i18n.messages.en, { gift: en })
        );
        $i18n.setLocaleMessage(
          "zhcn",
          Object.assign($i18n.messages.zhcn, { gift: zhcn })
        );
        $i18n.setLocaleMessage(
          "zhtw",
          Object.assign($i18n.messages.zhtw, { gift: zhtw })
        );
        commit("setStoreList", res.data);
      });
    },
    getStoreMine({ commit, state }) {
      axios.post_form("/member/shop/received_gift", {}).then((res) => {
        res = res.data;
        commit("setStoreMine", res.data);
      });
    },
  },
};

export default Store;
