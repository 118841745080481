<template>
  <!-- 聊天内容 -->
  <div
    :id="'chatwindow' + data.uid"
    class="fixedchatwindow"
    :style="{ bottom: curItem.show ? '370px' : '-10px' }"
  >
    <!-- 聊天窗口 -->
    <div class="flexbox-c fixedchatcontent">
      <div class="flexbox fixedchatcontentbody">
        <!-- 头部 -->
        <div
          class="flexbox chatheader"
          :style="{ height: curItem.show ? '40px' : '20px' }"
          @click="tooglewindow(curItem.uid)"
        >
          <div class="userheader" v-show="curItem.show" @click.stop="todetail">
            <img :src="imgurl + curItem.avatar" />
            <i
              v-if="curItem.vip != 0 && curItem.show"
              class="iconfont icon-V vip"
              :style="{ color: curItem.vip == 1 ? 'silver' : 'gold' }"
            ></i>
          </div>
          <div class="flexbox username">
            <span
              class="online"
              :style="{
                backgroundColor: curItem.is_online == 1 ? '#9f9' : '#999',
              }"
            ></span>

            {{ curItem.username }}
          </div>
          <div class="flexbox optionbox">
            <i
              class="close toogle"
              :class="{
                'el-icon-arrow-down': curItem.show,
                'el-icon-arrow-up': !curItem.show,
              }"
              @click.stop="tooglewindow(curItem.uid)"
            ></i>
            <i
              class="el-icon-close close"
              @click.stop="closewindow(curItem.uid)"
            ></i>
          </div>
        </div>
        <!-- 聊天内容 -->
        <el-scrollbar noresize @scroll="chatwindowscrollevent">
          <div class="flexbox history" @click="queryHistory">
            {{ $t("chat.queryhistory") }}
          </div>

          <div class="flexbox chatbody" v-if="curItem">
            <chat-content
              v-for="(item, index) of curItem.list"
              :key="index + ' ' + item.content"
              :data="item"
            />
          </div>
        </el-scrollbar>

        <!-- 输入框 -->
        <div class="flexbox-s chatinputbox" v-if="true">
          <el-input v-model="chatcontent" @keydown.enter="send"></el-input>
          <div class="flexbox opratebox">
		    <!-- 礼物按钮 -->
            <div class="flexbox iconbox" @click="gift.showgift = true">
              <i class="iconfont icon-liwu" style="font-size: 20px"></i>
            </div>
            <!-- 翻译按钮 -->
            <div class="flexbox iconbox" @click.stop="showtrans = true">
              <i class="iconfont icon-zhuanhuan" style="font-size: 20px"></i>
            </div>
            <!-- 图片按钮 -->
            <div class="flexbox iconbox" @click="$refs.fileinput.click()">
              <i class="iconfont icon-tupian" style="font-size: 20px"></i>
            </div>
            <!-- 图片文件 -->
            <input
              type="file"
              accept="image/*"
              v-show="false"
              @change="fileChange"
              ref="fileinput"
            />
            <!-- 表情按钮 -->
            <div class="flexbox iconbox" @click.stop="showexpress = true">
              <i
                class="iconfont icon-biaoqing"
                style="color: #eedd55; font-size: 20px"
              ></i>
            </div>
            <!-- 发送按钮 -->
            <div class="flexbox sendbtn" @click="send">
              {{ $t("global.send") }}
            </div>
          </div>
          <!-- 表情框 -->
          <div class="flexbox expressbox">
            <img
              class="img"
              v-for="(item, index) of expressionStrList"
              :key="index"
              :src="expressionSource[item]"
              @click.stop="pushexp(item)"
              v-show="showexpress"
            />
          </div>
          <!-- 翻译框 -->
          <div class="flexbox transbox" v-show="showtrans" @click.stop>
            <Trans :id="curItem.uid" height="100px" />
          </div>
		  <!-- 礼物面板 -->
		  <el-dialog :title="$t('mine.giftbox')" v-model="gift.showgift">
			<div class="giftcontainer">
			  <van-tabs v-model:active="gift.active" >
				<van-tab
				  v-for="(type, index) of giftlist"
				  :key="index"
				  :title="$t(`gift.type${type.id}`)"
				>
				  <!-- 礼物列表 -->
				  <div class="flexbox giftitembox" v-if="giftlist.length > 0">
					<div
					  class="flexbox giftitem"
					  v-for="(item, index) of type.gift"
					  :key="index"
					>
					  <div
						class="img"
						:style="{ backgroundImage: `url(${imgurl + item.path})` }"
					  ></div>
					  <div class="flexbox giftname">
						{{ $t(`gift.type${type.id}gift${item.id}`) }}
					  </div>
					  <div class="flexbox giftprice">
						{{ item.money }} {{ $t("store.credits") }}
					  </div>
					  <div class="flexbox sendbtn" @click="sendlaunch(item)">
						{{ $t("store.sendgift") }}
					  </div>
					</div>
				  </div>
				</van-tab>
			  </van-tabs>
			</div>
		  </el-dialog>

        </div>
      </div>
    </div>
  </div>
  <div v-show="false">{{ curItemlist }}</div>
  
  <!-- 赠送礼物弹窗 -->
    <el-dialog :title="$t('store.sendgift')" v-model="sendwindowvisibility">
      <el-form :model="form">
	    <div class="details">
			<div class="dleft">
				<img :src="form.pic" alt="" />
			</div>
			<div class="dright">
				<div class="gtitle">{{ $t("globalgift.giftname") }}：{{ form.gtitle }}</div>
				<p class="ginfo">{{ $t("globalgift.giftinfo") }}：{{ form.ginfo }}</p>
				<p class="gprice">{{ $t("globalgift.giftprice") }}：{{ form.gprice}} {{ $t("store.credits") }}</p>
				<p class="ginfo">{{ $t("globalgift.sendto") }}：{{ form.name }}</p>
				<p class="ginfo">
					<el-button @click="sendwindowvisibility = false">
						{{ $t("global.cancel") }}
					</el-button>
					<el-button type="primary" @click="sendGift">
						{{ $t("global.send") }}
					</el-button>
				</p>
				
			</div>
		</div>
      </el-form>
    </el-dialog>
</template>

<script>
import ChatContent from "../ChatContent.vue"
import Expression from "../Expression.vue"
import Trans from "../Trans.vue"
import { expressionSource, expressionStrList } from "../../util/expression"
export default {
  props: ['data'],
  data () {
    return {
      showchatwindow: false,
      // 输入内容
      chatcontent: '',
      // 表情数据
      expressionStrList,
      expressionSource,
      showexpress: false,

      lastScrollPos: 0,
      scrollAffix: false,

      showtrans: false,
	  gift: {
        showgift: false,
        active: '',
        showsend: false,
        sendnum: 1,
        sendid: 0,
      },
	  
	  giftid: '',
	  sendwindowvisibility: false,
      form: {
	    pic: '',
		gtitle:'',
		ginfo:'',
		gprice:'',
        name: '',
        message: ''
      },
	  
    }
  },
  components: {
    ChatContent,
    Expression,
    Trans,
  },
  computed: {
    curItem () {
      return this.data;
    },
    curItemlist () {
      if (!this.scrollAffix) {
        setTimeout(() => {
          let view_container = document.querySelector('#chatwindow' + this.data.uid + ' .el-scrollbar__wrap');
          if (view_container) {
            view_container.scrollTo(0, view_container.scrollHeight - view_container.clientHeight);
          }
        })
      }
      return this.data.list.length;
    },
	// 礼物列表
    giftlist () {
      return this.$store.state.Store.storeList;
    },
  },
  methods: {
    tooglewindow (id) {
      this.$store.commit('toogleUserFromChatWindowList', id);
    },
    closewindow (id) {
      this.$store.commit('deleteUserFromChatWindowList', id);
    },
    // 发送图片
    fileChange (e) {
	  if(this.curItem.uid != 2 && this.$store.state.User.vip < 2){
		this.$message.success(this.$t('tips.nopermission')+', '+this.$t('tips.needupgrade')+this.$t('upgrade.gold'));
		setTimeout(() => {
			this.$router.push('/w/home/upgrade')
		},1500);
		return;
	  }
      e.preventDefault();
      if (this.loading !== 1) {
        let files = e.target.files || e.dataTransfer.files;
        this.sourceImgUrl = files[0];
        this.axios.post_form("/member/index/uploads", {
          file: this.sourceImgUrl
        }).then(res => {
          res = res.data;
          if (res.status == 200) {
            this.$store.dispatch('wssend', {
              opt: 1,
              uid: this.$store.state.User.id,
              address: this.curItem.uid,
              img: res.data.url,
              type: 'img',
              origin: true,
            })
          }
        })
      }
    },
    // 插入表情
    pushexp (item) {
      this.chatcontent += "[" + item + "]";
    },
    // 发送聊天信息
    send () {
      if (this.chatcontent.trim() == "") {
        this.$message.error(this.$t('tips.contentcantempty'));
        return false;
      }
      this.$store.dispatch('wssend', {
        opt: 1,
        uid: this.$store.state.User.id,
        address: this.curItem.uid,
        txt: this.chatcontent,
        type: 'text',
        origin: true,
      })
      this.chatcontent = "";
    },
    queryHistory () {
      this.$store.dispatch('wssend', {
        opt: 3,
        uid: this.$store.state.User.id,
        address: this.curItem.uid,
        origin: true,
        create_time: this.curItem.list.length > 0 ? this.curItem.list[0].create_time : Math.floor(new Date().getTime / 1000)
      })
    },
    chatwindowscrollevent () {
      let view_container = document.querySelector('#chatwindow' + this.data.uid + ' .el-scrollbar__wrap');
      if (view_container.scrollHeight - 20 < view_container.scrollTop + view_container.clientHeight) {
        this.scrollAffix = false;
      } else {
        this.scrollAffix = true;
      }
    },
    todetail () {
      this.$router.push('/w/home/user/detail/' + this.data.uid);
    },
	sendlaunch (item) {
	  console.log(item)
      this.giftid = item.id;
      this.sendwindowvisibility = true;
	  this.form.pic = this.imgurl + item.path
	  this.form.gtitle = this.$t(`gift.type${item.classify}gift${item.id}`)
	  this.form.ginfo = this.$t(`gift.type${item.classify}giftinfo${item.id}`)
	  this.form.name = this.curItem.username
	  this.form.gprice = item.money;
    },
	
	sendGift () {
	  this.axios.post_form("/member/gift/gift_giving", {
        username: this.form.name,
        message: this.form.message,
        number: 1,
        giftid: this.giftid,
      }).then(res => {
	    this.sendwindowvisibility = false;
        res = res.data;
        if (res.status == 200) {
          this.$message.success({
            message: this.$t('tips.sendsuccess'),
            type: 'success'
          });
		  this.gift.showgift = false;
        } else if (res.status == -1) {
          this.$message.error(this.$t('tips.usernotexist'));
        } else if (res.status == -2) {
          this.$message.error(res.msg);
        } else {
          this.$message.error(this.$t('tips.sendfail'));
        }
      })
      
    },
  },
  mounted () {
    document.querySelector('#chatwindow' + this.data.uid).onclick = () => {
      this.showexpress = false;
      this.showtrans = false;
    }
  },
  beforeUnmount () {
    document.querySelector('#chatwindow' + this.data.uid).onclick = null;
  },
  

}
</script>

<style lang="scss" scoped>
$boderColor: rgba(
  $color: #fff,
  $alpha: 0.3,
);
$border: 1px solid $boderColor;
.fixedchatwindow {
  position: relative;
  width: 290px;
  height: 400px;
  background-color: #f1f2f3;
  overflow: hidden;
  transition: bottom 0.1s;
  margin-right: 10px;
  border-radius: 4px 4px 0 0;
}
.fixedchatcontent {
  width: 100%;
  height: 400px;
  .fixedchatcontentbody {
    width: 100%;
    height: 400px;
    flex-direction: column;
    flex-wrap: nowrap;
    .chatheader {
      height: 40px;
      border-bottom: $border;
      padding: 0 15px;
      flex-wrap: nowrap;
      flex-shrink: 0;
      cursor: pointer;
      .userheader {
        position: relative;
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        border-radius: 4px;
        overflow: hidden;
        img {
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
        .vip {
          position: absolute;
          right: 0px;
          bottom: 0px;
          color: gold;
          font-size: 16px;
          cursor: pointer;
        }
      }
      .username {
        font-size: 12px;
        font-weight: bold;
        color: #666;
        justify-content: flex-start;
        padding-left: 10px;
        cursor: pointer;
        .online {
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
      .optionbox {
        width: auto;
        justify-content: flex-end;
        flex-wrap: nowrap;
        .close {
          cursor: pointer;
          margin-left: 10px;
        }
        .toogle {
          display: none;
        }
        .arrow {
          padding-left: 100px;
        }
      }
    }
    .chatheader:hover {
      .toogle {
        display: inline-block;
      }
    }
    .history {
      font-size: 12px;
      color: #999;
      cursor: pointer;
    }
    .chatbody {
      width: 290px;
      height: 100%;
      overflow: auto;
    }
    .chatinputbox {
      height: auto;
      flex-shrink: 0;
      border-top: $border;
      .opratebox {
        justify-content: flex-end;
        padding: 5px;
        .iconbox {
          width: 20px;
          height: 20px;
          cursor: pointer;
          margin-right: 10px;
        }
        .sendbtn {
          width: auto;
          height: 20px;
          padding: 0 5px;
          background-color: $themeColor;
          border-radius: 3px;
          border: 1px solid #333;
          color: #fff;
          cursor: pointer;
          font-size: 12px;
        }
      }
    }
  }
  .expressbox {
    width: 100%;
    height: auto;
    background-color: #fff;
    justify-content: flex-start;
    align-content: flex-start;
    overflow: hidden;
    img {
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }
}
.empty {
  color: #333;
  font-size: 12px;
  margin-top: 100px;
}
.el-dialog__body{
  padding:0;
}
.giftcontainer {
  width: 100%;
  height: 500px;
  overflow-y:scroll;
  .giftitembox {
    justify-content: flex-start;
    color: $fontColor;
    padding: 30px;

    .giftitem {
      width: 15%;
      height: auto;
      align-content: space-between;
      font-size: 12px;
      margin: 2.5%;
      .img {
        width: 100%;
        padding: 50%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
      .giftname {
        height: 20px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .giftprice {
        height: 20px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .sendbtn {
        padding: 0 5px;
        height: 20px;
        border: 1px solid #ff4a53;
        color: #ff4a53;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }
}
.details{
	display: flex;
	flex-wrap: wrap;
	.dleft{
		width:40%;
		padding: 0 0 20px 20px;
		img{
			width:100%;
		}
	}
	.dright{
		width: 55%;
		margin-left: 5%;
	}
}
</style>