<template>
  <router-view></router-view>
</template>

<script>
import { ElLoading } from 'element-plus';
export default {
  methods: {
    initData () {
      let loadingInstance = ElLoading.service({ fullscreen: true });
      this.axios.post_form("/member/index/initdata", {}).then(res => {
        res = res.data;
        loadingInstance.close();
        if (res.status == 200) {
          this.$store.commit("setAuthority", res.data.authority);
          this.$store.dispatch("updateUserAuthority");
          this.$store.commit("setService", res.data.service);
		  localStorage.setItem("currentVip", res.data.vip);
        }
      })
    },
  },
  created () {
    this.initData();
    this.$store.dispatch('getPayPackage');
    this.$store.dispatch('getUpgradePackages');
  }
}
</script>
