import axios from "../util/axios";
export default {
  state() {
    return {
      packages: [],
    };
  },
  mutations: {
    setPayPackages(state, data) {
      state.packages = data;
    },
  },
  actions: {
    getPayPackage({ commit }) {
      axios.post_form("/member/recharge/package", {}).then((res) => {
        res = res.data;
        commit("setPayPackages", res.data);
      });
    },
  },
};
