<template>
    <div class="invest_area">
        <div class="invest_area_top">
            <div class="invest_area_top_icon">
                <div @click="back">取消</div>
            </div>
            <span>{{ $t("user.setup.signature") }}</span>
            <div class="invest_area_top_save" @click="save">
                <el-button type="primary" style="background:#ff4a53;border:1px solid #ff4a53"> {{$i18n.t('user.edit.save')}}</el-button>
            </div>
        </div>
    </div>
    <div class="menuList">
        <el-input
            type="textarea"
            maxlength="30"
            v-model="signature"
            show-word-limit>
        </el-input>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    data () {
        return {
            signature: this.$store.state.User.signature,
        }
    },
    computed: {
        
    },
    created () {
        this.initData();
    },
    methods:{
        back(){
            if (window.history.length <= 1) {
                this.$router.push({path:'/'})
                return false
            } else {
                this.$router.back()  
            }
        },
        initData () {
            this.gender = parseInt(this.$store.state.User.gender);
        },
        save(){
            this.axios.post_form("/member/index/changesignature", {
                signature: this.signature,
            }).then(res => {
                res = res.data;
                if (res.status == 200) {
                    ElMessage.success({
                        message: this.$t('user.edit.modifiedsuccess'),
                        type: 'success'
                    });
                    this.$store.dispatch("updateUserInfo");
                } else if (res.status == 0) {
                    ElMessage.error(this.$t('user.edit.modifiedfail'));
                } else {
                    ElMessage.error(this.$t('user.edit.modifiedfail'));
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.invest_area{
    width: 100%;
    height: 20px;
    padding: 20px;
    color: #303133;
    position: relative;
}
.invest_area_top_icon{
    position: absolute;
    height: 20px;
    left: 20px;
    top: 20px;
    display: flex;
    align-items: center;
    color: #465DFE;
}
.invest_area_top{
    text-align: center;
    font-size: 18px;
    height: 20px;
    line-height:20px;
}
.invest_area_top_save{
    position: absolute;
    height: 20px;
    right: 20px;
    top: 20px;
    display: flex;
    align-items: center;
}
.el-form-item-span{
    margin-right: 16px;
}
.menuList {
    padding: 20px 0 100px;
}
.menuList_titile{
    color: #303133;
    font-size: 14px;
    padding: 15px;
    background-color: #F5F7FA;
}
.menuItem {
    display: flex;
    justify-content: space-between;
    margin: 0px 15px;
    padding: 15px 0px;
    align-items: center;
    border-bottom: 1px solid #EDEFF2;
}
.menuItem .left {
    display: flex;
    align-items: center;
    color: #909399;
    font-size: 14px;
}
.menuItem .left .ico {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    border: 1px solid #f1f1f1;
}
.langtxt{
    display: inline-block;
    float: right;
    margin-right: 10px;
    color: #000;
}
.logout{
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 100;
    background: #fff;
    height: 60px;
    padding:0px 16px;
}
.draweritem{
    text-align: center;
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #f4f4f4;
}
.el-drawer.btt{
    height: auto !important;
}
</style>