import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n/index";
import Vant from "vant";
import { ActionSheet } from 'vant';
import "vant/lib/index.css";
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import axios from "./util/axios";
import util from "./util/util";
import { imgurl } from "./common/project.config";
import "./assets/css/common.css";
import "./assets/css/iconfont.css";

import "./mock/index";

var app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(Vant)
  .use(ActionSheet)
  .use(ElementPlus);

app.config.globalProperties.axios = axios;

app.config.globalProperties.util = util;
app.config.globalProperties.imgurl = imgurl;
app.mount("#app");
