<template>
  <div class="headBox">
    <div class="headBox_top">
      <router-link to="/m/home/message">
        <img src="~@/assets/images/phone/notification.png" alt="" />
      </router-link>
      <router-link to="/m/home/my/Setup/index">
        <img src="~@/assets/images/phone/mine_set.png" alt="" />
      </router-link>
    </div>
    <!-- 用户信息 -->
    <div class="userInfo">
      <router-link :to="`/m/home/my/newsfeed/${myId}`" class="headImg">
        <img :src="imgurl + avatar" />
      </router-link>
      <div class="headName">
        <h3>{{ username }}</h3>
        <div class="age">
          <img src="~@/assets/images/my/my_ico_m.png" v-if="gender == 1" />
          <img src="~@/assets/images/my/my_ico_w.png" v-else />
          <span>{{
            new Date().getFullYear() -
            new Date(parseInt(birthday)).getFullYear()
          }}</span>
        </div>
      </div>
    </div>
    <!-- 会员 -->
    <div class="vipBox">
      <div>
        {{ $t("mine.membership") }} ：{{
          vip == 2
            ? $t("mine.goldcard")
            : vip == 1
            ? $t("mine.silvercard")
            : $t("mine.free")
        }}
      </div>
      <div>
        {{ $t("mine.expires") }}：{{
          vip == 1
            ? util.getDateByTimeStamp(parseInt(silverDeadline))
            : vip == 2
            ? util.getDateByTimeStamp(parseInt(goldDeadline))
            : $t("mine.permanent")
        }}
      </div>
    </div>
  </div>
  <div class="headShow">
    <!-- <div class="item">
        <p class="val red">0</p>
        <p class="title">访客</p>
      </div> -->
    <div class="item">
      <router-link :to="'/m/home/my/newsfeed/' + myId">
        <p class="val">{{ newfeedcount || 0 }}</p>
        <p class="title">{{ $t("mine.newsfeeds") }}</p>
      </router-link>
    </div>
    <div class="item">
      <p class="val">{{ amount || "0.00" }}</p>
      <p class="title">{{ $t("mine.balance") }}</p>
    </div>
  </div>

  <div class="menuList">
    <router-link class="terms" to="/m/home/Invest/index">
      <div class="menuItem">
        <div class="left">
          <img class="ico" src="~@/assets/images/my/my_ico1.png" alt="" />
          {{ $t("mine.recharge") }}
        </div>
        <van-icon name="arrow" />
      </div>
    </router-link>
    <router-link class="terms" to="/m/home/Upgrade/index">
      <div class="menuItem">
        <div class="left">
          <img class="ico" src="~@/assets/images/my/my_ico2.png" alt="" />
          {{ $t("mine.upgrade") }}
        </div>
        <van-icon name="arrow" />
      </div>
    </router-link>
    <router-link class="menuItem" to="/m/home/gift/list">
      <div class="left">
        <img class="ico" src="~@/assets/images/my/my_ico3.png" alt="" />
        {{ $t("mine.gift") }}
      </div>
      <van-icon name="arrow" />
    </router-link>
	<router-link class="menuItem" to="/m/home/gift/listbox">
      <div class="left">
        <img class="ico" src="~@/assets/images/phone/account_up.png" alt="" />
        {{ $t("mine.giftbox") }}
      </div>
      <van-icon name="arrow" />
    </router-link>
    <router-link
      class="menuItem"
      :to="'/m/home/my/newsfeed/' + myId + '?type=2'"
    >
      <div class="left">
        <img class="ico" src="~@/assets/images/my/my_ico4.png" alt="" />
        {{ $t("mine.albums") }}
      </div>
      <van-icon name="arrow" />
    </router-link>
    <!-- <div class="menuItem">
      <div class="left">
        <img class="ico" src="~@/assets/images/my/my_ico5.png" alt="" />
        {{ $t("mine.video") }}
      </div>
      <van-icon name="arrow" />
    </div> -->
    <router-link class="menuItem" to="/m/home/chat/service">
      <div class="left">
        <img class="ico" src="~@/assets/images/my/my_ico6.png" alt="" />
        {{ $t("mine.customerservice") }}
      </div>
      <van-icon name="arrow" />
    </router-link>
  </div>
  <Menu />
</template>

<script>
import Menu from "../../../components/mobile/Menu.vue"
export default {
  data () {
    return {};
  },
  components: {
    Menu,
  },
  computed: {
    myId () {
      return this.$store.state.User.id;
    },
    username () {
      return this.$store.state.User.username;
    },
    avatar () {
      return this.$store.state.User.avatar;
    },
    gender () {
      return this.$store.state.User.gender;
    },
    birthday () {
      return this.$store.state.User.birthday;
    },
    amount () {
      return this.$store.state.User.amount;
    },
    newfeedcount () {
      return this.$store.state.User.newfeedcount;
    },
    vip () {
      return this.$store.state.User.vip;
    },
    silverDeadline () {
      return this.$store.state.User.silver_deadline;
    },
    goldDeadline () {
      return this.$store.state.User.gold_deadline;
    },
  },
  methods: {},
  created () {
    // this.$store.dispatch('getPayPackage');
    // this.$store.dispatch('getUpgradePackages');
    this.$store.dispatch('updateUserInfo');
  },
};
</script>

<style scoped>
.headBox {
  width: 100%;
  height: 17rem;
  position: relative;
  /* z-index: -1; */
  overflow: hidden;
  padding: 20px;
}
.headBox::after {
  content: "";
  width: 136%;
  height: 17rem;
  position: absolute;
  left: -18%;
  top: 0;
  z-index: -1;
  border-radius: 0 0 50% 50%;
  background-image: linear-gradient(to bottom right, #ff9a76, #fe4f4f);
}
.headBox_top {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
}
.headBox_top img {
  height: 22px;
  width: 22px;
}
.userInfo {
  display: flex;
  padding-top: 2rem;
}
.headImg {
  width: 5.3rem;
  height: 5.3rem;
  border-radius: 50%;
  border: 3px solid #fff;
  overflow: hidden;
}
.headImg img {
  width: 100%;
  height: 100%;
}
.headName {
  padding-left: 20px;
}
.headName h3 {
  color: #fff;
  font-size: 19px;
  letter-spacing: 1px;
  margin: 0;
  padding: 13px 0;
}
.headName .age {
  display: flex;
  height: 1.6rem;
  width: 4rem;
  border-radius: 20px;
  background: #fff;
  padding: 0 1rem;
  color: #455dfb;
  align-items: center;
  justify-content: center;
}
.headName .age img {
  width: 15px;
  height: 15px;
  margin-right: 6px;
}
.headName .age span {
  padding-bottom: 2px;
}
.vipBox {
  display: flex;
  color: #fff;
  justify-content: space-between;
  padding: 15px 10px;
  border: 1px solid #ddd;
  margin: 0 10px;
  margin-top: 18px;
  border-radius: 10px;
}
.headShow {
  background: #fff;
  border-radius: 15px;
  display: flex;
  justify-content: space-evenly;
  /* border: 1px solid #000; */
  margin: 0 20px;
  margin-top: -50px;
  box-shadow: 0px 0px 4px #999;
  z-index: 1;
  position: relative;
}
.headShow .item {
  text-align: center;
  width: 33%;
}
.headShow .item p {
  text-align: center;
}
.headShow .item .val {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
  color: #333;
}
.headShow .item .val.red {
  color: red;
}
.headShow .item .title {
  color: #999;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 22px;
}
.menuList {
  padding: 20px 0 100px;
}
.menuItem {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  font-size: 14px;
  align-items: center;
  color: #000;
}
.menuItem .left {
  display: flex;
  align-items: center;
}
.menuItem .left .ico {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 13px;
}
</style>