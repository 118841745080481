import axios from "../util/axios";
export default {
  state() {
    return {
	  userList: [],
      onlineList: [],
      latestList: [],
      mylike: [],
      likeme: [],
      mutualike: [],
    };
  },
  mutations: {
	setUserList(state, list) {
      state.userList = list;
    },
    setOnlineList(state, list) {
      state.onlineList = list;
    },
    setLatestList(state, list) {
      state.latestList = list;
    },
    setMylike(state, list) {
      state.mylike = list;
    },
    setLikeMe(state, list) {
      state.likeme = list;
    },
    setMutuaLike(state, list) {
      state.mutualike = list;
    },
  },
  actions: {
	getUserList({ commit },param) {
      axios.post_form("/member/index/userlist", param).then((res) => {
        res = res.data;
        if (res.status == 200) {
          commit("setUserList", res.data.data);
        }
      });
    },
    getOnlineList({ commit }) {
      axios.post_form("/member/index/onlinelist", {}).then((res) => {
        res = res.data;
        if (res.status == 200) {
          commit("setOnlineList", res.data.data);
        }
      });
    },
    getLatestList({ commit }) {
      axios.post_form("/member/index/new_opposite_sex", {}).then((res) => {
        res = res.data;
        if (res.status == 200) {
          commit("setLatestList", res.data);
        }
      });
    },
    getMyLike({ commit }) {
      axios.post_form("/member/like/i_like", {}).then((res) => {
        res = res.data;
        if (res.status == 200) {
          commit("setMylike", res.data);
        }
      });
    },
    getLikeMe({ commit }) {
      axios.post_form("/member/like/love_me", {}).then((res) => {
        res = res.data;
        if (res.status == 200) {
          commit("setLikeMe", res.data);
        }
      });
    },
    getMutua({ commit }) {
      axios.post_form("/member/like/mutual_like", {}).then((res) => {
        res = res.data;
        if (res.status == 200) {
          commit("setMutuaLike", res.data);
        }
      });
    },
  },
};
