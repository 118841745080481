<template>
  <div class="globalchatwindow">
    <!-- 聊天列表 -->
    <div
      class="chatlistfixedwindow"
      :style="{ bottom: position.bottom + 'px' }"
    >
      <!-- header -->
      <div
        class="flexbox chatlistfixedwindowheader"
        @click="toogleList"
        :style="{ cursor: 'pointer' }"
      >
        <div></div>
        <div>{{ $t("chat.chatlist") }}[{{ chatListLength }}]</div>
        <div>
          <i
            class="el-icon-arrow-down close"
            v-show="showclose"
            @click.stop="hideList"
          ></i>
        </div>
      </div>
      <!-- body -->
      <div class="flexbox-c chatlistfixedwindowchatlist">
        <div class="searchbox">
          <input
            class="searchinput"
            type="text"
            v-model="searchcontent"
            :placeholder="$t('global.search')"
          />
        </div>
        <div class="empty" v-if="chatContentList.length <= 0">
          {{ $t("empty.chat") }}
        </div>
        <el-scrollbar noresize v-else>
          <div class="flexbox">
            <div
              class="flexbox chatlistitem"
              v-for="(item, index) of chatContentList"
              :key="index"
              @click="addToGlobalChatList(item)"
              :style="{
                backgroundColor:
                  curItem.uid == item.uid
                    ? 'rgba(255,255,255,1)'
                    : 'rgba(255,255,255,0.2)',
              }"
            >
              <el-badge
                :value="item.count"
                class="item"
                type="danger"
                :hidden="item.count <= 0"
              >
                <router-link
                  class="userheader"
                  :to="`/w/home/user/detail/${item.uid}`"
                  @click.stop
                >
                  <img :src="imgurl + item.avatar" />
                  <i
                    v-if="item.vip != 0"
                    class="iconfont icon-V vip"
                    :style="{ color: item.vip == 1 ? 'silver' : 'gold' }"
                  ></i>
                </router-link>
              </el-badge>
              <div class="flexbox chatcontent">
                <div class="name">
                  <span
                    class="online"
                    :style="{
                      backgroundColor: item.is_online == 1 ? '#9f9' : '#999',
                    }"
                  ></span>

                  {{ item.username }}
                </div>
                <div class="time">
                  {{
                    util.getTrendtimeByDate(new Date(item.create_time * 1000))
                  }}
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>

    <!-- 聊天内容 -->
    <ChatWindow
      v-for="item in visibleGlobalChatWindowList"
      :key="item.uid"
      :data="item"
    />
    <HiddenChatWindow
      :list="invisibleGlobalChatWindowList"
      v-if="invisibleGlobalChatWindowList.length > 0"
    />
  </div>
</template>

<script>
import ChatWindow from "./ChatWindow.vue"
import HiddenChatWindow from './HiddenChatWindow.vue'
export default {
  data () {
    return {
      searchcontent: '',
      position: {
        bottom: 0,
        right: 0,
      },
      showclose: false,

      chatwindownum: 2,
      // 聊天窗口宽度
      chatwindowwidth: 300,
      // 屏幕宽度
      windowWidth: 1440,
	  myvip:0,
    }
  },
  components: {
    ChatWindow,
    HiddenChatWindow,
  },
  computed: {
    chatContentList () {
      let reg = new RegExp(`(${this.searchcontent})`, 'g');
      return this.$store.state.Chat.chatList.filter((item) => {
        return reg.test(item.username);
      });
    },
    curItem () {
      return this.$store.state.Chat.curUser;
    },
    chatListLength () {
      return this.$store.state.Chat.chatList.length;
    },

    visibleGlobalChatWindowList () {
      const globalChatList = this.$store.state.Chat.globalChatList;
      const chatlist = [];
      for (let i = 0; i < this.chatwindownum; i++) {
        if (globalChatList[i]) {
          chatlist.push(globalChatList[i])
        }
      }
      return chatlist;
    },
    invisibleGlobalChatWindowList () {
      const globalChatList = this.$store.state.Chat.globalChatList;
      const chatlist = [];
      for (let i = this.chatwindownum; i < globalChatList.length; i++) {
        if (globalChatList[i]) {
          chatlist.push(globalChatList[i])
        }
      }
      return chatlist;
    },
  },
  methods: {
    toogleList () {
      if (this.showclose) {
        this.hideList();
      } else {
        this.showList();
      }
    },
    showList () {
      this.position.bottom = 370;
      this.showclose = true;
    },
    hideList () {
      this.position.bottom = 0;
      this.showclose = false;
    },
    addToGlobalChatList (data) {
	  this.myvip = localStorage.getItem("currentVip");
	  if(this.myvip == 0){
		this.$message.success(this.$t('tips.nopermission')+', '+this.$t('tips.needupgrade')+'~');
		setTimeout(() => {
		    this.hideList();
			this.$router.push('/w/home/upgrade')
		},1500);
		return;
	  }
      this.$store.commit('addUserToGlobalChatListFromChatList', data);
      this.$store.dispatch('wssend', {
        opt: 0,
        uid: this.$store.state.User.id,
        address: data.uid,
        origin: true,
      });
      this.$store.commit('clearcount', data.uid);
    },
    init () {
      this.chatwindownum = Math.floor((this.windowWidth - 300) / this.chatwindowwidth);
      this.$store.commit('setGlobalWindowCount', this.chatwindownum);
    },
  },

  created () {
    this.windowWidth = document.documentElement.clientWidth;
    this.init();
    window.onresize = (function () {
      let timeout = null;
      return function () {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          this.windowWidth = document.documentElement.clientWidth;
          this.init();
        }, 300);
      }.bind(this);
    }).call(this);
	
	//查看是否有权限
    //this.axios.post_form("/member/index/info", {}).then((res) => {
	//  res = res.data;
	//  if (res.status == 200) {
	//	this.myvip = res.data.vip;
	//  }
    //});
  },
  
  
  
}
</script>

<style lang="scss" scoped>
$boderColor: rgba(
  $color: #fff,
  $alpha: 0.3,
);
$border: 1px solid $boderColor;

.globalchatwindow {
  max-width: 100%;
  height: 30px;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row-reverse;
  z-index: 2;
}

.chatlistfixedwindow {
  position: relative;
  width: 300px;
  height: 400px;
  transition: bottom 0.1s;
  font-size: 12px;
  background-color: #eee;
  border-radius: 4px 4px 0 0;
  .chatlistfixedwindowheader {
    height: 30px;
    justify-content: space-between;
    padding: 0 10px;
    .close {
      cursor: pointer;
    }
  }
  .chatlistfixedwindowchatlist {
    flex-direction: column;
    width: 300px;
    height: 370px;
    border-right: $border;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    .searchbox {
      width: 100%;
      height: 22px;
      border-bottom: 1px solid #999;
      border-top: 1px solid #999;
      flex-shrink: 0;
      .searchinput {
        border: none;
        outline: none;
        font-size: 12px;
        width: 100%;
        height: 100%;
        padding: 10px;
      }
    }
    .chatlistitem {
      width: 100%;
      height: 40px;
      flex-wrap: nowrap;
      cursor: pointer;
      justify-content: flex-start;
      padding: 0 10px;
      .chaticon {
        width: 30px;
        height: 100%;
        color: #fff;
        flex-shrink: 0;
      }
      .userheader {
        position: relative;
        width: 30px;
        height: 30px;
        position: relative;
        flex-shrink: 0;
        overflow: hidden;
        img {
          width: 30px;
          height: 30px;
        }
        .vip {
          position: absolute;
          right: 0px;
          bottom: 0px;
          color: gold;
          font-size: 12px;
        }
      }
      .chatcontent {
        width: 100%;
        height: 100%;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 12px;
        justify-content: space-between;
        .name {
          color: #333;
          .online {
            display: inline-block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
          }
        }
        .time {
          color: #999;
        }
      }
    }
  }
  .empty {
    color: #333;
    font-size: 12px;
    margin-top: 100px;
  }
}
</style>