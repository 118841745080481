<template>
  <div class="invest_area">
    <div class="invest_area_top">
      <div class="invest_area_top_icon">
        <img
          src="@/assets/images/phone/topback_black.png"
          alt=""
          @click="back"
        />
      </div>
      <span>{{ $i18n.t("home.upgrade") }}</span>
    </div>
  </div>
  <div class="pay_number">
    <div class="pay_box">
      <p>{{ $i18n.t("upgrade.actuallypaid") }}</p>
      <p class="sum">
        <span>$</span><span class="money">{{ selectedamount }}</span>
      </p>
    </div>
  </div>
  <div class="pay_way">
    <p>{{ $i18n.t("upgrade.paymentmethod") }}</p>
    <div class="pay_list">
      <div class="pay_item" @click="paytype = 1">
        <img
          class="pay_choose"
          v-if="paytype == 1"
          src="@/assets/images/phone/langeage_change.png"
          alt=""
        />
        <img
          class="pay_choose"
          v-else
          src="@/assets/images/phone/change_language.png"
          alt=""
        />
        <img class="pay_type" src="@/assets/images/pay_cropay.png" alt="" />
      </div>
      <div class="pay_item" @click="paytype = 2">
        <img
          class="pay_choose"
          v-if="paytype == 2"
          src="@/assets/images/phone/langeage_change.png"
          alt=""
        />
        <img
          class="pay_choose"
          v-else
          src="@/assets/images/phone/change_language.png"
          alt=""
        />
        <img
          class="pay_type"
          src="@/assets/images/pay_lcropay_ocal.png"
          alt=""
        />
      </div>
      <div class="pay_item" @click="paytype = 3">
        <img
          class="pay_choose"
          v-if="paytype == 3"
          src="@/assets/images/phone/langeage_change.png"
          alt=""
        />
        <img
          class="pay_choose"
          v-else
          src="@/assets/images/phone/change_language.png"
          alt=""
        />
        <div class="pay_type pay_type1">
          <img src="@/assets/images/phone/glod.png" alt="" />
          <span>{{ $t("store.credits") }}{{ $t("index.balance") }}</span>
        </div>
      </div>
    </div>
    <div style="margin: 16px">
      <van-button
        round
        block
        type="danger"
        style="background: #ff4a53 !important"
        @click="recharge"
        >{{ $t("mine.upgrade") }}</van-button
      >
    </div>
  </div>
</template>
<script>
import { ElLoading } from 'element-plus';
import { ElMessage } from 'element-plus'
export default {
  data () {
    return {
      paytype: -1,
      selectedamount: 0,
      selected: 0,
      paymessage: [
        {
          name: "paypal",
          pay_type: 1,
        },
        {
          name: "cropay",
          pay_type: 3,
        },
        {
          name: "cropay_local",
          pay_type: 0,
          order_type: 0,
        },
        {
          name: "credits",
          pay_type: 2,
        },
      ],
    }
  },
  computed: {
  },
  created () {
    this.selectedamount = this.$route.params.amounts
    this.selected = this.$route.params.selected
	this.paytype = 1
  },
  methods: {
    back () {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/' })
        return false
      } else {
        this.$router.go(-1)
      }
    },
    recharge () {
      let pay_type = this.paymessage[this.paytype];
      if (pay_type.pay_type == 2) {
        // 金币支付
        let loadingInstance = ElLoading.service({ fullscreen: true });
        this.axios.post('member/Pay/pay', {
          user_id: this.$store.state.User.id,
          gainer_id: this.$store.state.User.id,
          pay_type: pay_type.pay_type,
          income: this.selectedamount,
          order_type: 2,
          packege_id: this.selected,
        }).then(res => {
          loadingInstance.close();
          res = res.data;
          if (res.status == 200) {
            this.$message.success(this.$t('upgrade.Rechargesuccess'));
          } else {
            //this.$message.error(this.$t('upgrade.Paymentfailed'));
			this.$message.error(res.msg);
          }
        })
      } else if (pay_type.pay_type == 0 || pay_type.pay_type == 3) {
        // 跳转添加银行信息页面
        this.$router.push({
          name: 'investinfo',
          params: {
            selectedamount: this.selectedamount,
            selected: this.selected,
            paytype: this.paytype,
            order_type: 2,
          }
        })
      } else if (pay_type.pay_type == 1) {
        // paypal
        let loadingInstance = ElLoading.service({ fullscreen: true });
        this.axios.post('member/Pay/pay', {
          user_id: this.$store.state.User.id,
          gainer_id: this.$store.state.User.id,
          pay_type: pay_type.pay_type,
          income: this.selectedamount,
          order_type: 2,
          packege_id: this.selected,
        }).then(res => {
          loadingInstance.close();
          res = res.data;
          // 金币充值
          if (res.status == 200) {
            location.assign(res.url)
            // window.open(res.url);
          } else {
            //this.$message.error(this.$t('upgrade.Paymentfailed'));
			this.$message.error(res.msg);
          }
        })
      }
    },

  },
}
</script>
<style lang="scss" scoped>
.invest_area {
  width: 100%;
  height: 20px;
  padding: 20px;
  color: #303133;
  position: relative;
}
.invest_area_top_icon {
  position: absolute;
  height: 20px;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
}
.invest_area_top_icon img:nth-of-type(1) {
  width: 20px;
  height: 16px;
  margin-right: 15px;
}
.invest_area_top_icon img:nth-of-type(2) {
  width: 25px !important;
  height: 25px !important;
  top: 10px;
}
.invest_area_top {
  text-align: center;
  font-size: 18px;
  height: 20px;
  line-height: 20px;
}
.pay_number {
  width: 92%;
  height: 200px;
  border-radius: 10px;
  margin: 20px auto;
  background: #ffffff;
}
.pay_box {
  padding: 54px;
}
.pay_box > p:nth-of-type(1) {
  font-size: 16px;
  color: #303133;
  text-align: center;
}
.pay_box > p:nth-of-type(2) {
  text-align: center;
  color: #ff4a53;
  margin-top: 20px;
}
.sum > span:nth-of-type(1) {
  font-size: 25px;
}
.sum > span:nth-of-type(2) {
  font-size: 40px;
  margin-left: 5px;
}
.pay_way {
  width: 92%;
  border-radius: 10px;
  background: #ffffff;
  margin: 0 auto 40px auto;
}
.pay_way > p:nth-of-type(1) {
  font-size: 12px;
  color: #bfc2cc;
  padding: 20px 0 0px 10px;
}
.pay_item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.pay_choose {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.pay_type {
  width: 130px;
  height: 50px;
  display: flex;
  align-items: center;
}
.pay_type1 {
  width: auto;
}
.pay_type img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
</style>