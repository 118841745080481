<template>
  <div class="flexbox-s trenditem">
    <a
      @click="
        util.checkPermisionAndPushRouter(
          '/w/home/user/detail/' + data.mid,
          'View_profiles'
        )
      "
      class="flexbox useravatar"
    >
      <img :src="imgurl + data.avatar" />
      <img class="vip" src="@/assets/images/icon-dimand.png" v-if="data.vip == 1"/>
	  <img class="vip" src="@/assets/images/icon-king.png" v-if="data.vip == 2"/>
    </a>
    <div class="flexbox trendcontent">
      <div class="flexbox-s textsector">
        <a
          @click="
            util.checkPermisionAndPushRouter(
              '/w/home/user/detail/' + data.mid,
              'View_profiles'
            )
          "
          class="username"
        >
          {{ data.username }}
        </a>
        <!-- 用户发布得动态 -->
        <div v-if="data.type == 1">
          <ExpText :text="data.content" />
        </div>
        <!-- 加入了网站 -->
        <div v-if="data.type == 0">
          {{ $t("trend.joined") }}
        </div>
        <!-- 更换了头像 -->
        <div v-if="data.type == 2">
          {{ $t(`trend.avatar${data.sex}`) }}
        </div>
      </div>
      <div
        class="flexbox-s imagesector"
        v-if="data.img && data.img != 'null' && data.img != 'undefined'"
      >
        <div class="img">
          <el-image :src="url" lazy :preview-src-list="[url]"></el-image>
        </div>
      </div>
    </div>
    <div class="flexbox trendtime">
      <div class="report" @click="report">
        {{ $t("user.report") }}
      </div>
      <div class="time">
        {{ util.getTrendtimeByDate(new Date(data.create_time)) }}
      </div>
    </div>
  </div>
</template>

<script>
import ExpText from "../Expression.vue"
export default {
  props: ["data"],
  computed: {
    date () {
      return $i18n.locale;
    },
    url () {
      return this.imgurl + this.data.img
    }
  },
  methods: {
    report () {
      this.$store.commit('report', this.data.user);
    },
  },
  components: {
    ExpText
  },
}
</script>

<style lang="scss" scoped>
.trenditem {
  flex-wrap: nowrap;
  margin-bottom: 10px;
  .useravatar {
    width: 50px;
    height: 50px;
    background-color: rgb(173, 140, 173);
    border-radius: 4px;
    overflow: hidden;
    flex-shrink: 0;
    cursor: pointer;
    position: relative;
    .vip {
      position: absolute;
      right: 2px;
      bottom: 0px;
      font-size: 20px;
	  width:18px;
	  height:18px;
    }
    img {
      width: 44px;
      height: 44px;
      border-radius: 3px;
    }
  }
  .trendcontent {
    padding: 0 5px;
    color: #fff;
    .textsector {
      margin-bottom: 10px;
      .username {
        color: $linkColor;
        padding: 0 5px;
        font-weight: bold;
        cursor: pointer;
      }
      .username:hover {
        text-decoration: underline;
      }
    }
    .imagesector {
      padding: 0 10px;
      margin-bottom: 10px;
      .img {
        max-width: 200px;
        height: auto;
      }
    }
  }
  .trendtime {
    color: $themeColor;
    width: auto;
    min-height: 50px;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;
    flex-shrink: 0;
    .report {
      display: none;
      width: 100%;
      font-size: 12px;
      text-align: right;
      cursor: pointer;
    }
  }
}
.trenditem:hover {
  .trendtime {
    align-content: space-between;
    .report {
      display: block;
    }
  }
}
</style>