<template>
    <div class="invest_area">
        <div class="invest_area_top">
            <div class="invest_area_top_icon">
                <img src="@/assets/images/phone/topback_black.png" alt="" @click="back">
                <img src="@/assets/images/phone/home_black.png" alt="" @click="gohome">
            </div>
            <span>{{ $t("user.setup.languge") }}</span>
            <div class="invest_area_top_save" @click="save">
                <el-button type="primary" style="background:#ff4a53;border:1px solid #ff4a53"> {{$i18n.t('user.edit.save')}}</el-button>
            </div>
        </div>
    </div>
    <div class="menuList">
        <div class="menuItem" @click="avatardrawer = true">
            <div class="left"> English</div>
            <div class="left" @click="setlang('en')" >  
                <img v-if="chooselang == 'English'" src="@/assets/images/phone/langeage_change.png" alt="">
                <img v-else src="@/assets/images/phone/change_language.png" alt="">
            </div>
        </div>
        <div class="menuItem">
            <div class="left"> 简体中文 </div>
            <div class="left"  @click="setlang('zhcn')">
                <img v-if="chooselang == '简体中文'" src="@/assets/images/phone/langeage_change.png" alt="">
                <img v-else src="@/assets/images/phone/change_language.png" alt="">
            </div>
        </div>
        <div class="menuItem">
            <div class="left"> 繁體中文 </div>
            <div class="left"  @click="setlang('zhtw')">
                <img v-if="chooselang == '繁體中文'" src="@/assets/images/phone/langeage_change.png" alt="">
                <img v-else src="@/assets/images/phone/change_language.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    data () {
        return {
            chooselang:'',
            lang:'',
            postlang:'',
        }
    },
    computed: {
        
    },
    created () {
        this.initData();
    },
    methods:{
        back(){
            if (window.history.length <= 1) {
                this.$router.push({path:'/'})
                return false
            } else {
                this.$router.go(-1)
            }
        },
        gohome(){
            this.$router.push({path:'/'})
        },
        initData () {
            let lang = this.$i18n.locale;
            if (lang === "en") {
                this.lang = "English";
                this.chooselang= "English";
            } else if (lang === "zhcn") {
                this.lang = "简体中文";
                this.chooselang= "简体中文";
            } else if (lang === "zhtw") {
                this.lang = "繁體中文";
                this.chooselang= "繁體中文";
            }
        },
        setlang (lang) {
            if (lang === "en") {
                this.chooselang = "English";
                this.postlang = "en";
            } else if (lang === "zhcn") {
                this.chooselang= "简体中文";
                this.postlang= "zhcn";
            } else if (lang === "zhtw") {
                this.chooselang= "繁體中文";
                this.postlang= "zhtw";
            }
        },
        save(){
            this.$i18n.setLang(this.postlang);
            this.$store.commit("changeLang", this.postlang);
            this.back()
        },
        
    },
}
</script>

<style lang="scss" scoped>
.invest_area{
    width: 100%;
    height: 20px;
    padding: 20px;
    color: #303133;
    position: relative;
}
.invest_area_top_icon{
    position: absolute;
    height: 20px;
    left: 20px;
    top: 20px;
    display: flex;
    align-items: center;
}
.invest_area_top_save{
    position: absolute;
    height: 20px;
    right: 20px;
    top: 20px;
    display: flex;
    align-items: center;
}
.invest_area_top_icon  img:nth-of-type(1) {
    width: 20px;
    height: 16px;
    margin-right: 15px;
}
.invest_area_top_icon  img:nth-of-type(2) {
    width: 25px!important;
    height: 25px!important;
    top: 10px;
}
.invest_area_top{
    text-align: center;
    font-size: 18px;
    height: 20px;
    line-height:20px;
}

.menuList {
    padding: 20px 0 100px;
}
.menuItem {
    display: flex;
    justify-content: space-between;
    margin: 0px 15px;
    padding: 15px 0px;
    align-items: center;
    border-bottom: 1px solid #EDEFF2;
}
.menuItem .left {
    display: flex;
    align-items: center;
    color: #909399;
    font-size: 14px;
}
.menuItem .left .ico {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    border: 1px solid #f1f1f1;
}
.menuItem .left img {
    width: 20px;
    height: 20px;
}
.langtxt{
    display: inline-block;
    float: right;
    margin-right: 10px;
    color: #000;
}
.logout{
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 100;
    background: #fff;
    height: 60px;
    padding:0px 16px;
}
.draweritem{
    text-align: center;
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #f4f4f4;
}
.el-drawer.btt{
    height: auto !important;
}
</style>