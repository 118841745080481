<template>
  <div class="banner">
    <div class="swiper-container" v-if="wall.length > 0">
      <div class="swiper-wrapper">
        <div v-for="(item, index) of wall" :key="index" class="swiper-slide">
          <el-popover
            placement="top"
            width="350"
            trigger="hover"
            :show-after="500"
          >
            <template #reference>
              <div
                @click="$router.push(`/w/home/user/detail/${item.id}`)"
                class="swiper-slide-image"
                :style="{ backgroundImage: `url(${imgurl + item.avatar})` }"
              >
                <i
                  v-if="item.vip != 0"
                  class="iconfont icon-V vip"
                  :style="{ color: item.vip == 1 ? 'silver' : 'gold' }"
                ></i>
              </div>
            </template>
            <UserPopCard
              :data="item"
              @follow="item.is_like = item.is_like == 0 ? 1 : 0"
            />
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserPopCard from "./UserPopCard.vue"
export default {
  props: ['wall'],
  components: {
    UserPopCard,
  },
  methods: {
    initSwiper () {
      this.swiper = new Swiper('.swiper-container', {
        loop: true,
        autoplay: true,
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
          observer: true,
          observeSlideChildren: true,
        },
      })
    },
  },
  updated () {
    this.initSwiper();
  }
}
</script>

<style lang="scss" scoped>
.banner {
  height: 300px;
  .swiper-container {
    width: 80%;
    padding: 40px 0;
  }
  .swiper-slide {
    width: 220px;
    height: 220px;

    .swiper-slide-image {
      position: relative;
      background-position: center;
      background-size: cover;
      width: 220px;
      height: 220px;
      border: 10px solid #fff;
      .vip {
        position: absolute;
        right: 5px;
        bottom: 5px;
        font-size: 30px;
      }
    }
  }
}
</style>