function getisLogin() {
  if (localStorage.getItem("token")) {
    return true;
  } else {
    return false;
  }
}

const User = {
  state() {
    return {
      isLogin: getisLogin(),
      id: localStorage.getItem("id") || "",
      avatar: localStorage.getItem("avatar") || "",
      token: localStorage.getItem("token") || "",
      username: localStorage.getItem("username") || "",
      gender: localStorage.getItem("gender") || 1,
      birthday: localStorage.getItem("birthday") || "",
      email: localStorage.getItem("email") || "",
      country: localStorage.getItem("country") || "",
      vip: localStorage.getItem("vip") || "",
      signature: localStorage.getItem("signature") || "",
      // 余额
      amount: localStorage.getItem("amount") || "",
      // 邀请码
      invitation_code: localStorage.getItem("invitation_code") || "",
      // 动态条数
      newfeedcount: localStorage.getItem("newfeedcount") || "",
      // 银卡会员到期时间戳
      silver_deadline: localStorage.getItem("newfeedcount") || "",
      // 金卡会员到期时间戳
      gold_deadline: localStorage.getItem("newfeedcount") || "",
      // 审核状态
      status: localStorage.getItem("status") || "",
      authority: {
        Allow_profile_wall_posts: false,
        Search_users: false,
        View_profiles: false,
        Auth_message: false,
        Allow_comments_on_newsfeed: false,
        Dynamic_unrestricted: false,
        Allows_newsfeed_to_be_sent: false,
        Upload_photo: false,
        View_photo: false,
        Comment_photo: false,
        Add_video: false,
        View_video: false,
        Comment_video: false,
        Start_mail_dialog: false,
        Continue_mail_dialog: false,
        Read_chat_messages: false,
        Send_chat_messages_for_free: false,
        Start_chat_dialog: false,
        Read_mails: false,
        Continue_chat_dialog: false,
        Free_use_of_the_translation_function: false,
      },
	  
    };
  },
  mutations: {
    login(state, data) {
      localStorage.setItem("token", data.token);
      state.token = data.token;
      state.isLogin = true;
      state.id = data.id;
      localStorage.setItem("id", data.id);
      state.username = data.username;
      localStorage.setItem("username", data.username);
      state.avatar = data.avatar;
      localStorage.setItem("avatar", data.avatar);
      state.gender = data.sex;
      localStorage.setItem("gender", data.sex);
      state.birthday = data.birthday;
      localStorage.setItem("birthday", data.birthday);
      state.email = data.email;
      localStorage.setItem("email", data.email);
      state.country = data.country;
      localStorage.setItem("country", data.country);
      state.vip = data.vip;
      localStorage.setItem("vip", data.vip);
      state.signature = data.signature;
      localStorage.setItem("signature", data.signature);
      state.amount = data.amount;
      localStorage.setItem("amount", data.amount);
      state.invitation_code = data.invitation_code;
      localStorage.setItem("invitation_code", data.invitation_code);
      state.newfeedcount = data.newfeedcount;
      localStorage.setItem("newfeedcount", data.newfeedcount);
      state.silver_deadline = data.overduedate_1;
      localStorage.setItem("silver_deadline", data.overduedate_1);
      state.gold_deadline = data.overduedate_2;
      localStorage.setItem("gold_deadline", data.overduedate_2);
      state.status = data.status;
      localStorage.setItem("status", data.status);
    },
    logout(state) {
      state.token = "";
      state.isLogin = false;
      localStorage.setItem("token", "");
      state.id = "";
      localStorage.setItem("id", "");
      state.username = "";
      localStorage.setItem("username", "");
      state.avatar = "";
      localStorage.setItem("avatar", "");
      state.gender = "";
      localStorage.setItem("gender", 1);
      state.birthday = "";
      localStorage.setItem("birthday", "");
      state.email = "";
      localStorage.setItem("email", "");
      state.country = "";
      localStorage.setItem("country", "");
      state.vip = "";
      localStorage.setItem("vip", "");
      state.signature = "";
      localStorage.setItem("signature", "");
      state.amount = "";
      localStorage.setItem("amount", "");
      state.invitation_code = "";
      localStorage.setItem("invitation_code", "");
      state.newfeedcount = "";
      localStorage.setItem("newfeedcount", "");
      state.silver_deadline = "";
      localStorage.setItem("silver_deadline", "");
      state.gold_deadline = "";
      localStorage.setItem("gold_deadline", "");
      state.status = "";
      localStorage.setItem("status", "");
    },
    updateUserInfo(state, data) {
      state.id = data.id;
      localStorage.setItem("id", data.id);
      state.username = data.username;
      localStorage.setItem("username", data.username);
      state.avatar = data.avatar;
      localStorage.setItem("avatar", data.avatar);
      state.gender = data.sex;
      localStorage.setItem("gender", data.sex);
      state.birthday = data.birthday;
      localStorage.setItem("birthday", data.birthday);
      state.email = data.email;
      localStorage.setItem("email", data.email);
      state.country = data.country;
      localStorage.setItem("country", data.country);
      state.vip = data.vip;
      localStorage.setItem("vip", data.vip);
      state.signature = data.signature;
      localStorage.setItem("signature", data.signature);
      state.amount = data.amount;
      localStorage.setItem("amount", data.amount);
      state.invitation_code = data.invitation_code;
      localStorage.setItem("invitation_code", data.invitation_code);
      state.newfeedcount = data.newfeedcount;
      localStorage.setItem("newfeedcount", data.newfeedcount);
      state.silver_deadline = data.overduedate_1;
      localStorage.setItem("silver_deadline", data.overduedate_1);
      state.gold_deadline = data.overduedate_2;
      localStorage.setItem("gold_deadline", data.overduedate_2);
      state.status = data.status;
      localStorage.setItem("status", data.status);
    },
    setUserAuthority(state, data) {
	  for (const jp in state.authority) {
		localStorage.setItem(jp, 'false');
      }
      for (const item of data) {
        state.authority[item] = true;
		localStorage.setItem(item, 'true');
      }
	  
    },
  },
  actions: {},
  getters: {
    userid(state) {
      return state.id;
    },
  },
};

export default User;
