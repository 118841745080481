import axios from "../util/axios";
export default {
  state() {
    return {
      message: [],
      unread: [],
    };
  },
  mutations: {
    setNoticeMessage(state, data) {
      state.message = data;
    },
    setNoticeUnready(state, data) {
      let unread = [];
      for (const item of state.message) {
        if (item.status == 0) {
          unread.push(item);
          if (unread.length >= 5) {
            break;
          }
        }
      }
      state.unread = unread;
    },
  },
  actions: {
    getNotice({ commit }) {
      axios.post_form("/member/index/senotice", {}).then((res) => {
        res = res.data;
        if (res.status == 200) {
          let unread = [];
          for (const item of res.data) {
            if (item.type == 0) {
              unread.push(item);
              if (unread.length >= 3) {
                break;
              }
            }
          }
          commit("setNoticeMessage", res.data);
          commit("setNoticeUnready");
        }
      });
    },
  },
};
