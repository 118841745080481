<template>
  <div class="flexbox-c homecontentbox">
    <div class="fullradiusbox bannerbox">
      <div class="flexbox title">
        <i class="iconfont icon-yonghu-fuben"></i>
        {{ $t("index.member") }}
      </div>
      <Wall :wall="wall" />
    </div>

    <div class="flexbox-c indexbody">
      <div class="lefecolumn">
        <div class="fullradiusbox">
          <div class="flexbox title">
            <i class="iconfont icon-xiangqingon"></i>
            {{ $t("index.mycredits") }}
          </div>
          <div class="accountbox">
            <div class="flexbox desc">{{ $t("index.balance") }}</div>
            <div class="flexbox acount">{{ amount }}</div>
            <div class="flexbox btncontainer">
              <router-link to="/w/home/pay" tag="div" class="flexbox btn">
                {{ $t("index.pay") }}
              </router-link>
            </div>
          </div>
        </div>

        <div class="fullradiusbox">
          <div class="flexbox title">
            <i class="iconfont icon-yonghu-fuben"></i>
            {{ $t("index.user") }}
          </div>
          <div class="flexbox communitybox">
            <div class="flexbox btncontainer" style="margin-top: 0">
              <div
                class="flexbox btn"
                style="border-right: none"
                :class="{ active: member == 'latest' }"
                @click="member = 'latest'"
              >
                {{ $t("index.latest") }}
              </div>
              <div
                class="flexbox btn"
                @click="member = 'online'"
                :class="{ active: member == 'online' }"
              >
                {{ $t("index.online") }}
              </div>
            </div>
            <div class="flexbox userbox" v-if="memberList.length > 0">
              <div
                class="flexbox useravatar"
                v-for="(item, index) of memberList"
                :key="index"
              >
                <el-popover
                  placement="top"
                  width="350"
                  trigger="hover"
                  :show-after="500"
                >
                  <template #reference>
                    <img :src="imgurl + item.avatar" />
                  </template>
                  <UserPopCard
                    :data="item"
                    @follow="item.is_like = item.is_like == 0 ? 1 : 0"
                  />
                </el-popover>
                <i
                  v-if="item.vip != 0"
                  class="iconfont icon-V vip"
                  :style="{ color: item.vip == 1 ? 'silver' : 'gold' }"
                ></i>
              </div>
            </div>
            <div class="flexbox userbox" v-else>{{ $t("empty.nouser") }}</div>
            <div class="flexbox btncontainer">
              <router-link
                :to="
                  member == 'latest'
                    ? '/w/home/member/latest'
                    : '/w/home/member/online'
                "
                tag="div"
                class="flexbox btn"
              >
                {{ $t("index.viewall") }}
              </router-link>
            </div>
          </div>
        </div>
		
      </div>

      <div class="rightcolumn">
        <div class="halfradiusbox">
          <div class="flexbox title">
            <i class="iconfont icon-dongtai2"></i>
            {{ $t("index.trend") }}
          </div>
          <div class="flexbox-s trendbox">
            <Trend
              v-for="(item, index) of trendList"
              :key="index"
              :data="item"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 邀请链接 -->
  <el-dialog :title="$t('index.invite')" v-model="inviteShow">
    <div class="detailcontent">
      <el-input v-model="invitepath" readonly>
        <template #prepend>
          {{ $store.state.User.username }}
        </template>
        <template #append>
          <el-button @click="copy">{{ $t("index.copy") }}</el-button>
        </template>
      </el-input>
    </div>
  </el-dialog>
</template>

<script>
import UserPopCard from "../../../components/web/UserPopCard.vue"
import PopUser from "../../../components/web/PopUser.vue"
import Wall from "../../../components/web/Wall.vue"
import "../../../assets/css/card.css"
import Trend from "../../../components/web/Trend.vue";
import { ElMessage } from 'element-plus'
export default {
  data () {
    return {
      swiper: null,
      trendList: [],
      member: "latest",
      inviteShow: false,
      invitepath: window.location.protocol + '//' + window.location.hostname + '#/invitation/' + this.$store.state.User.invitation_code,
    }
  },
  components: {
    UserPopCard,
    Trend,
    PopUser,
    Wall,
  },
  computed: {
    amount () {
      return this.$store.state.User.amount;
    },
    onlineList () {
      return this.$store.state.Member.onlineList;
    },
    latestList () {
      return this.$store.state.Member.latestList;
    },
    photo () {
      return this.$store.state.Photo.photos;
    },
    wall () {
      return this.$store.state.Wall.wall;
    },
    memberList () {
      if (this.member == 'latest') {
        return this.latestList;
      } else {
        return this.onlineList;
      }
    },

  },
  methods: {
    // 动态
    getTrendList () {
      this.axios.post_form("/member/trend/trend", {}).then(res => {
        res = res.data;
        if (res.status == 200) {
          this.trendList = res.data;
        }
      })
    },

    initData () {
      // 初始化动态
      this.getTrendList();
      // 初始化在线、最近用户
      this.$store.dispatch('getLatestList');
      this.$store.dispatch('getOnlineList');
      this.$store.dispatch('getPhoto');
      this.$store.dispatch('getWall');
      this.$store.dispatch('updateUserInfo');
    },
    copy () {
      // 创建一个 Input标签
      const cInput = document.createElement('input');
      cInput.value = this.invitepath;
      document.body.appendChild(cInput);
      cInput.select();
      document.execCommand('Copy');
      cInput.remove();
      ElMessage.success(this.$t('tips.copysuccess'));
    }
  },
  created () {
    this.initData();
  },

}
</script>

<style lang="scss" scoped>
.homecontentbox {
  width: 1000px;
  padding: 100px 0;
  .fullradiusbox {
    border-radius: 6px;
  }
  .halfradiusbox {
    border-radius: 6px 6px 0px 0px;
  }
  .fullradiusbox,
  .halfradiusbox {
    background: rgba(255, 255, 255, 0.2)
      url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpi/P//WRoDGmAB4ifYBF9gE/w3Kkiu4P/Bp/0/DbRTIAgQYAC2PiJjuOjlPgAAAABJRU5ErkJggg==)
      repeat-x left top;
    border: none;
    border-bottom: none;
    margin-bottom: 35px;
    .title {
      justify-content: flex-start;
      padding: 8px 10px;
      font-size: 16px;
      i {
        margin-right: 10px;
        color: $themeColor;
        font-size: 16px;
      }
    }
  }

  .bannerbox {
    width: 1000px;
  }

  .indexbody {
    width: 1000px;
    justify-content: space-between;
    .lefecolumn {
      width: 400px;
    }
    .rightcolumn {
      width: 580px;
      .trendbox {
        font-size: 14px;
        padding: 10px;
      }
    }
  }

  .accountbox {
    .desc {
      font-size: 12px;
      color: #fff;
      margin-top: 10px;
    }
    .acount {
      font-size: 20px;
      color: $themeColor;
      margin-top: 5px;
      font-weight: bold;
    }
    .btncontainer {
      margin-top: 15px;
      justify-content: flex-end;
      align-content: flex-end;
      align-items: flex-end;
      padding: 10px;
      .btn {
        width: auto;
        padding: 3px 8px;
        color: #fff;
        font-size: 12px;
        background-color: $fontColor;
        border-radius: 2px;
        border: 1px solid $themeColor;
        cursor: pointer;
      }
    }
  }

  .communitybox {
    .btncontainer {
      margin-top: 15px;
      justify-content: flex-end;
      align-content: flex-end;
      align-items: flex-end;
      padding: 10px;
      .btn {
        width: auto;
        padding: 3px 8px;
        color: #fff;
        font-size: 12px;
        background-color: $fontColor;
        border-radius: 2px;
        border: 1px solid $themeColor;
        cursor: pointer;
      }
      .active {
        background-color: $themeColor;
      }
    }
    .userbox {
      padding: 10px 5px;
      color: #fff;
      font-size: 14px;
      .useravatar {
        width: 50px;
        height: 50px;
        background-color: #bd9cbd;
        border-radius: 4px;
        overflow: hidden;
        flex-shrink: 0;
        margin: 5px;
        position: relative;
        .vip {
          position: absolute;
          right: 2px;
          bottom: 0px;
          font-size: 20px;
        }
        img {
          width: 44px;
          height: 44px;
          border-radius: 3px;
        }
      }
    }
  }

  .invitebox {
    img {
      width: 45px;
      height: 45px;
      margin-top: 10px;
    }
    .btncontainer {
      margin-top: 15px;
      justify-content: flex-end;
      align-content: flex-end;
      align-items: flex-end;
      padding: 10px;
      .btn {
        width: auto;
        padding: 3px 8px;
        color: #fff;
        font-size: 12px;
        background-color: $fontColor;
        border-radius: 2px;
        border: 1px solid $themeColor;
        cursor: pointer;
      }
    }
  }

  .photobox {
    padding: 10px 0;
    .photoitem {
      width: 60px;
      height: 60px;
      margin: 5px;
    }
  }
}
</style>