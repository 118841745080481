<template>
  <div class="flexbox diy-radio-box" :style="boxstyle">
    <div class="diy-radio-inner" :style="innerstyle" v-if="ischecked"></div>
  </div>
</template>

<script>
export default {
  props: ['color', 'ischecked', 'width'],
  computed: {
    boxstyle () {
      return {
        width: this.width ? this.width : '14px',
        height: this.width ? this.width : '14px',
        borderColor: this.color ? this.color : '#9c6d97',
        borderWidth: this.width ? this.width * 0.2 + 'px' : '2px',

      }
    },
    innerstyle () {
      return {
        backgroundColor: this.color ? this.color : '#9c6d97',
      }
    }
  }
}
</script>

<style scoped>
.diy-radio-box {
  border-radius: 50%;
  border-style: solid;
  flex-shrink: 0;
}
.diy-radio-inner {
  width: 75%;
  height: 75%;
  border-radius: 50%;
}
</style>