<template>
  <van-search
    v-model="searchcontent"
    :placeholder="$i18n.t('member.search')"
    @search="onSearch"
  />
  <div class="flexbox chatlist">
    <div
      class="flexbox"
      v-for="(item, index) of chatContentList"
      :key="index"
      @click="setCur(item.uid)"
    >
      <van-swipe-cell>
        <div class="flexbox chatitem">
          <div class="avatar" v-if="item.count > 0">
            <van-badge :content="item.count" max="99">
              <img :src="imgurl + item.avatar" alt="" class="avatar" />
            </van-badge>
          </div>
          <img :src="imgurl + item.avatar" alt="" class="avatar" v-else />
          <div class="flexbox usermsg">
            <div class="flexbox msgbox">
              <div class="username">
                <img class="vip" src="@/assets/images/icon-dimand.png" v-if="item.vip == 1"/>
				<img class="vip" src="@/assets/images/icon-king.png" v-if="item.vip == 2"/>
                {{ item.username }}
              </div>
              <div class="chatcontent">
                <Expression :text="item.content" :singleLine="true" />
              </div>
            </div>
            <div class="flexbox onlinebox">
              <div
                class="circle"
                :class="{
                  online: item.is_online == 1,
                  offline: item.is_online == 0,
                }"
              ></div>
            </div>
          </div>
        </div>

        <template #right>
          <div class="flexbox delete" @click="deleteuser(item.uid)">
            <van-icon name="delete-o" color="#fff" size="24px" />
          </div>
        </template>
      </van-swipe-cell>
    </div>
  </div>
</template>

<script>
import Expression from "../../../../components/Expression.vue"
import Menu from '../../../../components/mobile/Menu.vue'
export default {
  data () {
    return {
      searchcontent: '',
	  myvip:0,
    }
  },
  components: {
    Expression,
    Menu,
  },
  methods: {
    onSearch () {

    },
    setCur (id) {
	  this.myvip = localStorage.getItem("currentVip");
	  if(this.myvip == 0 && id != 2){
		this.$message.success(this.$t('tips.nopermission')+', '+this.$t('tips.needupgrade')+'!');
		setTimeout(() => {
			this.$router.push('/m/home/Upgrade/index')
		},1500);
		return;
	  }
      this.$store.commit('setCur', id);
      this.$store.dispatch('wssend', {
        opt: 0,
        uid: this.$store.state.User.id,
        address: id,
        origin: true,
      });
      this.$store.commit('clearcount', id);
	  if(id == 2){
		this.$router.push('/m/home/chat/service')
		return;
	  }
      this.$router.push('/m/home/chat/index');
    },
    deleteuser (address) {
      this.$store.dispatch('wssend', {
        opt: 5,
        uid: this.$store.state.User.id,
        address: address,
        origin: true,
      })
    },
  },
  computed: {
    chatContentList () {
      let reg = new RegExp(`(${this.searchcontent})`, 'g');
      return this.$store.state.Chat.chatList.filter((item) => {
        return reg.test(item.username);
      });
    },
  },
  created () {
    //查看是否有权限
	//this.axios.post_form("/member/index/info", {}).then((res) => {
	//	res = res.data;
	//	if (res.status == 200) {
	//		this.myvip = res.data.vip;
	//	}
	//}); 
	this.myvip = localStorage.getItem("currentVip");
	
  },
}
</script>

<style lang="scss" scoped>
.van-swipe-cell {
  width: 100%;
}
.chatlist {
  padding-bottom: 60px;
  .chatitem {
    padding: 11px 15px;
    flex-wrap: nowrap;
    .avatar {
      width: 52px;
      height: 52px;
      border-radius: 50%;
      flex-shrink: 0;
    }
    .usermsg {
	  width:85%;
      height: 52px;
      flex-wrap: nowrap;
	  justify-content:flex-start;
      .msgbox {
        height: 52px;
        padding: 0 10px;
        align-content: space-around;
		justify-content:flex-start;
        .username {
          width: 80%;
		  .vip{
			width:20px;
			float:left;
			margin-right:5px;
		  }
        }
        .chatcontent {
          width: 80%;
          font-size: 12px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .onlinebox {
        width: 50px;
        flex-shrink: 0;
        .circle {
          width: 5px;
          height: 5px;
          border-radius: 50%;
        }
        .online {
          background-color: #6f6;
        }
        .offline {
          background-color: #aaa;
        }
      }
    }
  }
}
.delete {
  background-color: #ff4a53;
  width: 74px;
  height: 74px;
}
</style>