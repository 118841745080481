<template>
  <div class="container">
    <div class="flexbox-c msgwindow">
      <div class="flexbox messagebox">
        <div
          class="flexbox item"
          v-for="(item, index) in email"
          :key="index"
          @click="showDetail(item)"
        >
          <div>
            <i class="iconfont icon-youjian" v-if="item.type == 0"></i>
            <i class="iconfont icon-xinfengdakai" v-else></i>
          </div>
          <div style="width: 100%">
            <div class="fristline">
              <div class="sendername" v-if="item.type == 0">
                <van-badge dot>
                  {{ item.sendername }}
                </van-badge>
              </div>
              <div class="sendername" v-else>
                {{ item.sendername }}
              </div>
              <div class="datetime">{{ item.create_time }}</div>
            </div>
            <div class="msgtitle">{{ item.msg }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <router-link to="/m/home/email/send" class="newmsg">
    <van-icon name="add" style="color: gold" />
  </router-link>

  <van-action-sheet v-model:show="detailShow" :title="detail.sendername">
    <div class="detailcontent">
      {{ detail.msg }}
    </div>
  </van-action-sheet>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  data () {
    return {
      sendwindowvisibility: false,
      form: {
        address: '',
        message: '',
      },
      detailShow: false,
      detail: {},
	  myvip:0,
    }
  },
  methods: {
    send () {
      if (this.util.checkPermision('Start_mail_dialog')) {
        this.axios.post_form("/member/email/released", {
          email: this.form.address,
          content: this.form.message,
        }).then(res => {
          this.sendwindowvisibility = false;
          res = res.data;
          if (res.status == 200) {
            ElMessage.success({
              message: this.$t('tips.sendsuccess'),
              type: 'success'
            });
          } else if (res.status == -1) {
            ElMessage.error(this.$t('tips.usernotexist'));
          } else if (res.status == -2) {
            ElMessage.error(this.$t('tips.usernotexist'));
          } else {
            ElMessage.error(this.$t('tips.sendfail'));
          }
        })
      } else {
        this.$message.error(this.$t("tips.nopermission")+'~^~');
      }
    },
    showDetail (data) {
	  if(data.eid == 2){
		this.$router.push('/m/home/chat/service')
		return;
	  }else if(this.myvip == 0){
		this.$message.success(this.$t('tips.nopermission')+', '+this.$t('tips.needupgrade')+'~!');
		setTimeout(() => {
			this.$router.push('/m/home/Upgrade/index')
		},1500);
		return;
	  }
      if (this.util.checkPermision('Read_mails')) {
        this.detail = data;
        this.detailShow = true;
        if (data.type == 0) {
          this.axios.post_form('/member/email/updatestatus', {
            id: data.eid,
          }).then(res => {
            res = res.data;
            if (res.status == 200) {
              data.type = 1;
              this.$store.commit("setUnready");
            }
          })
        }
      } else {
        this.$message.error(this.$t("tips.nopermission")+'^~');
      }
    },
  },
  computed: {
    email () {
      return this.$store.state.Message.message;
    },
  },
  created () {
    this.$store.dispatch('getMessage');
	//查看是否有权限
	this.axios.post_form("/member/index/info", {}).then((res) => {
		res = res.data;
		if (res.status == 200) {
			this.myvip = res.data.vip;
		}
	});
  }
}
</script>

<style lang="scss" scoped>
$boderColor: rgba(
  $color: #000,
  $alpha: 0.2,
);
$border: 1px solid $boderColor;
.msgwindow {
  width: 100%;
  background-color: rgba($color: #fff, $alpha: 0.2);
  border-radius: 4px;
  overflow: hidden;
  padding-bottom: 60px;
  .messagebox {
    padding: 0 15px;
    color: $themeColor;
    font-size: 14px;
    .item {
      height: 80px;
      border-bottom: $border;
      justify-content: flex-start;
      flex-wrap: nowrap;
      cursor: pointer;
      i {
        font-size: 20px;
        flex-shrink: 0;
      }
      .fristline {
        justify-content: space-between;
        flex-wrap: nowrap;
        margin-bottom: 5px;
      }
      .sendername {
        display: inline-block;
        width: 50%;
        padding-left: 10px;
        white-space: nowrap;
        flex-shrink: 0;
        .tip {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #c00;
          margin-left: 5px;
        }
      }
      .msgtitle {
        width: 250px;
        padding-left: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .datetime {
        display: inline-block;
        width: 50%;
        padding-left: 10px;
        overflow: hidden;
        white-space: nowrap;
        flex-shrink: 0;
        color: #999;
        text-align: right;
      }
    }
  }
}
.searchbtncontainer {
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: 10px;
}
.sendtextarea {
  margin-top: 20px;
}
.detailcontent {
  width: 100%;
  padding: 10px;
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.newmsg {
  position: fixed;
  right: 10px;
  bottom: 60px;
  font-size: 50px;
  color: gold;
  cursor: pointer;
}
</style>