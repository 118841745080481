<template>
  <div class="websit">
    <div class="flexbox-c header">
      <div class="menu">
        <Navigator :signin="true" :signup="true" />
      </div>
      <div class="websittitle">MAKE YOUR LIFE MORE INTERESTING</div>
      <div class="flexbox-s loginbox">
        <img src="~@/assets/images/heart_logo.png" alt="" class="logo" />
        <router-link class="btn" to="/w/login">
          {{ $t("websit.signin") }}
        </router-link>
        <router-link class="btn" to="/w/regist">
          {{ $t("websit.join") }}
        </router-link>
      </div>
    </div>
    <div class="flexbox-c body">
      <router-view></router-view>
    </div>
    <div class="footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../../components/web/LoginFooter"
import Navigator from "../../components/web/Navigator"
export default {
  components: {
    Footer,
    Navigator
  }
}
</script>

<style lang="scss" scoped>
.websit {
  background-color: #9c6d97;
  // background-image: url(~@/assets/images/bodybg.png);
  background-image: linear-gradient(
    rgb(138, 126, 169),
    rgb(158, 118, 158),
    rgb(154, 114, 154)
  );
  background-position: left top;
  background-repeat: repeat-x;
}
.header {
  width: 100%;
  height: 500px;
  background-image: url(~@/assets/images/promo2.jpg);
  background-size: cover;
  background-position: center center;
  align-content: space-between;
  .menu {
    width: auto;
    height: auto;
    position: fixed;
    right: 0px;
    top: 0px;
  }
  .websittitle {
    color: #fff;
    font-size: 50px;
    margin-top: 150px;
    padding-right: 100px;
    font-family: "Times New Roman", Times, serif;
  }
  .loginbox {
    width: 100%;
    height: 75px;
    background: rgba(255, 255, 255, 0.3);
    align-self: flex-end;
    padding-top: 20px;
    .logo {
      width: 40px;
      height: 40px;
      margin-right: 60px;
      margin-left: 15px;
    }
    .btn {
      padding-top: 10px;
      padding-right: 20px;
      font-size: 21px;
      color: $themeColor;
    }
    .btn:hover {
      text-decoration: underline;
    }
  }
}
.body {
  min-width: 1000px;
  min-height: 400px;
}
.footer {
  background-color: #b997ae;
}
</style>