<template>
  <div>
    <!-- <el-popover placement="top" width="350" trigger="hover" :show-after="500"> -->
    <!-- <template #reference> -->
    <img :src="imgurl + data.avatar" />
    <!-- </template> -->
    <!-- <UserPopCard :img="data" /> -->
    <!-- </el-popover> -->
  </div>
</template>

<script>
// import UserPopCard from "./UserPopCard.vue"
export default {
  props: 'data',
  components: {
    // UserPopCard,
  }
}
</script>

<style>
</style>