<template>
  <div>
    <div class="headBox">
      <div class="swiper-container">
        <div
          class="swiper-wrapper"
          v-if="data.backphotos && data.backphotos.length > 1"
        >
          <div class="swiper-slide" v-for="item in data.backphotos" :key="item">
            <img class="bg" :src="imgurl + item.img" alt="" srcset="" />
          </div>
        </div>
        <div class="swiper-wrapper" v-else>
          <div class="swiper-slide">
            <img
              class="bg"
              src="~@/assets/images/my/my_bg1.jpg"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
      <div class="headBox_top">
        <img src="~@/assets/images/phone/white_back.png" alt="" @click="back" />
        <img
          src="~@/assets/images/my/my_ico_bg.png"
          alt=""
          @click="avatardrawer = true"
          v-if="myId == id"
        />
      </div>
    </div>
    <div class="contBox">
      <div class="userBox">
        <div class="left">
          <span class="name">{{ data.username }}</span>
          <span class="age">
            <i
              class="iconfont icon-nan"
              v-if="data.sex == 1"
              style="color: #99f"
            ></i>
            <i
              class="iconfont icon-nv"
              v-if="data.sex == 0"
              style="color: #f4a"
            ></i
            >{{
              new Date().getFullYear() -
              new Date(parseInt(data.birthday)).getFullYear()
            }}</span
          >
        </div>
        <div class="like">
          <van-icon
            name="like-o"
            size="1.52rem"
            color="#666"
            v-if="data.is_like == 0"
            @click="follow(data)"
          />
          <van-icon
            name="like"
            size="1.52rem"
            color="#f4a"
            @click="follow(data)"
            v-else
          />
          <div>{{ data.likecount }} {{ $t("dynamic.like") }}</div>
        </div>
      </div>
    </div>
    <div class="tab">
      <div :class="['tabItem', onTab == 0 ? 'on' : '']" @click="onTab = 0">
        {{ $t("dynamic.dynamic") }}
      </div>
      <div :class="['tabItem', onTab == 1 ? 'on' : '']" @click="onTab = 1">
        {{ $t("dynamic.photo") }}
      </div>
      <div :class="['tabItem', onTab == 2 ? 'on' : '']" @click="onTab = 2">
        {{ $t("dynamic.personaldata") }}
      </div>
    </div>
    <div v-show="onTab == 0">
      <Trend v-for="(item, index) of trends" :key="index" :data="item" />
    </div>
    <div class="photoList" v-show="onTab == 1">
      <div class="item" v-for="(item, index) of photos" :key="index">
        <div class="img">
          <el-image
            :src="imgurl + item.img"
            lazy
            :preview-src-list="
              photos.map((item) => {
                return imgurl + item.img;
              })
            "
          ></el-image>
        </div>
		<a @click="delPhoto(item.pid)" style="color:#999;margin:10px auto;display:block;">
			{{ $t("dynamic.deteleone") }}
		</a>
      </div>
    </div>
    <div class="information" v-show="onTab == 2">
      <div class="title">{{ $t("regist.formtitlte") }}</div>
      <div class="sex">
        {{ $t("regist.gender") }}：{{
          data.sex == 1 ? $t("regist.male") : $t("regist.female")
        }}
      </div>
    </div>
    <!-- 图片文件 -->
    <input
      type="file"
      accept="image/*"
      v-show="false"
      @change="fileChange"
      ref="fileinput"
    />
    <input
      type="file"
      accept="image/*"
      v-show="false"
      @change="fileChange2"
      ref="fileinput2"
    />
    <div class="placeholder"></div>
    <div class="btn" v-if="onTab == 0">
      <router-link class="menuItem" to="/m/home/trend/list">
        <van-button
          round
          block
          type="danger"
          style="background: #ff4a53 !important"
        >
          {{ $t("index.trend") }}</van-button
        >
      </router-link>
    </div>
    <div class="btn" v-if="onTab == 1">
      <van-button
        round
        block
        type="danger"
        style="background: #ff4a53 !important"
        @click="$refs.fileinput2.click()"
        >{{ $t("authority.Upload_photo") }}</van-button
      >
    </div>

    <el-drawer
      v-model="avatardrawer"
      direction="btt"
      :with-header="false"
      destroy-on-close
    >
      <div class="draweritem" @click="$refs.fileinput.click()">
        {{ $t("dynamic.upload") }}
      </div>
      <div class="draweritem" @click="delBg">{{ $t("dynamic.detele") }}</div>
      <div class="draweritem">{{ $t("user.setup.setting") }}</div>
      <div class="draweritem" @click="avatardrawer = false">
        {{ $t("user.edit.cancel") }}
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Trend from "../../../components/mobile/Trend.vue";
export default {
  props: ["id"],
  data () {
    return {
      onTab: 0,
      data: "",
      trends: "",
      photos: "",
      avatardrawer: false,
      swiper: "",
      first: true,
    };
  },
  mounted: function () {
    let type = this.$route.query.type;
    if (type) {
      this.onTab = type - 1;
    }
  },
  computed: {
    myId () {
      return this.$store.state.User.id;
    },
  },
  components: {
    Trend,
  },
  updated () {
    if (this.first) {
      this.initSwiper();
      this.first = false;
    }
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    initSwiper () {
      this.swiper = new Swiper(".swiper-container", {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
      });
    },
	delPhoto(pid){
		this.axios
        .post_form("/member/photo/del_photo", {
          id: pid,
        })
        .then((res) => {
          res = res.data;
          if (res.status == 200) {
            this.data = res.data;
			this.$message.success(this.$t("tips.deletedsuccess"));
			this.getPhotoList();
          }
        });
	},
    delBg () {
      if (data.backphotos.length == 0) {
        return false;
      }
      this.axios
        .post_form("/member/backphoto/deletephoto", {
          id: this.data.backphotos[this.swiper.activeIndex - 1].id,
        })
        .then((res) => {
          res = res.data;
          if (res.status == 200) {
            this.first = true;
            this.avatardrawer = false;
            this.initData();
            this.$message.success(this.$t("tips.deletedsuccess"));
          }
        });
    },
    follow ({ id, is_like }) {
      this.axios
        .post_form("/member/like/like", { target_id: id })
        .then((res) => {
          res = res.data;
          if (res.status == 200) {
            this.UpdateLike();
            if (is_like == 0) {
              this.$message.success(this.$t("tips.likesuccess"));
            } else {
              this.$message.success(this.$t("tips.likecancel"));
            }
            this.$emit("follow");
          }
        });
    },
    UpdateLike () {
      this.axios
        .post_form("/member/index/userdetail", {
          id: this.id,
        })
        .then((res) => {
          res = res.data;
          if (res.status == 200) {
            this.data.is_like = res.data.is_like;
            this.data.likecount = res.data.likecount;
          }
        });
    },
    // 获取动态
    getTrend () {
      this.axios
        .post_form("/member/trend/his_trend", {
          userid: this.id,
        })
        .then((res) => {
          res = res.data;
          if (res.status == 200) {
            this.trends = res.data;
          }
        });
    },
    // 获取用户信息
    initData () {
      this.axios
        .post_form("/member/index/userdetail", {
          id: this.id,
        })
        .then((res) => {
          res = res.data;
          if (res.status == 200) {
            this.data = res.data;
          }
        });
    },
    // 获取照片
    getPhotoList () {
      this.axios
        .post_form("/member/photo/his_photo", {
          userid: this.id,
        })
        .then((res) => {
          res = res.data;
          if (res.status == 200) {
            this.photos = res.data;
          }
        });
    },
    // 发送图片 背景图上传
    fileChange (e) {
      e.preventDefault();
      if (this.loading !== 1) {
        let files = e.target.files || e.dataTransfer.files;
        this.sourceImgUrl = files[0];
        this.axios
          .post_form("/member/index/uploads", {
            file: this.sourceImgUrl,
          })
          .then((res) => {
            res = res.data;
            if (res.status == 200) {
              this.axios
                .post_form("/member/backphoto/released", {
                  img: res.data.url,
                })
                .then((res) => {
                  if (res.status == 200) {
                    this.avatardrawer = false;
                    this.initData();
                    this.$message.success(this.$t("tips.savesuccess"));
                  }
                });
            }
          });
      }
    },
    fileChange2 (e) {
      e.preventDefault();
      if (this.loading !== 1) {
        let files = e.target.files || e.dataTransfer.files;
        this.sourceImgUrl = files[0];
        this.axios
          .post_form("/member/index/uploads", {
            file: this.sourceImgUrl,
          })
          .then((res) => {
            res = res.data;
            if (res.status == 200) {
              this.releasedPhoto(res);
            }
          });
      }
    },
    releasedPhoto (res) {
      this.axios
        .post_form("/member/photo/released", {
          img: res.data.url,
        })
        .then((res) => {
          if (res.status == 200) {
            this.getPhotoList();
            this.$message.success(this.$t("tips.savesuccess"));
          }
        });
    },
  },
  created () {
    // this.$store.dispatch("getPhoto");
    this.initData();
    this.getTrend();
    this.getPhotoList();
  },
};
</script>

<style scoped>
.headBox {
  width: 100%;
  height: 21rem;
  position: relative;
  /* z-index: -1; */
}
.headBox .bg {
  width: 100%;
  height: 100%;
}
.headBox_top {
  position: absolute;
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  padding: 18px;
  top: 0;
  z-index: 999;
}
.headBox_top img {
  height: 22px;
  width: 22px;
}
.swiper-container {
  height: 100%;
  width: 100%;
}
.contBox {
  background: #fff;
  border-radius: 15px 15px 0 0;
  margin-top: -35px;
  padding: 30px 20px 0;
  position: relative;
  z-index: 2;
}
.contBox .userBox {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding-bottom: 25px;
}
.contBox .userBox .name {
  font-size: 20px;
}
.contBox .userBox .age {
  font-size: 14px;
  color: #465dfe;
  margin-left: 15px;
}
.contBox .userBox .like {
  text-align: center;
  color: #8f8f94;
  font-size: 14px;
}
.contBox .userBox .like div {
  margin-top: 3px;
}
.tab {
  display: flex;
  padding: 20px;
  align-items: center;
}
.tab .tabItem {
  margin-right: 20px;
  font-size: 14px;
  position: relative;
  line-height: 35px;
  cursor: pointer;
  color: #333;
}
.tab .tabItem.on {
  font-size: 20px;
  color: #ff4a53;
}
.tab .tabItem.on::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  border-radius: 10px;
  background: #ff4a53;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 88;
}
.photoList {
  display: flex;
  padding: 14px;
  flex-wrap: wrap;
}
.photoList .item {
  width: 25%;
  text-align: center;
  padding: 0 1.5%;
  padding-bottom: 12px;
}
.photoList .item .img {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.photoList .item .el-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.information {
  padding: 15px 20px;
}
.information .title {
  color: #303133;
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 20px;
}
.information .sex {
  margin-bottom: 10px;
  color: #909399;
  font-size: 14px;
}
.placeholder {
  height: 100px;
}
.draweritem {
  text-align: center;
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #f4f4f4;
}
.swiper-pagination-fraction {
  bottom: 50px;
  background: rgba(0, 0, 0, 0.3);
  color: #ffffff;
  font-size: 14px;
  width: auto;
  padding: 8px 15px;
  border-radius: 30px;
  right: 20px;
  left: auto;
  display: inline-block;
}
.btn {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 100;
  background: #fff;
  height: 60px;
  padding: 0px 16px;
}
.btn button {
  margin-top: 5px;
}
</style>