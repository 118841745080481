<template>
  <div class="container">
    <div class="title">{{ $t("home.recharge") }}</div>
    <div class="flexbox usermsg">
      <span class="balance"> {{ $t("index.balance") }} </span>
      <i class="iconfont icon-meiyuan"></i>
      <span class="num">{{ amount }}</span>
      <Radio :ischecked="true" />
      <span class="objectuser">{{ $t("recharge.self") }}</span>
    </div>
    <div class="flexbox butoptionsbox">
      <div
        class="flexbox chargeitem"
        :class="{ checkedpackage: index == 0 }"
        @click="setSelected(0, 0)"
      >
        <Radio :ischecked="selected == 0" />
        <i class="iconfont icon-meiyuan"></i>
        <input class="input" v-model="inputamount" @input="checkmount" />
        <span class="doller"> USD</span>
      </div>
      <div
        class="flexbox chargeitem"
        :class="{ checkedpackage: index == 1 }"
        v-for="(item, index) of packages"
        :key="index"
        @click="setSelected(item.id, item.amounts)"
      >
        <Radio :ischecked="selected == item.id" />
        <i class="iconfont icon-meiyuan"></i>
        <span class="num">{{ item.amounts }}</span>
        <span class="doller"> USD</span>
      </div>
    </div>

    <!-- 支付方式 -->
    <div class="flexbox-s paytype">
      <div
        class="flexbox paytypeitem"
        :class="{ active: paytype == 1 }"
        @click="paytype = 1"
      >
        <Radio :ischecked="paytype == 1" />
        <div
          class="paylogo"
          :style="{ backgroundImage: `url(${image.pay_cropay})` }"
        ></div>
      </div>
      <div
        class="flexbox paytypeitem"
        :class="{ active: paytype == 2 }"
        @click="paytype = 2"
      >
        <Radio :ischecked="paytype == 2" />
        <div
          class="paylogo"
          :style="{ backgroundImage: `url(${image.pay_lcropay_ocal})` }"
        ></div>
      </div>
    </div>

    <div class="flexbox rechagebox">
      <div class="button" @click="recharge">{{ $t("recharge.pay") }}</div>
    </div>
  </div>

  <!-- 信用卡信息窗口 -->
  <van-overlay :show="creditshow" @click="creditshow = false">
    <div class="flexbox creditwindow">
      <div class="flexbox formbox" @click.stop>
        <div class="flexbox inputitem">
          <el-input v-model="creditform.name" :placeholder="$t('pay.ename')">
            <template #prepend>{{ $t("pay.name") }}</template>
          </el-input>
        </div>
        <div class="flexbox inputitem">
          <el-input
            v-model="creditform.email"
            :placeholder="$t('login.email')"
          >
            <template #prepend>{{ $t("login.email") }}</template>
          </el-input>
        </div>
        <div class="flexbox inputitem">
          <el-input v-model="creditform.phone" :placeholder="$t('pay.phone')">
            <template #prepend>{{ $t("pay.phone") }}</template>
          </el-input>
        </div>

        <div class="flexbox creditrechargebtncontainer">
          <div class="flexbox creditrechargebtn" :class="[clickStatus == true ? 'red' : 'grey']" @click="creditrecharge">
            {{ $t("recharge.pay") }}
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { ElLoading } from 'element-plus';
import Radio from "../../../components/web/radio.vue"
import pay_paypal from "../../../assets/images/pay_paypal.png"
import pay_cropay from "../../../assets/images/pay_cropay.png"
import pay_lcropay_ocal from "../../../assets/images/pay_lcropay_ocal.png"
import country from "../../../util/country"
export default {
  data () {
    return {
      image: {
        pay_paypal,
        pay_cropay,
        pay_lcropay_ocal,
      },
      selected: 3,
      selectedamount: 800,
      inputamount: '',
      paytype: 1,
      paymessage: [
        {
          name: "paypal",
          pay_type: 1,
        },
        {
          name: "cropay",
          pay_type: 3,
        },
        {
          name: "cropay_local",
          pay_type: 0,
          order_type: 0,
        },
        {
          name: "credits",
          pay_type: 2,
        },
      ],
      uuid: '',
      country,
      creditshow: false,
      creditform: {
        name: '',
        email: '',
        phone: '',
      },
	  clickStatus:true,
    }
  },
  components: {
    Radio,
  },
  computed: {
    packages () {
      return this.$store.state.Pay.packages;
    },
    amount () {
      return this.$store.state.User.amount;
    }
  },
  methods: {
    setSelected (id, amount) {
      this.selected = id;
      this.selectedamount = amount;
    },
    checkmount (e) {
      this.$nextTick(() => {
        let val = e.target.value.toString();
        val = val.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
        val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
        val = val.replace(/^0+\./g, '0.');
        val = val.match(/^0+[1-9]+/) ? val = val.replace(/^0+/g, '') : val
        val = (val.match(/^\d*(\.?\d{0,2})/g)[0]) || ''
        this.inputamount = val;
      });
    },
    creditrecharge () {
	  //10秒等待
	  if(this.clickStatus == false){
	    this.$message.error(this.$t('tips.patience'));
		return ;
	  }
	
	  this.clickStatus = false
	  setTimeout(()=>{
	    this.clickStatus = true
	  },10000)
	  
	  this.creditform.email = this.creditform.email.trim()
	
      if (this.creditform.name == '') {
        this.$message.error(this.$t('regist.form.required') + ' ' + this.$t('pay.name'));
        return false;
	  }else if(!/[a-zA-Z0-9]+/.test(this.creditform.name)) {
		this.$message.error(this.$t('regist.username') + ' ' + this.$t('regist.form.fomate'));
        return false;
      } else if (this.creditform.email == '') {
        this.$message.error(this.$t('regist.form.required') + ' ' + this.$t('login.email'));
        return false;
	  } else if(!/^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(this.creditform.email)) {
		this.$message.error(this.$t('regist.email') + ' ' + this.$t('regist.form.fomate'));
        return false;
      }
      
      else if (this.creditform.phone == '') {
        this.$message.error(this.$t('regist.form.required') + ' ' + this.$t('pay.phone'));
        return false;
      }
	  
	  let incomes = this.selected == 0 ? this.inputamount : this.selectedamount;
	  if(incomes == '' || incomes <= 0){
		this.$message.error(this.$t('regist.form.required') + ' ' + this.$t('tips.incomeErr'));
        return false;
	  }
      
      let pay_type = this.paymessage[this.paytype];
      let loadingInstance = ElLoading.service({ fullscreen: true });
      this.axios.post('member/Pay/pay', {
        user_id: this.$store.state.User.id,
        gainer_id: this.$store.state.User.id,
        pay_type: pay_type.pay_type,
        income: incomes,
        order_type: 1,
        packege_id: this.selected,
        card_fullName: this.creditform.name,
        card_fullPhone: this.creditform.phone,
		card_email: this.creditform.email,
        uuid: this.uuid,
      }).then(res => {
        loadingInstance.close();
        this.creditshow = false;
        res = res.data;
        if (res.status == 200) {
          if (pay_type.pay_type == 3) {
            document.write(`<iframe width="100%" height="100%" frameborder="0" src="${res.data.payment_urll}"></iframe>`)
          } else if (pay_type.pay_type == 0) {
            // this.$message.success(this.$t('upgrade.Rechargesuccess'));
            location.assign(res.data);
          }
        } else {
          //this.$message.error(this.$t('upgrade.runninglow'));
		  this.$message.error(res.msg);
        }
      })
    },
    recharge () {
      let pay_type = this.paymessage[this.paytype];
	  let incomes = this.selected == 0 ? this.inputamount : this.selectedamount;
	  if(incomes == '' || incomes <= 0){
		this.$message.error(this.$t('regist.form.required') + ' ' + this.$t('tips.incomeErr'));
        return false;
	  }
	  
      if (pay_type.pay_type == 2) {
        // 金币支付
        let loadingInstance = ElLoading.service({ fullscreen: true });
        this.axios.post('member/Pay/pay', {
          user_id: this.$store.state.User.id,
          gainer_id: this.$store.state.User.id,
          pay_type: pay_type.pay_type,
          income: incomes,
          order_type: 1,
          packege_id: this.selected,
        }).then(res => {
          res = res.data;
          // 金币充值
          if (res.status == 200) {
            this.$message.success(this.$t('upgrade.Rechargesuccess'));
          } else {
            //this.$message.success(this.$t('upgrade.Paymentfailed'));
			this.$message.error(res.msg);
          }
        })
        loadingInstance.close();
        this.creditshow = false;
      } else if (pay_type.pay_type == 0 || pay_type.pay_type == 3) {
        // cropay 
        this.creditshow = true;
      } else if (pay_type.pay_type == 1) {
        // paypal
        let loadingInstance = ElLoading.service({ fullscreen: true });
        this.axios.post('member/Pay/pay', {
          user_id: this.$store.state.User.id,
          gainer_id: this.$store.state.User.id,
          pay_type: pay_type.pay_type,
          income: incomes,
          order_type: 1,
          packege_id: this.selected,
        }).then(res => {
          loadingInstance.close();
          this.creditshow = false;
          res = res.data;
          // 金币充值
          if (res.status == 200) {
            location.assign(res.url);
          } else {
            //this.$message.error(this.$t('upgrade.Paymentfailed'));
			this.$message.error(res.msg);
          }
        })
      }
    },
    getuuid () {
      this.axios.post_form('/member/pay/generateuuid', {}).then(res => {
        res = res.data;
        if (res.status == 200) {
          this.uuid = res.data.uuid;
        }
      })
    },
  },
  created () {
    this.$store.dispatch('getPayPackage');
    this.getuuid();
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 1000px;
  padding: 60px 30px;
  background-color: #fff;
  .title {
    font-size: 20px;
    color: $themeColor;
    margin-bottom: 28px;
    font-weight: bold;
  }
  .usermsg {
    height: 30px;
    justify-content: flex-start;
    color: $fontColor;
    padding-left: 15px;
    .balance {
      font-size: 16px;
    }
    i {
      color: gold;
      font-size: 25px;
      margin-left: 10px;
    }
    .num {
      padding-left: 5px;
      padding-right: 30px;
    }

    .objectuser {
      font-size: 14px;
      padding-left: 5px;
    }
  }
  .butoptionsbox {
    width: 800px;
    border: 1px solid #ccc;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: flex-start;
    padding: 50px 0;
    .chargeitem {
      width: auto;
      height: 50px;
      min-width: 180px;
      border: 1px solid #ccc;
      margin: 10px 35px;
      border-radius: 8px;
      color: $fontColor;
      padding-left: 10px;
      padding-right: 10px;
      justify-content: flex-start;
      font-size: 14px;
      flex-wrap: nowrap;
      cursor: pointer;
      i {
        margin-left: 10px;
        font-size: 25px;
        color: gold;
        flex-shrink: 0;
      }
      .num {
        font-size: 16px;
        font-weight: bold;
        padding-left: 5px;
        flex-shrink: 0;
      }
      .input {
        width: 80px;
        height: 30px;
        border: 1px solid $fontColor;
        border-radius: 4px;
        outline: none;
        padding: 0 5px;
      }
      .doller {
        width: 100%;
        text-align: right;
      }
    }
  }
  .paytype {
    .paytypeitem {
      width: 200px;
      height: 54px;
      flex-wrap: nowrap;
      justify-content: flex-start;
      padding: 0 10px;
      border: 1px solid #dcdee1;
      border-radius: 10px;
      cursor: pointer;
      margin: 0 20px 20px 20px;
      .paylogo {
        width: 150px;
        height: 40px;
        margin-left: 20px;
        background-position: left center;
        background-repeat: no-repeat;
        background-size: contain;
        justify-content: space-around;
      }
    }
    .active {
      background: #fff2f3;
      border: 1px solid #ff4a53 !important;
    }
  }

  .rechagebox {
    justify-content: flex-end;
    .button {
      padding: 2px 15px;
      color: #fff;
      background-color: $fontColor;
      border-radius: 4px;
      margin-right: 200px;
      cursor: pointer;
    }
  }
}
.creditwindow {
  height: 100%;
  .formbox {
    width: 600px;
    padding: 30px;
    background-color: rgb(156, 109, 151);
    .inputitem {
      margin-bottom: 10px;
      justify-content: flex-start;
    }
    .creditrechargebtncontainer {
      justify-content: flex-end;
      .creditrechargebtn {
        width: auto;
        height: 30px;
        padding: 0 15px;
        color: #fff;
        border-radius: 15px;
        cursor: pointer;
      }
	  .red{
		background-color: #f33;
	  }
	  .grey{
		background-color: #999;
	  }
    }
  }
}
</style>