import { createI18n } from "vue-i18n";

import zhcn from "./zh-cn";
import zhtw from "./zn-tw";
import en from "./en";

var i18n = createI18n({
  locale: localStorage.getItem("lang") || "zhtw",
  fallbackLocale: "zhtw",
  messages: { zhcn, zhtw, en },
});

/**
 * 切换语言
 * @param {"en" | "zhcn" | "zhtw"} lang
 */
i18n.global.setLang = function(lang) {
  this.locale = lang;
  localStorage.setItem("lang", lang);
};

export default i18n;
