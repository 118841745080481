<template>
  <div class="container">
    <div class="title">{{ data.username }}</div>
    <div class="flexbox-s buttonbox" v-if="data.id">
      <div class="button" @click="addUserToChatList">
        {{ $t("user.sendemail") }}
      </div>
      <div class="button" @click="follow(data)">
        {{
          data.is_like == 0 ? $t("member.follow") : $t("member.cancelfollow")
        }}
      </div>
      <router-link to="/w/home/store/index" class="button">{{
        $t("user.gift")
      }}</router-link>
      <div class="button" @click="report">{{ $t("user.report") }}</div>
      <router-link :to="`/w/home/photo/${data.id}`" class="button">{{
        $t("user.photo")
      }}</router-link>
    </div>
    <div class="flexbox-s usercontent">
      <div class="leftcontent">
        <!-- 头像 -->
        <div class="flexbox fullradiusbox userheader">
          <img :src="imgurl + data.avatar" v-if="data.avatar" />
          <img class="vip" src="@/assets/images/icon-dimand.png" v-if="data.vip == 1"/>
		  <img class="vip" src="@/assets/images/icon-king.png" v-if="data.vip == 2"/>
          <div
            class="online"
            :style="{
              backgroundColor: data.is_online == 1 ? '#6f6' : '#aaa',
            }"
          ></div>
        </div>
      </div>
      <div class="rightcontent">
        <!-- 用户信息 -->
        <div class="flexbox usermsgbox">
          <div class="flexbox item">
            <div class="flexbox left">{{ $t("regist.username") }}</div>
            <div class="flexbox right">{{ data.username }}</div>
          </div>
          <div class="flexbox item">
            <div class="flexbox left">{{ $t("regist.gender") }}</div>
            <div class="flexbox right">{{ gender }}</div>
          </div>
        </div>
        <div class="flexbox trendbox">
          <!-- 最新动态-标题 -->
          <div class="flexbox halfradiusbox">
            <div class="flexbox title">
              <i class="iconfont icon-dongtai2"></i>
              {{ $t("index.trend") }}
            </div>
          </div>
          <!-- 最新动态-动态列表 -->
          <div class="flexbox-s trendlist">
            <Trend v-for="(item, index) of trends" :key="index" :data="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Trend from "../../../../components/web/Trend.vue";
export default {
  props: ['id'],
  data () {
    return {
      data: {},
      trends: [],
    }
  },
  watch: {
    id () {
      this.initData();
    }
  },
  computed: {
    gender () {
      return this.data.sex == 1 ? this.$t('regist.male') : this.$t('regist.female');
    }
  },
  components: {
    Trend,
  },
  methods: {
    initData () {
      this.axios.post_form('/member/index/userdetail', {
        id: this.id,
      }).then(res => {
        res = res.data;
        if (res.status == 200) {
          this.data = res.data;
        }
      })
    },
    getTrend () {
      this.axios.post_form('/member/trend/his_trend', {
        userid: this.id,
      }).then(res => {
        res = res.data;
        if (res.status == 200) {
          this.trends = res.data;
        }
      })
    },
    addUserToChatList () {
      this.$store.commit('addUserToChatList', this.data);
      this.$store.commit('setCur', this.data.id);
      this.$router.push('/w/home/chat');
    },
    follow ({ id, is_like }) {
      this.axios.post_form('/member/like/like', { target_id: id }).then(res => {
        res = res.data;
        if (res.status == 200) {
          if (is_like == 0) {
            this.$message.success(this.$t('tips.followsuccess'));
            this.data.is_like = 1;
          } else {
            this.$message.success(this.$t('tips.followcancel'));
            this.data.is_like = 0;
          }
        }
      })
    },
    report () {
      this.$store.commit('report', this.data);
    },
  },
  created () {
    if (this.id == this.$store.state.User.id) {
      this.$router.push({ path: '/w/home/user/index' })
      return;
    }
    this.initData();
    this.getTrend();
  }
}
</script>

<style lang="scss" scoped>
$boderColor: rgba(
  $color: #fff,
  $alpha: 0.2,
);
$border: 1px solid $boderColor;
.container {
  width: 1000px;
  padding: 100px 30px;
  .title {
    font-size: 20px;
    color: $themeColor;
    font-weight: bold;
  }
  .buttonbox {
    margin-top: 30px;
    .button {
      width: auto;
      padding: 3px 8px;
      color: #fff;
      font-size: 12px;
      background-color: $fontColor;
      border-radius: 2px;
      border: 1px solid $themeColor;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .usercontent {
    justify-content: space-between;
    margin-top: 30px;
    font-size: 14px;
    .leftcontent {
      width: 215px;
      .userheader {
        position: relative;
        width: 215px;
        height: 215px;
        img {
          width: 175px;
          height: 175px;
        }
        .vip {
          position: absolute;
          right: 20px;
          bottom: 20px;
          font-size: 30px;
		  width:30px;
		  height:30px;
        }
        .editbtn {
          position: absolute;
          top: 10px;
          right: 10px;
          padding: 2px 8px;
          color: $themeColor;
          cursor: pointer;
          background-color: rgba($color: $fontColor, $alpha: 0.8);
          border-radius: 4px;
        }
        .online {
          position: absolute;
          bottom: 5px;
          left: 5px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: yellowgreen;
        }
      }
      .usersign {
        width: 215px;
        margin-top: 30px;
        background-color: #fff;
        padding: 5px;
        border-radius: 6px;

        .btncontainer {
          margin-top: 20px;
        }
      }
    }
    .rightcontent {
      width: 705px;
      .usereditcontainer {
        justify-content: flex-end;
      }
      .usermsgbox {
        color: #fff;
        .item {
          height: 30px;
          .left {
            width: 30%;
            justify-content: flex-end;
            padding: 0 5px;
            border-right: 1px solid rgba($color: #fff, $alpha: 0.5);
          }
          .right {
            width: 70%;
            justify-content: flex-start;
            padding: 0 5px;
          }
        }
      }
      .trendbox {
        margin-top: 30px;
      }
      .inputbox {
        margin-top: 15px;
      }
      .trendlist {
        margin-top: 15px;
        .trenditem {
          flex-wrap: nowrap;
          .useravatar {
            width: 50px;
            height: 50px;
            background-color: rgb(173, 140, 173);
            border-radius: 4px;
            overflow: hidden;
            flex-shrink: 0;
            img {
              width: 44px;
              height: 44px;
              border-radius: 3px;
            }
          }
          .trendcontent {
            padding: 0 5px;
            color: #fff;
            .textsector {
              margin-bottom: 10px;
              .username {
                color: $linkColor;
                padding: 0 5px;
                font-weight: bold;
              }
            }
            .imagesector {
              padding: 0 10px;
              margin-bottom: 10px;
              img {
                max-width: 200px;
                height: auto;
              }
            }
          }
          .trendtime {
            color: $themeColor;
            width: auto;
            height: 50px;
            align-items: flex-end;
            align-content: flex-end;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}

.commonbtn {
  width: auto;
  height: 32px;
  justify-content: space-between;
  color: $themeColor;
  background-color: #e8cbed;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  .textbox {
    padding: 0 5px;
  }
  .iconbox {
    padding: 0 5px;
    font-size: 20px;
  }
}

.fullradiusbox {
  border-radius: 6px;
}
.halfradiusbox {
  border-radius: 6px 6px 0px 0px;
}
.fullradiusbox,
.halfradiusbox {
  background: rgba(255, 255, 255, 0.2)
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpi/P//WRoDGmAB4ifYBF9gE/w3Kkiu4P/Bp/0/DbRTIAgQYAC2PiJjuOjlPgAAAABJRU5ErkJggg==)
    repeat-x left top;
  border: none;
  border-bottom: none;
  .title {
    justify-content: flex-start;
    padding: 8px 10px;
    font-size: 16px;
    i {
      margin-right: 10px;
      color: $themeColor;
      font-size: 16px;
    }
  }
}
</style>