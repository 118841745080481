import axios from "../util/axios";
export default {
  state() {
    return {
      photos: [],
    };
  },
  mutations: {
    setPhotos(state, data) {
      state.photos = data;
    },
  },
  actions: {
    getPhoto({ commit }) {
      axios.post_form("/member/photo/my_photo", {}).then((res) => {
        res = res.data;
        commit("setPhotos", res.data);
      });
    },
  },
};
